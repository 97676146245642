import React, { MouseEventHandler, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {
  CheckCircle as ActionCheck,
  ErrorOutline as AlertError,
} from '@mui/icons-material';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';

/**
 * Confirmation dialog
 *
 * @example
 * <ConfirmDialog
 *   isOpen={true}
 *   title="Delete Item"
 *   content="Are you sure you want to delete this item?"
 *   confirm="Yes"
 *   confirmColor="primary"
 *   ConfirmIcon=ActionCheck
 *   CancelIcon=AlertError
 *   cancel="Cancel"
 *   onConfirm={() => { // do something }}
 *   onClose={() => { // do something }}
 * />
 */
export const ConfirmDialog = (props: IConfirmDialogProps) => {
  const {
    className,
    isOpen = false,
    loading,
    title,
    content,
    cancel = 'Cancel',
    confirm = 'Confirm',
    confirmColor = 'primary',
    ConfirmIcon = ActionCheck,
    CancelIcon = AlertError,
    onClose,
    onConfirm,
    translateOptions = {},
    ...rest
  } = props;

  const handleConfirm = useCallback((e: any) => {
    e.stopPropagation();
    onConfirm(e);
  }, [onConfirm]);
  const handleClick = useCallback((e: any) => e.stopPropagation(), []);

  return (
    <StyledDialog
      className={className}
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof content === 'string'
          ? (
            <DialogContentText>
              {content}
            </DialogContentText>
          ) : content
        }
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onClose}
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          className={clsx('ra-confirm', {
            [ConfirmClasses.confirmWarning]: confirmColor === 'warning',
            [ConfirmClasses.confirmPrimary]: confirmColor === 'primary',
          })}
          autoFocus
          startIcon={<ConfirmIcon />}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export interface IConfirmDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {
  cancel?: string;
  className?: string;
  confirm?: string;
  confirmColor?: string;
  ConfirmIcon?: any; //ReactComponentLike;
  CancelIcon?: any; //ReactComponentLike;
  content: string; //React.ReactNode;
  isOpen?: boolean;
  loading?: boolean;
  onClose: MouseEventHandler;
  onConfirm: MouseEventHandler;
  title: string;
  translateOptions?: object;
};

export const ConfirmClasses = {
  confirmPrimary: `RaConfirm-confirmPrimary`,
  confirmWarning: `RaConfirm-confirmWarning`,
};

const StyledDialog = styled(Dialog, {
  name: 'RaConfirm',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${ConfirmClasses.confirmPrimary}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${ConfirmClasses.confirmWarning}`]: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));