import { Typography } from '@mui/material';

export const TitlePortal = ({ className }: { className?: string }) => (
  <Typography
    id="react-admin-title"
    flex="1"
    textOverflow="ellipsis"
    whiteSpace="nowrap"
    overflow="hidden"
    variant="h6"
    color="inherit"
    className={className}
  />
);