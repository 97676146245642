import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  AccountCircle as AccountIcon,
  CallEnd as CallEndIcon,
  ContentCopy as ContentCopyIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  QuestionAnswer as QuestionAnswerIcon,
  ScreenShare as ScreenShareIcon,
  Share as ShareIcon,
  StopScreenShare as StopScreenShareIcon,
} from '@mui/icons-material';
import screenfull from 'screenfull';

import {
  AudioDropdown,
  DialStateHungUp,
  Panel,
  ShareDesktopDropdown,
  SidebarPanelChat,
  ToolbarButton,
  VideoDropdown,
} from '.';
import { UsersOnlineDialog } from '../Dialogs';
import { useMediaDevices, useVideoCall } from '../../hooks';
import { User } from '../../types';

import '../../css/chat/index.css';

export interface VideoCallToolbarProps {
  user: User;
  sidebarPanel?: Panel;
  openSidebar: boolean;
  onToggleSidebar: () => void;
};

export interface VideoCallToolbarState {
  openOnlineUsers: boolean;
  hidden: boolean;
  readMessages: number;
  fullScreenEnabled: boolean;
};

const canShare = (navigator: Navigator): boolean => 'share' in navigator;

export const VideoCallToolbar = (props: VideoCallToolbarProps) => {
  const {
    user,
    sidebarPanel,
    openSidebar,
    onToggleSidebar,
  } = props;

  const {
    callState,
    messages,
    participants,
    usersOnline,

    callUsers,
    leaveCall,
  } = useVideoCall();
  const {
    localSettings,
    onSetMediaDevice,
    onToggleMic,
    onToggleVideo,
  } = useMediaDevices();

  const messageCount = messages?.length ?? 0;
  const [state, setState] = useState<VideoCallToolbarState>({
    openOnlineUsers: false,
    hidden: false,
    readMessages: messageCount,
    fullScreenEnabled: false,
  });

  const unreadCount = messageCount - state.readMessages;
  const hasUnread = unreadCount > 0;
  const isInCall = callState?.dialState !== DialStateHungUp;
  const className = classnames('toolbar', {'toolbar-hidden': openSidebar || state.hidden});
  const openChat = openSidebar && sidebarPanel === SidebarPanelChat;

  const handleToggleHidden = useCallback((e: MouseEvent) => {
    const tagName = e.target && (e.target as HTMLElement).tagName;
    if (tagName === 'DIV' || tagName === 'VIDEO') {
      setState({
        ...state,
        hidden: !state.hidden,
      });
    }
  }, [state]);

  const handleFullscreenClick = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  const handleCopyInvitationURL = async (callId: string) => {
    const link = window.location.href;
    const text = `${user?.username} has invited you to a meeting on Peer Calls`;
    if (canShare(navigator)) {
      await navigator.share({
        title: 'Peer Call',
        text,
        url: link,
      });
      return;
    }
    const value = `${text}. \nRoom: ${callId} \nLink: ${link}`;
    await navigator.clipboard.writeText(value);
  };

  const handleOpen = () => setState({...state, openOnlineUsers: true});
  const handleClose = () => setState({...state, openOnlineUsers: false});

  const handleCall = (userIds: number[]) => {
    callUsers(userIds);
    handleClose();
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setState({
        ...state,
        fullScreenEnabled: screenfull.isEnabled && screenfull.isFullscreen,
      });
    };

    // TODO: document.body.addEventListener('click', handleToggleHidden);
    document.addEventListener('click', handleToggleHidden);
    screenfull.isEnabled && screenfull.on('change', handleFullscreenChange);

    return () => {
      // TODO: document.body.removeEventListener('click', handleToggleHidden);
      document.removeEventListener('click', handleToggleHidden);
      screenfull.isEnabled && screenfull.off('change', handleFullscreenChange);
    };
  }, [state, handleToggleHidden]);

  return (
    <React.Fragment>
      <div className={'toolbar-other ' + className}>
        <ToolbarButton
          key='copy-url'
          icon={canShare(navigator) ? ShareIcon : ContentCopyIcon}
          title={canShare(navigator) ? 'Share' : 'Copy Invitation URL'}
          className='copy-url'
          size='small'
          onClick={() => handleCopyInvitationURL(callState?.roomId.toString()!)}
        />
        <ToolbarButton
          key='open-users'
          icon={AccountIcon}
          title={state.openOnlineUsers ? 'Hide online users' : 'Show online users'}
          className='copy-url'
          size='small'
          onClick={handleOpen}
        />
        {isInCall && (
          <ToolbarButton
            badge={unreadCount}
            className='toolbar-btn-chat'
            key='chat'
            icon={QuestionAnswerIcon}
            blink={!openChat && hasUnread}
            onClick={onToggleSidebar}
            on={openChat}
            size='small'
            title='Show Sidebar'
          />
        )}
      </div>

      {/*isInCall && (*/}
        <div className={'toolbar-call ' + className}>
          {callState?.dialState === 'in-call' && (
            <ShareDesktopDropdown
              key='stream-desktop'
              title='Share Desktop'
              user={user}
              className='stream-desktop'
              icon={ScreenShareIcon}
              offIcon={StopScreenShareIcon}
            />
          )}

          <VideoDropdown
            enabled={localSettings.isVideoEnabled}
            devices={localSettings.devices.videoInputs}
            mediaConstraint={{enabled: true, constraints: { enabled: true }}}
            selectedDevice={localSettings.selectedVideoDevice}
            onSetSelectedDevice={onSetMediaDevice}
            onToggleDevice={onToggleVideo}
          />

          {callState?.dialState !== 'hung-up' && (
            <ToolbarButton
              key='hangup'
              icon={CallEndIcon}
              title='Hang Up'
              className='hangup'
              size='small'
              onClick={() => leaveCall(callState!.roomId)}
            />
          )}

          <AudioDropdown
            enabled={localSettings.isAudioInEnabled}
            devices={localSettings.devices.audioInputs}
            mediaConstraint={{enabled: true, constraints: { enabled: true }}}
            selectedDevice={localSettings.selectedAudioInDevice}
            onSetSelectedDevice={onSetMediaDevice}
            onToggleDevice={onToggleMic}
          />

          {callState?.dialState === 'in-call' && (
            <ToolbarButton
              key='fullscreen'
              icon={FullscreenExitIcon}
              on={state.fullScreenEnabled}
              offIcon={FullscreenIcon}
              title={state.fullScreenEnabled ? 'Exit Fullscreen' : 'Enter Fullscreen'}
              className='fullscreen'
              size='small'
              onClick={handleFullscreenClick}
            />
          )}
        </div>
      {/*)*/}

      <UsersOnlineDialog
        open={state.openOnlineUsers}
        title='Select Members'
        excludeUserIds={Object.keys(participants)}
        usersOnline={usersOnline ?? {}}
        onCall={handleCall}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};