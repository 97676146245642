import { Link } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Instagram as InstagramIcon } from '@mui/icons-material';

import {
  CarRaceTrackTimes,
  CarSpecsTabControl,
  ImageGallerySwiper,
  LikeComponent,
  UserAvatar,
} from '..';
import { InstagramUrl } from '../../consts';
import { getCarImageUrl } from '../../modules';
import { CarService } from '../../services';
import { getUserToken } from '../../stores';

export const ViewCarDetails = (props: any) => {
  //console.log('ViewCarDetails props:', props);
  const { car, onReload } = props;
  const currentUser = getUserToken();
  const isLiked = car?.likes?.includes(currentUser?.id) ?? false;

  const handleLike = () => {
    CarService.likeCar(car.id, car.userId).then((response: any) => {
      //console.log('likeCar response:', response);
      onReload && onReload();
    });
  };

  return (
    <Container sx={{ pt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert color={car?.blockChallenges ? 'error' : 'success'}>
            <AlertTitle>This user is {car?.blockChallenges ? 'not' : ''} accepting race challenges for this car!</AlertTitle>
          </Alert>
        </Grid>
        <Grid item sm={4} xs={12}>
          <div className="text-center mb-3">
            <Link
              to={`${InstagramUrl}/${car?.user?.username}`}
              className="instagram-tag mb-3"
              target="_blank"
            >
              <Typography variant="h5" gutterBottom>
                <InstagramIcon />&nbsp;@{car?.user?.username}
              </Typography>
            </Link>
            <div className="row mb-3" style={{justifyContent: 'center'}}>
              <div className="col col-12" style={{display: 'flex', justifyContent: 'center'}}>
                <UserAvatar
                  fileName={getCarImageUrl(car?.id)}
                  fullName={car?.name ?? car?.model ?? car?.manufacturer ?? car?.user?.username}
                  size={250}
                  style={{
                    flex: 1,
                    width: '250px',
                    height: '250px',
                    //marginBottom: '15px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    maxHeight: '250px',
                    maxWidth: '250px',
                  }}
                />
              </div>
              <div className="col col-12">
                <Box
                  sx={{
                    color: 'white',
                    bgcolor: 'background.paper',
                    //border: '1px solid #313131',
                    border: '1px solid black',
                    borderRadius: 2,
                    marginTop: 3,
                    //width: '100%',
                  }}
                >
                  <LikeComponent
                    currentUser={currentUser}
                    likes={car?.likes}
                    tooltip={isLiked ? 'Unlike car' : 'Like car'}
                    onClick={handleLike}
                  />
                </Box>
              </div>
            </div>
            <CarSpecsTabControl {...car!} />
          </div>
        </Grid>
        <Grid item sm={8} xs={12}>
          {car?.description && (
            <Typography paragraph>
              {car?.description}
            </Typography>
          )}
          <Typography variant="h5" gutterBottom className="text-center">Photos</Typography>
          <ImageGallerySwiper images={car?.images} />
        </Grid>
        {(car?.times?.length ?? 0) > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom className="text-center">Race Track Times</Typography>
            <CarRaceTrackTimes car={car} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};