import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
} from '@mui/material';

import { UploadImage } from '..';
import { manufacturers, years } from '../../modules';

export const CarDetailsForm = (props: any) => {
  const { state, showDefaultUpload = true, onChange } = props;

  return (
    <Grid container spacing={2} className="mb-3">
      {showDefaultUpload && (
        <Grid item xs={12} className="text-center">
          <FormLabel>Default Profile Picture</FormLabel>
          <UploadImage default={true} {...props} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          autoFocus
          //margin="dense"
          id="name"
          name="name"
          label="Car Nickname"
          type="text"
          fullWidth
          value={state.name}
          variant="standard"
          onChange={onChange}
          className="mb-3"
          required
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            labelId="year-label"
            id="year"
            name="year"
            label="Year"
            value={state.year}
            maxRows={10}
            onChange={onChange}
            required
          >
            {years().map((year: number) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="color-label">Color</InputLabel>
          <Select
            labelId="color-label"
            id="color"
            name="color"
            label="Color"
            value={state.color}
            maxRows={10}
            onChange={onChange}
            required
          >
            <MenuItem value="Black">Black</MenuItem>
            <MenuItem value="White">White</MenuItem>
            <MenuItem value="Red">Red</MenuItem>
            <MenuItem value="Orange">Orange</MenuItem>
            <MenuItem value="Yellow">Yellow</MenuItem>
            <MenuItem value="Blue">Blue</MenuItem>
            <MenuItem value="Green">Green</MenuItem>
            <MenuItem value="Purple">Purple</MenuItem>
            <MenuItem value="Brown">Brown</MenuItem>
            <MenuItem value="White Pearl">White Pearl</MenuItem>
            <MenuItem value="Black Pearl">Black Pearl</MenuItem>
            <MenuItem value="Silver">Silver</MenuItem>
            <MenuItem value="Gold">Gold</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={12} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="manufacturer-label">Manufacturer</InputLabel>
          <Select
            labelId="manufacturer-label"
            id="manufacturer"
            name="manufacturer"
            label="Manufacturer"
            value={state.manufacturer}
            maxRows={10}
            onChange={onChange}
            required
          >
            {manufacturers.map(manufacturer => (
              <MenuItem key={manufacturer} value={manufacturer}>
                {manufacturer}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          margin="dense"
          id="model"
          name="model"
          label="Car model name"
          type="text"
          fullWidth
          value={state.model}
          variant="standard"
          onChange={onChange}
          required
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          margin="dense"
          id="trim"
          name="trim"
          label="Car model trim"
          type="text"
          fullWidth
          value={state.trim}
          variant="standard"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextareaAutosize
          aria-label="Description"
          id="description"
          name="description"
          className="bg-dark text-light"
          placeholder="Write a description about your car..."
          minRows={3}
          maxRows={10}
          value={state.description}
          onChange={onChange}
          style={{
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12} className="text-nowrap text-start">
        <FormControlLabel
          control={
            <Switch
              id="blockChallenges"
              name="blockChallenges"
              checked={state.showInFleet}
              color="primary"
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Block Race Challenges."
          className="text-light"
        />
      </Grid>
      <Grid item xs={12} className="text-nowrap text-start">
        <FormControlLabel
          control={
            <Switch
              id="showInFleet"
              name="showInFleet"
              checked={state.showInFleet}
              color="primary"
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Show in 'The Fleet' page."
          className="text-light"
        />
      </Grid>
      <Grid item xs={12} className="text-nowrap text-start">
        <FormControlLabel
          control={
            <Switch
              id="showInLeaderboard"
              name="showInLeaderboard"
              checked={state.showInLeaderboard}
              color="primary"
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Show in 'Leaderboard' page."
          className="text-light"
        />
      </Grid>
    </Grid>
  );
};