import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Contact } from '..';
import { useSockets } from '../../../hooks';
import { UserService } from '../../../services';
import { User } from '../../../types';

import '../MessageSettingsDialog/MessageSettingsDialog.css';

export const MessageContactsDialog = (props: any) => {
  //console.log('MessageContactsDialog props:', props);
  const { userId, roomId = 0, ignoreUserIds = [], onClick, onClose } = props;
  const [people, setPeople] = useState<User[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const { socket } = useSockets();
  const availablePeople = people && people?.filter((person: User) => person.id !== userId && !ignoreUserIds.includes(person.id));

  const handleRadioClick = (event: any) => {
    const { target: { value, type, checked } } = event;
    //console.log('handleRadioClick:', value, type, checked, selectedContacts);
    if (type === 'checkbox' && checked) {
      setSelectedContacts([...selectedContacts, parseInt(value)]);
    } else {
      setSelectedContacts([...selectedContacts.filter((contact: number) => contact !== parseInt(value))]);
    }
  };

  useEffect(() => {
    UserService.getUsers().then((response: any) => {
      //console.log('get people response:', response);
      setPeople(response.users);
    });
  }, [setPeople]);

  return (
    <div className="msg-settings-bg">
      <div className="msg-settings-modal">
        <div className="msg-settings-header">
          <div className="msg-set-title">
            <label>People</label>
          </div>
          <IconButton
            title="Close"
            onClick={onClose}
            style={{marginRight: '10px'}}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="settings-content">
          <Grid container spacing={0} columnSpacing={0}>
            {availablePeople?.length > 0 ? availablePeople?.map((person: User) => (
              <Grid key={person.id} item xs={12} className="contact-item" style={{display: 'flex'}}>
                <FormControlLabel
                  label=""
                  value={person.id}
                  style={{width: '100%'}}
                  control={(
                    <Grid container spacing={0} style={{alignItems: 'center'}}>
                      <Grid item xs={2}>
                        <Checkbox
                          name={"userId_" + person.id}
                          className="end"
                          checked={selectedContacts.includes(person.id)}
                          value={person.id}
                          onChange={handleRadioClick}
                          //className="float-start"
                          //style={{display: 'inline-block'}}
                          //inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Contact
                          userId={userId}
                          contact={person}
                          showUsername={true}
                          showDate={false}
                          showMessagePreview={false}
                        />
                      </Grid>
                      {/*
                      <Grid item xs={2}>
                        <Button
                          //variant="text"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            onClick(socket, person);
                            onClose();
                          }}
                        >
                          Message
                        </Button>
                      </Grid>
                      */}
                    </Grid>
                  )}
                />
              </Grid>
            )) : 'No available users to include.'}
          </Grid>
        </div>
        <div className="contact-buttons-section">
          <FormControlLabel
            label="Select All"
            style={{width: '100%'}}
            control={(
              <Checkbox
                name="selectAll"
                className="end"
                checked={selectAll}
                onChange={() => {
                  const value = !selectAll;
                  setSelectAll(value);
                  if (value) {
                    const users = people
                      .map((person: any) => person.id)
                      .filter((id: number) => id !== userId);
                    setSelectedContacts(users);
                  } else {
                    setSelectedContacts([]);
                  }
                }}
                //className="float-start"
                //inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
          />
          <Button
            variant="contained"
            title="Close dialog"
            color="secondary"
            size="small"
            onClick={onClose}
          >
            Close
          </Button>
          {selectedContacts?.length > 0 && (
            <Button
              //variant="text"
              variant="contained"
              size="small"
              onClick={() => {
                onClick(socket, roomId, selectedContacts);
                onClose();
              }}
            >
              Message
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};