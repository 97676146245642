import React, { useState } from 'react';
import classnames from 'classnames';
import {
  ArrowDropUp as ArrowDropUpIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
} from '@mui/icons-material';

import {
  Backdrop,
  DEVICE_DEFAULT_ID,
  DEVICE_DISABLED_ID,
  MediaKind,
  MediaKindAudio,
  MediaKindVideo,
  SizeConstraint,
  ToolbarButton,
} from '.';

import '../../css/chat/index.css';

export interface DeviceDropdownProps {
  enabled: boolean;
  className: string;
  icon: any;
  offIcon: any;
  devices: MediaDeviceInfo[];
  selectedDeviceId: string;
  title: string;
  dropdownTitle: string;
  kind: MediaKind;
  //stream?: MediaStream;
  mediaConstraint: MediaConstraint;

  onToggleDevice: () => void;
  onSetSelectedDevice: (deviceId: string) => void;
};

export interface MediaConstraint {
  constraints: MediaTrackConstraints;
  enabled: boolean;
};

const labels = {
  audio: 'Audio',
  video: 'Video',
};

const qualityLow: SizeConstraint  = {
  width: 320,
  height: 240,
};

const qualityMd: SizeConstraint  = {
  width: 640,
  height: 480,
};

const qualitySd: SizeConstraint  = {
  width: 1280,
  height: 720,
};

const qualityHd: SizeConstraint  = {
  width: 1920,
  height: 1080,
};

export interface DeviceDropdownState {
  open: boolean
};

export const DeviceDropdown = (props: DeviceDropdownProps) => {
  const {
    enabled,
    devices,
    selectedDeviceId,
    kind,
    mediaConstraint,
    dropdownTitle,
    title,
    className,
    icon,
    offIcon,
    onToggleDevice,
    onSetSelectedDevice,
  } = props;
  const [state, setState] = useState<DeviceDropdownState>({
    open: false,
  });

  const handleToggleOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleOpen(!state.open);
  };

  const handleToggleDevice = async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onToggleDevice();
  };

  const handleClose = () => handleOpen(false);
  const handleOpen = (open: boolean) => setState({ open });

  const handleDevice = async (deviceId: string) => {
    //console.log('handleDevice:', deviceId);
    onSetSelectedDevice(deviceId);
    handleClose();
  };

  const handleQuality = async (quality: SizeConstraint) => {
    handleClose();

    if (kind !== 'video') {
      return;
    }

    const existing = mediaConstraint.constraints;
    const { width, height } = quality;

    if (existing.width === width && existing.height === height) {
      // Nothing to do.
      return;
    }

    //setSizeConstraint(quality);
  };

  const { height } = mediaConstraint?.constraints ?? {};

  const classNames = classnames('dropdown-list dropdown-center', {
    'dropdown-list-open': state.open,
  });

  const buttonsRowClassNames = classnames('buttons-row', className);

  return (
    <div className='dropdown'>
      <div className={buttonsRowClassNames}>
        <ToolbarButton
          className='device-button-toggle'
          icon={icon}
          offIcon={offIcon}
          on={enabled}
          title={title}
          size='small'
          onClick={handleToggleDevice}
        />

        <ToolbarButton
          className='device-button-dropdown'
          icon={ArrowDropUpIcon}
          on={mediaConstraint?.enabled ?? false}
          title={dropdownTitle}
          size='small'
          onClick={handleToggleOpen}
        />
      </div>

      <Backdrop
        visible={state.open}
        onClick={handleClose}
      />

      <ul className={classNames}>
        {kind === 'video' && (
          <li>
            <ul className='horizontal'>
              <QualityOption
                constraint={qualityLow}
                selected={height === qualityLow.height}
                onClick={handleQuality}
              >
                Lo
              </QualityOption>
              <QualityOption
                constraint={qualityMd}
                selected={height === qualityMd.height}
                onClick={handleQuality}
              >
                Md
              </QualityOption>
              <QualityOption
                constraint={qualitySd}
                selected={height === qualitySd.height}
                onClick={handleQuality}
              >
                Sd
              </QualityOption>
              <QualityOption
                constraint={qualityHd}
                selected={height === qualityHd.height}
                onClick={handleQuality}
              >
                Hd
              </QualityOption>
            </ul>
          </li>
        )}
        <DeviceOption
          deviceId={DEVICE_DISABLED_ID}
          name={'No ' + labels[kind]}
          selected={selectedDeviceId === DEVICE_DISABLED_ID}
          onClick={handleDevice}
        />
        <DeviceOption
          deviceId={DEVICE_DEFAULT_ID}
          name={'Default ' + labels[kind]}
          selected={selectedDeviceId === DEVICE_DEFAULT_ID || selectedDeviceId === 'default'}
          onClick={handleDevice}
        />
        {devices?.map(device => (
          <DeviceOption
            key={device.deviceId}
            name={device.label}
            deviceId={device.deviceId}
            selected={selectedDeviceId !== DEVICE_DEFAULT_ID && selectedDeviceId !== 'default' && selectedDeviceId === device.deviceId}
            onClick={handleDevice}
          />
        ))}
      </ul>
    </div>
  );
};

export interface DeviceOptionProps {
  selected: boolean;
  name: string;
  deviceId: string;
  onClick: (deviceId: string) => void;
};

export const DeviceOption = (props: DeviceOptionProps) => {
  const { deviceId, name, selected, onClick } = props;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(deviceId);
  };

  const checked = selected
    ? <RadioButtonCheckedIcon />
    : <RadioButtonUncheckedIcon />;

  return (
    <li className='device' onClick={handleClick}>
      {checked}&nbsp;{name}
    </li>
  );
};

export interface QualityOptionProps {
  constraint: SizeConstraint;
  selected: boolean;
  onClick: (constraint: SizeConstraint) => void;
  children: any;
};

export class QualityOption extends React.PureComponent<QualityOptionProps> {
  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onClick(this.props.constraint);
  };

  render() {
    const { selected } = this.props;

    const className = classnames('quality', { selected });

    return (
      <li className={className} onClick={this.handleClick}>
        {this.props.children}
      </li>
    );
  };
};

export const AudioDropdown = (props: any) => {
  const {
    enabled,
    devices,
    mediaConstraint,
    selectedDevice,
    onToggleDevice,
    onSetSelectedDevice,
  } = props;

  return (
    <DeviceDropdown
      enabled={enabled}
      className='audio'
      icon={MicIcon}
      offIcon={MicOffIcon}
      title='Toggle microphone'
      dropdownTitle='Microphone'
      kind={MediaKindAudio}
      devices={devices}
      selectedDeviceId={selectedDevice}
      mediaConstraint={mediaConstraint}
      onToggleDevice={onToggleDevice}
      onSetSelectedDevice={(deviceId: string) => onSetSelectedDevice('audioinput', deviceId)}
    />
  );
};

export const VideoDropdown = (props: any) => {
  const {
    enabled,
    devices,
    mediaConstraint,
    selectedDevice,
    onToggleDevice,
    onSetSelectedDevice,
  } = props;

  return (
    <DeviceDropdown
      enabled={enabled}
      className='video'
      icon={VideocamIcon}
      offIcon={VideocamOffIcon}
      title='Toggle Video'
      dropdownTitle='Video Camera'
      kind={MediaKindVideo}
      devices={devices}
      selectedDeviceId={selectedDevice}
      mediaConstraint={mediaConstraint}
      onToggleDevice={onToggleDevice}
      onSetSelectedDevice={(deviceId: string) => onSetSelectedDevice('videoinput', deviceId)}
    />
  );
};