import {
  Avatar,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { getAvatarUrl } from '../../modules';
import { getUserToken } from '../../stores';
import { User } from '../../types';

export enum SelectionType {
  Single = 0,
  Multiple = 1,
};

interface UsersOnlineProps {
  users: { [userId: string]: User };
  selectedUserIds: number[];
  excludeUserIds: number[];
  selectionType: SelectionType;
  onCall: (userIds: number[]) => void;
  onToggle: (userId: number) => void;
};

export const UsersOnline = (props: UsersOnlineProps) => {
  //console.log('UsersOnline props:', props);
  const {
    selectedUserIds, excludeUserIds = [], selectionType = SelectionType.Multiple, users,
    onCall, onToggle,
  } = props;
  //const [selected, setSelected] = useState<number[]>([]);
  const currentUser = getUserToken();

  /*
  const handleStartGroupChat = () => {
    if (selected.length === 0) {
      return;
    }
    onCall([...selected]);
    setSelected([]);
  };
  */

  const handleOpen = (userId: number) => onCall([userId]);

  /*
  const handleToggle = (value: number) => () => {
    const currentIndex = selected.indexOf(value);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };
  */

  const userIds = Object
    .keys(users)
    .map((userId) => parseInt(userId))
    .filter((userId) => Number.isInteger(userId))
    .filter((userId) => userId !== currentUser?.id)
    .filter((userId) => !excludeUserIds.includes(userId));
  //console.log('userIds:', userIds);

  return (
    <Grid container spacing={0}>
      {/*
      <Grid item xs={10}>
        <Typography variant="h6" component="h5" className="mt-2 ms-1">
          Users Online
        </Typography>
      </Grid>
      */}
      {/*
      <Grid item xs={12}>
        {selectedUserIds?.length > 0 && (
          <IconButton
            title="Start video chat group"
            className="float-end"
            size="small"
            onClick={handleStartGroupChat}
          >
            <CallIcon />
          </IconButton>
        )}
      </Grid>
      */}
      <Grid item xs={12}>
        <List dense>
          {userIds.length > 0 ? userIds?.map((userId: number) => (
            <ListItem
              disablePadding
              dense
              key={userId}
              style={{
                border: '1px solid rgba(128, 128, 128, 0.2)',
                borderRadius: 8,
              }}
            >
              <ListItemButton
                dense
                title={`Call member ${users[userId]?.username}`}
                onClick={selectionType === SelectionType.Single
                  ? handleOpen(userId)!
                  : onToggle(userId)!
                  //: handleToggle(userId)
                }
              >
                {selectionType === SelectionType.Multiple && (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedUserIds.indexOf(userId) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': `checkbox-list-label-${userId}` }}
                    />
                  </ListItemIcon>
                )}
                <ListItemAvatar>
                  <Avatar
                    src={getAvatarUrl(userId)}
                    alt={users[userId].username}
                  />
                </ListItemAvatar>
                <ListItemText primary={users[userId].username} />
              </ListItemButton>
            </ListItem>
          )) : (
            <ListItem
              disablePadding
              dense
              style={{
                border: '1px solid rgba(128, 128, 128, 0.2)',
                borderRadius: 8,
              }}
            >
              <ListItemButton
                dense
                title='No members currently available'
              >
                <ListItemText primary='No members currently available' />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
};