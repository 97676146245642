import React, { useState } from 'react';
import env from 'react-dotenv';

// pintura
import {
  // editor
  openEditor,
  locale_en_gb,
  createDefaultImageOrienter,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  legacyDataToImageState,
  processImage,
  getEditorDefaults,

  // plugins
  setPlugins,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  plugin_decorate,
  plugin_decorate_locale_en_gb,
  plugin_fill,
  plugin_fill_locale_en_gb,
  plugin_frame,
  plugin_frame_locale_en_gb,
  plugin_frame_defaults,
  plugin_redact,
  plugin_redact_locale_en_gb,
  plugin_resize,
  plugin_resize_locale_en_gb,
} from '@pqina/pintura';

import {
  LocaleCore,
  LocaleCrop,
  LocaleFinetune,
  LocaleFilter,
  LocaleAnnotate,
  LocaleDecorate,
  LocaleFill,
  LocaleFrame,
  LocaleSticker,
  LocaleResize,
  LocaleRedact,
  LocaleMarkupEditor,
} from '@pqina/pintura/locale/en_GB';
import '@pqina/pintura/pintura.css';

// filepond
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';

import {
  MaxFileUploadLimit,
  MaxParallelFileUploadLimit,
} from '../../consts';
import './StoryFilePond.css';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileMetadata,
);

setPlugins(
  plugin_crop, plugin_finetune, plugin_filter,
  plugin_annotate, plugin_decorate, plugin_fill,
  plugin_frame, plugin_redact, plugin_resize,
  plugin_sticker,
);

const ImageUtils = [
  'crop', 'filter', 'finetune', 'annotate', 'decorate',
  'frame', 'fill', 'redact', 'resize', 'sticker',
];

export const StoryFilePond = (props: any) => {
  //console.log('StoryFilePond props:', props);
  const { user, setStories } = props;
  const [files, setFiles] = useState<any[]>([]);

  const handleSetFiles = (files: any) => {
    //console.log('handleSetFiles:', files);
    setFiles(files);
    setStories(files);
    return false;
  };

  return (
    <FilePond
      files={files}
      allowBrowse
      allowDrop
      allowFileMetadata
      allowFilePoster
      allowFileSizeValidation
      allowFileTypeValidation
      allowImageEditor
      allowReorder
      required
      onupdatefiles={handleSetFiles}
      acceptedFileTypes={['image/*', 'video/*']}
      allowMultiple={MaxFileUploadLimit > 1}
      maxFiles={MaxFileUploadLimit}
      maxParallelUploads={MaxParallelFileUploadLimit}
      instantUpload
      server={{
        url: env.BASE_API_URL + '/story/images',
        process: {
          url: '', //'/story/images',
          method: 'POST',
          withCredentials: true,
          headers: {
            'x-access-token': user?.accessToken ?? '',
          },
          timeout: 60 * 1000,
          ondata: (data: FormData) => {
            data.append('userId', user?.id);
            return data;
          },
          onerror: (response: any) => {
            console.error('onerror:', response);
            window.alert('onerror: ' + response?.message);
            return false;
          },
        },
        /*
        revert: {
          //url: '/images/upload',
          url: '/story/images',
          method: 'DELETE',
          withCredentials: true,
          headers: {
            'x-access-token': currentUser?.accessToken ?? '',
          },
          timeout: 60 * 1000,
          onerror: (response: any) => {
            console.error('revert onerror:', response);
            window.alert('revert onerror: ' + response?.message);
            return false;
          },
        },
        */
        remove: (source, load, error) => {
          console.log('remove:', source, load, error);
          // Should somehow send `source` to server so server can remove the file with this source

          // Can call the error method if something is wrong, should exit after
          //error('oh my goodness');

          // Should call the load method when done, no parameters required
          load();
        },
      }}
      name="file"
      imageEditor={{
        // map legacy data objects to new imageState objects
        legacyDataToImageState: legacyDataToImageState,

        // used to create the editor, receives editor configuration, should return an editor instance
        createEditor: openEditor,

        imageReader: [createDefaultImageReader],
        imageWriter: [createDefaultImageWriter],

        // used to generate poster images, runs an editor in the background
        imageProcessor: processImage,

        // editor options
        editorOptions: {
          ...getEditorDefaults(),
          utils: ImageUtils,
          openEditor: openEditor,
          imageOrienter: createDefaultImageOrienter(),
          shapePreprocessor: createDefaultShapePreprocessor(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          ...plugin_frame_defaults,
          locale: {
            ...locale_en_gb,
            ...plugin_crop_locale_en_gb,
            ...plugin_finetune_locale_en_gb,
            ...plugin_filter_locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...plugin_decorate_locale_en_gb,
            ...plugin_fill_locale_en_gb,
            ...plugin_frame_locale_en_gb,
            ...plugin_redact_locale_en_gb,
            ...plugin_resize_locale_en_gb,
            ...plugin_sticker_locale_en_gb,
            ...markup_editor_locale_en_gb,
            ...LocaleCore,
            ...LocaleCrop,
            ...LocaleFinetune,
            ...LocaleFilter,
            ...LocaleAnnotate,
            ...LocaleDecorate,
            ...LocaleFill,
            ...LocaleFrame,
            ...LocaleResize,
            ...LocaleRedact,
            ...LocaleSticker,
            ...LocaleMarkupEditor,
          },
        },
      }}
    />
  );
};