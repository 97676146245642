import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { StoryFilePond } from '..';

export const CreateStoryDialog = (props: any) => {
  //console.log('CreateStoryDialog props:', props);
  const { user, open, onClose } = props;
  const [stories, setStories] = useState([]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {user?.username}&nbsp; | &nbsp;Create New Story
          </Typography>
          {(stories?.length ?? 0) > 0 && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={onClose}
            >
              Done
            </Button>
          )}
          &nbsp;
          <IconButton
            color="inherit"
            title="Close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container component="main" sx={{ mb: 2 }}>
          <Paper
            variant="outlined"
            sx={{
              my: { xs: 1, md: 2 },
              p: { xs: 1, md: 2 },
            }}
            className="bg-dark text-light"
          >
            <StoryFilePond
              user={user}
              setStories={setStories}
            />
          </Paper>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

/*
import React, { Fragment, useState } from 'react';
import {
  AppBar,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  KeyboardArrowLeft as ArrowLeft,
  KeyboardArrowRight as ArrowRight,
} from '@mui/icons-material';

import {
  CreateStory,
  ImageGallerySwiper,
  StoryFilePond,
  StoryImageCropResize,
} from '..';
import { ImageService, StoryService } from '../../services';
import { Story } from '../../types';

const steps = [
  'Media Selection',
  //'Crop & Filter',
  'Review & Submit',
];

export const CreateStoryDialog = (props: any) => {
  //console.log('CreateStoryDialog props:', props);
  const { user, open, onClose } = props;
  const [story, setStory] = useState<Story | null>({
    userId: user?.id,
    images: [],
  });
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleCreateStory = (event: any) => {
    event.preventDefault();

    if ((story?.images?.length ?? 0) === 0) {
      console.log('no images, unable to create story');
      return;
    }

    //StoryService.createStory({userId: user.id, images: []}).then((response: any) => {
    StoryService.createStory(story!).then((response: any) => {
      //console.log('createStory response:', response);
      if (response.status !== 200) {
        // Show error notification
        console.error('createStory:', response.message);
        return;
      }

      ImageService.uploadImages(story!.images, `stories/${response.story.id}`, (progress: any) => {
        if (progress.progress === 1) {
          setStory(null);
          setActiveStep(0);
          onClose && onClose();
          // Show success notification
          return false;
        }
      });
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {user?.username}&nbsp; | &nbsp;Create New Story
          </Typography>
          <IconButton
            title="Go back to previous page"
            onClick={handleBack}
            hidden={activeStep === 0}
          >
            <ArrowLeft />
          </IconButton>
          <IconButton
            title="Go to next page"
            onClick={handleNext}
            hidden={activeStep === steps.length - 1 || (story?.images?.length ?? 0) === 0}
          >
            <ArrowRight />
          </IconButton>
          &nbsp;{// | &nbsp;//}
          <IconButton
            color="inherit"
            title="Close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <CreateStorySteps
          user={user}
          story={story}
          setStory={setStory}
          activeStep={activeStep}
        />
      </DialogContent>
      <DialogActions>
        {(story?.images?.length ?? 0) > 0 && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleCreateStory}
          >
            Create
          </Button>
        )}
        <Button size="small">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateStorySteps = (props: any) => {
  //console.log('CreateStorySteps props:', props);
  const { activeStep } = props;

  return (
    <Container component="main" sx={{ mb: 2 }}>
      <Paper
        variant="outlined"
        sx={{
          my: { xs: 1, md: 2 },
          p: { xs: 1, md: 2 },
        }}
        className="bg-dark text-light"
      >
        <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
          {steps.map((label: string) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fragment>
          {getStepContent(activeStep, props)}
        </Fragment>
      </Paper>
    </Container>
  );
};

const getStepContent = (step: number, props: any) => {
  switch (step) {
    //case 0:
    //  return <CreateStory {...props} />;
    //case 1:
    //  return <StoryImageCropResize {...props} />;
    //case 2:
    //  return <ReviewSubmitStory {...props} />;
    case 0:
      return <StoryFilePond {...props} />;
    case 1:
      return <ReviewSubmitStory {...props} />;
    default:
      throw new Error('Unknown step');
  }
};

const ReviewSubmitStory = (props: any) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Review
      </Typography>
      <Typography variant="subtitle1">
        Review your story below and click the Create button once you are ready.
      </Typography>
      <ImageGallerySwiper
        images={props.story?.images}
        editMode={false}
      />
    </>
  );
};
*/