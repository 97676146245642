import React, { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import {
  CalendarBoard,
  CalendarBoardDragLayer,
  CalendarContext,
  CalendarHeader,
} from '.';

const useStyles: any = ((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    paddingTop: 1,
    backgroundColor: theme.palette.background.paper,
  },
  body: {
    height: 'calc(100% - 150px)',
    //overflowX: 'scroll',
    //overflow: 'scroll',
    overflow: 'hidden',
    alignItems: 'stretch',
    '&:before': {
      backgroundImage: 'linear-gradient(to right,white,rgba(255,255,255,0))',
      content: '',
      height: 2,
      position: 'absolute',
      width: 80,
      zIndex: 51,
    },
  },               
  timeColumnContainer: {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
    height: 'auto',
    overflowY: 'hidden',
    flex: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: 45,
    maxWidth: 45,
    marginTop: -8,
  },
  timeColumn: {
    position: 'relative',
    WebkitBoxSizing: 'border-box',
    marginLeft: 'auto',
  },
  timeColumnElement: {
    position: 'relative',
    height: 60,
    paddingRight: 8,
    textAlign: 'right',
    color: '#70757a',
    fontSize: 12,
  },
  boardContainer: {
    //overflowX: 'auto',
    //overflowY: 'auto',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 128,
    //maxWidth: 128,
  },
}));

const DefaultHours = 23;

export const CalendarLayoutDayWeek = (props: any) => {
  //console.log('CalendarLayoutDayWeek props:', props);
  const { selectedWeekIndex, selectedWeek } = props;

  const { stateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, layout, defaultEventDuration } = stateCalendar;
  const theme = useTheme();
  const classes = useStyles(theme);

  return useMemo(() => {
    return (
      <div style={classes.root}>
        <CalendarHeader
          selectedWeekIndex={selectedWeekIndex}
          selectedWeek={selectedWeek}
        />
        <Grid
          container
          spacing={0}
          direction='row'
          justifyContent='center'
          alignItems='stretch'
          style={classes.body}
        >
          <Grid item xs={1} style={classes.timeColumnContainer}>
            <div style={classes.timeColumn}>
              <div style={classes.timeColumnElement} />
              {Array.from(Array(DefaultHours).keys()).map(index => (
                <div style={classes.timeColumnElement} key={`time-${index}`}>
                  <span>{index > 11 ? index - 12 + 1 : index + 1}{index >= 11 ? 'pm' : 'am'}</span>
                </div>
              ))}
              <div style={classes.timeColumnElement} />
            </div>
          </Grid>

          <Grid item xs style={classes.boardContainer}>
            <CalendarBoard
              selectedWeekIndex={selectedWeekIndex}
              selectedWeek={selectedWeek}
            />
            <CalendarBoardDragLayer />
          </Grid>
        </Grid>
      </div>
    );
  }, [
    classes,
    selectedDate,
    locale,
    layout,
    defaultEventDuration,
    selectedWeek,
    selectedWeekIndex,
  ]);
};