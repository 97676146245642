import React, { useContext, useMemo } from 'react';
import format from 'date-fns/format';
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  CalendarMonth as CalendarClosedIcon,
  CalendarMonthOutlined as CalendarOpenIcon,
  CalendarViewDay as CalendarViewDayIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Today as TodayIcon,
  ViewModule as ViewModuleIcon,
  ViewWeek as ViewWeekIcon,
} from '@mui/icons-material';

import {
  CalendarContext,
  getSelectedWeekIndex,
  getWeekDays,
  useSidebarState,
} from '..';
import { set } from '../../modules';

const DrawerOpenAppBarWidth = 250;

const useStyles: any = ((theme: Theme, isSidebarOpen: boolean, isSmall: boolean = false) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    backgroundColor: theme.palette.background.paper,
    width: `calc(100% - ${isSidebarOpen ? 220 : 52}px)`,
    borderBottom: '1px solid rgb(18, 18, 18)',
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    fontWeight: 400,
    fontSize: theme.breakpoints.down('sm') ? 14 : 18, // TODO: Fix title sizing
    textTransform: 'capitalize',
  },
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    //width: `calc(100% - ${isSidebarOpen ? 220 : DrawerOpenAppBarWidth}px)`,
    width: `calc(100% - ${isSidebarOpen ? 210 : DrawerOpenAppBarWidth}px)`,
    marginLeft: `${DrawerOpenAppBarWidth - (isSmall ? 0 : isSidebarOpen ? 40 : 0)}px`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  tooltip: {
    marginTop: 2,
  },
}));

export const CalendarToolbar = (props: any) => {
  //console.log('CalendarToolbar props:', props);
  const {
    open,
    onDrawerOpen,
    onDrawerClose,
    onGoToToday,
    onNext,
    onPrevious,
  } = props;

  const [isSidebarOpen] = useSidebarState();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const buttonSize = isXSmall ? 'small' : 'medium';

  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, layout } = stateCalendar;
  const theme = useTheme();
  const classes = useStyles(theme, isSidebarOpen, isSmall);

  return useMemo(() => {
    const setLayout = (props: any) => {
      const { option } = props;
      setStateCalendar({ ...stateCalendar, layout: option });
      set('layout', option);
    };

    const weeks = getWeekDays(selectedDate, 7);
    const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0);
    const selectedWeek = weeks[selectedWeekIndex];

    const firstDayOfWeek = format(selectedWeek[0], 'do', { locale });
    const lastDayOfWeek = format(selectedWeek[6], 'do', { locale });
    const firstDayOfWeekMonth = format(selectedWeek[0], 'MMM', { locale });
    const lastDayOfWeekMonth = format(selectedWeek[6], 'MMM', { locale });
    const firstDayOfWeekYear = format(selectedWeek[0], 'yyyy', { locale });
    const lastDayOfWeekYear = format(selectedWeek[6], 'yyyy', { locale });

    const showMonthsAndYears =
      layout !== 'week' &&
      (firstDayOfWeekMonth !== lastDayOfWeekMonth && firstDayOfWeekYear !== lastDayOfWeekYear)
        ? `${firstDayOfWeekMonth} ${firstDayOfWeekYear} - ${lastDayOfWeekMonth} ${lastDayOfWeekYear}`
        : false;
    const showMonthsAndYear =
      !showMonthsAndYears && layout === 'week' && firstDayOfWeekMonth !== lastDayOfWeekMonth
        ? `${firstDayOfWeekMonth} - ${lastDayOfWeekMonth} ${firstDayOfWeekYear}`
        : false;
    const showMonthAndYear = !showMonthsAndYear
      ? format(selectedDate, 'MMMM yyyy', { locale })
      : false;

    const range = (!(open && isSmall) && !(open && isXSmall)) &&
     (firstDayOfWeekMonth !== lastDayOfWeekMonth && firstDayOfWeekYear !== lastDayOfWeekMonth)
      ? `${firstDayOfWeekMonth} ${firstDayOfWeek} - ${lastDayOfWeekMonth} ${lastDayOfWeek} ${lastDayOfWeekYear}`
      : `${firstDayOfWeekMonth} ${firstDayOfWeek} - ${lastDayOfWeek} ${lastDayOfWeekYear}`;

    const title = layout === 'week' && !isSmall
      ? range
      : showMonthsAndYears || showMonthsAndYear || showMonthAndYear;

    /*
    const range = layout === 'week' && (!(open && isSmall) && !(open && isXSmall))
      //? `${firstDayOfWeekMonth} ${firstDayOfWeek} - ${lastDayOfWeekMonth} ${lastDayOfWeek}`
      ? `${firstDayOfWeek} - ${lastDayOfWeek}`
      : '';
    */

    return (
      <AppBar
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          maxHeight: '64px',
        }}
        style={{
          ...classes.root,
          ...(open ? classes.appBarShift : classes.appBar),
          position: 'static',
        }}
      >
        <Toolbar>
          <Tooltip
            title={open ? 'Close calendar date selector drawer' : 'Open calendar date selector drawer'}
            style={classes.tooltip}
          >
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={open ? onDrawerClose : onDrawerOpen}
              edge='start'
              size={buttonSize}
              style={classes.menuButton}
            >
              {open ? <CalendarOpenIcon /> : <CalendarClosedIcon />}
              {/*open ? <ChevronLeftIcon /> : <ChevronRightIcon />*/}
            </IconButton>
          </Tooltip>

          <Tooltip
            title={`Go to today's date\n${format(new Date(), 'eeee, MMMM d, yyyy', { locale })}`}
            style={classes.tooltip}
          >
            <IconButton
              color='inherit'
              aria-label='Today'
              onClick={onGoToToday}
              edge='start'
              size={buttonSize}
              //style={classes.menuButton}
            >
              <TodayIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={getLayoutText(layout, true)}
            style={classes.tooltip}
          >
            <IconButton
              size={buttonSize}
              onClick={onPrevious}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={getLayoutText(layout, false)}
            style={classes.tooltip}
          >
            <IconButton
              size={buttonSize}
              onClick={onNext}
            >
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>

          <Typography style={classes.title}>
            {title}
            {/*{showMonthsAndYears || showMonthsAndYear || showMonthAndYear}&nbsp;&nbsp;<small style={{fontSize: 16, verticalAlign: 'middle', justifyContent: 'center'}}>{range}</small>*/}
          </Typography>

          <Tooltip
            title='Day'
            style={classes.tooltip}
          >
            <IconButton
              color='inherit'
              aria-label='Day View'
              onClick={e => setLayout({ e, option: 'day' })}
              edge='start'
              size={buttonSize}
              style={{
                ...classes.menuButton,
                color: layout === 'day' ? theme.palette.primary.main : 'inherit',
              }}
            >
              <CalendarViewDayIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title='Week'
            style={classes.tooltip}
          >
            <IconButton
              color='inherit'
              aria-label='Week View'
              onClick={e => setLayout({ e, option: 'week' })}
              edge='start'
              size={buttonSize}
              style={{
                ...classes.menuButton,
                color: layout === 'week' ? theme.palette.primary.main : 'inherit',
              }}
            >
              <ViewWeekIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title='Month'
            style={classes.tooltip}
          >
            <IconButton
              color='inherit'
              aria-label='Month View'
              onClick={e => setLayout({ e, option: 'month' })}
              edge='start'
              size={buttonSize}
              style={{
                ...classes.menuButton,
                color: (layout === 'month' ? theme.palette.primary.main : 'inherit'),
                marginRight: -3,
              }}
            >
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    );
  }, [
    classes,
    selectedDate,
    layout,
    locale,
    onGoToToday,
    onNext,
    onPrevious,
    isSmall,
    isXSmall,
    open,
    onDrawerClose,
    onDrawerOpen,
    stateCalendar,
    setStateCalendar,
    buttonSize
  ]);
};

const getLayoutText = (layout: string, previous: boolean) => {
  switch (layout) {
    case 'day':
      return previous ? 'Previous Day' : 'Next Day';
    case 'week':
      return previous ? 'Previous Week' : 'Next Week';
    case 'month':
      return previous ? 'Previous Month' : 'Next Month';
  }
};