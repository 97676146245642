import React from 'react';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
} from '@mui/icons-material';

import './SocialFollow.css';

export const SocialFollow = (props: any) => {
  //console.log('SocialFollow props:', props);
  const { social } = props;

  return (
    <div className="social-container">
      {/*<h3>Social Follow</h3>*/}
      {social?.youtube && (
        <a
          href={`https://youtube.com/c/${social.youtube}`}
          className="youtube social"
          title={social.youtube}
        >
          <YouTubeIcon />
        </a>
      )}
      {social?.facebook && (
        <a
          href={`https://facebook.com/${social.facebook}`}
          className="facebook social"
          title={social.facebook}
        >
          <FacebookIcon />
        </a>
      )}
      {social?.twitter && (
        <a
          href={`https://twitter.com/${social.twitter}`}
          className="twitter social"
          title={social.twitter}
        >
          <TwitterIcon />
        </a>
      )}
      {social?.instagram && (
        <a
          href={`https://instagram.com/${social.instagram}`}
          className="instagram social"
          title={social.instagram}
        >
          <InstagramIcon />
        </a>
      )}
      {social?.linkedin && (
        <a
          href={`https://linkedin.com/${social.linkedin}`}
          className="linkedin social"
          title={social.linkedin}
        >
          <LinkedInIcon />
        </a>
      )}
    </div>
  );
};