import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Flag as ReportIcon } from '@mui/icons-material';

import { ReportUserDialog } from '../Dialogs';

export const ReportUserButton = (props: any) => {
  //console.log('ReportUserButton props:', props);
  const { isCurrentUser, isDisabled = false, currentUser, profile, onReload } = props;
  const [open, setOpen] = useState(false);

  const handleOpenReportUserDialog = () => setOpen(true);
  const handleCloseReportUserDialog = () => setOpen(false);

  return (
    <>
      <IconButton
        title={`Report user ${profile?.username}`}
        onClick={handleOpenReportUserDialog}
        //className="mx-3"
        disabled={isCurrentUser || isDisabled}
      >
        <ReportIcon style={{color: isCurrentUser || isDisabled ? 'grey' : 'white'}} />
      </IconButton>
      <ReportUserDialog
        isOpen={open}
        onClose={handleCloseReportUserDialog}
        profile={profile}
        currentUser={currentUser}
      />
    </>
  );
};