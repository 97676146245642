import React from 'react';
import {
  Dialog,
  ListItemIcon,
  ListItemText,
  Paper,
  MenuList,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  FacebookRounded as FacebookIcon,
  Link as LinkIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from '@mui/icons-material';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import theme from '../../themes/theme';

const styles = (theme: any) => ({
  clipboard: {
    fontSize: '18px',
    textAlign: 'center',
    marginTop: '10px',
    color: '#1e882d',
    fontWeight: 400,
  },
  networkShare: {
    width: '100%',
    height: '100%',
  },
  fullPageXs: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      overflowY: 'auto',
    },
  },
  shareLinkPaper: {
    minHeight: 80,
    padding: 10,
    minWidth: 460,
  },
});

const style = styles(theme);

export const ShareDialog = (props: any) => {
  const { shareOpen, onClose, openCopyLink, onCopyLink, link, text = '', tags = [] } = props;

  return (
    <Dialog
      style={style.fullPageXs}
      title='Share On'
      open={shareOpen}
      onClose={onClose}
    >
      <Paper style={{padding: '10px'}}>
        <Typography variant="h5" gutterBottom className="text-center">Share via</Typography>
        {!openCopyLink ? (
          <MenuList>
            <div>
              <FacebookShareButton
                className="w-100"
                onShareWindowClose={onClose}
                url={link}
                quote={text}
                hashtag={tags}
              >
                <MenuItem className="bg-dark text-light">
                  <ListItemIcon>
                    <FacebookIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Facebook" />
                </MenuItem>
              </FacebookShareButton>
            </div>
            <div>
              <TwitterShareButton
                className="w-100"
                onShareWindowClose={onClose}
                url={link}
                title={text}
                hashtags={tags}
              >
                <MenuItem className="bg-dark text-light">
                  <ListItemIcon>
                    <TwitterIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Twitter" />
                </MenuItem>
              </TwitterShareButton>
            </div>
            <div>
              <LinkedinShareButton
                className="w-100"
                onShareWindowClose={onClose}
                url={link}
                source={link}
                summary={text}
              >
                <MenuItem className="bg-dark text-light">
                  <ListItemIcon>
                    <LinkedInIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="LinkedIn" />
                </MenuItem>
              </LinkedinShareButton>
            </div>
            <div className="w-100">
              <MenuItem onClick={onCopyLink} className="bg-dark text-light">
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText inset primary="Copy Link" />
              </MenuItem>
            </div>
          </MenuList>
        ) : (
          <div>
            <TextField
              autoFocus
              fullWidth={true}
              id="text-field-default"
              defaultValue={link}
            />
            <Typography variant="h6" gutterBottom>
              Link has been copied to clipboard ...
            </Typography>
          </div>
        )}
      </Paper>
    </Dialog>
  );
};