import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment';

import {
  CarSpecificationsList,
  ChallengeCarousel,
  ChallengeListItemResult,
} from '..';
import { Routes } from '../../consts';
import { ViewRaceTrackDetailsDialog } from '../Dialogs';
import { buildCarName, getCarImageUrl } from '../../modules';

const useStyles: any = (theme: Theme) => ({
  root: {
    borderRadius: 20,
    border: '1px solid black',
  },
  carImage: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    objectFit: 'cover',
    width: '100%',
    height: '200px',
  },
});

export const ViewChallengeDetails = (props: any) => {
  //console.log('ViewChallengeDetails props:', props);
  const { challenge } = props;
  const [open, setOpen] = useState(false);
  const dateTime = moment(challenge?.date).format('dddd MMMM Do, YYYY');
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Paper style={classes.root}>
      <Card sx={{borderRadius: 5}}>
        <CardHeader
          title={
            <Typography variant="h5" className="text-center">
              <a href={`/${challenge?.challenger?.username}`} className="instagram-tag">
                  {challenge?.challenger?.username}
              </a>
              &nbsp;vs&nbsp;
              <a href={`/${challenge?.challengee?.username}`} className="instagram-tag">
                  {challenge?.challengee?.username}
              </a>
            </Typography>
          }
          subheader={
            <Typography className="text-center">
              <strong>{dateTime}</strong>
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2" color="text.secondary" className="mb-3 ms-3">
                {challenge?.challenger?.username} and {challenge?.challengee?.username} go head to head at {challenge?.raceTrack?.name} with their {buildCarName(challenge?.challengerCar!)} vs {buildCarName(challenge?.challengeeCar!)}.
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Button
                variant="contained"
                onClick={handleOpen}
                className="float-end me-3"
                size="small"
              >
                Track Details
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h5" gutterBottom className="text-center">
                {challenge?.challengerCar.name}
              </Typography>
              <Typography variant="h6" gutterBottom className="text-center">
                <small>{buildCarName(challenge?.challengerCar)}</small>
              </Typography>
              <CardActionArea href={Routes.car + challenge?.challengerCarId}>
                <CardMedia
                  component="img"
                  image={getCarImageUrl(challenge?.challengerCarId)}
                  alt=""
                  style={classes.carImage}
                />
                {challenge?.challengerCar?.specs && (
                  <CarSpecificationsList
                    specs={challenge?.challengerCar?.specs}
                    carId={challenge?.challengerCarId}
                    showImage={false}
                  />
                )}
              </CardActionArea>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h5" gutterBottom className="text-center">
                {challenge?.challengeeCar.name}
              </Typography>
              <Typography variant="h6" gutterBottom className="text-center">
                <small>{buildCarName(challenge?.challengeeCar)}</small>
              </Typography>
              <CardActionArea href={getCarImageUrl(challenge?.challengeeCarId)}>
                <CardMedia
                  component="img"
                  image={getCarImageUrl(challenge?.challengeeCarId)}
                  alt=""
                  style={classes.carImage}
                />
                {challenge?.challengeeCar?.specs && (
                  <CarSpecificationsList
                    specs={challenge?.challengeeCar?.specs}
                    carId={challenge?.challengeeCarId}
                    showImage={false}
                  />
                )}
              </CardActionArea>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </Paper>
  
      <br />
  
      <Paper style={classes.root}>
        <Card sx={{borderRadius: 5}}>
          <CardContent>
            <Typography variant="h5" gutterBottom className="text-center">
              Winner
            </Typography>
            <Typography paragraph className="text-center">
              {challenge?.resultsId !== null ? (
                <a
                  href={`/${challenge?.results?.winnerCar?.user?.username}`}
                  className="instagram-tag"
                >
                  {challenge?.results?.winnerCar?.user?.username}'s {challenge?.results?.winnerCar?.name}
                </a>
              ) : (
                'TBD'
              )}
            </Typography>
            <Grid container spacing={2} className="mb-3">
              <Grid item sm={6} xs={12}>
                <ChallengeListItemResult
                  car={challenge?.challengerCar}
                  bestTime={9.12}
                  bestReaction={0.741}
                  topSpeed={74}
                  totalRuns={3}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ChallengeListItemResult
                  car={challenge?.challengeeCar}
                  bestTime={8.42}
                  bestReaction={0.991}
                  topSpeed={85}
                  totalRuns={3}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
  
      <br />
    
      <Paper style={classes.root}>
        <Card sx={{borderRadius: 5}}>
          <CardContent>
            <Typography variant="h5" gutterBottom className="text-center mb-3">
              Gallery
            </Typography>
            <ChallengeCarousel challenge={challenge} />
          </CardContent>
        </Card>
      </Paper>
  
      <ViewRaceTrackDetailsDialog
        open={open}
        onClose={handleClose}
        track={challenge?.raceTrack}
      />
    </>
  );
};