import { User, UserVideoStream } from '../../types';

import '../../css/chat/index.css';

export interface UserListProps {
  users: { [userId: string]: User };
  streams: UserVideoStream[];
  onMinimizeToggle: (stream: UserVideoStream) => void;
};

interface UserListItemProps {
  users: { [userId: string]: User };
  stream: UserVideoStream;
  onMinimizeToggle: (stream: UserVideoStream) => void;
};

const UserListItem = (props: UserListItemProps) => {
  //console.log('UserListItem props:', props);
  const { users, stream, onMinimizeToggle } = props;
  const user = users[stream.userId];

  return (
    <li>
      <label htmlFor={user?.id}>
        <input
          id={user?.id}
          type='checkbox'
          checked={stream?.windowState !== 'minimized'}
          onChange={() => onMinimizeToggle(stream)}
        />
        {user?.username ?? stream.userId} ({stream.type})
      </label>
    </li>
  );
};

export const UserList = (props: UserListProps) => {
  //console.log('UserList props:', props);
  const { users, streams, onMinimizeToggle } = props;

  return (
    <div className='users bg-dark'>
      <ul className='users-list'>
        {streams && streams?.map((stream: UserVideoStream, index: number) => (
          <UserListItem
            key={index}
            users={users}
            stream={stream}
            onMinimizeToggle={onMinimizeToggle}
          />
        ))}
      </ul>
      <div></div> {/*necessary for flex to stretch */}
    </div>
  );  
};