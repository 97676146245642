import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';

export const CarSpecificationsForm = (props: any) => {
  const { specs, onChange } = props;

  return (
    <List
      dense
      disablePadding
    >
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <ListItemText primary="Engine Size" />
        <TextField
          id="engineSize"
          name="engineSize"
          type="text"
          variant="outlined"
          size="small"
          value={specs?.engineSize}
          placeholder="4-Cylinder 1.6L"
          onChange={onChange}
        />
      </ListItem>
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <ListItemText primary="Horsepower" />
        <TextField
          id="horsepower"
          name="horsepower"
          type="text"
          variant="outlined"
          size="small"
          value={specs?.horsepower}
          placeholder="283"
          onChange={onChange}
        />
      </ListItem>
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <ListItemText primary="Displacement" />
        <TextField
          id="displacement"
          name="displacement"
          type="text"
          variant="outlined"
          size="small"
          value={specs?.displacement}
          placeholder="Supercharger"
          onChange={onChange}
        />
      </ListItem>
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <ListItemText primary="Weight (lbs)" />
        <TextField
          id="weight"
          name="weight"
          type="text"
          variant="outlined"
          size="small"
          value={specs?.weight}
          placeholder="2,600"
          onChange={onChange}
        />
      </ListItem>
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <ListItemText primary="0-60 Time (sec)" />
        <TextField
          id="timeTo60"
          name="timeTo60"
          type="text"
          variant="outlined"
          size="small"
          value={specs?.timeTo60}
          placeholder="4.73"
          onChange={onChange}
        />
      </ListItem>
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <ListItemText primary="Top Speed (MPH)" />
        <TextField
          id="topSpeed"
          name="topSpeed"
          type="number"
          variant="outlined"
          size="small"
          value={specs?.topSpeed}
          placeholder="150"
          onChange={onChange}
        />
      </ListItem>
      <ListItem disablePadding sx={{ py: 1, px: 0 }}>
        <FormControl fullWidth>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status"
            size="small"
            value={specs?.status}
            placeholder="Stock"
            onChange={onChange}
            input={<OutlinedInput label="Name" />}
          >
            <MenuItem key="Stock" value="Stock">
              Stock
            </MenuItem>
            <MenuItem key="Modified" value="Modified">
              Modified
            </MenuItem>
            <MenuItem key="NonOp" value="NonOp">
              Non-Operational
            </MenuItem>
          </Select>
        </FormControl>
      </ListItem>
    </List>
  );
};