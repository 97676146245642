import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';

import { useSidebarState } from '.';
import { set } from '../../modules/storage';

/**
 * A button that toggles the sidebar. Used by default in the <AppBar>.
 * @param props The component props
 * @param {String} props.className An optional class name to apply to the button
 */
export const SidebarToggleButton = (props: ISidebarToggleButtonProps) => {
  const { className } = props;
  const [open, setOpen] = useSidebarState();

  const handleOpenClick = () => {
    const value = !open;
    setOpen(value);
    set('sidebar.open', value);
  };

  return (
    <Tooltip
      className={className}
      title={open ? 'Close Menu' : 'Open Menu'}
      enterDelay={500}
    >
      <StyledIconButton color="inherit" onClick={handleOpenClick}>
        <MenuIcon
          classes={{
            root: open
              ? SidebarToggleButtonClasses.menuButtonIconOpen
              : SidebarToggleButtonClasses.menuButtonIconClosed,
          }}
        />
      </StyledIconButton>
    </Tooltip>
  );
};

export type ISidebarToggleButtonProps = {
  className?: string;
};

export const SidebarToggleButtonClasses = {
  menuButtonIconClosed: `RaSidebarToggleButton-menuButtonIconClosed`,
  menuButtonIconOpen: `RaSidebarToggleButton-menuButtonIconOpen`,
};

const StyledIconButton = styled(IconButton, {
  name: 'RaSidebarToggleButton',
  overridesResolver: (props: any, styles: any) => styles.root,
})(({ theme }: any) => ({
  [`& .${SidebarToggleButtonClasses.menuButtonIconClosed}`]: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },

  [`& .${SidebarToggleButtonClasses.menuButtonIconOpen}`]: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
}));