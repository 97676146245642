import {
  Box,
  Fade,
  IconButton,
  Popper,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { ChatMessenger } from '../../components';
import '../Messages/ChatMessageContainer/ChatMessageContainer.css';

export const ChatMessengerDialog = (props: any) => {
  //console.log('ChatMessengerDialog props:', props);
  const { open, anchor, onClose } = props;
  const canOpen = open && Boolean(anchor);
  const id = canOpen ? 'simple-popper' : undefined;

  return (
    <Popper
      id={id}
      open={canOpen}
      anchorEl={anchor}
      placement="right-start"
      //placement="left-end"
      //placement="top"
      sx={{zIndex: 1000}}
      //disablePortal
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box
            sx={{
              border: '1px solid grey',
              bgcolor: 'background.paper',
            }}
          >
            <IconButton
              title="Close messenger"
              onClick={onClose}
              className="float-end"
              style={{
                marginLeft: '94%',
                marginRight: 'auto',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom className="text-center">
              Messages
            </Typography>
            <ChatMessenger />
          </Box>
        </Fade>
      )}
    </Popper>
  );
};