import { createContext, useContext } from 'react';

import { SocketContextType } from '../contexts';

export const SocketContext = createContext<SocketContextType>({
  socket: null,
  activeRoomId: 0,
  rooms: [],
  messages: [],

  sendMessage: () => false,
  addMessage: () => false,
  addMessages: () => false,
  updateMessage: () => false,
  deleteMessage: () => false,
  clearMessages: () => false,
  getChatHistory: () => false,
  setActiveRoomId: () => false,

  createVideoCallRoom: () => false,
  joinVideoCallRoom: () => false,
  joinVideoCallRoomRelay: () => false,
  leaveVideoCallRoom: () => false,
});

export const useSockets = () => useContext(SocketContext);