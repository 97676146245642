import http from '../modules/http-common';
import { Story } from '../types';

const getStory = async (userId: number) => {
  if (!userId) {
    return;
  }
  const response = await http
    .get(`story/${userId}`);
  return response.data;
};

const getFollowerStories = async (userId: number) => {
  if (!userId) {
    return;
  }
  const response = await http
    .get(`story/followers/${userId}`);
  return response.data;
};

const createStory = async (story: Story) => {
  const response = await http
    .post('story/create', { story });
  return response.data;
};

const deleteStory = async (storyId: number) => {
  const response = await http
    .delete(`story/${storyId}/delete`);
  return response.data;
};

const likeStory = async (storyId: number, userId: number) => {
  const response = await http
    .post(`story/${storyId}/like`, { userId });
  return response.data;
};

const markRead = async (storyId: number, userId: number) => {
  const response = await http
    .get(`story/${storyId}/read?userId=${userId}`);
  return response.data;
};

const hasSeenStory = async (storyId: number, userId: number) => {
  const response = await http
    .get(`story/${storyId}/seen?userId=${userId}`);
  return response.data;
};

export const StoryService = {
  getStory,
  getFollowerStories,
  createStory,
  deleteStory,
  likeStory,
  markRead,
  hasSeenStory,
};