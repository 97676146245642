import { createContext, useContext } from 'react';

import { VideoCallContextType } from '../contexts';

export const VideoCallContext = createContext<VideoCallContextType>({
  peerIds: [],
  callState: {
    creatorUserId: 0,
    openAnswerCallDialog: false,
    openOutgoingCallDialog: false,
    roomId: 0,
    incomingCall: null,
    dialState: 'hung-up',
    localStream: null,
    desktopStream: null,
  },
  messages: [],
  participants: {},
  usersOnline: {},

  callUsers: () => false,
  answerCall: () => false,
  declineCall: () => false,
  cancelCall: () => false,
  leaveCall: () => false,

  onMinimizeStream: () => false,
  onMaximizeStream: () => false,

  onAddDesktopStream: () => false,
  onRemoveDesktopStream: () => false,

  onSendMessage: () => false,
  onSendFileMessage: () => false,
});

export const useVideoCall = () => useContext(VideoCallContext);