import React, { ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';

export const UploadImages = (props: any) => {
  //console.log('UploadImages props:', props);
  const [state, setState] = useState<any>({
    files: props.state.images ?? [],
    progress: 0,
    message: '',
  });

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.target.files;
    const files = [];
    const length = fileList?.length ?? 0;
    for (let i = 0; i < length; i++) {
      const file: any = fileList?.item(i);
      files.push({
        file,
        id: file.name,
        name: file.name,
        preview: URL.createObjectURL(file),
      });
    }
    setState({
      files,
      progress: 0,
      message: '',
    });

    const images: File[] = [];
    files.forEach((file: any) => images.push(file));
    props.setState({...props.state, images})
  };

  return (
    <>
      <label className="btn btn-default p-0 pb-3">
        <input
          id="file"
          name="file"
          type="file"
          accept="image/*"
          onChange={selectFile}
          multiple
          style={{
            color: 'white',
            width: '100%',
          }}
        />
      </label>
      <br />

      <Grid container spacing={2} style={{ justifyContent: 'center' }}>
        {state.files && state.files?.map((file: any) => file?.preview && (
          <Grid item key={file.file.name} lg={3} md={4} xs={6}>
            <img
              src={file.preview}
              alt=""
              style={{
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
                maxWidth: '200px',
                maxHeight: '200px',
              }}
            />
          </Grid>
        ))}
      </Grid>

      {state.message && (
        <div className="alert alert-secondary mt-3" role="alert">
          {state.message}
        </div> 
      )}
    </>
  );
};