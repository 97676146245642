import { AES, enc } from 'crypto-js';
const Buffer = require('buffer').Buffer;

const secretKey = '10_2#@#r!twHeI6q603IK:S8fx9SWVXbpQoh0';

export const encrypt = (text: any) => {
  try {
    const data = JSON.stringify(text);
    const encrypted = AES.encrypt(data, secretKey);
    return encrypted?.toString();
  } catch (err) {
    console.error('encrypt error:', err);
  }
  return null;
};

export const encryptFile = (file: any) => btoa(file);
export const encryptFiles = (files: any) => {
  const encrypted = [];
  for (const file of files) {
    const data = btoa(file);
    encrypted.push(data);
  }
  return encrypted;
};

export const decrypt = (cipher: any) => {
  try {
    const decrypted = AES.decrypt(cipher, secretKey);
    const plainText = decrypted?.toString(enc.Utf8);
    const data = JSON.parse(plainText);
    return data;
  } catch (err) {
    console.error('decrypt error:', err);
  }
  return null;
};

export const decryptFile = (file: any) => atob(file);
export const decryptFiles = (files: any) => {
  const decrypted = [];
  for (const file of files) {
    const data = decryptFile(file);
    decrypted.push(data);
  }
  return decrypted;
};

export const atob = (encoded: any) => Buffer.from(encoded, 'base64').toString('utf-8');
export const btoa = (decoded: any) => Buffer.from(decoded).toString('base64');