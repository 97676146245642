import React, { useState } from 'react';
import {
  Avatar,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  Comment as CommentIcon,
  EmojiEvents as EmojiEventsIcon,
  Event as EventIcon,
  Note as NoteIcon,
  People as PeopleIcon,
  Search as SearchIcon,
  TimeToLeave as CarIcon,
} from '@mui/icons-material';

import { Routes } from '../../consts';
import { UserAvatar } from '..';
import { getAvatarUrl, getCarImageUrl } from '../../modules';

export const SearchBar = (props: any) => {
  //console.log('SearchBar props:', props);
  const { results, onSearch, showResults = true, } = props;
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const handleSearchChanged = (event: any) => {
    const { target: { value }  } = event;
    //console.log('handleSearchChanged:', value);
    setAnchorEl(value.length > 0 ? event.target : null);
    onSearch && onSearch(value);
  };

  const handleCloseResults = () => setAnchorEl(null);

  return (
    <Search title="Search...">
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        //placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearchChanged}
      />
      {showResults && (
        <SearchResults
          onClose={handleCloseResults}
          anchorEl={anchorEl}
          results={results}
        />
      )}
    </Search>
  );
};

const Search = styled('div')(({ theme }: any) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  //backgroundColor: alpha(theme.palette.common.white, 0.15),
  //'&:hover': {
  //  backgroundColor: alpha(theme.palette.common.white, 0.25),
  //},
  marginLeft: theme.spacing(0),
  //width: '100%',
  [theme.breakpoints.up('sm')]: {
    //marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }: any) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }: any) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.only('xs')]: {
      width: '5ch',
      '&:focus': {
        width: '10ch',
      },
    },
    [theme.breakpoints.only('sm')]: {
      width: '5ch',
      '&:focus': {
        width: '14ch',
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '5ch',
      '&:focus': {
        width: '25ch',
      },
    },
    '&:focus': {
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      backgroundColor: alpha(theme.palette.common.white, 0.25),
      borderRadius: '20px',
    },
  },
}));

const SearchResults = (props: any) => {
  //console.log('SearchResults props:', props);
  const { results, onClose, anchorEl } = props;
  const open = Boolean(anchorEl);
  const subHeaders = Object.keys(results || {});
  const values: any[] = Object.values(results || {});
  const merged = [].concat(...values);

  return (
    <Popover
      disableAutoFocus
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <List
        dense
        style={{ width: '31ch', paddingBottom: 0 }}
        subheader={<li />}
      >
        {merged && merged.length > 0 ? (
          subHeaders.map((header: string) => {
            const items = results[header];
            return items && items.length > 0 && (
              <li key={`section-${header}`}>
                <ul style={{paddingLeft: 0}}>
                  <ListSubheader>
                    {getSearchIcon(header)}&nbsp;{getSearchHeader(header)}
                  </ListSubheader>
                  {items.map((item: any) => (
                    <ListItemButton
                      dense
                      key={item.id}
                      style={{
                        height: '32px',
                        marginTop: 3,
                        marginBottom: 3,
                        color: 'inherit',
                      }}
                      href={getSearchUrl(header, item)}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ width: 28, height: 28 }}>
                          {getSearchResultIcon(header, item)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={getSearchValue(header, item)}
                        //secondary={'Secondary text'}
                      />
                    </ListItemButton>
                  ))}
                </ul>
              </li>
            );
          })
        ) : (
          <ListItem
            dense
            style={{
              height: '32px',
              marginTop: 3,
              marginBottom: 3,
              color: 'inherit',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <ListItemText
              primary="No search results found..."
              //secondary={'Secondary text'}
            />
          </ListItem>
        )}
      </List>
    </Popover>
  );
};

const getSearchValue = (type: string, item: any) => {
  switch (type) {
    case 'users': return item.username;
    case 'cars': return item.name;
    case 'posts': return item.body.substr(0, Math.min(15, item.body.length));
    case 'comments': return item.text.substr(0, Math.min(15, item.text.length));
    case 'challenges':
    case 'events': return item.id;
    default: return '';
  }
};

const getSearchIcon = (type: string) => {
  switch (type) {
    case 'users': return <PeopleIcon />;
    case 'cars': return <CarIcon />;
    case 'posts': return <NoteIcon />;
    case 'comments': return <CommentIcon />;
    case 'challenges': return <EmojiEventsIcon />;
    case 'events': return <EventIcon />;
    default: return '';
  }
};

const getSearchResultIcon = (type: string, item: any) => {
  switch (type) {
    case 'users': return <UserAvatar fileName={getAvatarUrl(item.id)} />;
    case 'cars': return <UserAvatar fileName={getCarImageUrl(item.id)} />;
    case 'posts': return <NoteIcon />;
    case 'comments': return <CommentIcon />;
    case 'challenges': return <EmojiEventsIcon />;
    case 'events': return <EventIcon />;
    default: return '';
  }
};

const getSearchHeader = (type: string) => {
  switch (type) {
    case 'users': return 'Members';
    case 'cars': return 'Cars';
    case 'posts': return 'Posts';
    case 'comments': return 'Comments';
    case 'challenges': return 'Challenges';
    case 'events': return 'Events';
    default: return type;
  }
};

const getSearchUrl = (type: string, item: any) => {
  switch (type) {
    case 'users': return `/${item.username}`;
    case 'cars': return Routes.car + item.id;
    case 'posts': return `/posts/${item.id}`;
    case 'comments': return `/comments/${item.id}`;
    case 'challenges': return Routes.challenges + item.id;
    case 'events': return Routes.events + item.id;
    default: return Routes.home;
  }
};