import {
  Box,
  Divider,
  Drawer,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';

import { CalendarSmall } from '.';
import { useSidebarState } from '../Sidebar';

const DrawerWidth = 250; // 260
const DrawerSidebarOpenWidth = 25;
const DrawerSidebarClosedWidth = 7;

const useStyles: any = ((theme: Theme, isSidebarOpen: boolean, isSmall: boolean = false) => ({
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DrawerWidth,
    marginLeft: isSidebarOpen
      ? isSmall
        ? 0
        : DrawerSidebarOpenWidth
      : isSmall
        ? 0
        : DrawerSidebarClosedWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // TODO: padding: '0 8px',
    justifyContent: 'flex-end',
  },
  calendarSmall: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
    minHeight: 265,
    //minWidth: 240, //260,
    background: theme.palette.background.paper,
  },
}));

export const CalendarDrawer = (props: any) => {
  //console.log('CalendarDrawer props:', props);
  const { open, onDrawerClose } = props;
  const [isSidebarOpen] = useSidebarState();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme, isSidebarOpen, isSmall);

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={open}
      style={classes.drawer}
      PaperProps={{
        sx: classes.drawerPaper
      }}
    >
      <Box sx={{overflow: 'auto'}}>
        <div style={classes.drawerHeader}>
          <IconButton onClick={onDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <div style={classes.calendarSmall}>
          <CalendarSmall />
        </div>
        <Divider />
      </Box>
    </Drawer>
  );
};