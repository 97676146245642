import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { TimeToLeave as CarIcon } from '@mui/icons-material';

import { CarsDialog } from '../Dialogs';

export const ViewUserCarsButton = (props: any) => {
  //console.log('ViewUserCarsButton props:', props);
  const { isCurrentUser, currentUser, profile, onReload } = props;
  const [open, setOpen] = useState(false);

  const handleOpenCarsDialog = () => setOpen(true);
  const handleCloseCarsDialog = () => setOpen(false);

  return (
    <>
      <IconButton
        title={`View ${profile?.username}'s car garage`}
        onClick={handleOpenCarsDialog}
      >
        <CarIcon />
      </IconButton>
      <CarsDialog
        open={open}
        title={`${profile?.username}'s Garage`}
        profile={profile}
        currentUser={currentUser}
        onClose={handleCloseCarsDialog}
      />
    </>
  );
};