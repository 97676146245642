import React, { memo, useContext } from 'react';
import { format } from 'date-fns';
import { Theme, useTheme } from '@mui/material';

import { CalendarContext } from '.';

export const EventMarkGhost = memo((props: any) => {
  //console.log('EventMarkGhost props:', props);
  const { dataDate, dataHour, dataMin } = props;
  const { stateCalendar } = useContext(CalendarContext);
  const { ghostProperties, calendarEvent } = stateCalendar;
  const newDate = new Date(format(new Date(dataDate), `yyyy/MM/dd ${dataHour}:${dataMin}:s`));

  const useStyles: any = ((theme: Theme) => ({
    root: {
      display: 'inline-block',
      //margin: 3,
      marginBottom: 1,
      //border: '2px dashed rgba(66, 165, 245, 0.8)',
      border: `2px dashed white`,
      borderRadius: 3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: 8,
      marginLeft: -4,
    },
    box: {
      //backgroundColor: 'rgba(66, 165, 245, 0.6)',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 3,
      lineHeight: '31px',
      cursor: 'move',
      fontSize: 10,
      width: ghostProperties.width,
      height: ghostProperties.height,
      padding: 8,
    },
    title: {
      lineHeight: '8px',
      fontSize: 12,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginBottom: 8,
      textAlign: 'center',
    },
    dateTime: {
      textAlign: 'center',
      lineHeight: '8px',
    },
  }));

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div style={classes.root}>
      <div style={classes.box} data-ghost data-date={newDate}>
        <div style={classes.title}>{calendarEvent.title}</div>
        <p style={classes.dateTime}>
          {format(newDate, `eee MMM dd yyyy`)}
          <br />
          <br />
          {format(newDate, `hh:mm:ss a`)}
        </p>
      </div>
    </div>
  );
});