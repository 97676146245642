import React, { useEffect, useState } from 'react';

import { DEVICE_DEFAULT_ID, DEVICE_DISABLED_ID } from '../components';
import { MediaDeviceContext } from '../hooks';
import { VideoCallSettings, VideoCallState } from '../types';

export type MediaDeviceContextType = {
  localSettings: VideoCallSettings;
  onSetLocalSettings: (localSettings: VideoCallSettings) => void;

  onSetMediaDevice: (type: MediaDeviceKind, deviceId: string) => void;
  onStartStream: (videoDeviceId?: string, audioInDeviceId?: string) => void;
  onToggleMic: () => void;
  onToggleVideo: () => void;
  onToggleRemoteAudio: (userId: number) => void;
};

export interface MediaDeviceContextProviderProps {
  callState: VideoCallState;
  children: any;
};

export const MediaDeviceContextProvider = (props: MediaDeviceContextProviderProps) => {
  const { callState, children } = props;
  const [localSettings, setLocalSettings] = useState<VideoCallSettings>({
    isAudioInEnabled: false,
    isVideoEnabled: true,
    devices: {
      audioInputs: [],
      audioOutputs: [],
      videoInputs: [],
    },
    selectedAudioInDevice: DEVICE_DEFAULT_ID,
    selectedAudioOutDevice: DEVICE_DEFAULT_ID,
    selectedVideoDevice: DEVICE_DEFAULT_ID,

    remoteAudio: {},
  });

  const getMediaDevices = () => {
    navigator.mediaDevices?.enumerateDevices().then(async (devices: MediaDeviceInfo[]) => {
      //console.log('devices:', devices);
      const audioOutDevices = devices.filter((device: MediaDeviceInfo) => device.kind === 'audiooutput');
      const audioInDevices = devices.filter((device: MediaDeviceInfo) => device.kind === 'audioinput');
      const videoDevices = devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput');
  
      setLocalSettings({
        ...localSettings,
        devices: {
          audioInputs: audioInDevices,
          audioOutputs: audioOutDevices,
          videoInputs: videoDevices,
        },
        selectedAudioOutDevice: !localSettings.selectedAudioOutDevice || localSettings.selectedAudioOutDevice === DEVICE_DEFAULT_ID
          ? audioOutDevices[0]?.deviceId || DEVICE_DEFAULT_ID
          : localSettings.selectedAudioOutDevice === DEVICE_DISABLED_ID
            ? DEVICE_DISABLED_ID
            : localSettings.selectedAudioOutDevice,
        selectedAudioInDevice: !localSettings.selectedAudioInDevice || localSettings.selectedAudioInDevice === DEVICE_DEFAULT_ID
          ? audioInDevices[0]?.deviceId || DEVICE_DEFAULT_ID
          : localSettings.selectedAudioInDevice === DEVICE_DISABLED_ID
            ? DEVICE_DISABLED_ID
            : localSettings.selectedAudioInDevice,
        selectedVideoDevice: !localSettings.selectedVideoDevice || localSettings.selectedVideoDevice === DEVICE_DEFAULT_ID
          ? videoDevices[0]?.deviceId || DEVICE_DEFAULT_ID
          : localSettings.selectedVideoDevice === DEVICE_DISABLED_ID
            ? DEVICE_DISABLED_ID
            : localSettings.selectedVideoDevice,
      });
    });
  };

  const handleStartStream = async (videoDeviceId?: string, audioInDeviceId?: string) => {
    //video: {
    //  cursor: 'always' | 'motion' | 'never',
    //  displaySurface: 'application' | 'browser' | 'monitor' | 'window'
    //}

    const videoInputDeviceId = videoDeviceId ?? localSettings.selectedVideoDevice;
    const audioInputDeviceId = audioInDeviceId ?? localSettings.selectedAudioInDevice;

    const constraints = {
      video: !!videoInputDeviceId //|| videoInputDeviceId === DEVICE_DEFAULT_ID
        ? videoInputDeviceId === DEVICE_DISABLED_ID
          ? false
          : { deviceId: { exact: videoInputDeviceId } }
        : true,
      audio: !!audioInputDeviceId //|| audioInputDeviceId === DEVICE_DEFAULT_ID
        ? audioInputDeviceId === DEVICE_DISABLED_ID
          ? false
          : { deviceId: { exact: audioInputDeviceId } }
        : true,
    };
    //console.log('constraints:', constraints);
    const currentStream = await navigator.mediaDevices?.getUserMedia(constraints);
    callState.localStream = currentStream;

    // TODO: setParticipants(prev => {
    //  prev[currentUser?.id] = {
    //    ...prev[currentUser?.id],
    //    camera: { userId: currentUser?.id, type: VideoStreamTypeCamera, stream: currentStream, isLocal: true },
    //    // TODO: desktop: undefined, //prev[currentUser?.id]?.desktop,
    //  };
    //  return prev;
    //});
  };

  const handleToggleVideoLocal = () => {
    const videoTracks = callState.localStream!.getVideoTracks();
    const value = !localSettings.isVideoEnabled;
    videoTracks?.forEach((track: MediaStreamTrack) => track.enabled = value);
    setLocalSettings({
      ...localSettings,
      isVideoEnabled: value,
    });
  };

  const handleToggleAudioLocal = () => {
    const audioTracks = callState.localStream!.getAudioTracks();
    const value = !localSettings.isAudioInEnabled;
    audioTracks?.forEach((track: MediaStreamTrack) => track.enabled = value);
    setLocalSettings({
      ...localSettings,
      isAudioInEnabled: value,
    });
  };

  const handleToggleAudioRemote = (userId: number) => {
    const audioState = Object.assign({}, localSettings.remoteAudio);
    audioState[userId] = !!audioState[userId]
      ? !audioState[userId]
      : true;
    setLocalSettings({
      ...localSettings,
      remoteAudio: audioState,
    });
  };

  const handleSetMediaDevice = (type: MediaDeviceKind, deviceId: string) => {
    //console.log('handleSetMediaDevice:', type, deviceId);
    switch (type) {
      case 'audioinput':
        setLocalSettings({
          ...localSettings,
          selectedAudioInDevice: deviceId,
        });
        break;
      case 'audiooutput':
        setLocalSettings({
          ...localSettings,
          selectedAudioOutDevice: deviceId,
        });
        break;
      case 'videoinput':
        setLocalSettings({
          ...localSettings,
          selectedVideoDevice: deviceId,
        });
        break;
    }
    handleStartStream(
      type === 'videoinput'
        ? deviceId
        : localSettings.selectedVideoDevice,
      type === 'audioinput'
        ? deviceId
        : localSettings.selectedAudioInDevice
      );
  };

  useEffect(() => {
    const hasGetUserMedia = () => {
      const nav: any = navigator;
      const getUserMedia = 
        nav.getUserMedia ||
        nav.mediaDevices?.getUserMedia ||
        nav.mediaDevices?.mozGetUserMedia ||
        nav.mediaDevices?.msGetUserMedia ||
        nav.mediaDevices?.webkitGetUserMedia;
      return !!getUserMedia;
    };

    if (!hasGetUserMedia()) {
      console.error('Unable to getUserMedia, unsupported browser or non-secure host');
      return;
    }

    getMediaDevices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MediaDeviceContext.Provider
      value={{
        localSettings,
        onSetLocalSettings: setLocalSettings,

        onSetMediaDevice: handleSetMediaDevice,
        onStartStream: handleStartStream,
        onToggleMic: handleToggleAudioLocal,
        onToggleVideo: handleToggleVideoLocal,
        onToggleRemoteAudio: handleToggleAudioRemote,
      }}
    >
      {children}
    </MediaDeviceContext.Provider>
  );
};