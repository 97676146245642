import React, { useEffect, useRef, useState } from 'react';
import Peer, { DataConnection, MediaConnection } from 'peerjs';

import { DEVICE_DISABLED_ID, DialState } from '../components';
import { useMediaDevices, useSockets, VideoCallContext } from '../hooks';
import { packageFileMessage } from '../modules';
import { getUserToken } from '../stores';
import { 
  User,
  UserVideoStream,
  VideoCallMessage,
  VideoCallParticipants,
  VideoCallState,
  VideoStreamPeer,
  VideoStreamTypeCamera,
  VideoStreamTypeDesktop,
} from '../types';

// Credits: https://stackoverflow.com/a/75265696
//(window as any).global = window;
//(window as any).process = process;
//(window as any).Buffer = [];

const peerConfig = {
  host: '/',
  port: 5000,
  path: '/peerjs',
};

export type VideoCallContextType = {
  callState: VideoCallState | null;
  messages: VideoCallMessage[];
  participants: VideoCallParticipants;
  peerIds: number[];
  usersOnline: { [userId: string]: User};

  callUsers: (userIds: number[]) => void;
  answerCall: () => void;
  declineCall: () => void;
  cancelCall: (force: boolean, dialState: DialState) => void;
  leaveCall: (roomId: number) => void;

  onMinimizeStream: (video: UserVideoStream) => void;
  onMaximizeStream: (video: UserVideoStream) => void;

  onAddDesktopStream: (stream: MediaStream) => void;
  onRemoveDesktopStream: (userId: number) => void;

  onSendMessage: (message: VideoCallMessage) => void;
  onSendFileMessage: (message: VideoCallMessage) => void;
};

// Reference: https://cloudinary.com/blog/guest_post/stream-videos-with-webrtc-api-and-react
// Reference: https://webrtc.github.io/samples/
export const VideoCallContextProvider = ({ children }: any) => {
  // #region States
  const localPeer = useRef<Peer>();
  const peersRef = useRef<{ [userId: number]: VideoStreamPeer }>({});
  const [messages, setMessages] = useState<VideoCallMessage[]>([]);
  const [participants, setParticipants] = useState<VideoCallParticipants>({});
  const [peerIds, setPeerIds] = useState<number[]>([]);
  const [usersOnline, setUsersOnline] = useState<{ [userId: number]: User | any }>({});

  const [callState, setCallState] = useState<VideoCallState>({
    creatorUserId: 0,
    openAnswerCallDialog: false,
    openOutgoingCallDialog: false,
    roomId: 0,
    incomingCall: null,
    dialState: 'hung-up',
    localStream: null,
    desktopStream: null,
  });

  const currentUser = getUserToken();

  const { localSettings } = useMediaDevices();

  const {
    socket,
    createVideoCallRoom,
    joinVideoCallRoom,
    joinVideoCallRoomRelay,
    leaveVideoCallRoom,
  } = useSockets();
  // #endregion

  // #region Video Call Methods
  const endCall = () => {
    if (callState.incomingCall) {
      callState.incomingCall.close();
    }
  };

  const hangUp = () => {
    //if (callState.dialState === 'hung-up') {
    //  return;
    //}

    setCallState({
      ...callState,
      openAnswerCallDialog: false,
      openOutgoingCallDialog: false,
      roomId: 0,
      incomingCall: null,
      dialState: 'hung-up',
    });
  };

  const createPeer = (userId: number) => {
    const peer = new Peer(userId.toString(), peerConfig);
    peer.on('connection', (conn: DataConnection) => {
      conn.on('open', () => addPeer(parseInt(conn.peer), peer, conn));
      conn.on('data', (message: any) => addMessage(JSON.parse(message)));
    });
    peer.on('call', handleIncomingCall);
    peer.on('disconnected', (currentId: string) => {
      console.debug('peer.disconnected', currentId, userId);
      if (!peer.destroyed) {
        console.debug('peer.disconnected, reconnecting...', currentId, userId);
        peer.reconnect();
      }
    })
    peer.on('close', () => {
      console.debug('peer.close', userId);
      if (!peer.destroyed) {
        console.debug('peer.close, reconnecting...', userId);
        peer.reconnect();
      }
    })
    peer.on('error', console.error);    
    return peer;
  };

  const addPeer = (userId: number, peer: Peer, data: DataConnection) => {
    peersRef.current[userId] = { userId, peer, data };
  };

  const handleStartStream = async (videoDeviceId?: string, audioInDeviceId?: string) => {
    //video: {
    //  cursor: 'always' | 'motion' | 'never',
    //  displaySurface: 'application' | 'browser' | 'monitor' | 'window'
    //}

    const videoInputDeviceId = videoDeviceId ?? localSettings.selectedVideoDevice;
    const audioInputDeviceId = audioInDeviceId ?? localSettings.selectedAudioInDevice;

    const constraints = {
      video: !!videoInputDeviceId //|| videoInputDeviceId === DEVICE_DEFAULT_ID
        ? videoInputDeviceId === DEVICE_DISABLED_ID
          ? false
          : { deviceId: { exact: videoInputDeviceId } }
        : true,
      audio: !!audioInputDeviceId //|| audioInputDeviceId === DEVICE_DEFAULT_ID
        ? audioInputDeviceId === DEVICE_DISABLED_ID
          ? false
          : { deviceId: { exact: audioInputDeviceId } }
        : true,
    };
    //console.log('constraints:', constraints);
    const currentStream = await navigator.mediaDevices?.getUserMedia(constraints);
    callState.localStream = currentStream;

    setParticipants(prev => ({
      ...prev,
      [currentUser?.id]: {
        ...prev[currentUser?.id],
        camera: { userId: currentUser?.id, type: VideoStreamTypeCamera, stream: currentStream, isLocal: true },
      },
    }));
  };

  const handleCallStream = (roomId: number, stream: UserVideoStream) => {
    //console.log('handleCallStream:', roomId, stream);
    if (callState.dialState !== 'in-call') {
      setCallState({
        ...callState,
        openAnswerCallDialog: false,
        openOutgoingCallDialog: false,
        roomId,
        dialState: 'in-call',
      });
    }
    setParticipants(prev => ({
      ...prev,
      [stream.userId]: {
        camera: stream.type === VideoStreamTypeCamera ? stream : prev[stream.userId]?.camera,
        desktop: stream.type === VideoStreamTypeDesktop ? stream : prev[stream.userId]?.desktop,
      },
    }));
  };

  const handleIncomingCall = (call: MediaConnection) => {
    //console.log('handleIncomingCall:', call);
    const peerId = parseInt(call.peer);
    const { roomId, streamType, ignoreAnswer } = call?.metadata;

    if (ignoreAnswer) {
      if (call && callState.localStream) {
        call.answer(callState.localStream);
        call.on('stream', (remoteStream) =>
          handleCallStream(roomId, {
            userId: peerId,
            type: streamType,
            stream: remoteStream,
          })
        );
  
        if (callState.dialState !== 'in-call') {
          setCallState({
            ...callState,
            openAnswerCallDialog: false,
            openOutgoingCallDialog: false,
            dialState: 'in-call',
            roomId: roomId ?? 0,
          });
        }
      }
    } else {
      if (!callState.incomingCall && peerId !== currentUser?.id && callState.dialState !== 'dialing') {
        setCallState({
          ...callState,
          openAnswerCallDialog: true,
          roomId: roomId ?? 0,
          incomingCall: call,
          dialState: 'dialing',
        });
      }
    }
  };
  // #endregion

  // #region Stream Desktop Methods
  const handleAddDesktopStream = (stream: MediaStream) => {
    //console.log('handleAddDesktopStream:', stream);
    const roomId = callState.roomId;
    const userIds = Object.keys(participants);
    for (const userId of userIds) {
      const peerId = parseInt(userId);
      if (peerId === currentUser?.id) {
        continue;
      }
      const call = localPeer.current?.call(peerId.toString(), stream, {
        metadata: {
          roomId,
          streamType: VideoStreamTypeDesktop,
          ignoreAnswer: true,
        },
      });
      call?.on('stream', (remoteStream) => handleCallStream(roomId, {
        userId: currentUser?.id,
        type: call.metadata.streamType,
        stream,
      }));

      peersRef.current[peerId] = {
        ...peersRef.current[peerId]!,
        userId: peerId,
        peer: localPeer.current!,
      };
    }

    // TODO: Possibly don't show actual user desktop to current user
    const currentUserId = currentUser?.id;
    setParticipants(prev => ({
      ...prev,
      [currentUserId]: {
        ...prev[currentUserId],
        desktop: { userId: currentUserId, type: VideoStreamTypeDesktop, stream },
      },
    }));
  };

  const handleRemoveDesktopStream = (userId: number) => {
    console.log('handleRemoveDesktopStream:', userId, participants);
    const newUsers = Object.assign({}, participants);
    const desktopStream = newUsers[userId].desktop;
    if (desktopStream?.stream) {
      const stream = desktopStream.stream;
      const tracks = stream.getTracks();
      tracks?.forEach((track: MediaStreamTrack) => {
        console.log('stopping and removing desktop stream and tracks:', userId, track);
        track.stop();
        stream.removeTrack(track);
      });
    }
    newUsers[userId].desktop = undefined;
    setParticipants(newUsers);
  };
  // #endregion

  // #region Video Call Interaction Methods
  const handleCallUser = (roomId: number, userId: number, userIds: number[]) => {
    if (!callState.localStream || userId === currentUser?.id) {
      return;
    }
    joinVideoCallRoomRelay(roomId, userId, userIds);
  };

  const handleCallUsers = (userIds: number[]) => {
    //console.log('handleCallUsers:', userIds);
    if (callState.dialState === 'in-call') {
      const result = window.confirm(`You are currently in an active call, proceeding will end the call and start a new one. Do you wish to continue?`);
      if (!result) {
        return;
      }
      console.warn('ending existing call:', callState);
      handleHangUpCall(callState.roomId);
    }

    if (callState.dialState !== 'dialing') {
      setCallState({
        ...callState,
        dialState: 'dialing',
      });
    }

    setPeerIds(userIds);

    createVideoCallRoom(currentUser?.id, userIds);
  };

  const handleAcceptCall = () => {
    const { roomId, streamType } = callState.incomingCall?.metadata;
    //console.log('metadata:', callState.incomingCall?.metadata);
    if (callState.incomingCall && callState.localStream) {
      callState.incomingCall.answer(callState.localStream);
      callState.incomingCall.on('stream', (remoteStream) =>
        handleCallStream(callState.roomId, {
          userId: parseInt(callState.incomingCall?.peer!),
          type: streamType,
          stream: remoteStream,
        })
      );

      if (callState.dialState !== 'in-call') {
        setCallState({
          ...callState,
          openAnswerCallDialog: false,
          openOutgoingCallDialog: false,
          dialState: 'in-call',
          roomId,
        });
      }
    }
  };

  const handleDeclineCall = () => {
    endCall();
    hangUp();
  };

  const handleHangUpCall = (roomId: number) => {
    endCall();
    leaveVideoCallRoom(roomId, currentUser?.id);
    hangUp();
  };

  const handleCancelOutgoingCall = (force: boolean, dialState: DialState) => {
    //console.log('handleCancelOutgoingCall:', callState);
    if (force) {
      setCallState(prev => ({
        ...prev,
        openAnswerCallDialog: false,
        openOutgoingCallDialog: false,
        dialState,
      }));
    } else {
      setCallState(prev => {
        prev.openAnswerCallDialog = false;
        prev.openOutgoingCallDialog = false;
        prev.dialState = dialState;
        return prev;
      });
    }
  };
  // #endregion

  // #region Socket Event Handlers
  const handleCreateVideoRoom = (data: CreateVideoCallRoomResponse) => {
    //console.log('handleCreateVideoRoom:', data, callState);
    const { roomId, creatorUserId, userIds } = data;

    // Have room creator join room
    joinVideoCallRoom(roomId, creatorUserId, userIds);

    //if (callState.dialState === 'hung-up') {
    if (callState.dialState !== 'in-call') {
      setCallState({
        ...callState,
        openAnswerCallDialog: creatorUserId !== currentUser?.id,
        openOutgoingCallDialog: creatorUserId === currentUser?.id,
        dialState: roomId > 0 ? 'in-call' : 'dialing',
        roomId,
      });
    }

    for (const userId of userIds) {
      handleCallUser(roomId, userId, userIds);
    }
  };

  const handleUserConnected = (data: UserConnectedResponse) => {
    //console.log('handleUserConnected:', data, peerIds);
    const { roomId, userId } = data;
    const conn = localPeer.current?.connect(userId.toString());
    conn?.on('open', () => addPeer(userId, localPeer.current!, conn));
    conn?.on('data', (data: any) => addMessage(JSON.parse(data)));

    const call = localPeer.current?.call(userId.toString(), callState.localStream!, {
      metadata: {
        roomId,
        streamType: VideoStreamTypeCamera,
      },
    });
    call?.on('stream', (remoteStream) => handleCallStream(roomId, {
      userId,
      type: call.metadata.streamType,
      stream: remoteStream,
    }));
  };

  const handleUserDisconnected = (data: UserDisconnectedResponse) => {
    console.log('handleUserDisconnected:', data);
    const { userId } = data;

    if (userId === currentUser?.id) {
      // If current user disconnected, only show current user streams
      if (participants[userId]) {
        setParticipants([participants[userId]]);
      }
    } else {
      // Otherwise remote disconnected user from participants
      const filteredParticipants = participants;
      delete filteredParticipants[userId];
      setParticipants(filteredParticipants);
    }

    // Check if any clients still connected
    const clients = Object.keys(participants).filter((id) => parseInt(id) !== currentUser?.id);
    if (clients.length === 0) {
      // No clients left, set dial state
      setCallState({
        ...callState,
        dialState: 'hung-up',
      });
    }

    const peerRef = peersRef.current[userId];
    if (!peerRef?.peer) {
      console.warn('failed to get peer with id:', userId, peersRef.current);
      return;
    }

    // End peer call
    endCall();

    try {
      // Close peer data connection
      if (peerRef?.data?.peer) {
        peerRef.data.close();
      }
      if (!peerRef?.peer?.disconnected) {
        // Disconnect peer connection
        peerRef.peer.disconnect();
      }
      // TODO: peerRef?.peer.destroy();
    } catch (err) {
      console.error(err);
    }

    // Remove user from connected peers list
    delete peersRef.current[userId];
  };

  const handleUserJoinedRoom = (data: UserJoinedRoomResponse) => {
    //console.log('handleUserJoinedRoom:', data);
    const { roomId, userId, userIds } = data;
    // Received join room relay request, join room if specified user is current user
    setPeerIds(userIds);
    if (currentUser?.id === userId) {
      joinVideoCallRoom(roomId, userId, userIds);
    }
  };

  const handleRoomFull = (data: RoomFullResponse) => {
    const { roomId, userId } = data;
    console.error(userId, 'failed to join room', roomId, 'room is full!');
  };
  // #endregion

  // #region Video Call Chat Message Methods
  const handleSendMessage = (message: VideoCallMessage) => {
    const ids = peerIds.filter((id) => id !== currentUser?.id);
    for (const userId of ids) {
      const peerRef = peersRef.current[userId];
      if (!peerRef?.peer) {
        console.warn('peer data connection not set:', message, peersRef.current, peerIds);
        continue;
      }
  
      const json = JSON.stringify(message, null, 2);
      peerRef.data?.send(json);
    }
    addMessage(message);
  };

  const handleSendFileMessage = async (message: VideoCallMessage) => {
    //console.log('handleSendFileMessage:', message, callState);
    if (!window.FileReader) {
      console.error('File API is not supported by your browser');
      return;
    }

    if ((message.files?.length ?? 0) === 0) {
      console.warn('no files specified to send:', message);
      return;
    }

    const base64Files = await packageFileMessage(message);
    const payload: VideoCallMessage = {
      ...message,
      type: 'file',
      base64Files,
      timestamp: new Date().getTime() / 1000,
    };
    for (const userId of peerIds!) {
      if (userId === currentUser?.id) {
        continue;
      }

      const peerRef = peersRef.current[userId];
      if (!peerRef?.peer) {
        console.warn('peer data connection not set:', message, peersRef.current);
        continue;
      }

      const json = JSON.stringify(payload, null, 2);
      peerRef.data?.send(json);
    }
    addMessage(payload);
  };

  const addMessage = (message: VideoCallMessage) => setMessages(prev => [...prev, message]);
  // #endregion

  useEffect(() => {
    if (!socket.connected) {
      console.log(socket.id, 'attemping to reconnect socket...');
      socket.connect();
    }

    handleStartStream(localSettings.selectedVideoDevice, localSettings.selectedAudioInDevice).then(() => {
      if (!localPeer.current) {
        localPeer.current = createPeer(currentUser?.id);
      }

      // Fetch list of users online
      socket?.on('users', setUsersOnline);

      // New user has joined video chat room
      socket?.on('user-connected', handleUserConnected);
      
      // User has left video chat room
      socket?.on('user-disconnected', handleUserDisconnected);
      
      // Handle create video call room response containing room id
      socket?.on('create-room', handleCreateVideoRoom);
      
      // Join room relay
      socket?.on('join-room', handleUserJoinedRoom);
      
      // Unable to join, room is at maximum capacity
      socket?.on('room-full', handleRoomFull);
      
      // Get list of online users
      socket?.emit('users');
    });

    // Handle disconnecting from the peer server
    window.addEventListener('beforeunload', () => {
      localPeer.current?.destroy();
    });

    return () => {
      if (!localPeer.current?.disconnected) {
        localPeer.current?.disconnect();
      }
      localPeer.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VideoCallContext.Provider
      value={{
        callState,
        messages,
        usersOnline,
        participants,
        peerIds: peerIds ?? [],

        callUsers: handleCallUsers,
        answerCall: handleAcceptCall,
        declineCall: handleDeclineCall,
        cancelCall: handleCancelOutgoingCall,
        leaveCall: handleHangUpCall,

        onMinimizeStream: (stream) => stream.windowState = 'minimized',
        onMaximizeStream: (stream) => stream.windowState = undefined,

        onAddDesktopStream: handleAddDesktopStream,
        onRemoveDesktopStream: handleRemoveDesktopStream,

        onSendMessage: handleSendMessage,
        onSendFileMessage: handleSendFileMessage,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};

export interface UserConnectedResponse {
  roomId: number;
  userId: number;
};

export interface UserDisconnectedResponse {
  roomId: number;
  userId: number;
};

export interface UserJoinedRoomResponse {
  roomId: number;
  userId: number;
  userIds: number[];
};

export interface RoomFullResponse {
  roomId: number;
  userId: number;
};

export interface CreateVideoCallRoomResponse {
  roomId: number;
  creatorUserId: number;
  userIds: number[];
};