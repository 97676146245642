import React, { useRef, useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import {
  AccountCircle as AccountIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Collections as CollectionsIcon,
  EmojiEvents as EmojiEventsIcon,
  Event as EventsIcon,
  Garage as GarageIcon,
  Logout as LogoutIcon,
  Message as MessageIcon,
  People as PeopleIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  TimeToLeave as CarIcon,
} from '@mui/icons-material';

import { UserAvatar } from '..';
import { Routes } from '../../consts';
import { getAvatarUrl } from '../../modules';
import { getUserToken } from '../../stores';

const accountOptions = [
  { title: 'My Profile', to: '/', icon: <AccountIcon /> },
  { title: 'My Garage', to: Routes.account.garage, icon: <GarageIcon /> },
  { title: 'Messages', to: Routes.account.messages, icon: <MessageIcon /> },
  { title: 'Favorite Cars', to: Routes.account.favorites, icon: <CarIcon /> },
  { title: 'Gallery', to: Routes.account.gallery, icon: <CollectionsIcon /> },
  { title: 'Settings', to: Routes.account.settings, icon: <SettingsIcon /> },
  { key: 'divider', to: 'divider' },
  { title: 'Logout', to: Routes.logout, icon: <LogoutIcon /> },
];

const mainOptions = [
  { title: 'Cars', to: Routes.cars, icon: <CarIcon /> },
  { title: 'Members', to: Routes.members, icon: <PeopleIcon /> },
  { title: 'Challenges', to: Routes.challenges, icon: <EmojiEventsIcon /> },
  { title: 'Events', to: Routes.events, icon: <EventsIcon /> },
  { title: 'Search', to: '', icon: <SearchIcon /> },
  { title: 'Account', to: Routes.account.settings, icon: <AccountIcon />, children: accountOptions },
];

interface IBottomNavState {
  open: boolean;
  page: number;
  selectedIndex: number;
  anchorRef: any;
};

export const BottomNav = (props: any) => {
  const [state, setState] = useState<IBottomNavState>({
    open: false,
    page: 0,
    selectedIndex: 0,
    anchorRef: useRef<HTMLDivElement>(null),
  });
  const currentUser = getUserToken();  

  const handleClick = (event: any, index: number) => {
    setState({...state, selectedIndex: index, open: true});

    const selectedOption = mainOptions[index];
    const children = selectedOption.children;

    window.location.href = children
      ? children[index].to
      : selectedOption.to;
  };
  const handleToggle = (event: any, index: number) => setState({...state, open: !state.open, selectedIndex: index});
  const handleClose = (event: Event) => {
    if (
      state.anchorRef?.current &&
      state.anchorRef?.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setState({...state, open: false});
  };

  return (
    <Paper
      sx={{
        borderTop: '1px solid grey',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={state.page}
        onChange={(event, newValue) => setState({...state, page: newValue})}
      >
        {mainOptions.map((option: any, index: number) => (option.children?.length ?? 0) > 0 ? (
          <BottomNavigationAction
            key={option.title}
            ref={state.anchorRef}
            aria-controls={state.open ? 'split-button-menu' : undefined}
            aria-expanded={state.open ? 'true' : undefined}
            aria-haspopup="menu"
            label={<span>{option.title}&nbsp;<ArrowDropDownIcon /></span>}
            icon={
              <UserAvatar
                fileName={getAvatarUrl(currentUser?.id)}
                fullName={currentUser?.username}
                size={24}
              />
            }
            onClick={(event: any) => handleToggle(event, index)}
          />
        ) : (
          <BottomNavigationAction
            key={option.to}
            label={option.title}
            icon={option.icon}
            href={option.to}
          />
        ))}
      </BottomNavigation>
      <BottomNavigationPopper
        state={state}
        onClick={handleClick}
        onClose={handleClose}
      />
    </Paper>
  );
};

const BottomNavigationActionDropdown = (props: any) => {
  //console.log('BottomNavigationActionDropdown props:', props);
  const { option, state, index, onToggle } = props;
  const currentUser = getUserToken();  

  return (
    <BottomNavigationAction
      key={option.title}
      ref={state.anchorRef}
      aria-controls={state.open ? 'split-button-menu' : undefined}
      aria-expanded={state.open ? 'true' : undefined}
      aria-haspopup="menu"
      label={<span>{option.title}&nbsp;<ArrowDropDownIcon /></span>}
      icon={
        <UserAvatar
          fileName={getAvatarUrl(currentUser?.id)}
          fullName={currentUser?.username}
          size={24}
        />
      }
      onClick={(event: any) => onToggle(event, index)}
    />
  );
};

const BottomNavigationPopper = (props: any) => {
  //console.log('BottomNavigationPopper props:', props);
  const { state, onClick, onClose } = props;
  const option = mainOptions[state.selectedIndex];

  return (
    <Popper
      sx={{
        zIndex: 1,
      }}
      open={state.open}
      anchorEl={state.anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList
                id={"split-button-menu"}
                className="bg-dark text-light"
                autoFocusItem
              >
                {(option.children?.length ?? 0) > 0 && option.children?.map((child: any, index: number) =>
                  child.to === 'divider' ? (
                    <MenuItem
                      divider
                      key={child.key}
                    />
                  ) : (
                    <MenuItem
                      key={child.to}
                      className="bg-dark text-light"
                      selected={index === state.selectedIndex}
                      onClick={(event: any) => onClick(event, index)}
                    >
                      <>{child.icon}&nbsp;{child.title}</>
                    </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};