import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import {
  BlockUserButton,
  FollowersLabelDialog,
  FollowingLabelDialog,
  FollowUserButton,
  MessageUserButton,
  ReportUserButton,
  UserAvatar,
  ViewUserCarsButton,
} from '../components';
import { SearchBar } from '../components/Sidebar';
import { getAvatarUrl } from '../modules';
import { SearchService, UserService } from '../services';
import { getUserToken } from '../stores';
import { User } from '../types';

const useStyles: any = (theme: Theme) => ({
  root: {
    justifyContent: 'center',
    maxWidth: 'calc(100vw - 0.1em)',
  },
  personContainer: {
    borderRadius: 20,
    border: '1px solid black',
    padding: 15,
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  divider: {
    backgroundColor: 'white',
  },
  name: {
    color: 'white',
  },
  username: {
    color: 'silver',
  },
  personLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  actionsButtonContainer: {
    justifyContent: 'center',
    marginTop: '10px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

const PeoplePage = (props: any) => {
  const [state, setState] = useState({
    currentUser: null,
    users: [],
  });
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleReload = () => {
    const user = getUserToken();  
    UserService.getUsers().then((response: any) => {
      //console.log('getUsers response:', response);
      const currentUser = response.users.find((u: any) => u.username === user?.username)
      setState({...state, users: response.users, currentUser});
    });
  };

  const handleSearch = (text: string) => {
    //console.log('handleSearch:', text);
    SearchService.search(text).then((response: any) => {
      //console.log('search response:', response);
      const { users } = response.results;
      setState({...state, users});
    });
  };

  // eslint-disable-next-line
  useEffect(() => handleReload(), []);

  return (
    <>
      <Typography
        variant="h3"
        gutterBottom
        className="text-center"
      >
        Members
      </Typography>
      <Grid
        container
        spacing={1}
        style={classes.root}
      >
        <Grid key='search' item xs={12}>
          <div className="float-end">
            <SearchBar
              onSearch={handleSearch}
              showResults={false}
            />
          </div>
        </Grid>
        {state.users && state.users?.map((user: User) => (
          <Grid key={user.id} item lg={3} md={4} sm={6} xs={12}>
            <Person
              user={user}
              currentUser={state.currentUser}
              onReload={handleReload}
              classes={classes}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export const Person = (props: any) => {
  //console.log('Person props:', props);
  const { user, currentUser, onReload, classes } = props;

  return (
    <Paper elevation={6} className="text-center" style={classes.personContainer}>
      <PersonHeader
        user={user}
        classes={classes}
      />
      <PersonFollowersBox
        user={user}
        currentUser={currentUser}
      />
      <Divider style={classes.divider} />
      <PersonActionButtons
        user={user}
        currentUser={currentUser}
        classes={classes}
        onReload={onReload}
      />
    </Paper>
  );
};

const PersonFollowersBox = (props: any) => {
  const { user, currentUser } = props;
  return (
    <Box display={'flex'}>
      <FollowersLabelDialog
        currentUser={currentUser}
        profile={user}
        label="Followers"
        value={user?.followers?.length ?? 0}
      />
      <FollowingLabelDialog
        currentUser={currentUser}
        profile={user}
        label="Following"
        value={user?.following?.length ?? 0}
      />
    </Box>
  );
};

const PersonHeader = (props: any) => {
  const { user, classes } = props;
  return (
    <a
      href={`/${user.username}`}
      title={`Visit ${user.username}'s profile`}
      style={classes.link}
    >
      <UserAvatar
        fullName={user.username}
        fileName={getAvatarUrl(user.id)}
        size={64}
        style={classes.avatar}
        showBadge={true}
        lastOnline={new Date(user.lastLoginAt)}
      />
      <br />
      <span style={classes.name}>
        {user.firstName} {user.lastName}
      </span>
      <br />
      <span style={classes.username}>@{user.username}</span>
    </a>
  );
};

const PersonActionButtons = (props: any) => {
  const { user, currentUser, classes, onReload } = props;
  const isCurrentUser = user.id === currentUser?.id;
  const isBlocked = UserService.isUserBlocked(user.id, currentUser!);

  return (
    <Grid
      container
      spacing={0}
      style={classes.actionsButtonContainer}
    >
      <Grid item xs={2}>
        <MessageUserButton
          isCurrentUser={isCurrentUser}
          isBlocked={isBlocked}
          isDisabled={!currentUser}
          currentUser={currentUser}
          profile={user}
          onReload={onReload}
        />
      </Grid>
      <Grid item xs={2}>
        <FollowUserButton
          isCurrentUser={isCurrentUser}
          isDisabled={!currentUser}
          currentUser={currentUser}
          profile={user}
          onReload={onReload}
        />
      </Grid>
      <Grid item xs={2}>
        <BlockUserButton
          isCurrentUser={isCurrentUser}
          isDisabled={!currentUser}
          currentUser={currentUser}
          profile={user}
          onReload={onReload}
        />
      </Grid>
      <Grid item xs={2}>
        <ViewUserCarsButton
          isCurrentUser={isCurrentUser}
          currentUser={currentUser}
          profile={user}
          onReload={onReload}
        />
      </Grid>
      <Grid item xs={2}>
        <ReportUserButton
          isCurrentUser={isCurrentUser}
          isDisabled={!currentUser}
          currentUser={currentUser}
          profile={user}
          onReload={onReload}
        />
      </Grid>
    </Grid>
  );
};

export default PeoplePage;