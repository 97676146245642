import axios from 'axios';

import { getUserToken } from '../stores/tokens';
import http from '../modules/http-common';

const getImages = async (path: string) => {
  const response = await http
    .get(`images?folder=${path}`);
  return response.data;
};

const uploadImage = (file: File, path: string, onUploadProgress?: any) => {
  //console.log('uploadImage:', file, path);

  const formData = new FormData();
  formData.append('path', path);
  formData.append('file', file);

  const user = getUserToken();
  const url = 'images/upload';
  return http.post(url, formData, {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'multipart/form-data',
      'x-access-token': user?.accessToken,
    },
    onUploadProgress,
  });
};

const uploadImages = (files: any[], folder: string, onUploadProgress?: any) => {
  //console.log('uploadImages:', files, folder);

  const formData = new FormData();
  formData.append('path', folder);
  for (const file of files) {
    formData.append(file.id, file.file);
  }

  const user = getUserToken();
  const url = 'images/upload-multi';
  return http.post(url, formData, {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'multipart/form-data',
      'x-access-token': user?.accessToken,
    },
    onUploadProgress,
  });
};

const deleteImage = async (path: string) => {
  const response = await http
    .delete(`images/delete?path=${path}`);
  return response.data;
};

const imageUrlExists = async (url: string) => {
  try {
    await axios.head(url);
    return true;
  } catch (err: any) {
    //console.error('error:', err);
    return false;
  }
};

const getAlbums = async (userId: number) => {
  const response = await http
    .get(`images/albums/${userId}`);
  return response.data;
};

const createAlbum = async (name: string, userId: number) => {
  const response = await http
    .post('images/albums/create', { name, userId });
  return response.data;
};

const deleteAlbum = async (name: string, userId: number) => {
  const response = await http
    .delete(`images/albums/${userId}/delete?name=${name}`);
  return response.data;
};

export const ImageService = {
  getImages,
  uploadImage,
  uploadImages,
  deleteImage,
  imageUrlExists,
  getAlbums,
  createAlbum,
  deleteAlbum,
};