export const DefaultProfileBannerUrl = 'https://firebasestorage.googleapis.com/v0/b/open-social-33d92.appspot.com/o/images%2F751145a1-9488-46fd-a97e-04018665a6d3.JPG?alt=media&token=1a1d5e21-5101-450e-9054-ea4a20e06c57';
export const DefaultPhotoAlbumCoverUrl = 'https://images.ctfassets.net/pdf29us7flmy/2z2UHcJrokSHHZh05Clg5O/ca19af0a45a29da83dfa73b732a77337/Writing-a-General-Purpose-Cover-Letter-Social.png';

export const DefaultAppBarBackColor = '#556cd6';
export const DefaultAppBarForeColor = 'white';

export const DefaultStreamPostsLimit = 10;

export const MaxParallelFileUploadLimit = 2;
export const MaxFileUploadLimit = 10;
export const MaxFileUploadSize = 256 * 1024 * 1024; // 256 MB
export const MaxPostMessageLimit = 1024;

export const LoginRedirectDelay = 1500; // 1.5 seconds

export const InstagramUrl = 'https://instagram.com';

// TODO: Pick random back color
export const DefaultUserAvatarBackColor = 'black'; //rgb(85, 108, 214)
export const DefaultUserAvatarForeColor = 'white';

export const PoweredBy = {
  title: 'ver.sx',
  url: 'https://ver.sx',
};
export const SponsoredBy = {
  title: 'Adorable Productions',
  url: 'https://instagram.com/Fordrod64',
};

export const AvatarsFolder = '/images/avatars/';
export const BannersFolder = '/images/banners/';
export const CarsFolder = '/images/cars/';
export const PostsFolder = '/images/posts/';
export const StoriesFolder = '/images/stories/';
export const SoundsFolder = '/sounds/';

export const Routes = {
  login: '/login',
  logout: '/logout',
  register: '/register',
  home: '/',
  cars: '/cars',
  car: '/cars/',
  members: '/members',
  challenges: '/challenges',
  challenge: '/challenges/',
  events: '/events',
  repairs: '/repairs',
  about: '/about',
  privacy: '/privacy',
  tos: '/terms',
  account: {
    garage: '/account/garage',
    messages: '/account/messages',
    favorites: '/account/favorites',
    gallery: '/account/gallery',
    settings: '/account/settings',
    notifications: '/account/notifications',
  },
  pageNotFound: '/404',
};

export const Events = {
  Connection: 'connection',
  Connect: 'connect',
  Disconnect: 'disconnect',
  Me: 'me',
  Client: {
    GetRooms: 'getRooms',
    CreateRoom: 'createRoom',
    JoinRoom: 'joinRoom',
    LeaveRoom: 'leaveRoom',
    SendMessage: 'sendMessage',
    UploadFile: 'uploadFile',
    Typing: 'typing',
    AnswerCall: 'answerCall',
    CallAccepted: 'callAccepted',
    CallUser: 'callUser',
    CallLeave: 'callLeave',
    GetUsers: 'getUsers',

    CreateVoiceRoom: 'createVoiceRoom',
    MakeCall: 'makeCall',
    JoinVoiceRoom: 'joinVoiceRoom',
    AcceptCall: 'acceptCall',
  },
  Server: {
    Rooms: 'rooms',
    CreatedRoom: 'createdRoom',
    JoinedRoom: 'joinedRoom',
    LeftRoom: 'leftRoom',
    ReceivedMessage: 'receivedMessage',
    UploadedFile: 'uploadedFile',
    TypingResponse: 'typingResponse',
    CallUserResponse: 'callUserResponse',
    CallAcceptedResponse: 'callAcceptedResponse',
    CallLeaveResponse: 'callLeaveResponse',
    GetUsersResponse: 'getUsersResponse',

    CreatedVoiceRoomResponse: 'createdVoiceRoomResponse',
    JoinedVoiceRoomResponse: 'joinedVoiceRoomResponse',
    VoiceCallResponse: 'voiceCallResponse',
    AcceptedCallResponse: 'acceptedCallResponse',
  },
};