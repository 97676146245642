import React, { createRef } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import {
  FileDownload as FileDownloadIcon,
  QuestionAnswer as QuestionAnswerIcon,
} from '@mui/icons-material';

import { Input, UserAvatar } from '..';
import { atob, getAvatarUrl } from '../../modules';
import { Base64File, User, VideoCallMessage } from '../../types';

import '../../css/chat/index.css';

export interface MessageProps {
  message: VideoCallMessage;
};

const MessageEntry = (props: MessageProps) => {
  const { message } = props;

  const handleDownloadAll = () => {
    if ((message.files?.length ?? 0) === 0) {
      console.warn('unable to download all message files, not set');
      return;
    }

    for (const file of message.base64Files!) {
      handleDownload(file);
    }
  };

  const handleDownload = (file: Base64File) => {
    const a = document.createElement('a');
    a.setAttribute('href', file.data);
    a.setAttribute('download', file.name);
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Grid container spacing={0.5}>
      <Grid item key='message' xs={12}>
        <p className='message-text'>
          {atob(message.message)}
        </p>
      </Grid>
      {message.base64Files && message.base64Files?.map((file: Base64File, index: number) => (
        <Grid item key={index} xs={12}>
          {file.type.includes('image') ? (
            <img src={file.data} width='100%' title={file.name} alt={file.name} />
          ) : file.type.includes('video') ? (
            <video src={file.data} width='100%' title={file.name} />
          ) : (
            <Button
              title={`Download ${file.name}`}
              className='message-download'
              size='small'
              variant='contained'
              onClick={() => handleDownload(file)}
            >
              <span>{file.name}</span>
              <FileDownloadIcon className='icon' />
            </Button>
          )}
        </Grid>
      ))}
      {message.base64Files && (
        <Grid item key='download-all' xs={12}>
          <IconButton
            title='Download all files'
            className='message-download float-end'
            size='small'
            onClick={handleDownloadAll}
          >
            <FileDownloadIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export interface ChatProps {
  userId: number;
  messages: VideoCallMessage[];
  roomId: number;
  visible: boolean;
  usersOnline: { [userId: number]: User };
  onSendMessage: (message: VideoCallMessage) => void;
  onSendFileMessage: (message: VideoCallMessage) => void;
};

export class Chat extends React.PureComponent<ChatProps> {
  chatHistoryRef = createRef<HTMLDivElement>();
  inputRef = createRef<any>();

  scrollToBottom = () => {
    const chatHistoryRef = this.chatHistoryRef.current!;
    chatHistoryRef.scrollTop = chatHistoryRef.scrollHeight;
  }

  componentDidMount () {
    this.focus();
    this.scrollToBottom();
  }

  componentDidUpdate () {
    this.focus();
    this.scrollToBottom();
  }

  focus() {
    if (this.props.visible) {
      this.inputRef.current?.textArea.current?.focus();
    }
  }

  render () {
    const {
      userId, messages, roomId, usersOnline,
      onSendMessage, onSendFileMessage,
    } = this.props;

    const createMessage = (message: VideoCallMessage) => (
      <div className='message'>
        <span className='message-user-name'>
          {usersOnline[message.userId]?.username ?? message.userId}
        </span>
        <time className='message-time'>
          {new Date(message.timestamp!).toLocaleTimeString()}
        </time>
        <MessageEntry message={message} />
      </div>
    );

    return (
      <div className='chat bg-dark'>
        <div className='chat-history' ref={this.chatHistoryRef}>
          {messages.length ? (
            messages.map((message: VideoCallMessage, index: number) => (
              <div key={index}>
                {message.userId === userId ? (
                  <div className='chat-item chat-item-me'>
                    {createMessage(message)}
                    <span className='chat-item-img'>
                      <UserAvatar
                        fileName={getAvatarUrl(message?.userId)}
                        fullName={message?.userId?.toString()}
                      />
                    </span>
                  </div>
                ) : (
                  <div className='chat-item chat-item-other'>
                    <span className='chat-item-img'>
                      <UserAvatar
                        fileName={getAvatarUrl(message?.userId)}
                        fullName={message?.userId?.toString()}
                      />
                    </span>
                    {createMessage(message)}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className='chat-empty'>
              <span className='chat-empty-icon'>
                <QuestionAnswerIcon />
              </span>
              <div className='chat-empty-message'>No Messages</div>
            </div>
          )}
        </div>

        <Input
          roomId={roomId}
          onSendMessage={onSendMessage}
          onSendFileMessage={onSendFileMessage}
        />
      </div>
    );
  }
};