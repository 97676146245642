import React, { useEffect, useState } from 'react';
import {
  Box,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import {
  ChallengesList,
  LatestChallengesList,
  RaceTrackDetails,
  RunTimesList,
  RunTimeType,
  TabPanel,
} from '../components';
import { ChallengeDialog, CreateRaceTrackDialog } from '../components/Dialogs';
import { getCarImageUrl, rndNum, sortByKey } from '../modules';
import {
  ChallengeService,
  RaceTrackService,
  TimeService,
  UserService,
} from '../services';
import { getUserToken } from '../stores';
import { Challenge, RaceTrack, Time, User } from '../types';

interface IChallengesPageState {
  challenges: Challenge[];
  openChallengeDialog: boolean;
  openRaceTrackDialog: boolean;
  raceTracks: RaceTrack[];
  selectedTabIndex: number;
  times: Time[];
  user: User | null;
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ChallengesPage = (props: any) => {
  //console.log('ChallengesPage props:', props);
  const [state, setState] = useState<IChallengesPageState>({
    challenges: [],
    openChallengeDialog: false,
    openRaceTrackDialog: false,
    raceTracks: [],
    selectedTabIndex: 0,
    times: [],
    user: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = getUserToken();

  const handleChange = (event: any, newValue: number) => {
    setState({...state, selectedTabIndex: newValue});
  };

  const handleOpenChallengeDialog = () => setState({...state, openChallengeDialog: true});
  const handleCloseChallengeDialog = () => {
    setState({...state, openChallengeDialog: false});
    handleReload();
  };

  const handleOpenRaceTrackDialog = () => setState({...state, openRaceTrackDialog: true});
  const handleCloseRaceTrackDialog = () => setState({...state, openRaceTrackDialog: false});

  const handleReload = () => {
    ChallengeService.getChallenges().then((response: any) => {
      //console.log('getChallenges response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to fetch challenges ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      const challenges = response.challenges?.sort((a: any, b: any) => sortByKey(b, a, 'date'));
      TimeService.getLeaderboardTimes().then((response: any) => {
        //console.log('getLeaderboardTimes response:', response);
        if (response.status !== 200) {
          enqueueSnackbar(`Unable to fetch leaderboard track times ${response.message}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          return;
        }
        const bestTimes = response.times.slice(0, 25);
        bestTimes.sort((a: any, b: any) => sortByKey(a, b, 'time'));
        RaceTrackService.getRaceTracks().then((response: any) => {
          //console.log('getRaceTracks response:', response);
          if (response.status !== 200) {
            enqueueSnackbar(`Unable to fetch race tracks ${response.message}`, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
            return;
          }
          const tracks = response.tracks;
          UserService.getUser(currentUser?.id).then((response: any) => {
            //console.log('getUser response:', response);
            if (response.status !== 200) {
              enqueueSnackbar(`Unable to fetch user details ${response.message}`, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              });
              return;
            }
            setState({
              ...state,
              openChallengeDialog: false,
              challenges,
              raceTracks: tracks,
              times: bestTimes,
              user: response.user,
            });
          });
        });
      });
    });
  };

  const getTimes = () => {
    const cars: any = {};
    for (const time of state.times) {
      if (cars[time.carId]) {
        cars[time.carId].push(time);
      } else {
        cars[time.carId] = [time];
      }
    }
    return cars;
  };

  const cars = getTimes();
  const carIds = Object.keys(cars);

  // eslint-disable-next-line
  useEffect(() => handleReload(), []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={state.selectedTabIndex}
            onChange={handleChange}
            variant="fullWidth"
            centered
          >
            <Tab label="Challenges" {...a11yProps(0)} />
            <Tab label="Leaderboard" {...a11yProps(1)} />
            <Tab label="Tracks" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={state.selectedTabIndex} index={0}>
          <Typography variant="h3" gutterBottom className="text-center">Challenges</Typography>
          <Fab
            color="primary"
            size="small"
            aria-label="Create new challenge"
            onClick={handleOpenChallengeDialog}
            className="float-end"
            title="Create new challenge"
          >
            <AddIcon />
          </Fab>
          <Grid container spacing={2} className="mb-3">
            <Grid item md={6} xs={12}>
              <Typography variant="h5" gutterBottom className="text-center">
                Latest Challenges
              </Typography>
              <LatestChallengesList />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h5" gutterBottom className="text-center">
                Upcoming Challenges
                <LatestChallengesList showUpcoming={true} />
              </Typography>
            </Grid>
          </Grid>
    
          <Typography variant="h5" gutterBottom className="text-center">
            Challenges
          </Typography>
          <ChallengesList
            challenges={state.challenges}
            raceTracks={state.raceTracks}
          />
        </TabPanel>
        <TabPanel value={state.selectedTabIndex} index={1}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Typography variant="h3" gutterBottom className="text-center">Leaderboard</Typography>
            <Grid container spacing={2} className="mb-3">
              <Grid item md={6} xs={12}>
                <Typography variant="h5" gutterBottom className="text-center">
                  Latest Run Times
                </Typography>
                <RunTimesList timeType={RunTimeType.latest} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="h5" gutterBottom className="text-center">
                  Best Run Times
                </Typography>
                <RunTimesList timeType={RunTimeType.best} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {(carIds?.length ?? 0) > 0 && carIds?.map((carId: any, index: number) => {
                const times = cars[carId];
                const { car, user } = times[0];
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    //style={{borderBottom: '1px solid grey'}}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid item xs={12} className="text-center">
                        <b>#{index + 1} Place</b><br />
                        <span>{car?.name} by {user?.username}</span>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <a
                          href={getCarImageUrl(carId)}
                        >
                          <img
                            src={getCarImageUrl(carId)}
                            alt={car?.name}
                            width="100%"
                            height="auto"
                          />
                        </a>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <small>
                          <span>Top Speed: 100 MPH</span><br />
                          <span>Best Time: 10.2000s</span><br />
                          <span>Best Reaction: 0.7420s</span><br />
                          <span>Total Runs: 3</span><br />
                        </small>
                        <List
                          sx={{
                            width: '100%',
                            //maxWidth: 360,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            //maxHeight: 300,
                            '& ul': { padding: 0 },
                          }}
                          subheader={<li />}
                        >
                          {['1/8', '1/4'].map((trackLength: string) => (
                            <li key={`track-${trackLength}`}>
                              <ul>
                                <ListSubheader>{trackLength} ᵀᴴ</ListSubheader>
                                {times.map((time: Time) => trackLength === time.raceTrack?.length && (
                                  <ListItem key={`${trackLength}-${time.id}`}>
                                    <ListItemText primary={`${rndNum(time.time)}s @ ${rndNum(time.speed, 2)} MPH`} />
                                  </ListItem>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={state.selectedTabIndex} index={2}>
          <Typography variant="h3" gutterBottom className="text-center">Tracks</Typography>
          <Fab
            color="primary"
            size="small"
            aria-label="Create new race track"
            onClick={handleOpenRaceTrackDialog}
            className="float-end"
            title="Create new race track"
          >
            <AddIcon />
          </Fab>
          <br />
          <div className="mt-5">
          {(state.raceTracks?.length ?? 0) > 0 && state.raceTracks.map((track: RaceTrack) => (
            <RaceTrackDetails key={track.name} track={track} />
          ))}
          </div>
        </TabPanel>
      </Box>

      <ChallengeDialog
        key="challenge-dialog"
        open={state.openChallengeDialog}
        title="Challenge"
        raceTracks={state.raceTracks}
        //profile={profile}
        currentUser={state.user}
        onClose={handleCloseChallengeDialog}
      />

      <CreateRaceTrackDialog
        key="track-dialog"
        open={state.openRaceTrackDialog}
        raceTracks={state.raceTracks}
        onClose={handleCloseRaceTrackDialog}
      />
    </>
  );
};

export default ChallengesPage;