import classnames from 'classnames';
import { Panel } from '.';

export interface VideoCallSidebarButtonProps {
  activePanel: Panel;
  className?: string;
  label: string;
  panel: Panel;
  onClick: (panel: Panel) => void;
};

export const VideoCallSidebarButton = (props: VideoCallSidebarButtonProps) => {
  const { activePanel, label, panel, className, onClick } = props;

  const classNames = classnames(className, 'sidebar-button', {
    active: activePanel === panel,
  });

  return (
    <li
      aria-label={label}
      className={classNames}
      onClick={() => onClick(panel)}
      role='button'
    >
      {label}
    </li>
  );
};