import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { ChallengeListItem } from '.';
import { ViewRaceTrackDetailsDialog } from '../Dialogs';
import { getUserToken } from '../../stores';
import { Challenge } from '../../types';

export const ChallengesList = (props: any) => {
  const { challenges, raceTracks } = props;
  const [open, setOpen] = useState(false);
  const currentUser = getUserToken();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container spacing={2}>
        {challenges && challenges?.map((challenge: Challenge) => (
          <Grid item key={challenge.id} xs={12}>
            <ChallengeListItem
              challenge={challenge}
              currentUser={currentUser}
              onOpen={handleOpen}
              //onClose={handleClose}
            />
          </Grid>
        ))}
      </Grid>
      <ViewRaceTrackDetailsDialog
        open={open}
        onClose={handleClose}
        track={raceTracks[0]} // TODO: Selected track from challenge
      />
    </>
  );
};