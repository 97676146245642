import React, { createRef, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
} from '@mui/material';
import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
} from '@mui/icons-material';

import { btoa, elipses, formatFileSize } from '../../modules';
import { getUserToken } from '../../stores';
import { VideoCallMessage } from '../../types';

export interface InputProps {
  roomId: number;
  onSendMessage: (message: VideoCallMessage) => void;
  onSendFileMessage: (message: VideoCallMessage) => void;
};

export interface InputState {
  message: string;
  files: File[];
};

const MaximumFileCount = 5;
const MaximumFileSize = 25 * 1024 * 1024; // 25 MB

export const Input = (props: InputProps) => {
  const { roomId, onSendMessage, onSendFileMessage } = props;
  const [state, setState] = useState<InputState>({
    message: '',
    files: [],
  });
  const file = createRef<HTMLInputElement>();
  const textArea = createRef<HTMLTextAreaElement>();
  const messageFiles = [...state.files];
  const currentUser = getUserToken();

  const handleChange = (event: any) => {
    setState({
      ...state,
      message: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    submit();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      submit();
    }
  };

  const handleSmileClick = (event: any) => {
    setState({
      ...state,
      message: textArea.current!.value + event.currentTarget.innerHTML,
    });
  };

  const handleSelectFiles = (event: any) => {
    event.preventDefault();
    let files = [...event.target!.files!];
    const newFiles = [];
    if (files.length > MaximumFileCount) {
      console.warn('too many files selected, limited to a maximum of', MaximumFileCount);
      files = files.slice(0, 4);
    }
    for (const file of files) {
      if (file.size > MaximumFileSize) {
        console.warn('skipping file', file.name, 'size is greater than', MaximumFileSize);
        continue;
      }
      newFiles.push(file);
    }
    setState({...state, files: newFiles});
  };

  const submit = () => {
    const timestamp = new Date().getTime() / 1000;
    let sent = false;
    if (state.files.length === 0) {
      if (state.message) {
        const message = btoa(state.message);
        onSendMessage({
          roomId,
          userId: currentUser?.id,
          type: 'text',
          message,
          files: state.files,
          timestamp,
        });
        sent = true;
      }
    } else {
      onSendFileMessage({
        roomId,
        userId: currentUser?.id,
        type: 'file',
        message: btoa(state.message ?? ''),
        files: state.files,
        timestamp,
      });
      sent = true;
    }

    if (sent) {
      setState({
        ...state,
        message: state.message !== '' ? '' : state.message,
        files: state.files.length > 0 ? [] : state.files,
      });
    }
  };

  const handleSendFile = () => file.current!.click();

  const removeAttachment = (file: File) => {
    const files = messageFiles.filter((f) => f.name !== file.name);
    setState({
      ...state,
      files,
    });
  };

  return (
    <div className="chat-controls">
      <input
        type='file'
        multiple
        ref={file}
        style={{
          display: 'none',
        }}
        onChange={handleSelectFiles}
      />
      <textarea
        className="chat-controls-textarea"
        placeholder="Type a message"
        value={state.message}
        ref={textArea}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <div className="chat-controls-buttons">
        <Button
          title="Send message"
          className="chat-controls-buttons-send"
          //variant="contained"
          onClick={handleSubmit}
        >
          Send
        </Button>

        <div className="chat-controls-buttons-wrapper">
          <div className="emoji">
            <div className="chat-controls-buttons-smiles">
              <SentimentSatisfiedIcon style={{color: 'black'}} />
              <div className="chat-controls-buttons-smiles-menu">
                <EmojiButton emoji='😑' onClick={handleSmileClick} />
                <EmojiButton emoji='😕' onClick={handleSmileClick} />
                <EmojiButton emoji='😊' onClick={handleSmileClick} />
                <EmojiButton emoji='😎' onClick={handleSmileClick} />
                <EmojiButton emoji='💪' onClick={handleSmileClick} />
              </div>
            </div>
          </div>
          <IconButton
            title='Add file attachment to message'
            size='small'
            onClick={handleSendFile}
            style={{color: 'black'}}
          >
            <AttachFileIcon />
          </IconButton>
        </div>
      </div>
      {messageFiles?.length > 0 && (
        <FileAttachmentsList
          attachments={messageFiles}
          onRemove={removeAttachment}
        />
      )}
    </div>
  );
};

interface FileAttachmentsListProps {
  attachments: File[];
  onRemove: (file: File) => void;
};

const FileAttachmentsList = (props: FileAttachmentsListProps) => {
  const { attachments, onRemove } = props;

  return (
    <Grid
      container
      spacing={0}
      className='bg-dark'
      style={{
        overflowY: 'scroll',
        maxHeight: '150px',
        marginTop: 5,
        paddingTop: 5,
        borderRadius: 8,
      }}
    >
      {attachments && attachments.map((file: File, index: number) => (
        <Grid item key={index} xs={12}>
          <span className='text-nowrap' style={{fontSize: 12}}>
            <div className='float-start'>
              <AttachFileIcon />
              &nbsp;{elipses(file.name)}&nbsp;
            </div>
            <div className='float-end'>
              {formatFileSize(file.size)}&nbsp;
              <IconButton
                title={`Remove attachment ${file.name}`}
                size='small'
                onClick={() => onRemove(file)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </span>
        </Grid>
      ))}
    </Grid>
  );
};

interface EmojiButtonProps {
  emoji: any;
  onClick: (event: any) => void;
};

const EmojiButton = ({ emoji, onClick }: EmojiButtonProps) => (
  <span
    className="chat-controls-buttons-smile"
    onClick={onClick}
    style={{
      color: 'black',
    }}
  >
    {emoji}
  </span>
);