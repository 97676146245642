import React, { useState } from 'react';
import { Image as SvgImage } from '@mui/icons-material';

export const Img = (props: any) => {
  const styles: any = {
    loding: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100px',
      position: 'relative',
      color: '#cacecd',
      fontWeight: 400,
    },
    loadingContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    loadingImage: {
      fill: 'aliceblue',
      width: '50px',
      height: '50px',
    },
  };

  const { fileName, style } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const handleLoadImage = () => setIsImageLoaded(true);

  return (
    <>
      <img
        onLoad={handleLoadImage}
        src={fileName || ''}
        style={isImageLoaded ? style : { display: 'none' }}
        alt=''
      />
      <div style={Object.assign({}, {backgroundColor: 'white'}, isImageLoaded ? {display: 'none'} : styles.loding)}>
        <div style={styles.loadingContent}>
          <SvgImage style={styles.loadingImage} />
          <div>Image not loaded</div>
        </div>
      </div>
    </>
  );
};