import { useIsMobile, useStore } from '../../hooks';
import { get } from '../../modules/storage';

/**
 * A hook that returns the sidebar open state and a function to toggle it.
 *
 * @example
 * const ToggleSidebar = () => {
 *     const [open, setOpen] = useSidebarState();
 *     return (
 *         <Button onClick={() => setOpen(!open)}>
 *             {open ? 'Open' : 'Close'}
 *         </Button>
 *     );
 * };
 */
export const useSidebarState = (value: boolean = true): useSidebarStateResult => {
  const isMobile = useIsMobile();
  const cachedValue = isMobile ? false : get('sidebar.open');
  const store = useStore<boolean>('sidebar.open', cachedValue ?? value);
  return store;
};

export type useSidebarStateResult = [boolean, (open: boolean) => void];