import Peer, { DataConnection, MediaConnection } from 'peerjs';

import { DialState } from '../components';

export type VideoStreamType = 'camera' | 'desktop';
export const VideoStreamTypeCamera: VideoStreamType = 'camera';
export const VideoStreamTypeDesktop: VideoStreamType = 'desktop';

export interface UserVideoStream {
  userId: number;
  stream: MediaStream;
  type?: VideoStreamType;
  isMirrored?: boolean;
  isLocal?: boolean;
  isMuted?: boolean;
  dimensions?: VideoDimensions;
  windowState?: VideoWindowState;
};

export interface MediaStreamDevices {
  audioInputs: MediaDeviceInfo[];
  audioOutputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];
};

export interface VideoStreamPeer {
  userId: number;
  peer: Peer;
  data?: DataConnection;
};

export type VideoCallSettings = {
  isAudioOutEnabled?: boolean;
  isAudioInEnabled?: boolean;
  isVideoEnabled?: boolean;
  
  devices: MediaStreamDevices;
  selectedAudioInDevice: string;
  selectedAudioOutDevice: string;
  selectedVideoDevice: string;

  remoteAudio: RemoteAudioSettings;
};

export type VideoCallState = {
  creatorUserId: number;
  openAnswerCallDialog: boolean;
  openOutgoingCallDialog: boolean;
  roomId: number;
  incomingCall: MediaConnection | null;
  dialState: DialState;
  localStream: MediaStream | null;
  desktopStream: MediaStream | null;
};

export interface VideoCallMessage {
  roomId: number;
  userId: number;
  type: VideoCallMessageType;
  message?: string;
  timestamp?: number;
  files?: File[];
  base64Files?: Base64File[];
};

export type VideoCallMessageType = 'text' | 'file';

export interface Base64File {
  name: string;
  size: number;
  type: string;
  data: string;
};

export interface VideoDimensions {
  x: number;
  y: number;
};

export type VideoWindowState = undefined | 'minimized';

export type VideoCallParticipants = {
  [userId: number]: {
    camera: UserVideoStream | undefined;
    desktop: UserVideoStream | undefined;
  };
};

export interface RemoteAudioSettings {
  [userId: number]: boolean;
};