import { Typography } from '@mui/material';

import { PhotoAlbumsGallery } from '../../components';
import { getUserToken } from '../../stores';

export const PhotoAlbumsPage = (props: any) => {
  //console.log('PhotoAlbumsPage props:', props);
  const user = getUserToken();
  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">
        Photo Albums
      </Typography>
      <PhotoAlbumsGallery
        userId={user.id}
        editMode={true}
      />
    </>
  );
};