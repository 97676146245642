import http from '../modules/http-common';
import { Time } from '../types';

const getTimes = async (userId: number) => {
  const response = await http
    .get(`times?userId=${userId}`);
  return response.data;
};

const getLeaderboardTimes = async () => {
  const response = await http
    .get('times/leaderboard');
  return response.data;
};

const createTime = async (time: Time) => {
  const response = await http
    .post('times/create', { time });
  return response.data;
};

const deleteTimes = async (userId: number, timeIds: any[]) => {
  const response = await http
    .post('times/delete', { userId, timeIds });
  return response.data;
};

export const TimeService = {
  getTimes,
  getLeaderboardTimes,
  createTime,
  deleteTimes,
};