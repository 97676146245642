// Reference: https://github.com/allantrabuco/react-calendar
// Reference: https://react-calendar.atrabuco.com/
// Reference: https://blog.logrocket.com/react-calendar-tutorial-build-customize-calendar/

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  addDays, addMonths, addWeeks,
  subDays, subMonths, subWeeks,
} from 'date-fns';
import { enUS } from 'date-fns/locale';

import {
  CalendarContext,
  CalendarDrawer,
  CalendarEventDialog,
  CalendarEventViewDialog,
  CalendarEventsViewDialog,
  CalendarMain,
  CalendarState,
  CalendarToolbar,
} from '.';
import { get } from '../../modules';

const useStyles: any = ((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Calendar = (props: any) => {
  const [stateCalendar, setStateCalendar] = useState<CalendarState>({
    selectedDate: new Date(),
    selectedDay: new Date(),
    layout: get('layout', '"week"'),
    locale: enUS,
    openDialog: false,
    openViewDialog: false,
    openViewAllDialog: false,
    eventBeginDate: new Date(),
    eventBeginTime: { value: '', label: '' },
    eventEndDate: new Date(),
    eventEndTime: { value: '', label: '' },
    defaultEventDuration: 60, // minutes
    modal: false,
    eventDialogMaxWidth: 'md',
    eventID: 0,
    fullscreen: false,
    allowFullScreen: false,
    withCloseIcon: true,
    title: '',
    description: '',
    content: '',
    actions: '',
    calendarEvent: {},
    calendarEvents: [],
    draggingEventId: -1,
    startDragging: false,
    ghostProperties: { width: 0, height: 0, date: new Date() },
    // handleCloseDialog,
    // handleCloseViewDialog,
  });

  const [open, setOpen] = useState(false);
  const [runAnimation, setRunAnimation] = useState(true);
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleGoToToday = () => {
    setRunAnimation(false);
    const newDate = new Date();
    setStateCalendar({ ...stateCalendar, selectedDate: newDate });
  };

  const handleLayoutChange = (args: any) => {
    const { value } = args;
    setStateCalendar({ ...stateCalendar, layout: value });
    //history.push(`/d/${value}/${format(selectedDate, 'YYYY/MM/DD')}`);
  };

  const handleNext = () => {
    setRunAnimation(false);
    let newDate;

    switch (stateCalendar.layout) {
      case 'week':
        newDate = addWeeks(stateCalendar.selectedDate, 1);
        break;
      case 'day':
        newDate = addDays(stateCalendar.selectedDate, 1);
        break;
      case 'month':
      default:
        newDate = addMonths(stateCalendar.selectedDate, 1);
        break;
    }
    setStateCalendar({ ...stateCalendar, selectedDate: newDate });
  };

  const handlePrevious = () => {
    setRunAnimation(false);
    let newDate;

    switch (stateCalendar.layout) {
      case 'week':
        newDate = subWeeks(stateCalendar.selectedDate, 1);
        break;
      case 'day':
        newDate = subDays(stateCalendar.selectedDate, 1);
        break;
      default:
        // month
        newDate = subMonths(stateCalendar.selectedDate, 1);
        break;
    }
    setStateCalendar({ ...stateCalendar, selectedDate: newDate });
  };

  return (
    <CalendarContext.Provider value={{ stateCalendar, setStateCalendar }}>
      <Box style={classes.root}>
        <CalendarToolbar
          open={open}
          onGoToToday={handleGoToToday}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onDrawerOpen={handleDrawerOpen}
          onDrawerClose={handleDrawerClose}
          onLayoutChange={handleLayoutChange}
        />

        <CalendarDrawer
          selectedDate={stateCalendar.selectedDate}
          next={handleNext}
          previous={handlePrevious}
          open={open}
          onDrawerClose={handleDrawerClose}
          layout='month'
        />

        <CalendarMain
          open={open}
          runAnimation={runAnimation}
        />

        <CalendarEventDialog />
        <CalendarEventViewDialog />
        <CalendarEventsViewDialog />
      </Box>
    </CalendarContext.Provider>
  );
};