import {
  Typography,
} from '@mui/material';

const PrivacyPage = (props: any) => {
  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">Privacy Policy</Typography>
    </>
  );
};

export default PrivacyPage;