import React, { createRef, CSSProperties, RefObject } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import { v4 } from 'uuid';

import {
  DefaultAspectRatio,
  GridKind,
  SettingsGridAspect,
  SettingsGridAuto,
  Video,
} from '.';
import {
  User,
  UserVideoStream,
  VideoDimensions,  
  VideoFrame,
} from '../../types';

import '../../css/chat/index.css';

export interface VideoCallGridProps {
  usersOnline: { [userId: string]: User };
  //participants: VideoCallParticipants;
  maximized: UserVideoStream[];
  minimized: UserVideoStream[];
  showMinimizedToolbar: boolean;
  gridKind: GridKind;
  defaultAspectRatio: number;
  videoStyle: CSSProperties;
};

export interface VideoCallGridState {
  videoSize: VideoDimensions;
  toolbarVideoStyle: CSSProperties;
};

export class VideoCallGrid extends React.PureComponent<VideoCallGridProps, VideoCallGridState> {
  private gridRef = createRef<HTMLDivElement>();
  private toolbarRef = createRef<HTMLDivElement>();
  private frame: VideoFrame;
  private videoStyle?: CSSProperties;
  private gridObserver: ResizeObserver;
  private toolbarObserver: ResizeObserver;

  constructor(props: VideoCallGridProps) {
    super(props);

    this.state = {
      videoSize: {x: 0, y: 0},
      toolbarVideoStyle: {},
    };

    this.frame = new VideoFrame(DefaultAspectRatio);
    this.gridObserver = new ResizeObserver(this.handleResize);
    this.toolbarObserver = new ResizeObserver(this.handleToolbarResize);
  }

  getAspectRatio = () => {
    //const { streams, gridKind } = this.props;
    //const { participants, gridKind } = this.props;
    const { maximized, gridKind } = this.props;
    //const cameraStreams = Object.values(participants).map((stream) => stream.camera!);
    //const desktopStreams = Object.values(participants).map((stream) => stream.desktop!);
    //const streams = [...cameraStreams, ...desktopStreams];
    //const maximized = streams.filter((stream: UserVideoStream) => !stream?.windowState);
    const numWindows = maximized?.length ?? 0;

    if (
      gridKind === SettingsGridAspect ||
      (gridKind === SettingsGridAuto && numWindows > 2)
    ) {
      const aspectRatio = calcAspectRatio(DefaultAspectRatio, maximized);
      return aspectRatio;
    }

    return 0;
  }

  componentDidMount = () => {
    this.handleResize();
    this.handleToolbarResize();

    this.gridObserver.observe(this.gridRef.current!);
    this.toolbarObserver.observe(this.toolbarRef.current!);
  }

  componentWillUnmount = () => {
    this.gridObserver.disconnect();
    this.toolbarObserver.disconnect();
  }

  handleToolbarResize = () => {
    const size = getSize(this.toolbarRef);

    this.setState({
      ...this.state,
      toolbarVideoStyle: {
        width: Math.round(size.y * DefaultAspectRatio * 100) / 100,
        height: size.y,
      },
    });
  }

  handleResize = () => {
    const size = getSize(this.gridRef);
    this.frame.setSize(size);

    //this.setState({
    //  ...this.state,
    //  videoSize: size,
    //});

    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.videoSize.x = size.x;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.videoSize.y = size.y;
  }

  componentDidUpdate = () => {
    if (this.getAspectRatio()) {
      return;
    }

    const videos = this.gridRef.current!.querySelectorAll('.video-container') as unknown as HTMLElement[];
    const size = videos.length;
    const x = (1 / Math.ceil(Math.sqrt(size))) * 100;

    videos.forEach((video: any) => {
      video.style.flexBasis = x + '%';
    });
  }

  maybeUpdateSizeStyle = () => {
    //const { participants } = this.props;
    //const cameraStreams = Object.values(participants).map((stream) => stream.camera!);
    //const desktopStreams = Object.values(participants).map((stream) => stream.desktop!);
    //const streams = [...cameraStreams, ...desktopStreams];
    //const maximized = streams.filter((stream: UserVideoStream) => !stream?.windowState);
    const { maximized } = this.props;
    const aspectRatio = this.getAspectRatio();

    if (!aspectRatio) {
      this.videoStyle = undefined;
      return;
    }

    this.frame.setAspectRatio(aspectRatio);
    this.frame.setNumWindows(maximized?.length ?? 0);

    if (this.frame.needsCalc() || !this.videoStyle) {
      const size = this.frame.calcSize();
      this.videoStyle = {
        width: size.x,
        height: size.y,
      };
    }
  }

  render() {
    const {
      //streams,
      showMinimizedToolbar = true,
      //participants,
      maximized,
      minimized,
      usersOnline,
    } = this.props;

    this.maybeUpdateSizeStyle();

    //const cameraStreams = Object.values(participants).map((stream) => stream.camera!);
    //const desktopStreams = Object.values(participants).map((stream) => stream.desktop!);
    //const streams = [...cameraStreams, ...desktopStreams];
    //const minimized = streams.filter((stream) => stream?.windowState === 'minimized');
    //const maximized = streams.filter((stream) => stream?.windowState === undefined);
    const toolbarClassName = classNames('videos videos-toolbar', {
      'hidden': !showMinimizedToolbar || (minimized?.length ?? 0) === 0,
    });
    const isAspectRatio = this.videoStyle !== undefined;

    const videosToolbar = (
      <div
        key="videos-toolbar"
        className={toolbarClassName}
        ref={this.toolbarRef}
      >
        {minimized.map((video: UserVideoStream) => (
          <Video
            key={(video?.userId ?? video?.stream?.id) + video?.type! + '_' + v4()}
            forceContain={isAspectRatio}
            stream={video}
            user={usersOnline[video.userId]}
            style={this.state.toolbarVideoStyle}
          />
        ))}
      </div>
    );

    const { videoStyle } = this.props;
    const maximizedVideos = maximized.map((video: UserVideoStream) => video && (
      <Video
        key={(video?.userId ?? video?.stream?.id) + video?.type! + '_' + v4()}
        forceContain={isAspectRatio}
        stream={video}
        user={usersOnline[video.userId]}
        style={{...this.videoStyle, ...videoStyle}}
      />
    ));

    const videosGrid = (
      <div
        key='videos-grid'
        className={isAspectRatio ? 'videos-grid videos-grid-aspect-ratio' : 'videos-grid videos-grid-flex'}
        ref={this.gridRef}
      >
        {isAspectRatio ? (
          <div className='videos-grid-aspect-ratio-container'>
            {maximizedVideos}
          </div>
        ) : (
          maximizedVideos
        )}
      </div>
    );

    return [videosToolbar, videosGrid];
  }
}

const getSize = <T extends HTMLElement>(ref: RefObject<T>): VideoDimensions => {
  const { width: x, height: y } = ref.current?.getBoundingClientRect() ?? { width: 350, height: 250 };
  return { x, y };
};

const calcAspectRatio = (defaultAspectRatio: number, streams: UserVideoStream[]): number => {
  let ratio = 0.0;

  for (let i = 0; i < streams.length; i++) {
    const stream = streams[i];
    if (!stream) {
      continue;
    }

    const dim = stream.dimensions;
    if (!dim) {
      continue;
    }

    const r = dim.x / dim.y;
    if (ratio === 0) {
      ratio = r;
      continue;
    }

    if (ratio !== r) {
      ratio = defaultAspectRatio;
      break;
    }
  }

  return ratio || defaultAspectRatio;
};