import http from '../modules/http-common';

const search = async (text: string, searchItems: any = null) => {
  const response = await http
    .get(`search?query=${text}`);
  //console.log('search response:', response);
  return response.data;
};

export const SearchService = {
  search,
};