import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { ViewChallengeDetails } from '../components';
import { withRouter } from '../hooks';
import { ChallengeService } from '../services';
import { Challenge } from '../types';

const ChallengeDetailsPage = (props: any) => {
  //console.log('ChallengeDetailsPage props:', props);
  const { params: { challengeId } } = props;
  const [challenge, setChallenge] = useState<Challenge>();

  const handleReload = () => {
    ChallengeService.getChallenge(challengeId, true).then((response: any) => {
      //console.log('getChallenge response:', response);
      setChallenge(response.challenge);
    });
  };

  // eslint-disable-next-line
  useEffect(() => handleReload(), [challengeId]);

  return (
    <>
      <Typography variant="h4" gutterBottom className="text-center">
        Challenge Details
      </Typography>
      <ViewChallengeDetails challenge={challenge} />
    </>
  );
};

export default withRouter(ChallengeDetailsPage);