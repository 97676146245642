import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  Delete as DeleteIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  //Autoplay,
  //Controller,
  EffectCoverflow,
  Manipulation,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
  Zoom,
} from 'swiper';
import 'swiper/css';
//import 'swiper/css/autoplay';
//import 'swiper/css/controller';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/manipulation';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';

import { ViewImageDialog } from '../Dialogs';
import { useCurrentWidth } from '../../hooks';
import { ImageService } from '../../services';
import { CarImage } from '../../types';
import './ImageGallerySwiper.css';

const useStyles: any = (theme: Theme) => ({
  swiper: {
    width: '100%',
    height: 'auto', //'100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  swiperSlide: {
    textAlign: 'center',
    fontSize: '18px',
    /*background: #fff;*/
    /* Center slide text vertically */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  swiperSlideImage: {
  },
});

export const ImageGallerySwiper = (props: any) => {
  //console.log('ImageGallerySwiper props:', props);
  const { images, editMode = false, onReload } = props;
  const [mainSwiper, setMainSwiper] = useState<any>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [state, setState] = useState<any>({
    //mainSwiper: null,
    //thumbsSwiper: null,
    openViewImageDialog: false,
    selectedImage: null,
    isFullscreen: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const width = useCurrentWidth();
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleFullscreen = (image: CarImage) => {
    setState({
      ...state,
      selectedImage: image,
      isFullscreen: !state.isFullscreen,
      openViewImageDialog: !state.isFullscreen,
    });
  };

  const handleCloseImageDialog = () => {
    setState({...state, isFullscreen: false, openViewImageDialog: false});
  };
  
  const handleDeleteImage = (path: string) => {
    //console.log('handleDeleteImage:', path);
    const result = window.confirm('Are you sure that you want to delete this image?');
    if (!result) {
      return;
    }
    const imagePath = path.includes('/images/') ? path.replace('/images/', '') : path;
    ImageService.deleteImage(imagePath).then((response: any) => {
      //console.log('deleteImage response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to delete photo ${imagePath}, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;        
      }
      enqueueSnackbar(`Successfully deleted photo ${imagePath}`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      onReload && onReload();
    });
  };

  // Credits: https://codesandbox.io/p/sandbox/vjme97
  return (
    <div className="mb-3">
      <Swiper
        key="main-swiper"
        modules={[EffectCoverflow, Manipulation, Navigation, Pagination, Scrollbar, Thumbs, Zoom]}
        onSwiper={(swiper) => setMainSwiper(swiper)}
        loop
        spaceBetween={10}
        navigation
        pagination={{
          clickable: true,
          //el: '.swiper-pagination',
          type: 'bullets', // bullets, fraction, progressbar, custom
        }}
        scrollbar={{draggable: true}}
        thumbs={{ swiper: thumbsSwiper }}
        //centeredSlides
        grabCursor
        slideToClickedSlide
        className="main-swiper"
        effect="coverflow" // coverflow, fade
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        zoom
        style={{
          maxWidth: width - 120,//125, //111,
        }}
      >
        {images?.length > 0 && images?.map((image: CarImage) => (
          <SwiperSlide key={(image.name ?? image.url) + '_main'}>
            <IconButton
              onClick={() => handleFullscreen(image)}
              title={state.isFullscreen ? "Exit full screen mode" : "Enter full screen mode"}
              style={{
                backgroundColor: 'rgba(128, 128, 128, 0.7)',
                position: 'absolute',
                top: 5,
                right: 5,
                zIndex: 100,
              }}
            >
              {state.isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <div className="swiper-zoom-container">
              <img src={image.url} alt={image.name} title={image.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        key="thumbs-swiper"
        modules={[EffectCoverflow, Manipulation, Navigation, Scrollbar, Thumbs, Zoom]}
        onSwiper={(swiper) => setThumbsSwiper(swiper)}
        loop
        spaceBetween={10}
        navigation
        scrollbar={{draggable: true}}
        //centeredSlides
        grabCursor
        slideToClickedSlide
        slidesPerView={3}
        className="thumbs-swiper"
        effect="coverflow" // coverflow, fade
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        watchSlidesProgress
        zoom
        style={{
          maxWidth: width - 120,//125, //111,
        }}
      >
        {images?.length > 0 && images?.map((image: CarImage) => (
          <SwiperSlide key={(image.name ?? image.url) + '_thumb'}>
            {editMode && (
              <IconButton
                onClick={() => handleDeleteImage(image.url)}
                onMouseOver={(e: any) => e.target.style.color = 'red'}
                onMouseOut={(e: any) => e.target.style.color = 'inherit'}
                style={{
                  backgroundColor: 'rgba(128, 128, 128, 0.7)',
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  zIndex: 100,
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
            <div className="swiper-zoom-container">
              <img src={image.url} alt={image.name} title={image.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <ViewImageDialog
        open={state.openViewImageDialog}
        title={state.selectedImage?.name}
        image={state.selectedImage?.url}
        onClose={handleCloseImageDialog}
      />
    </div>
  );
};