import { Breakpoint, createTheme } from '@mui/material/styles';
import { red, /*deepPurple*/ } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: '#fff',
    },
    primary: {
      main: '#556cd6',
    },
    secondary: {
      //main: '#19857b',
      main: '#808080',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiFormControlLabel: {
      defaultProps: {
        color: 'white',
      },
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'white',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          //backgroundColor: 'black',
          //color: 'white',
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
  breakpoints: {
    down: (key: number | Breakpoint): string => {
      return '';
    },
  },
});

export default theme;