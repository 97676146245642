import { useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Call as CallIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { UsersOnline, SelectionType } from '..';
import { User } from '../../types';

interface UsersOnlineDialogProps {
  open: boolean;
  title: string;
  usersOnline: { [userId: string]: User };
  excludeUserIds: string[];
  onCall: (userIds: number[]) => void;
  onClose: () => void;
};

export const UsersOnlineDialog = (props: UsersOnlineDialogProps) => {
  //console.log('UsersOnlineDialog props:', props);
  const {
    open, title = 'Select Members', usersOnline, excludeUserIds = [],
    onCall, onClose,
  } = props;

  const [selected, setSelected] = useState<number[]>([]);

  const handleCall = () => {
    if (selected.length === 0) {
      return;
    }
    onCall([...selected]);
    setSelected([]);
    onClose();
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = selected.indexOf(value);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  return (
    <Dialog
      //fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography
            variant="h6"
            component="h3"
            className="text-center"
            sx={{ ml: 2, flex: 1 }}
          >
            {title}
          </Typography>
          <IconButton
            title="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <UsersOnline
          users={usersOnline}
          selectedUserIds={selected}
          excludeUserIds={excludeUserIds.map((userId) => parseInt(userId))}
          selectionType={SelectionType.Multiple}
          onCall={onCall}
          onToggle={handleToggle}
        />
      </DialogContent>
      {selected && selected.length > 0 && (
        <DialogActions>
          <Button
            title="Start video chat group"
            className="float-end"
            size="small"
            variant='contained'
            onClick={handleCall}
          >
            <CallIcon />&nbsp;Call
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};