import React, { Component } from 'react';
import {
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Clear as CloseIcon,
  SentimentSatisfiedAlt as HappyIcon,
  SentimentNeutral as NeutralIcon,
  SentimentVeryDissatisfied as SadIcon,
  TagFaces as AwesomeIcon,
} from '@mui/icons-material';

import { Feedback, FeedbackType } from '../../../types';
import './SendFeedbackDialog.css';

/**
 * Create component class
 */
export class SendFeedbackDialog extends Component<any, any> {
  /**
   * Component constructor
   * @param  {object} props is an object properties of component
   */
  constructor (props: any) {
    super(props);
    //console.log('SendFeedbackDialog props:', props);

    // Defaul state
    this.state = {
      feedText: '',
      error: false,
    };

    // Binding functions to `this`
    this.handleFeedText = this.handleFeedText.bind(this);
    this.getFeedbackForm = this.getFeedbackForm.bind(this);
    this.mainForm = this.mainForm.bind(this);
    this.loadingForm = this.loadingForm.bind(this);
    this.successForm = this.successForm.bind(this);
    this.errorForm = this.errorForm.bind(this);
  };

  handleFeedText = (event: any) => {
    const target = event ? event.target : {};
    const value = target ? target.value : '';
    if (value) {
      this.setState({feedText: value});
    }
  };

  handleSendFeed = (feedType: FeedbackType) => {
    const { onSend, currentUser } = this.props;
    const { feedText } = this.state;
    this.setState({...this.state, error: !feedText});

    if (feedText) {
      onSend && onSend(new Feedback(feedText, feedType, currentUser?.id));
    }
  };

  mainForm = () => (
    <Paper
      style={{
        borderRadius: 8,
        border: '1px solid black',
        width: '350px',
        height: '250px',
      }}
    >
      <Typography variant="h5" gutterBottom className="text-center mt-2">
        Submit Feedback
      </Typography>
      <div className="main-box">
        <TextField
          placeholder="Type your feedback here..."
          multiline
          onChange={this.handleFeedText}
          rows={5}
          error={this.state.error}
          //helperText="Feedback value must not be empty"
          autoFocus
          fullWidth
          required
        />
        <br />
        <div className="buttons">
          <Tooltip title="Awesome" placement="bottom-start">
            <IconButton onClick={() => this.handleSendFeed(FeedbackType.Awesome)}>
              <AwesomeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Happy" placement="bottom-start">
            <IconButton onClick={() => this.handleSendFeed(FeedbackType.Happy)}>
              <HappyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Acceptable" placement="bottom-start">
            <IconButton onClick={() => this.handleSendFeed(FeedbackType.Acceptable)}>
              <NeutralIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sad" placement="bottom-start">
            <IconButton onClick={() => this.handleSendFeed(FeedbackType.Sad)}>
              <SadIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );

  loadingForm = () => (
    <div className='loading'>
      <p>Sending message ...</p>
      <div className='icon'>
        <CircularProgress
          color='secondary'
          size={50}
          variant='determinate'
          value={(25 - 0) / (50 - 0) * 100}
        />
      </div>
    </div>
  );

  successForm = () => (
    <div className='success'>
      Thank you for your feedback, we appreciate it very much!
    </div>
  );

  errorForm = () => (
    <div className='error'>
      Unknown error has occurred!
    </div>
  );

  getFeedbackForm = () => {
    /*
    const { sendFeed, currentUser } = this.props;
    const { feedText } = this.state;

    const feedType = FeedbackType.Awesome;
    const feedback = new Feedback('', feedText, feedType, currentUser);
    //sendFeed(feedback);

    UserService.sendFeedback(currentUser?.id, feedback).then((response: any) => {
      console.log('send feedback response:', response);
    });
    */

    const sendFeedbackRequestType: string = '';
    switch (sendFeedbackRequestType) {
      case 'Sent':
        return this.loadingForm();
      case 'OK':
        return this.successForm();
      case 'Error':
        return this.errorForm();
      default:
        return this.mainForm();
    }
  };

  /**
   * Reneder component DOM
   * @return {react element} return the DOM which rendered by component
   */
  render () {
    const { onClose } = this.props;

    return (
      <div className="sendFeedback">
        <Paper className='paper'>
          <Tooltip title='Cancel' placement='bottom-start'>
            <IconButton
              onClick={onClose}
              style={{position: 'absolute', top: 0, right: 0}}
            >
              <CloseIcon />
            </IconButton>
           </Tooltip >
          {this.getFeedbackForm()}
        </Paper>
      </div>
    );
  };
};