import http from '../modules/http-common';
import { Notification } from '../types';

const getNotifications = async (userId: number, includeDeleted: boolean = false) => {
  const response = await http
    .get(`notifications/${userId}?includeDeleted=${includeDeleted}`);
  return response.data;
};

const createNotification = async (notification: Notification) => {
  const response = await http
    .post('notifications/create', { notification });
  return response.data;
};

const deleteNotification = async (id: number) => {
  const response = await http
    .delete(`notifications/${id}/delete`);
  return response.data;
};

const clearNotifications = async (userId: number) => {
  const response = await http
    .post('notifications/clear', { userId });
  return response.data;
};

const markRead = async (id: number) => {
  const response = await http
    .put(`notifications/${id}/read`);
  return response.data;
};

export const NotificationService = {
  getNotifications,
  createNotification,
  deleteNotification,
  clearNotifications,
  markRead,
};