import { IconButton } from '@mui/material';
import {
  Add as AddIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import './Contacts.css';
import '../ChatMessageContainer/ChatMessageContainer.css';

interface IMessageContactsHeaderProps {
  miniContacts: boolean,
  isLarge: boolean;
  onOpenContactsDialog: () => void;
  onOpenSettingsDialog: () => void;
};

export const MessageContactsHeader = (props: IMessageContactsHeaderProps) => {
  const { miniContacts, isLarge, onOpenContactsDialog, onOpenSettingsDialog } = props;

  return (
    <div className="contacts-header">
      {(!miniContacts && isLarge) && (
        <strong className="messages-title">
          <label className="text-light">Contacts</label>
        </strong>
      )}
      <div style={{justifyContent: 'end'}}>
        <IconButton
          onClick={onOpenContactsDialog}
          title="Start new conversation"
          style={{flex: 1}}
          size="small"
        >
          <AddIcon style={{width: '20px', height: '20px'}} />
        </IconButton>
        <IconButton
          onClick={onOpenSettingsDialog}
          title="Display contacts settings"
          style={{flex: 1}}
          size="small"
        >
          <SettingsIcon style={{width: '20px', height: '20px'}} />
        </IconButton>
      </div>
    </div>
  );
};