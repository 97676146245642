import React, { useContext, useMemo } from 'react';
import format from 'date-fns/format';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
  AccessTime as AccessTimeIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';

import { CalendarContext, CreateEditEvent } from '.';
import { CalendarService } from '../../services';

const Transition: any = React.forwardRef<unknown, TransitionProps>((props: any, ref: any) => {
  return <Slide direction='down' ref={ref} {...props} />
});

const useStyles: any = ((theme: Theme) => ({
  divTitleButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: theme.spacing(-1),
  },
  formControlFlex: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  optionsBar: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

export const CalendarEventViewDialog = (props: any) => {
  //console.log('CalendarEventViewDialog props:', props);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { openViewDialog, locale, calendarEvent } = stateCalendar;

  return useMemo(() => {
    const handleEdit = (event: any) => {
      CreateEditEvent({
        event,
        stateCalendar,
        setStateCalendar,
        calendarEvent,
      });
    };
  
    const handleDelete = () => {
      const result = window.confirm(`Are you sure that you want to delete this event?`);
      if (!result) {
        return;
      }
  
      CalendarService.deleteEvent(calendarEvent.id).then((response: any) => {
        console.log('deleteEvent response:', response);
        if (response.status !== 200) {
          // TODO: Show error notification
          return;
        }
        //const events = stateCalendar.calendarEvents.filter((ev: any) => ev.id !== calendarEvent.id);
        //setStateCalendar({...stateCalendar, calendarEvents: events});
        handleCloseViewDialog();
      });
    };

    const handleCloseViewDialog = () => {
      setStateCalendar({...stateCalendar, openViewDialog: false});
    };

    return (
      <Dialog
        fullScreen={false}
        fullWidth
        maxWidth='sm'
        open={openViewDialog}
        onClose={handleCloseViewDialog}
        aria-labelledby='max-width-dialog-title'
        TransitionComponent={Transition}
        keepMounted={false}
      >
        <DialogTitle>
          {calendarEvent.title || ''}
          <div style={classes.divTitleButton}>
            <IconButton
              aria-label='Close'
              onClick={handleCloseViewDialog}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={classes.dialogContent}>
          <FormControl style={classes.optionsBar}>
            <Tooltip title='Edit'>
              <IconButton
                size='medium'
                aria-label='Edit event'
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete'>
              <IconButton
                size='medium'
                edge='end'
                aria-label='Delete event'
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </FormControl>
          <FormControl style={{...classes.formControl, ...classes.formControlFlex}}>
            <Typography gutterBottom>
              <AccessTimeIcon style={classes.icon} />
              {format(calendarEvent.begin ? new Date(calendarEvent.begin) : new Date(), 'eeee, d MMMM yyyy ⋅ hh:mm a -', { locale })}
              {' '}
              {format(calendarEvent.end ? new Date(calendarEvent.end) : new Date(), 'hh:mm a', { locale })}
            </Typography>
          </FormControl>
          <Typography paragraph>
            {calendarEvent.description}
          </Typography>
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  }, [
    calendarEvent,
    classes,
    locale,
    openViewDialog,
    stateCalendar,
    setStateCalendar,
  ]);
};