import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { FormattedPhoneNumber } from '..';

const useStyles: any = (theme: Theme) => ({
  root: {
    border: '1px solid black',
    borderRadius: 20,
  },
});

export const RaceTrackDetails = (props: any) => {
  //console.log('RaceTrackDetails props:', props);
  const { track } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper style={classes.root}>
      <Card key={track?.name} style={{borderRadius: 20}}>
        <CardHeader
          title={
            <Typography variant="h5" gutterBottom className="text-center">
              {track.name}
            </Typography>
          }
        />
        <CardContent className="text-center">
          {track.description && (
            <Typography paragraph>
              {track.description}
            </Typography>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>Track Length</Grid>
              <Grid item xs={6}>
                <span className="float-md-start" style={{color: 'lightgrey', textAlign: 'start'}}>
                  {track.length}
                </span>
              </Grid>
            <Grid item xs={6}>Address</Grid>
            <Grid item xs={6}>
              <span className="float-md-start" style={{textAlign: 'start'}}>
                <a
                  href={track.gmapsUrl}
                  style={{color: 'lightgray', textDecoration: 'none'}}
                  target="_blank"
                  rel="noreferrer"
                  dangerouslySetInnerHTML={{__html: track.address}}
                />
              </span>
            </Grid>
            <Grid item xs={6}>Phone</Grid>
            <Grid item xs={6}>
              <span className="float-md-start" style={{textAlign: 'start'}}>
                <a
                  href={"tel://+" + track.phone}
                  style={{color: 'lightgray', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedPhoneNumber value={track.phone} />
                </a>
              </span>
            </Grid>
            <Grid item xs={6}>Website</Grid>
            <Grid item xs={6}>
              <span className="float-md-start" style={{textAlign: 'start'}}>
                <a
                  href={track.website}
                  style={{color: 'lightgray', textDecoration: 'none'}}
                  target="_blank"
                  rel="noreferrer"
                >
                  {track.website ?? '--'}
                </a>
              </span>
            </Grid>
            <Grid item xs={6}>Created By</Grid>
            <Grid item xs={6}>
              <span className="float-md-start" style={{textAlign: 'start'}}>
                <a
                  href={`/${track.createdByUser?.username}`}
                  style={{color: 'lightgray', textDecoration: 'none'}}
                  target="_blank"
                  rel="noreferrer"
                >
                  {track.createdByUser?.username}
                </a>
              </span>
            </Grid>
            <Grid item xs={12}>Pricing</Grid>
            <Grid item xs={6}>Racer Entrance Fee</Grid>
            <Grid item xs={6}>
              <span style={{textAlign: 'start'}}>
                {track.raceEntranceFee === 0
                  ? 'Free'
                  : `$${track.raceEntranceFee}`
                }
              </span>
            </Grid>
            <Grid item xs={6}>Spectator Entrance Fee</Grid>
            <Grid item xs={6}>
              <span style={{textAlign: 'start'}}>
                {track.specEntranceFee === 0
                  ? 'Free'
                  : `$${track.specEntranceFee}`
                }
              </span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};