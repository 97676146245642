import moment from 'moment';

interface IChatMessageDateHeaderProps {
  date: string;
  format?: string;
};

export const ChatMessageDateHeader = (props: IChatMessageDateHeaderProps) => {
  const { date, format = 'dddd MMMM Do, YYYY' } = props;

  return (
    <div
      className="text-center"
      style={{
        backgroundColor: '#313131',
        //marginTop: '10px',
        marginBottom: '15px',
        paddingTop: '5px',
        paddingBottom: '5px',
      }}
    >
      {moment(date).format(format)}
    </div>
  );
};