import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  AvatarChanger,
  UserBannerChanger,
} from '../components';
import { VerifyEmail } from '.';
import {
  ChangeEmailPage,
  DeleteAccountButton,
  PasswordResetPage,
  UpdateAccountDetailsPage,
  UpdateUserSocialProfiles,
} from './Account';
import { getAvatarUrl, getBannerUrl } from '../modules';
import { getUserToken } from '../stores';

const SettingsPage = (props: any) => {
  const [marginTop, setMarginTop] = useState(window.innerWidth <= 900 ? '0px' : '50px');
  const theme = useTheme();
  const currentUser = getUserToken();

  useEffect(() => {
    const handleResize = () => setMarginTop(window.innerWidth <= 900 ? '0px' : '50px');
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">Settings</Typography>
      <Grid container spacing={2} style={{width: '100%'}}>
        <Grid item md={4} xs={12}>
          <AvatarChanger
            path={getAvatarUrl(currentUser.id)}
            title={currentUser.username}
            userId={currentUser.id}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Paper
            className="bg-dark text-light"
            elevation={6}
            style={{
              marginTop: marginTop,
              [theme.breakpoints.down('sm')]: {
                marginTop: '0px',
              },
              position: 'relative',
              borderRadius: 20,
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '28px',
              paddingLeft: '70px',
              paddingRight: '70px',
              paddingTop: '15px',
            }}
          >
            <Typography component="h1" variant="h5" className="text-center">
              Account Details
            </Typography>
            <div className="row mx-auto">
              <div className="row">
                <div className="col col-md-5 col-xs-12">
                  <strong className="float-start">Account ID</strong>
                </div>
                <div className="col col-md-7 col-xs-12">
                  <span className="float-end">{currentUser?.id}</span>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-5 col-xs-12">
                  <strong className="float-start">Username</strong>
                </div>
                <div className="col col-md-7 col-xs-12">
                  <span className="float-end">{currentUser?.username}</span>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-5 col-xs-12">
                  <strong className="float-start">Email Address</strong>
                </div>
                <div className="col col-md-7 col-xs-12">
                  <span className="float-end">{currentUser?.email}</span>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-5 col-xs-12">
                  <strong className="float-start">Roles</strong>
                </div>
                <div className="col col-md-7 col-xs-12">
                  <span className="float-end">{currentUser?.roles}</span>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            className="bg-dark text-light"
            elevation={6}
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              borderRadius: 20,
              border: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography component="h1" variant="h5" className="text-center mt-3">
              Profile Banner
            </Typography>
            <div className="row w-100">
              <UserBannerChanger userId={currentUser.id} path={getBannerUrl(currentUser.id)} />
            </div>
          </Paper>
        </Grid>
        {currentUser.emailVerified ? '' : (
          <Grid item xs={12}>
            <VerifyEmail redirectToLogin={false} />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <PasswordResetPage />
        </Grid>
        <Grid item md={6} xs={12}>
          <ChangeEmailPage />
        </Grid>
        <Grid item xs={12}>
          <UpdateAccountDetailsPage user={currentUser} />
        </Grid>
        <Grid item xs={12}>
          <Paper
            className="bg-dark text-light"
            elevation={6}
            style={{
              //marginTop: '16px',
              position: 'relative',
              borderRadius: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '15px',
            }}
          >
            <Typography component="h1" variant="h5" className="mb-3">
              Social Profiles
            </Typography>
            <UpdateUserSocialProfiles user={currentUser} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            className="bg-dark text-light"
            elevation={6}
            style={{
              //marginTop: '16px',
              position: 'relative',
              borderRadius: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '15px',
            }}
          >
            <Typography component="h1" variant="h5" className="mb-3">
              Account Management
            </Typography>
            <DeleteAccountButton />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsPage;