import classnames from 'classnames';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  Chat,
  GridKind,
  Panel,
  Settings,
  SidebarPanelChat,
  SidebarPanelSettings,
  SidebarPanelUsers,
  UserList,
  VideoCallSidebarButton,
} from '.';
import { useVideoCall } from '../../hooks';
import { UserVideoStream } from '../../types';

import '../../css/chat/index.css';

export interface VideoCallSidebarProps {
  userId: number;

  playIncomingCallRinger: boolean;
  onToggleIncomingCallRinger: () => void;
  playOutgoingCallRinger: boolean;
  onToggleOutgoingCallRinger: () => void;
  gridKind: GridKind;
  onGridKindChange: (gridKind: GridKind) => void;
  showMinimizedToolbar: boolean;
  onShowMinimizedToolbarToggle: () => void;

  visible: boolean;
  panel: Panel;
  onHide: () => void;
  onShow: (panel: Panel) => void;
};

export const VideoCallSidebar = (props: VideoCallSidebarProps) => {
  const {
    userId, panel, visible,
    playIncomingCallRinger, playOutgoingCallRinger,
    gridKind, showMinimizedToolbar,
    onHide, onShow,
    onGridKindChange, onShowMinimizedToolbarToggle,
    onToggleIncomingCallRinger, onToggleOutgoingCallRinger,
  } = props;

  const {
    callState,
    messages,
    participants,
    usersOnline,

    onMinimizeStream,
    onMaximizeStream,
    onSendMessage,
    onSendFileMessage,
  } = useVideoCall();

  const handleToggleWindowState = (stream: UserVideoStream) => {
    stream.windowState === 'minimized'
      ? onMaximizeStream(stream)
      : onMinimizeStream(stream);
  };

  const streams = [
    ...Object.values(participants).filter((stream) => !!stream.camera).map((stream) => stream.camera!),
    ...Object.values(participants).filter((stream) => !!stream.desktop).map((stream) => stream.desktop!)];

  return (
    <div className={classnames('sidebar', { show: visible }) + ' vh-75'}>
      <div className='sidebar-header'>
        <IconButton
          title='Close sidebar'
          //className='sidebar-close'
          size='small'
          onClick={onHide}
          style={{
            padding: '12px',
            float: 'left',
          }}
        >
          <CloseIcon />
        </IconButton>
        <ul className='sidebar-menu'>
          <VideoCallSidebarButton
            activePanel={panel}
            className='sidebar-menu-chat'
            label='Chat'
            onClick={onShow}
            panel={SidebarPanelChat}
          />
          <VideoCallSidebarButton
            activePanel={panel}
            className='sidebar-menu-users'
            label='Users'
            onClick={onShow}
            panel={SidebarPanelUsers}
          />
          <VideoCallSidebarButton
            activePanel={panel}
            className='sidebar-menu-settings'
            label='Settings'
            onClick={onShow}
            panel={SidebarPanelSettings}
          />
        </ul>
      </div>
      <div className='sidebar-content'>
        {panel === SidebarPanelChat && (
          <Chat
            userId={userId}
            messages={messages}
            usersOnline={usersOnline}
            roomId={callState?.roomId!}
            visible={visible}
            onSendMessage={onSendMessage}
            onSendFileMessage={onSendFileMessage}
          />
        )}
        {panel === SidebarPanelUsers && (
          <UserList
            users={usersOnline ?? {}}
            streams={streams}
            onMinimizeToggle={handleToggleWindowState}            
          />
        )}
        {panel === SidebarPanelSettings && (
          <Settings
            callState={callState!}
            showMinimizedToolbar={showMinimizedToolbar}
            onShowMinimizedToolbarToggle={onShowMinimizedToolbarToggle}
            gridKind={gridKind}
            onSetGridKind={onGridKindChange}
            playIncomingCallRinger={playIncomingCallRinger}
            onToggleIncomingCallRinger={onToggleIncomingCallRinger}
            playOutgoingCallRinger={playOutgoingCallRinger}
            onToggleOutgoingCallRinger={onToggleOutgoingCallRinger}
          />
        )}
      </div>
    </div>
  );
};