import { Typography } from '@mui/material';

import { ChatMessenger } from '../../components';

export const MessagesPage = (props: any) => {
  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">
        Messages
      </Typography>
      <ChatMessenger />
    </>
  );
};