import { defaultTheme } from './defaultTheme';

export const darkTheme = {
  palette: {
    getContrastText: (text: string) => {
      //console.log('getContrastText:', text);
      return text;
    },
    primary: {
      //main: '#4f3cc9',
      main: '#556CD6',
    },
    secondary: {
      light: '#5f5fc4',
      //main: '#283593',
      main: '#5f5fc4',
      dark: '#556CD6',
    },
    background: {
      default: '#212121',
      main: '#556CD6',
    },
    mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
    closedWidth: 55,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #414141',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #556CD6',
          },
          '&.RaMenuItemLink-hover:hover': {
            color: '#556CD6',
          },
        },
      },
    }, //MuiMenuItem-root
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffb3',
          backgroundColor: '#6161e6',
        },
      },
    },
  },
};

export const lightTheme: any = {
  palette: {
    getContrastText: (text: string) => {
      //console.log('getContrastText:', text);
      return text;
    },
    primary: {
      //main: '#4f3cc9',
      main: '#556CD6', // #4f3cc9
    },
    secondary: {
      light: '#5f5fc4',
      main: '#5f5fc4',
      dark: '#556CD6',
      contrastText: '#fff',
    },
    background: {
      default: '#919191',
      main: '#919191',
    },
    mode: 'light' as 'light',
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
    closedWidth: 55,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #556CD6',
          },
          '&.RaMenuItemLink-hover:hover': {
            color: '#556CD6',
          },
        },
      },
    },
    /*
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    */
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'silver',
        },
        inherit: {
          color: 'silver',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          //color: '#808080',
          //backgroundColor: '#fff',
          color: '#ffffb3',
          backgroundColor: '#6161e6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': { border: 0 },
        },
      },
    },
  },
};