import React from 'react';
import { Button } from '@mui/material';

import { AuthService, UserService } from '../../services';

export const DeleteAccountButton = (props: any) => {
  const { userId, size = 'small' } = props;

  const onClick = () => {
    let result = window.confirm(`You are about to delete your account and all data related to it, this action is irreversible and cannot be undone.\n\nAre you sure that you want to do this?`);
    if (!result) {
      return;
    }
    result = window.confirm(`Last chance, are you sure?`);
    if (!result) {
      return;
    }

    UserService.deleteAccount(userId).then((response: any) => {
      if (response.status === 200) {
        AuthService.logout();
      }
    });
  };

  return (
    <Button
      color="error"
      variant="contained"
      size={size}
      onClick={onClick}
    >
      Delete Account
    </Button>
  );
};