import { Grid } from '@mui/material';

import { UserAvatarGroup } from '../..';
import { useSockets } from '../../../hooks';
import { decrypt, substr } from '../../../modules';
import './Contact.css';

export interface ContactProps {
  userId: number;
  contact: any;
  activeRoomId?: number;
  showUsername: boolean;
  isUnread?: boolean;
  showMessagePreview: boolean;
  showDate: boolean;
  date?: string;
  onClick?: (socket: any, room: any) => void;
};

export const Contact = (props: ContactProps) => {
  //console.log('Contact props:', props);
  const { userId, contact, onClick, activeRoomId = 0, showUsername = true, isUnread = false, showMessagePreview = true, showDate = false, date } = props;
  const { socket } = useSockets();

  const isContactList = (contact?.users?.length ?? 0) > 0;
  const users = isContactList
    ? contact?.users?.filter((user: any) => user?.user?.id !== userId) ?? [contact]
    : [contact];
  const userIds = isContactList
    ? users?.map((user: any) => user!.user?.id)?.filter((user: any) => user !== undefined)
    : [contact.id];
  const usernames = isContactList
    ? users?.map((user: any) => user!.user?.username)?.filter((user: any) => user !== undefined)
    : [contact.username];
  const message = contact?.lastMessage
    ? decrypt(contact?.lastMessage)
    : '';

  return (
    <Grid
      container
      spacing={0}
      onClick={() => onClick && onClick(socket, contact)}
      title={contact?.id}
      style={{
        display: 'flex',
        border: '1px solid grey',
        paddingRight: '5px',
        cursor: 'pointer',
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        backgroundColor: activeRoomId === contact?.id
          ? 'dodgerblue'
          : isUnread
            ? 'red'
            //: '#2d343e',
            : 'inherit',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item xs={showUsername ? 3 : 12} style={{flex: 1}}>
        <UserAvatarGroup userIds={userIds} />
      </Grid>
      {showUsername && (
        <Grid item xs={9} >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className={`guest-name ${showMessagePreview ? 'float-start' : 'text-center'}`}>
                <label><strong>&nbsp;{substr(usernames.join(', '))}</strong></label>
              </div>
              {showDate && (
                <div className="float-end">
                  <small style={{fontSize: 10}}>{date}</small>
                </div>
              )}
            </Grid>
            {showMessagePreview && (
              <Grid item xs={12}>
                <div className="guest-name">
                  <label><strong>{substr(message)}</strong></label>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};