import {
  Button,
  Paper,
  Typography,
} from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';

const PageNotFound = () => {
  return (
    <Paper className="bg-dark text-light">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: `100%`,
        }}
      >
        <Typography variant="h4" gutterBottom className="text-center">
          404 - Page Not Found
        </Typography>
        <Button
          color="secondary"
          aria-label="home"
          href="/"
          style={{ marginTop: 20 }}
        >
          <HomeIcon />
        </Button>
      </div>
    </Paper>
  );
};

export default PageNotFound;