import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

import { Calendar } from '../components';

const EventsPage = (props: any) => {
  //console.log('EventsPage props:', props);

  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">Events Calendar</Typography>
      <Calendar />
      
      <br />

      <Typography variant="h4" gutterBottom className="text-center">Previous Events</Typography>
      <Card style={{borderRadius: 20, border: '1px solid black'}}>
        <CardHeader
          title="Veloster Turbo vs 392 Scat Pack"
          titleTypographyProps={{align: 'center'}}
          subheader="Round #1 - Head to Head"
          subheaderTypographyProps={{
            textAlign: 'center',
          }}
        />
        <CardContent>
          <ul className="list-group text-nowrap mb-3">
            <li className="list-group-item list-group-item-dark">
              <dl className="row m-0 p-0">
                <dt className="col-sm-4"><span className="float-sm-start">Date</span></dt>
                <dd className="col-sm-8"><span className="float-sm-end">March 9th, 2023</span></dd>
              </dl>
            </li>
            <li className="list-group-item list-group-item-dark">
              <dl className="row m-0 p-0">
                <dt className="col-sm-4"><span className="float-sm-start">Location</span></dt>
                <dd className="col-sm-8"><span className="float-sm-end">Irwindale Speedway</span></dd>
              </dl>
            </li>
            <li className="list-group-item list-group-item-dark">
              <dl className="row m-0 p-0">
                <dt className="col-sm-4"><span className="float-sm-start">Track</span></dt>
                <dd className="col-sm-8"><span className="float-sm-end">1/8th mile</span></dd>
              </dl>
            </li>
            <li className="list-group-item list-group-item-dark">
              <dl className="row m-0 p-0">
                <dt className="col-sm-4"><span className="float-sm-start">Winner</span></dt>
                <dd className="col-sm-8"><span className="float-sm-end">2020 Dodge Challenger Scat Pack</span></dd>
              </dl>
            </li>
          </ul>

          <div className="row mb-1">
            <Typography variant="h5" gutterBottom className="text-center">Race Statistics</Typography>
            <div className="col col-md-6 col-sm-12 col-xs-12 mb-3">
              <Card className="bg-dark" style={{borderRadius: 20, border: '1px solid black'}}>
                <CardHeader
                  title="2015 Veloster Turbo"
                  titleTypographyProps={{align: 'center'}}
                  subheader=""
                  subheaderTypographyProps={{
                    textAlign: 'center',
                  }}
                />
                <CardContent>
                  <ul className="list-group text-nowrap">
                    <li className="list-group-item list-group-item-dark">
                      <dl className="row m-0 p-0">
                        <dt className="col-sm-4"><span className="float-sm-start">Best Time</span></dt>
                        <dd className="col-sm-8"><span className="float-sm-end">10 sec</span></dd>
                      </dl>
                    </li>
                    <li className="list-group-item list-group-item-dark">
                      <dl className="row m-0 p-0">
                        <dt className="col-sm-4"><span className="float-sm-start">Best Reaction</span></dt>
                        <dd className="col-sm-8"><span className="float-sm-end">0.771 sec</span></dd>
                      </dl>
                    </li>
                    <li className="list-group-item list-group-item-dark">
                      <dl className="row m-0 p-0">
                        <dt className="col-sm-4"><span className="float-sm-start">Top Speed</span></dt>
                        <dd className="col-sm-8"><span className="float-sm-end">74 MPH</span></dd>
                      </dl>
                    </li>
                    <li className="list-group-item list-group-item-dark">
                      <dl className="row m-0 p-0">
                        <dt className="col-sm-4"><span className="float-sm-start">Total Runs</span></dt>
                        <dd className="col-sm-8"><span className="float-sm-end">3</span></dd>
                      </dl>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            </div>
            <div className="col col-md-6 col-sm-12 col-xs-12 mb-3">
              <Card className="bg-dark" style={{borderRadius: 20, border: '1px solid black'}}>
                <CardHeader
                  title="2020 Challenger Scat Pack"
                  titleTypographyProps={{align: 'center'}}
                  subheader=""
                  subheaderTypographyProps={{
                    textAlign: 'center',
                  }}
                />
                <CardContent>
                <ul className="list-group text-nowrap">
                  <li className="list-group-item list-group-item-dark">
                    <dl className="row m-0 p-0">
                      <dt className="col-sm-4"><span className="float-sm-start">Best Time</span></dt>
                      <dd className="col-sm-8"><span className="float-sm-end">8.442 sec</span></dd>
                    </dl>
                  </li>
                  <li className="list-group-item list-group-item-dark">
                    <dl className="row m-0 p-0">
                      <dt className="col-sm-4"><span className="float-sm-start">Best Reaction</span></dt>
                      <dd className="col-sm-8"><span className="float-sm-end">0.947 sec</span></dd>
                    </dl>
                  </li>
                  <li className="list-group-item list-group-item-dark">
                    <dl className="row m-0 p-0">
                      <dt className="col-sm-4"><span className="float-sm-start">Top Speed</span></dt>
                      <dd className="col-sm-8"><span className="float-sm-end">88.34 MPH</span></dd>
                    </dl>
                  </li>
                  <li className="list-group-item list-group-item-dark">
                    <dl className="row m-0 p-0">
                      <dt className="col-sm-4"><span className="float-sm-start">Total Runs</span></dt>
                      <dd className="col-sm-8"><span className="float-sm-end">3</span></dd>
                    </dl>
                  </li>
                </ul>
                </CardContent>
              </Card>
            </div>
          </div>

          <Typography variant="h5" gutterBottom className="text-center">Race Gallery</Typography>
          <div id="carousel-round-1" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="1" aria-current="false" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="2" aria-current="false" aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="3" aria-current="false" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="4" aria-current="false" aria-label="Slide 5"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                  <video
                    className="d-block w-100"
                    src="/videos/round1/round1_vt_vs_scat.mp4"
                    controls
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Final Run</h5>
                    <p>Veloster Turbo vs Challenger Scat Pack - Head to Head</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <video
                    className="d-block w-100"
                    src="/videos/round1/round1_scat1_vs_bmw_m4.mov"
                    controls
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Skat Pack First Pass</h5>
                    <p>2020 Dodge Challenger Skat Pack vs 20?? BMW M4</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <video
                    className="d-block w-100"
                    src="/videos/round1/round1_scat2_vs_hellcat.mov"
                    controls
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Skat Pack Second Pass</h5>
                    <p>2020 Dodge Challenger Skat Pack vs 20?? Dodge Challenger Hellcat</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <video
                    className="d-block w-100"
                    src="/videos/round1/round1_vt1_vs_5.0.mov"
                    controls
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Veloster Turbo First Pass</h5>
                    <p>2015 Hyundai Veloster Turbo vs 2018 Ford Mustang 5.0</p>
                  </div>
                </div>
                <div className="carousel-item">
                  <video
                    className="d-block w-100"
                    src="/videos/round1/round1_vt2_vs_mustang.mov"
                    controls
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Veloster Turbo Second Pass</h5>
                    <p>2015 Hyundai Veloster Turbo vs 2020 Ford Mustang 5.0</p>
                  </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carousel-round-1" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carousel-round-1" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default EventsPage;