import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { ViewPostDetails } from '..';

export const ViewPostDetailsDialog = (props: any) => {
  //console.log('ViewPostDetailsDialog props:', props);
  const { open, onClose, post, currentUser, fullScreen } = props;

  const onDialogClick = (event: any) => {
    const { target: { className } } = event;
    if (typeof className !== 'object') {
      if (className?.includes('MuiDialogContent-root') ||
          className?.includes('MuiDialogTitle-root')) {
        onClose && onClose();
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={'lg'}
      hideBackdrop={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        onClick: onDialogClick,
      }}
    >
      <DialogTitle>
        Post Details
        <IconButton
          onClick={onClose}
          title="Close"
          className="float-end"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ViewPostDetails
          currentUser={currentUser}
          post={post}
        />
      </DialogContent>
    </Dialog>
  );
};