import { Grid } from '@mui/material';

import { getTimePosition } from '.';

export const CalendarEventsContainer = (props: any) => {
  //console.log('EventsContainer props:', props);
  const { eventsOfDay } = props;
  const xsSpacing = 12 / eventsOfDay.length;
  return (
    <Grid
      key={props['data-date']}
      container
      spacing={0}
      style={{justifyContent: 'center'}}
    >
      {eventsOfDay.map((events: any) => (
        <Grid
          item
          xs={xsSpacing}
          key={events[0].key}
          style={{
            justifyContent: 'center',
            marginTop: getTimePosition(new Date(events[0].props.calendarEvent.begin)),
          }}
        >
          {events[0]}
        </Grid>
      ))}
    </Grid>
  );
};