import env from 'react-dotenv';
import { Paper, Typography } from '@mui/material';

const AboutPage = () => {
  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">About</Typography>
      <Paper
        elevation={6}
        className="text-center"
        style={{
          backgroundColor: '#181818',
          borderRadius: 10,
          border: '1px solid black',
          marginBottom: 25,
        }}
      >
        <Typography paragraph className="my-3">
          {env.SITE_TITLE} was initially used just to promote a drag race between two people.<br />
          After more thought the idea was to create a social media platform for car enthusiasts<br />
          and dragsters as well as provide a way to showcase and keep track of car builds.<br />
          <br />
          To be continued...
        </Typography>
      </Paper>
    </>
  );
};

export default AboutPage;