import React, { useState } from 'react';
import { Button } from '@mui/material';
import {
  CheckCircle as AcceptedIcon,
  Help as HelpIcon,
} from '@mui/icons-material';

import { ChallengeResultsDialog } from '../Dialogs';
import { createNotification } from '../../modules';
import { ChallengeService } from '../../services';
import { Challenge } from '../../types';

export const ChallengeResultsButton = (props: any) => {
  //console.log('ChallengeResultsButton props:', props);
  const { challenge, currentUser, classes } = props;
  const [open, setOpen] = useState(false);
  const canSubmitResults = challenge?.date <= new Date() && (
    currentUser?.id === challenge?.challenger?.id ||
    currentUser?.id === challenge?.challengee?.id
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAcceptChallenge = (challenge: Challenge) => {
    //console.log('handleAcceptChallenge:', challenge);
    const result = window.confirm(`You are about to accept a racing challenge from '${challenge?.challenger?.username}', are you sure you want to do this?`);
    if (!result) {
      return;
    }

    ChallengeService.acceptChallenge(challenge?.id).then((response: any) => {
      console.log('acceptChallenge response:', response);
      if (response.status !== 200) {
        // TODO: Show error notification
        return;
      }
      //handleReload();
      challenge = response.challenge;
      createNotification(currentUser.id, challenge?.challengerUserId, `${currentUser?.username} accepted your challenge ${challenge?.id}`);
    });
  };

  const SubmitOrWinner = !!challenge?.resultsId ? (
    <>
    {/*
    <div style={classes.winnerText}>
      <Typography>Winner: {challenge?.results?.winnerCar?.user?.username}</Typography>
    </div>
    */}
    </>
  ) : (
    <>
      <span style={classes.acceptedIcon}>
        <AcceptedIcon />&nbsp;{challenge?.challengeeUserId === currentUser?.id ? 'Accepted' : `${challenge?.challengee?.username} has accepted`}
      </span>
      <Button
        variant="contained"
        size="small"
        style={classes.submitButton}
        onClick={handleOpen}
        disabled={!canSubmitResults}
      >
        Submit Results
      </Button>
      <ChallengeResultsDialog
        open={open}
        challenge={challenge}
        currentUser={currentUser}
        onClose={handleClose}
      />
    </>
  );

  const AcceptOrWaiting = challenge.challengerUserId === currentUser?.id ? (
    <span style={classes.notAcceptedIcon}>
      <HelpIcon />&nbsp;
      <span style={{color: 'white'}}>
        Waiting to be accepted
      </span>
    </span>
  ) : (
    <Button
      variant="contained"
      size="small"
      style={classes.acceptButton}
      onClick={() => handleAcceptChallenge(challenge)}
      disabled={challenge.challengeeUserId !== currentUser?.id}
    >
      Accept
    </Button>
  );

  return challenge?.accepted
    ? SubmitOrWinner
    : AcceptOrWaiting;
};