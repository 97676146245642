import http from '../modules/http-common';
import { Message } from '../types';

const getContacts = async (userId: number) => {
  const response = await http
    .get(`messages/${userId}/contacts`);
  return response.data;
};

const getMessages = async (roomId: number, includeDeleted: boolean = false) => {
  const response = await http
    .get(`messages/${roomId}?includeDeleted=${includeDeleted}`);
  return response.data;
};

const createMessage = async (message: Message) => {
  const response = await http
    .post('messages/create', { message });
  return response.data;
};

const updateMessage = async (message: Message) => {
  const response = await http
    .put(`messages/update`, { message });
  return response.data;
};

const deleteMessage = async (id: number) => {
  const response = await http
    .delete(`messages/${id}/delete`);
  return response.data;
};

const markRead = async (id: number) => {
  const response = await http
    .put(`messages/${id}/read`);
  return response.data;
};

const getUnreadCount = async (roomId: number, userId: number, includeDeleted: boolean = false) => {
  const response = await http
    .get(`messages/${roomId}/unread?userId=${userId}&includeDeleted=${includeDeleted}`);
  return response.data;
}

const getEmojis = async (id: number) => {
  const response = await http
    .get(`messages/${id}/emojis`);
  return response.data;
};

const addEmoji = async (id: number, userId: number, emoji: string) => {
  const response = await http
    .post(`messages/${id}/emojis/add`, { userId, emoji });
  return response.data;
};

export const MessageService = {
  getContacts,
  getMessages,
  createMessage,
  updateMessage,
  deleteMessage,
  markRead,
  getUnreadCount,
  getEmojis,
  addEmoji,
};