import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ChallengeListItem } from '../../components';
import { UserService } from '../../services';
import { Challenge } from '../../types';

export const MyChallengesPage = (props: any) => {
  const { user } = props;
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleReload = () => {
    if (!user?.id) {
      return;
    }

    UserService.getChallenges(user?.id).then((response: any) => {
      //console.log('getChallenges response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to fetch challenges ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      setChallenges(response.challenges);
    });
  };

  // eslint-disable-next-line
  useEffect(() => handleReload(), []);

  return (
    <Grid container spacing={2}>
      {(challenges?.length ?? 0) > 0 ? challenges?.map((challenge: Challenge) => (
        <Grid item key={challenge.id} xs={12}>
          <ChallengeListItem
            challenge={challenge}
            currentUser={user}
          />
        </Grid>
      )) : (
        <Grid item key='no-challenges' xs={12}>
          <Typography variant="h6" gutterBottom className="text-center">
            No Challenges Against You
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};