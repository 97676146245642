import {
  Box,
  Container,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { PoweredBy, SponsoredBy } from '../consts';

const FooterItems = [{
  title: 'About',
  url: '/about',
  classes: 'text-white float-start',
},{
  title: 'Terms of Service',
  url: '/terms',
  classes: 'text-white',
},{
  title: 'Privacy Policy',
  url: '/privacy',
  classes: 'text-white float-sm-end float-xs-start',
}];

const useStyles: any = (theme: Theme) => ({
  container: {
    bottom: 0,
    position: 'sticky',
    marginBottom: 0,
    maxHeight: '150px',
    zIndex: 999,
  },
});

export const StickyFooter = (props: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        m: 0,
        mt: 'auto',
        mb: 0,
        //zIndex: 999,
        borderTop: '1px solid grey',
        backgroundColor: '#121212',
        maxHeight: '150px',
        bottom: 0,
        left: isSmall ? 0 : 25,
        //flexShrink: 0,
        position: 'relative',
      }}
    >
      <Container style={classes.container}>
        <Grid container spacing={0} className="text-muted">
          <Grid item sm={6} xs={12}>
            <span className="float-start">
              Powered by&nbsp;
              <FooterLink
                title={PoweredBy.title}
                url={PoweredBy.url}
                classes="text-white"
                target="_blank"
                rel="noreferrer"
              />
            </span>
          </Grid>
          <Grid item sm={6} xs={12}>
            <span className="float-sm-end float-xs-start">
              Sponsored by&nbsp;
              <FooterLink
                title={SponsoredBy.title}
                url={SponsoredBy.url}
                classes="text-white"
                target="_blank"
                rel="noreferrer"
              />
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="text-nowrap">
          {FooterItems.map((item: any) => (
            <Grid item md={4} sm={6} xs={12} key={item.url}>
              <FooterLink
                title={item.title}
                url={item.url}
                classes={item.classes}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const FooterLink = (props: any) => {
  const { title, url, classes, target = '', rel = '' } = props;
  return (
    <a
      href={url}
      className={classes}
      style={{textDecoration: 'none'}}
      target={target}
      rel={rel}
    >
      {title}
    </a>
  );
};