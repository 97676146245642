import '../../css/chat/index.css';

export interface BackdropProps {
  visible: boolean;
  onClick: () => void;
};

export const Backdrop = ({ visible, onClick }: BackdropProps) => visible ? (
  <section
    className='dropdown-backdrop'
    onClick={onClick}
  />
) : (
  null
);