import React, { MouseEvent, useState } from 'react';
import classnames from 'classnames';
import {
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';

import { Backdrop, getDesktopStream, ToolbarButton } from '.';
import { useVideoCall } from '../../hooks';
import { User } from '../../types';

import '../../css/chat/index.css';

export const RES_IMG_FIREFOX_SHARE = '/res/ff_share.png';

export interface ShareDesktopConfig {
  video: true;
  audio: boolean;
};

const configDesktopOnly: ShareDesktopConfig = {
  audio: false,
  video: true,
};

const configDesktopAudioVideo: ShareDesktopConfig = {
  audio: true,
  video: true,
};

export interface ShareDesktopDropdownProps {
  user: User;
  className: string;
  icon: any;
  offIcon: any;
  title: string;
};

export interface ShareDesktopDropdownState {
  open: boolean;
  shareConfig: ShareDesktopConfig | false;
  rejectedShare: boolean;
  popupContent: any;
};

export const ShareDesktopDropdown = (props: ShareDesktopDropdownProps) => {
  const { user, title, icon, offIcon, className } = props;
  const [state, setState] = useState<ShareDesktopDropdownState>({
    open: false,
    shareConfig: false,
    rejectedShare: false,
    popupContent: null,
  });

  const { callState, onAddDesktopStream, onRemoveDesktopStream } = useVideoCall();

  const classNames = classnames(
    'stream-desktop-menu dropdown-list dropdown-center',
    { 'dropdown-list-open': state.open },
  );

  const handleToggleOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    handleOpen(!state.open);
  };

  const handleClose = () => handleOpen(false);
  
  const handleOpen = (open: boolean) => setState({...state, open});

  const handleRemoveDesktopStream = (userId: number) => {
    onRemoveDesktopStream(userId);
  };

  const handleShareDesktop = (shareConfig: ShareDesktopConfig | false) => {
    //console.log('handleShareDesktop:', shareConfig, state);

    handleClose();

    if (callState?.desktopStream) {
      // Remove onended handler from all tracks. See below for more info.
      callState?.desktopStream?.getTracks().forEach((track: MediaStreamTrack) => track.onended = null);
    }

    setState({
      ...state,
      shareConfig,
    });

    if (!shareConfig) {
      const stream = callState?.desktopStream;
      const tracks = stream?.getTracks();
      if (!tracks || tracks?.length === 0) {
        return;
      }

      // Remove the stream after all tracks end. This ensures the "Stop
      // sharing" desktop click in Chrome is handled correctly.
      tracks.forEach((track: MediaStreamTrack) => {
        track.stop();
        stream?.removeTrack(track);
      });

      setState({
        ...state,
        shareConfig: false,
      });

      if (callState?.desktopStream) {
        callState.desktopStream = null;
      }

      //console.warn('desktop sharing turned off');
      handleRemoveDesktopStream(user?.id);
      return;
    }

    getDesktopStream(shareConfig).then((stream: MediaStream) => {
      if (!callState!.desktopStream) {
        callState!.desktopStream = stream;
      }

      onAddDesktopStream(stream);

      const tracks = stream.getTracks();
      let activeTracks = tracks.length;

      // Remove the stream after all tracks end. This ensures the "Stop
      // sharing" desktop click in Chrome is handled correctly.
      tracks.forEach((track: MediaStreamTrack) => {
        track.onended = () => {
          console.warn('track.onended triggered!', track);
          activeTracks--;
          track.enabled = false;
          track.stop();
          stream?.removeTrack(track);
          if (activeTracks === 0) {
            setState({
              ...state,
              shareConfig: false,
            });
            if (callState?.desktopStream) {
              callState.desktopStream = null;
            }
            handleRemoveDesktopStream(user?.id);
          }
        };
      });
    }).catch((err: any) => {
      console.error('handleShareDesktop:', err);
      const browser = navigator.userAgent.toLowerCase();
      if (browser.indexOf('firefox') > -1) {
        handleFirefoxRejection();
      }

      setState({
        ...state,
        shareConfig: false,
      });
    });
  };

  const handleFirefoxRejection = () => {
    if (!state.rejectedShare) {
      return setState({...state, rejectedShare: true});
    }

    setState({
      ...state,
      popupContent: <>
        <div style={{paddingBottom: '2em'}}>
          If you dismissed a sharing dialog previously,
          you have to remove the resource restriction.<br/>
          Click on site permissions in the address bar
          and remove the blocked resource you want to use.
        </div>
        <img src={'/' + RES_IMG_FIREFOX_SHARE} alt='' />
      </>,
    });
  };

  const handleClosePopup = () => setState({...state, popupContent: null});

  return (
    <>
      {state.popupContent && (
        <div className='popup-overlay'>
          <div className='popup-window'>
            <div onClick={handleClosePopup} className='popup-close'>&times;</div>
            <div className='popup-content'>
              {state.popupContent}
            </div>
          </div>
        </div>
      )}

      <div className='dropdown'>
        <ToolbarButton
          className={className}
          icon={icon}
          offIcon={offIcon}
          on={!!state.shareConfig}
          title={title}
          size='small'
          onClick={handleToggleOpen}
        />
        <Backdrop
          visible={state.open}
          onClick={handleClose}
        />
        <ul className={classNames}>
          <DesktopShareOption
            config={false}
            name={'Off'}
            onClick={handleShareDesktop}
            selected={!state.shareConfig}
          />
          <DesktopShareOption
            config={configDesktopAudioVideo}
            name={'Screen with Audio'}
            onClick={handleShareDesktop}
            selected={state.shareConfig === configDesktopAudioVideo}
          />
          <DesktopShareOption
            config={configDesktopOnly}
            name={'Screen only'}
            onClick={handleShareDesktop}
            selected={state.shareConfig === configDesktopOnly}
          />
        </ul>
      </div>
    </>
  );
};

export interface DesktopShareOptionProps {
  selected: boolean;
  name: string;
  config: ShareDesktopConfig | false;
  onClick: (config: ShareDesktopConfig | false) => void;
};

const DesktopShareOption = (props: DesktopShareOptionProps) => {
  const { name, config, selected, onClick } = props;
  
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick(config);
  };

  const checked = selected
    ? <RadioButtonCheckedIcon />
    : <RadioButtonUncheckedIcon />;

  return (
    <li onClick={handleClick}>
      {checked}&nbsp;{name}
    </li>
  );
};