import {
  startOfMonth, endOfMonth,
  startOfWeek, lastDayOfWeek,
  addDays, eachDayOfInterval,
  differenceInDays, differenceInMinutes,
  getYear, getMonth, getDate, getTime,
  format,
} from 'date-fns';

export const getWeekDays = (selectedDate: any, size: number) => {
  const getMonthWeeks = (date: Date, { forceSixWeeks = false } = {}) => {
    const monthFirstDate = startOfMonth(date);
    const monthLastDate = endOfMonth(date);

    const start = forceSixWeeks ? startOfWeek(monthFirstDate) : monthFirstDate;
    let end = forceSixWeeks ? lastDayOfWeek(addDays(monthLastDate, 2)) : monthLastDate;

    // check for 6 lines (weeks)
    const totalOfDays = differenceInDays(end, start);
    if (totalOfDays !== 41) {
      end = lastDayOfWeek(addDays(end, 2));
    }

    return eachDayOfInterval({ start, end });
  };

  const days = getMonthWeeks(selectedDate, { forceSixWeeks: true }).map((date: Date, index: number) => date);

  const weekly = (_month: any, _size: number) =>
    _month.reduce(
      (a: any[], b: any[], index: number, group: any[]) =>
        !(index % _size) ? a.concat([group.slice(index, index + _size)]) : a,
      [],
    );

  return weekly(days, size);
};

export const getSelectedWeekIndex = (selectedDate: Date, weeks: any, startTime: number) => {
  const _year = getYear(selectedDate);
  const _month = getMonth(selectedDate);
  const _day = getDate(selectedDate);

  return weeks.reduce(
    (position: number, week: any, index: number) =>
      week.find((day: Date) => getTime(day) === getTime(new Date(_year, _month, _day, startTime, 0, 0)))
        ? (position = index)
        : position,
    0,
  );
};

export const getTimePosition = (date: Date) => {
  const initTime = new Date(format(date, 'yyyy/MM/dd 0:0:0'));
  const position: any = differenceInMinutes(date, initTime);
  return position;
};