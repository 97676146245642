import React, { ReactNode } from 'react';
import { MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import clsx from 'clsx';

import {
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  useSidebarState,
} from '.';
import { MenuItemLink } from './MenuItemLink';

/**
 * Renders a menu with one menu item per resource by default. You can also set menu items by hand.
 *
 * @example
 * import * as React from 'react';
 * import { Menu } from 'react-admin';
 *
 * import BookIcon from '@mui/icons-material/Book';
 * import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
 * import PeopleIcon from '@mui/icons-material/People';
 * import LabelIcon from '@mui/icons-material/Label';
 *
 * export const MyMenu = () => (
 *   <Menu>
 *     <Menu.DashboardItem />
 *     <Menu.Item to="/posts" primaryText="Posts" leftIcon={<BookIcon />}/>
 *     <Menu.Item to="/comments" primaryText="Comments" leftIcon={<ChatBubbleIcon />}/>
 *     <Menu.Item to="/users" primaryText="Users" leftIcon={<PeopleIcon />}/>
 *     <Menu.Item to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />}/>
 *   </Menu>
 * );
 */
export const Menu = (props: IMenuProps) => {
  const {
    children = [],
    className,
    ...rest
  } = props;

  const [open] = useSidebarState();

  return (
    <Root
      className={clsx(
        {
          [MenuClasses.open]: open,
          [MenuClasses.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Root>
  );
};

// NOTE: We don't extends MenuListProps here to avoid breaking changes
export interface IMenuProps {
  children?: ReactNode;
  className?: string;
  dense?: boolean;
  [key: string]: any;
};

// re-export MenuItem commponents for convenience
Menu.Item = MenuItemLink;

export const MenuClasses = {
  open: `RaMenu-open`,
  closed: `RaMenu-closed`,
};

const Root = styled(MenuList, {
  name: 'RaMenu',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '0.5em',
  marginBottom: '1em',
  [theme.breakpoints.only('xs')]: {
    marginTop: 0,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [`&.${MenuClasses.open}`]: {
    width: get(theme, 'sidebar.width', DRAWER_WIDTH),
  },

  [`&.${MenuClasses.closed}`]: {
    width: get(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
  },
}));