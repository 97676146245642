export const ChallengeCarousel = (props: any) => {
  //console.log('ChallengeCarousel props:', props);
  const { challenge } = props;

  return (
    <div id="carousel-round-1" className="carousel slide mb-3" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {(challenge?.images?.length ?? 0) > 0 && challenge?.images?.map((image: any, index: number) => (
          <button
            key={image.id}
            type="button"
            data-bs-target="#carousel-round-1"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-current="true"
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {(challenge?.images?.length ?? 0) > 0 && challenge?.images?.map((image: any, index: number) => (
          <div
            key={image.id}
            className={"carousel-item" + (index === 0 ? ' active' : '')}
          >
            <img
              className="d-block w-100"
              //src="/videos/round1/round1_vt_vs_scat.mp4"
              src={image.url}
              style={{borderRadius: 8}}
              alt=""
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Final Run</h5>
              <p>Veloster Turbo vs Challenger Scat Pack - Head to Head</p>
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carousel-round-1" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carousel-round-1" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

/*
  <div id="carousel-round-1" className="carousel slide mb-3" data-bs-ride="carousel">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="1" aria-current="false" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="2" aria-current="false" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="3" aria-current="false" aria-label="Slide 4"></button>
      <button type="button" data-bs-target="#carousel-round-1" data-bs-slide-to="4" aria-current="false" aria-label="Slide 5"></button>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <video
          className="d-block w-100"
          src="/videos/round1/round1_vt_vs_scat.mp4"
          controls
        />
        <div className="carousel-caption d-none d-md-block">
          <h5>Final Run</h5>
          <p>Veloster Turbo vs Challenger Scat Pack - Head to Head</p>
        </div>
      </div>
      <div className="carousel-item">
        <video
          className="d-block w-100"
          src="/videos/round1/round1_scat1_vs_bmw_m4.mov"
          controls
        />
        <div className="carousel-caption d-none d-md-block">
          <h5>Skat Pack First Pass</h5>
          <p>2020 Dodge Challenger Skat Pack vs 20?? BMW M4</p>
        </div>
      </div>
      <div className="carousel-item">
        <video
          className="d-block w-100"
          src="/videos/round1/round1_scat2_vs_hellcat.mov"
          controls
        />
        <div className="carousel-caption d-none d-md-block">
          <h5>Skat Pack Second Pass</h5>
          <p>2020 Dodge Challenger Skat Pack vs 20?? Dodge Challenger Hellcat</p>
        </div>
      </div>
      <div className="carousel-item">
        <video
          className="d-block w-100"
          src="/videos/round1/round1_vt1_vs_5.0.mov"
          controls
        />
        <div className="carousel-caption d-none d-md-block">
          <h5>Veloster Turbo First Pass</h5>
          <p>2015 Hyundai Veloster Turbo vs 2018 Ford Mustang 5.0</p>
        </div>
      </div>
      <div className="carousel-item">
        <video
          className="d-block w-100"
          src="/videos/round1/round1_vt2_vs_mustang.mov"
          controls
        />
        <div className="carousel-caption d-none d-md-block">
          <h5>Veloster Turbo Second Pass</h5>
          <p>2015 Hyundai Veloster Turbo vs 2020 Ford Mustang 5.0</p>
        </div>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carousel-round-1" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carousel-round-1" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
*/