import React, { useState, useEffect } from 'react';
import {
  Box,
  Fab,
  Grid,
  TableCell,
  TableRow,
  ThemeProvider,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import { useSnackbar } from 'notistack';

import { NewTimeDialog } from '../../components/Dialogs';
import { RaceTrackService, TimeService } from '../../services';
import { MuiDataTableTheme } from '../../themes';
import { Time } from '../../types';

// Reference: https://mui.com/x/react-data-grid/master-detail/
// Reference: https://mui.com/material-ui/react-table/
export const MyTimesPage = (props: any) => {
  //console.log('MyTimesPage props:', props);
  const { user } = props;
  const [state, setState] = useState({
    rowData: [],
    raceTracks: [],
    rowSelectionModel: [],
  });
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      name: 'carId',
      label: 'Car ID',
      options: {
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'track',
      label: 'Track',
    },
    {
      name: 'time',
      label: 'Time',
    },
    {
      name: 'speed',
      label: 'Speed',
    },
    {
      name: 'reaction',
      label: 'Reaction',
    },
    {
      name: 'actions',
      label: 'Actions',
    },
  ];

  const options: any = {
    search: true,
    download: false,
    print: false,
    viewColumns: true,
    expandableRows: true,
    expandableRowsHeader: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex: number, expandedRows: any) => {
      if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (expandedRows.data.length > 4 && expandedRows.data.filter((d: any) => d.dataIndex === dataIndex).length === 0)
        return false;
      return true;
    },
    rowsExpanded: [],
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            Custom expandable row option. Data: {JSON.stringify(rowData)}
          </TableCell>
        </TableRow>
      );
    },
    //onRowExpansionChange: (curExpanded: any, allExpanded: any, rowsExpanded: any) =>
    //  console.log(curExpanded, allExpanded, rowsExpanded),
    filter: true,
    filterOptions: {
      fullWidth: true,
    },
    filterType: 'dropdown',
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'vertical', //vertical, standard, simple
    tableBodyHeight: '400px',
    tableBodyMaxHeight: '400px',
    showResponsive: true,
    /*
    onTableChange: (action: any, state: any) => {
      console.log(action);
      console.dir(state);
    },
    */
  };

  const components = {
    ExpandButton: (props: any) => {
      if (props.dataIndex === 3 || props.dataIndex === 4) {
        return <div style={{ width: '24px' }} />;
      }
      return <ExpandButton {...props} />;
    },
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    TimeService.getTimes(user.id).then((response: any) => {
      //console.log('getTimes response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to fetch track times ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }

      const times = response.times;
      RaceTrackService.getRaceTracks().then((response: any) => {
        //console.log('getRaceTracks response:', response);
        if (response.status !== 200) {
          enqueueSnackbar(`Unable to fetch race tracks ${response.message}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          return;
        }

        setState({...state, raceTracks: response.tracks, rowData: times});
      });
    });
  }, [user, enqueueSnackbar, state]);

  const handleClickDelete = async () => {
    const timeIds: number[] = state.rowSelectionModel;
    const result = window.confirm(`You are about to delete ${timeIds.length} track run times from your cars history, are you sure you want to do this?`);
    if (!result) {
      return;
    }
    const response = await TimeService.deleteTimes(user.id, timeIds);
    //console.log('deleteTimes response:', response);
    if (response.status !== 200) {
      enqueueSnackbar(`Unable to delete track time ${response.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }
    const rowData = state.rowData.filter((row: any) => !timeIds.includes(row.id));
    setState({...state, rowData});
  };

  const handleClose = (time: Time) => {
    const rows: any = [...state.rowData, time];
    setState({...state, rowData: rows});
  };
  
  const hasSelection = state.rowSelectionModel.length > 0;
  return (
    <Box
      sx={{
        height: 'auto',
        overflow: 'auto',
        '@media print': {
          height: 'auto !important',
        },
        width: '100%',
      }}
    >
      <Grid container spacing={2} columnSpacing={2}>
        <Grid item xs={10} className="float-start" style={{justifyContent: 'start'}}>
        </Grid>
        <Grid item xs={hasSelection ? 1 : 2}>
          <div className="float-end">
            <NewTimeDialog
              user={user}
              raceTracks={state.raceTracks}
              onClose={handleClose}
            />
          </div>
        </Grid>
        <Grid item xs={hasSelection ? 1 : 0}>
          <Fab
            color="error"
            size="small"
            aria-label="delete"
            onClick={handleClickDelete}
            className="float-end"
            style={{display: hasSelection ? 'block' : 'none'}}
          >
            <DeleteIcon />
          </Fab>
        </Grid>
      </Grid>
      <ThemeProvider theme={MuiDataTableTheme}>
        <MUIDataTable
          title='My Track Times'
          data={state.rowData}
          columns={columns}
          options={options}
          components={components}
        />
      </ThemeProvider>
    </Box>
  );
};