import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { Routes } from '../consts';
import { sortByKey } from '../modules';
import { TimeService } from '../services';
import { Time } from '../types';

const MaxTimes = 10;

export enum RunTimeType {
  latest,
  best,
};

const useStyles = (theme: Theme) => ({
  root: {
    border: '1px solid black',
    borderRadius: 20,
  },
  ellipsis: {
    //whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 250,
  },
  carLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

export const RunTimesList = (props: any) => {
  const { timeType } = props;
  const [times, setTimes] = useState<Time[]>();
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    TimeService.getLeaderboardTimes().then((response: any) => {
      //console.log('getLeaderboardTimes response:', response);
      const runTimes = response.times.splice(0, MaxTimes);
      switch (timeType) {
        case RunTimeType.best:
          runTimes.sort((a: any, b: any) => sortByKey(a, b, 'time'));
          break;
        case RunTimeType.latest:
          runTimes.sort((a: any, b: any) => sortByKey(b, a, 'createdAt'));
          break;
      }
      setTimes(runTimes);
    });
  }, [timeType]);

  return (
    <Paper style={classes.root}>
      <List>
        {times?.map((time: Time) => (
          <ListItem key={time?.id}>
            <ListItemText>
              <span className="float-start" style={classes.ellipsis}>
                <a
                  href={Routes.car + time.car?.id}
                  target="_blank"
                  rel="noreferrer"
                  style={classes.carLink}
                >
                  <strong>
                    {time.car?.name} <small>({time.user?.username})</small>
                  </strong>
                </a>
              </span>
              <small className="float-end">1/8 @ {time.time}s</small>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};