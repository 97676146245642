import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { IMessageSettingsDialogProps } from './IMessageSettingsDialogProps';
import './MessageSettingsDialog.css';

export const MessageSettingsDialog = (props: IMessageSettingsDialogProps) => {
  const { onClose } = props;
  const [value, setValue] = useState('no-one');

  const handleChange = (event: any) => setValue(event.target.value);

  return (
    <div className="msg-settings-bg" style={{width: '100%'}}>
      <div className="msg-settings-modal">
        <div className="msg-settings-header">
          <div className="msg-set-title">
            <label>Message Settings</label>
          </div>
          <IconButton
            title="Close"
            onClick={onClose}
            style={{marginRight: '10px'}}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="settings-content">
          <div className="settings-title-text">
            <label>Who can send you direct messages</label>
          </div>
          <div className="settings-details-text">
            <Typography paragraph noWrap={false}>
              With any option, you cant receive from users that you've sent
              messages to. Friends are your followers that you follow back.
            </Typography>
          </div>
          <br/>
          <FormControl>
            <FormLabel id="messagePermissions">
              Permissions
            </FormLabel>
            <RadioGroup
              aria-labelledby="messagePermissions"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="any"
                control={<Radio />}
                label="Anyone"
              />
              <FormControlLabel
                value="friends"
                control={<Radio />}
                label="Only Friends"
              />
              <FormControlLabel
                value="no-one"
                control={<Radio />}
                label="No one"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <br/>
        <div className="contact-buttons-section">
          <Button
            variant="contained"
            color="secondary"
            title="Cancel"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            title="Save"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};