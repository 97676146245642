import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { UserAvatar } from '..';
import { getAvatarUrl } from '../../modules';
import { UserService } from '../../services';
import { getUserToken } from '../../stores';

export const ReportUserDialog = (props: any) => {
  const { isOpen, onClose, profile } = props;
  const [state, setState] = useState({
    reportedUserId: profile?.id,
    type: '',
    message: '',
  });
  const currentUser = getUserToken();
  const { enqueueSnackbar } = useSnackbar();

  const handleReportUser = () => {
    //console.log('state:', state);
    if (!currentUser?.id || !state.reportedUserId || !state.message) {
      return;
    }

    UserService.reportUser(currentUser?.id, profile, state.type, state.message).then((response: any) => {
      //console.log('reportUser response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to report user ${profile?.username}, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      enqueueSnackbar(`Successfully reported user ${profile?.username}`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      onClose && onClose();
    });
  };

  const handleChange = (event: any) => {
    const { target: { name, value } } = event;
    setState({...state, [name]: value});
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            Report User
          </Typography>
          <IconButton color="inherit" onClick={onClose} title="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{display: 'flex', marginBottom: 3}}>
              <UserAvatar
                fullName={profile?.username}
                fileName={getAvatarUrl(profile?.id)}
                size={42}
              />
              <span style={{marginLeft: '10px', marginTop: 'auto', marginBottom: 'auto'}}>
                {profile?.username}&nbsp; | &nbsp;{profile?.firstName} {profile?.lastName}
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Report Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                name="type"
                label="Report Type"
                value={state.type}
                maxRows={10}
                onChange={handleChange}
                className="text-light"
                required
              >
                <MenuItem key='' disabled selected>Select a reason type...</MenuItem>
                <MenuItem key={0} value={0}>Spamming</MenuItem>
                <MenuItem key={1} value={1}>Harassment</MenuItem>
                <MenuItem key={2} value={2}>Racism</MenuItem>
                <MenuItem key={3} value={3}>Copyright</MenuItem>
                <MenuItem key={4} value={4}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              //margin="dense"
              id="message"
              name="message"
              label="Reason"
              type="text"
              fullWidth
              value={state.message}
              variant="outlined"
              onChange={handleChange}
              rows={3}
              multiline
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleReportUser}
              className="float-end"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};