import React, { useRef } from 'react';
import { Fab } from '@mui/material';
import { Camera as CameraIcon } from '@mui/icons-material';

export const SingleImageUploadDialog = ({ handleSelected }: any) => {
  const inputFile = useRef<HTMLInputElement | null>(null);

  const onFileChanged = (e: any) => {
    if (!e.target.files) {
      return;
    }
    
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => handleSelected && handleSelected(file, e.target.result);
    reader.readAsDataURL(file);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        minWidth: 350,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <label htmlFor="file-input">
        <input
          id="file"
          name="file"
          type="file"
          ref={inputFile}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={onFileChanged}
        />
        <Fab
          onClick={() => { inputFile.current?.click(); return false; }}
          style={{
            position: 'absolute',
            zIndex: 99,
            bottom: 50,
            marginRight: -60,
          }}
          color="primary"
          aria-label="Select profile banner image"
          size="small"
        >
          <CameraIcon />
        </Fab>
      </label>
    </div>
  );
};