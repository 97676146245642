import React, { useRef, useState } from 'react';
import { EmojiClickData } from 'emoji-picker-react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  Block as BlockIcon, // Close (X), Block (/), RemoveCircle (-)
  Check as CheckIcon,
  EmojiEmotions as EmojiEmotionsIcon,
  Label as LabelIcon,
  PermMedia as PermMediaIcon,
  RemoveCircle as RemoveIcon,
  Room as RoomIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { EmojiPickerContainer, Img, PrivacyControlIconButton, UserAvatar } from '..'
import { MaxFileUploadLimit, MaxFileUploadSize } from '../../consts';
import { createNotification, fileToDataUrl, getAvatarUrl, getUserTags } from '../../modules';
import { ImageService, PostService, UserService } from '../../services';
import { Post, PostImage } from '../../types';

interface INewPostState {
  body: string;
  disableComments: boolean;
  disableSharing: boolean;
  images: PostImage[];
  openEmojiPicker: boolean,
  anchorEl: any;
};

const useStyles: any = (theme: Theme) => ({
  root: {
    border: '1px solid black',
    borderRadius: '20px',
    //minWidth: 256,
    textAlign: 'center',
    //marginBottom: '3px',
    paddingBottom: 0,
  },
  userAvatar: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '10px',
  },
  uploadImagesButton: {
    cursor: 'pointer',
    position: 'absolute',
    margin: 0,
    width: '100%',
    opacity: 0,
  },
  shareWrapper: {
    padding: '10px',
  },
  shareTop: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
  },
  shareBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  shareOptions: {
    display: 'flex',
    width: '100%',
    //[theme.breakpoints.down('sm')]: {
    //  display: 'block',
    //},
  },
  shareOption: {
    display: 'flex',
    alignItems: 'center',
  },
  shareIcon: {
    fontSize: '18px',
    marginRight: '3px',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  imgListItemGroup: {
    display: 'flex',
    position: 'relative',
  },
  imgListItem: {
    width: '100%',
    margin: 0,
    verticalAlign: 'bottom',
    position: 'static',
  },
  imgListItemContainer: {
    display: 'inline-block',
    width: '100%',
    marginRight: '8px',
    transition: 'transform .25s',
  },
  imgRemove: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  imgRemoveContainer: {
    position: 'absolute',
    width: '28px',
    backgroundColor: 'rgba(255, 255, 255, 0.22)',
    height: '28px',
    right: 12,
    top: 4,
    cursor: 'pointer',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
});

export const NewPost = (props: any) => {
  //console.log('NewPost props:', props);
  const { user, onReload, editModePost } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [state, setState] = useState<INewPostState>({
    body: '',
    disableComments: false,
    disableSharing: false,
    images: [],
    openEmojiPicker: false,
    anchorEl: null,
  });
  const btnEmojiRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event: any) => {
    event.preventDefault();
  
    if (!state.body) {
      return;
    }

    const post: Post = {
      userId : user?.id,
      views: 0,
      score: 0,
      body: state.body,
      likes: [],
      deleted: false,
      disableComments: state.disableComments,
      disableSharing: state.disableSharing,
    };
    //console.log('post:', post);
    PostService.createPost(post).then((response: any) => {
      //console.log('createPost response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to create post, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      const postId = response.post?.id;
      if (state.images.length) {
        //const postId = response.post.id;
        const folder = `posts/${postId}`;
        ImageService.uploadImages(state.images, folder, ((progress: any) => {
          console.log('uploadImages progress:', progress);
        }));
      }

      const tags = getUserTags(state.body);
      const results: string[] = [];
      for (let tag of tags ?? []) {
        let userTag = tag;
        if (tag.startsWith('@')) {
          userTag = tag.slice(1, tag.length);
        }
        results.push(userTag);
      }
      if (results.length > 0) {
        UserService.getUserTags(results).then((response: any) => {
          //console.log('getUserTags response:', response);
          const taggedUsers = response.users;
          if (taggedUsers?.length > 0) {
            for (const taggedUser of taggedUsers) {
              const postUrl = `/posts/${postId}`;
              createNotification(user.id, taggedUser.id, `${user.username} mentioned you in a post. ${postUrl}`);
            }
          }
        });
      }

      setState({...state, body: '', images: []});
      onReload();
    });
  };

  const handleSubmitEdit = (event: any) => {
    event.preventDefault();
  
    if (!state.body) {
      return;
    }

    //editModePost
    const post: Post = {
      ...editModePost,
      body: state.body,
      deleted: false,
      disableComments: state.disableComments,
      disableSharing: state.disableSharing,
    };
    //console.log('post:', post);
    PostService.createPost(post).then((response: any) => {
      //console.log('createPost response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to create post, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      if (state.images.length) {
        const postId = response.post.id;
        const folder = `posts/${postId}`;
        ImageService.uploadImages(state.images, folder, ((progress: any) => {
          console.log('uploadImages progress:', progress);
        }));
      }
      setState({...state, body: '', images: []});
      onReload();
    });
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    setState({...state, body: value});
  };

  const handleDisableComments = () => setState({...state, disableComments: !state.disableComments});
  const handleDisableSharing = () => setState({...state, disableSharing: !state.disableSharing});

  const handleTag = () => {};
  const handleLocation = () => {};

  const handleRemoveImage = (image: PostImage) => {
    const newImages = state.images.filter((img: PostImage) => img.id !== image.id);
    setState({...state, images: newImages});
  };

  const handleFileChange = async (event: any) => {
    event.preventDefault();

    const files = event.target.files;
    if (files?.length <= 0) {
      return;
    }

    let uploadFiles: any[] = [];
    for (const file of files) {
      // Ignore files exceeding size limit
      if (file.size > MaxFileUploadSize) {
        enqueueSnackbar(`File size ${(file.size / 1024 / 1024).toFixed(2)}MB for '${file.name}' exceeds maximum size allowed of ${MaxFileUploadSize / 1024 / 1024}MB, this file has been ignored.`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        continue;
      }
      uploadFiles.push(file);
    }

    if (uploadFiles.length > MaxFileUploadLimit) {
      uploadFiles = uploadFiles.slice(0, MaxFileUploadLimit - 1);
      enqueueSnackbar(`Maximum of ${MaxFileUploadLimit} files can be uploaded per message, the first 5 have been included and the remaining ignored.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }

    const readers: PostImage[] = [];
    for (const file of uploadFiles) {
      const dataUrl = await fileToDataUrl(file);
      readers.push(dataUrl);
    }
    Promise.all(readers).then((values: any[]) => setState({...state, images: values}));
  };

  const handleEmojiClick = (emoji: EmojiClickData, event: MouseEvent) => setState({...state, body: `${state.body}${emoji.emoji}`});
  const handleEmojiClose = () => setState({...state, openEmojiPicker: false});

  const toggleEmojis = (event: any) => {
    setState({
      ...state,
      openEmojiPicker: !state.openEmojiPicker,
      anchorEl: btnEmojiRef?.current
    });
  };

  return (
    <Card
      className="mb-3"
      style={classes.root}
    >
      <div style={classes.shareWrapper}>
        <div style={classes.shareTop}>
          <UserAvatar
            fullName={user?.username}
            fileName={getAvatarUrl(user?.id)}
            style={classes.userAvatar}
          />
          <TextField
            fullWidth
            size="small"
            multiline
            placeholder="What's on your mind?"
            value={editModePost ? editModePost.body : state.body}
            onChange={handleChange}
          />
          <div
            style={{
              width: 128,
              display: 'inline-flex',
              marginLeft: '10px',
              marginBottom: '6px',
              marginTop: 'auto',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={editModePost ? handleSubmitEdit : handleSubmit}
              //style={{marginLeft: '10px', marginBottom: '6px', marginTop: 'auto'}}
            >
              {editModePost ? 'Save' : 'Share'}
            </Button>
            &nbsp;
            <PrivacyControlIconButton />
          </div>
        </div>
        <div className="float-end m-0 me-3 p-0">
          <small className="text-muted">Characters remaining: 255</small>
        </div>
        <br />
        {state.images?.length > 0 && (
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={2}>
              {state.images.map((image: PostImage) => (
                <Grid item key={image.id} md={4} sm={6} xs={12}>
                <div style={classes.imgListItemGroup}>
                  <IconButton
                    onClick={() => handleRemoveImage(image)}
                    style={classes.imgRemoveContainer}
                  >
                    <RemoveIcon style={classes.imgRemove} />
                  </IconButton>
                  <div style={classes.imgListItemContainer}>
                    <div style={classes.imgListItem}>
                      <Img fileName={image.url} style={classes.img} />
                    </div>
                  </div>
                </div>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        )}
        <div className="row" style={classes.shareBottom}>
          <div className="col col-xs-12">
            <div className="float-start ms-4">
              <div style={classes.shareOptions}>
                <div style={classes.shareOption}>
                  <IconButton title="Photos or Videos">
                    <PermMediaIcon htmlColor="tomato" style={classes.shareIcon} />
                    <input
                      type="file"
                      accept="image/*"
                      style={classes.uploadImagesButton}
                      onChange={handleFileChange}
                      multiple
                    />
                  </IconButton>
                </div>
                <div style={classes.shareOption}>
                  <IconButton
                    onClick={handleTag}
                    title="User tags"
                    disabled
                  >
                    <LabelIcon htmlColor="blue" style={classes.shareIcon} />
                  </IconButton>
                </div>
                <div style={classes.shareOption}>
                  <IconButton
                    onClick={handleLocation}
                    title="Share your location"
                    disabled
                  >
                    <RoomIcon htmlColor="green" style={classes.shareIcon} />
                  </IconButton>
                </div>
                <div style={classes.shareOption}>
                  <IconButton
                    ref={btnEmojiRef}
                    aria-describedby={(Boolean(state.anchorEl)) ? 'spring-popper' : undefined}
                    onClick={toggleEmojis}
                    title="Emojis"
                  >
                    <EmojiEmotionsIcon htmlColor="goldenrod" style={classes.shareIcon} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-xs-12">
            <div className="float-sm-end float-xs-start me-4">
              <div style={classes.shareOptions}>
                <div style={classes.shareOption} className="me-1">
                  <IconButton
                    onClick={handleDisableComments}
                    title="Enable or disable comments for this post."
                  >
                    {state.disableComments
                      ? <BlockIcon style={{color: 'red'}} />
                      : <CheckIcon style={{color: 'green'}} />
                    }
                  </IconButton>
                  Comments
                </div>
                <div style={classes.shareOption} className="ms-1">
                  <IconButton
                    onClick={handleDisableSharing}
                    title="Enable or disable sharing for this post."
                  >
                    {state.disableSharing
                      ? <BlockIcon style={{ color: 'red' }} />
                      : <CheckIcon style={{color: 'green'}} />
                    }
                  </IconButton>
                  Sharing
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmojiPickerContainer
        onEmojiClick={handleEmojiClick}
        onClose={handleEmojiClose}
        anchorEl={state.anchorEl}
        openEmojiPicker={state.openEmojiPicker}
      />
    </Card>
  );
};