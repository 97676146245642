import http from '../modules/http-common';
import { User } from '../types';

const getRelationships = async (followedUserId: number) => {
  const response = await http
    .get(`relationships/${followedUserId}`);
  return response.data;
};

const createRelationship = async (followedUserId: number, followerUserId: number) => {
  const response = await http
    .post('relationships/create', { followedUserId, followerUserId });
  return response.data;
};

const deleteRelationship = async (followedUserId: number, followerUserId: number) => {
  const response = await http
    .delete(`relationships/delete?followedUserId=${followedUserId}&followerUserId=${followerUserId}`);
  return response.data;
};

/*
const isFollowingUser = (followedUserId: number, followingUser: User) => {
  const index = followingUser?.relationships?.following?.findIndex(
    (value: any) => value.followedUserId === followedUserId);
  const result = (index ?? -1) > -1;
  return result;
};
*/
const isFollowingUser = (followedUserId: number, followerUser: User) => {
  const index = followerUser?.following?.findIndex(
    (value: any) => value.followedUserId === followedUserId);
  const result = (index ?? -1) > -1;
  return result;
};

export const RelationshipService = {
  getRelationships,
  createRelationship,
  deleteRelationship,
  isFollowingUser,
};