import React, { useState } from 'react';
import {
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { Routes } from '../consts';
import { withRouter } from '../hooks';
import { AuthService } from '../services';

const VerifyEmailPage = (props: any) => {
  //console.log('VerifyEmailPage props:', props);
  const { params: { code } } = props;
  return (
    <VerifyEmail
      code={code}
      redirectToLogin={true}
    />
  );
};

export const VerifyEmail = (props: any) => {
  //console.log('VerifyEmail props:', props);
  const { code, redirectToLogin = true } = props;
  const [state, setState] = useState({
    code: code ?? '',
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!state.code || state.code === '') {
      enqueueSnackbar('Verification code cannot be empty!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }
    AuthService.verifyEmail(state.code).then((response: any) => {
      //console.log('verifyEmail response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(response.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      enqueueSnackbar(response.message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      if (redirectToLogin) {
        setInterval(() => {
          window.location.href = Routes.login;
        }, 1500);
      }
    });
  };

  const handleChange = (event: any) => {
    //console.log('handleChange:', event);
    const { target: { name, value } } = event;
    setState({...state, [name]: value});
  };

  return (
    <Paper
      className="bg-dark text-light"
      elevation={6}
      style={{
        //marginTop: '16px',
        position: 'relative',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '15px',
        width: '100%',
      }}
    >
      <Typography component="h1" variant="h5">
        Verify Email Address
      </Typography>
      <Paper
        //component="form"
        className="text-center"
        sx={{ width: '100%', p: '2px 4px', display: 'flex', alignItems: 'center' }}
      >
        <TextField
          id="code"
          name="code"
          value={state.code}
          variant="outlined"
          title="Verification Code"
          placeholder="Enter verification code"
          onChange={handleChange}
          inputProps={{ 'aria-label': 'enter verification code' }}
          sx={{ ml: 1 }}
          fullWidth
        />
        <Divider orientation="vertical" />
        <IconButton
          color="primary"
          sx={{ p: '10px', flex: 1 }}
          onClick={handleSubmit}
        >
          <CheckIcon />
        </IconButton>
      </Paper>
    </Paper>
  );
};

export default withRouter(VerifyEmailPage);