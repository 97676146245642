import React, { useContext } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import {
  CalendarContext,
  CalendarLayoutDayWeek,
  CalendarLayoutMonth,
  getSelectedWeekIndex,
  getWeekDays,
} from '.';
import { useSidebarState } from '../Sidebar';

const DrawerOpenAppBarWidth = 250;

const useStyles: any = ((theme: Theme, isSidebarOpen: boolean, isSmall: boolean = false) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: -33,
    //height: '100%',
    //width: '100%',
    //minWidth: 1000,
  },
  contentShift: {
    flexGrow: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //width: `calc(100% - ${DrawerOpenAppBarWidth}px)`,
    //marginLeft: `${DrawerOpenAppBarWidth}px`,
    width: `calc(100% - ${isSidebarOpen ? isSmall ? 220 : 220 : DrawerOpenAppBarWidth}px)`,
    marginLeft: `${isSidebarOpen ? isSmall ? 250 : 210 : DrawerOpenAppBarWidth}px`,
    marginRight: -1.5,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const CalendarMain = (props: any) => {
  //console.log('CalendarMain props:', props);
  const { open, runAnimation } = props;
  
  const { stateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, layout } = stateCalendar;
  const weeks = getWeekDays(selectedDate, 7);
  const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0);
  const selectedWeek = weeks[selectedWeekIndex];
  const [isSidebarOpen] = useSidebarState();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme, isSidebarOpen, isSmall);

  return (
    <Box
      component='main'
      sx={open ? classes.contentShift : classes.content}
    >
      {layout === 'month' ? (
        <CalendarLayoutMonth
          weeks={weeks}
          runAnimation={runAnimation}
          locale={locale}
        />
      ) : (layout === 'week' || layout === 'day') && (
        <CalendarLayoutDayWeek
          selectedWeekIndex={selectedWeekIndex}
          selectedWeek={selectedWeek}
        />
      )}
    </Box>
  );
};