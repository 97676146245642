import React from 'react';
import { uniqueId } from 'lodash';

import {
  GridKind,
  SettingsGridAspect,
  SettingsGridAuto,
  SettingsGridLegacy,
  SettingsState,
} from '.';

import '../../css/chat/index.css';

export interface SettingsProps extends SettingsState {
  onShowMinimizedToolbarToggle?: () => void;
  onSetGridKind?: (gridKind: GridKind) => void;
  onToggleIncomingCallRinger: () => void;
  onToggleOutgoingCallRinger: () => void;
};

interface CheckboxProps {
  label: string;
  checked: boolean;
  className: string;
  onChange: () => void;
};

class Checkbox extends React.PureComponent<CheckboxProps> {
  uniqueId: string;

  constructor(props: CheckboxProps) {
    super(props);
    this.uniqueId = uniqueId('checkbox-');
  }

  handleChange = () => this.props.onChange();

  render() {
    return (
      <label htmlFor={this.uniqueId}>
        <input
          id={this.uniqueId}
          className={this.props.className}
          type='checkbox'
          checked={this.props.checked}
          onChange={this.handleChange}
        />
        {this.props.label}
      </label>
    );
  }
};

interface RadioProps<Value> {
  label: string;
  name: string;
  value: Value;
  currentValue: Value;
  className: string;
  onChange: (value: Value) => void;
};

class Radio<Value extends string> extends React.PureComponent<RadioProps<Value>> {
  uniqueId: string;
  constructor(props: RadioProps<Value>) {
    super(props);
    this.uniqueId = uniqueId('radio-');
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const value = e.target.value as Value;
    onChange(value);
  }

  render() {
    const { label, name, value, currentValue, className } = this.props;

    return (
      <label htmlFor={this.uniqueId}>
        <input
          id={this.uniqueId}
          className={className}
          name={name}
          type='radio'
          value={value as string}
          onChange={this.handleChange}
          checked={value === currentValue}
        />
        {label}
      </label>
    );
  }
};

export class Settings extends React.PureComponent<SettingsProps> {
  render() {
    const {
      playIncomingCallRinger,
      onToggleIncomingCallRinger,
      playOutgoingCallRinger,
      onToggleOutgoingCallRinger,
      showMinimizedToolbar,
      onShowMinimizedToolbarToggle,
      gridKind,
      onSetGridKind,
    } = this.props;

    return (
      <div className='settings bg-dark'>
        <ul className='settings-list'>
          <li>
            <Checkbox
              checked={showMinimizedToolbar}
              label='Show Minimized Toolbar'
              className='settings-show-minimized-toolbar-toggle'
              onChange={onShowMinimizedToolbarToggle!}
            />
          </li>
          <li>
            <h3 className='settings-title'>Video Grid</h3>
            <Radio
              name='settings-grid-kind'
              value={SettingsGridAuto}
              currentValue={gridKind}
              label='Auto'
              className='settings-grid-kind settings-grid-kind-auto'
              onChange={onSetGridKind!}
            />
            <Radio
              name='settings-grid-kind'
              value={SettingsGridLegacy}
              currentValue={gridKind}
              label='Legacy fill'
              className='settings-grid-kind settings-grid-kind-legacy'
              onChange={onSetGridKind!}
            />
            <Radio
              name='settings-grid-kind'
              value={SettingsGridAspect}
              currentValue={gridKind}
              label='Aspect ratio'
              className='settings-grid-kind settings-grid-kind-aspect'
              onChange={onSetGridKind!}
            />
            <p className='settings-description'>
              {gridKind === SettingsGridAuto && (
                <span>
                  Automatically switch between legacy grid and preserving the
                  aspect ratio when there are more than two participants
                </span>
              )}
              {gridKind === SettingsGridLegacy && (
                <span>
                  Always try to fill in as much screen space with videos as
                  possible, even if it means cropping the picture
                </span>
              )}
              {gridKind === SettingsGridAspect && (
                <span>
                  Try to preserve the video aspect ratio, even if it means
                  leaving empty space on the sides.
                </span>
              )}
            </p>
          </li>
          <li>
            <Checkbox
              checked={playIncomingCallRinger}
              label='Play incoming call ringer sound'
              className='settings-play-incoming-call-sound-toggle'
              onChange={onToggleIncomingCallRinger!}
            />
          </li>
          <li>
            <Checkbox
              checked={playOutgoingCallRinger}
              label='Play outgoing call ringer sound'
              className='settings-play-outgoing-call-sound-toggle'
              onChange={onToggleOutgoingCallRinger!}
            />
          </li>
        </ul>
        <div></div> {/*necessary for flex to stretch */}
      </div>
    );
  }
};