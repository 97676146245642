import React, { ReactElement } from 'react';
import {
  Drawer,
  DrawerProps,
  styled,
  Theme,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';

import { useSidebarState } from '.';
import { get } from '../../modules';

export const Sidebar = (props: ISidebarProps) => {
  //console.log('Sidebar props:', props);
  const { children, closedSize, size, ...rest } = props;
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const [open, setOpen] = useSidebarState();
  const trigger = useScrollTrigger();
  const toggleSidebar = () => setOpen(!open);

  return isXSmall ? (
    <StyledDrawer
      variant='temporary'
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
      {...rest}
    >
      {children}
    </StyledDrawer>
  ) : (
    <StyledDrawer
      variant='permanent'
      open={open}
      onClose={toggleSidebar}
      classes={SidebarClasses}
      className={trigger ? SidebarClasses.appBarCollapsed : ''}
      {...rest}
    >
      <div className={SidebarClasses.fixed}>
        {children}
      </div>
    </StyledDrawer>
  );
};

export interface ISidebarProps extends DrawerProps {
  children: ReactElement;
  closedSize?: number;
  size?: number;
};

export const SidebarClasses = {
  docked: `RaSidebar-docked`,
  paper: `RaSidebar-paper`,
  modal: `RaSidebar-modal`,
  fixed: `RaSidebar-fixed`,
  appBarCollapsed: `RaSidebar-appBarCollapsed`,
};

const StyledDrawer = styled(Drawer, {
  name: 'RaSidebar',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
  shouldForwardProp: () => true,
})(({ open, theme }) => ({
  height: theme.breakpoints.down('sm') ? 'calc(100vh - 4em)' : '100vh',
  marginTop: 0,
  position: 'sticky',
  //backgroundColor: theme.palette.primary.dark,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`&.${SidebarClasses.appBarCollapsed}`]: {
    // compensate the margin of the Layout appFrame instead of removing it in the Layout
    // because otherwise, the appFrame content without margin may revert the scrollTrigger,
    // leading to a visual jiggle
    marginTop: theme.spacing(-6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-7),
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${SidebarClasses.fixed}`]: {
    position: 'fixed',
    //height: 'calc(100vh - 3em)',
    height: '100vh',
    overflowX: 'hidden',
    // hide scrollbar
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  [`& .MuiBox-root`]: {
    borderRight: '0.5px solid rgba(65, 65, 65, 0.75)',
    //borderRight: '0.5px solid #414141',
    position: 'relative',
    width: open
      ? get('sidebar.width', DRAWER_WIDTH)
      : get('sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'black',
    //borderRight: 'none',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      height: '100vh',
      position: 'inherit',
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
    zIndex: 'inherit',
  },
  [`& .MuiPaper-root`]: {
    position: 'relative',
    width: open
      ? get('sidebar.width', DRAWER_WIDTH)
      : get('sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'black',
    borderRight: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '100vh',
      position: 'fixed',
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.up('md')]: {
      //border: 'none',
    },
    zIndex: 'inherit',
  },
}));

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;