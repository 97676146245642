import {
  IconButton,
  IconButtonProps,
  styled,
} from '@mui/material';

export interface IExpandMoreProps extends IconButtonProps {
  expand: boolean;
};

export const ExpandMore = styled((props: IExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: any) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));