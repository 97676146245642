import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

import {
  NewsFeed,
  PhotoAlbumsGallery,
  ProfileCard,
  ProfileHeader,
} from '../components';
import { DefaultProfileBannerUrl, Routes } from '../consts';
import { withRouter } from '../hooks';
import { getBannerUrl } from '../modules';
import { ImageService, UserService } from '../services';
import { getUserToken } from '../stores';

// TODO: Limit photo albums shown

interface IProfilePageState {
  user: any;
  profile: any;
  banner: string;
};

const ProfilePage = (props: any) => {
  //console.log('ProfilePage props:', props);
  const { params: { username } } = props;
  const [state, setState] = useState<IProfilePageState>({
    user: null,
    profile: null,
    banner: '',
  });
  const currentUser = getUserToken();
  const currentUsername = currentUser?.username;
  const showNewPost = currentUsername === username;

  const handleReload = useCallback(() => {
    UserService.getUser(username, true).then(async (response: any) => {
      //console.log('getUserProfile response:', response);
      if (response.status !== 200) {
        window.location.href = Routes.pageNotFound;
        return;
      }
      const userProfile = response.user
      const userId = userProfile.id;
      const bannerUrl = getBannerUrl(userId);
      const exists = await ImageService.imageUrlExists(bannerUrl);
      UserService.getUser(currentUser?.username, true).then((response: any) => {
        //console.log('getUser response:', response);
        setState({
          ...state,
          user: response.user,
          profile: userProfile,
          banner: exists ? bannerUrl : DefaultProfileBannerUrl,
        });
      });
    });
  }, [state, username, currentUser?.username]);

  useEffect(handleReload, []);

  return (
    <>
      <Grid container spacing={2}>
        {state.banner && (
          <Grid item xs={12}>
            <ProfileHeader
              banner={state.banner}
              userId={state.profile?.id}
            />
          </Grid>
        )}
        <Grid item md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileCard
                user={state.user}
                profile={state.profile}
                onReload={handleReload}
              />
            </Grid>
            {state.profile?.albums?.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom className="text-center">
                  Photo Albums
                </Typography>
                <PhotoAlbumsGallery
                  userId={state.profile?.id}
                  editMode={false}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
          <NewsFeed
            isUserFeed={false}
            showNewPost={showNewPost}
            currentUser={state.user}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(ProfilePage);