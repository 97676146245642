import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { CarGarageModel } from '../../components';
import { UserService } from '../../services';
import { getUserToken } from '../../stores';
import { CarLike } from '../../types';

export const FavoriteCarsPage = (props: any) => {
  //console.log('FavoriteCarsPage props:', props);
  const [cars, setCars] = useState<CarLike[]>([]);
  const user = getUserToken();

  const handleReload = (userId: number) => {
    UserService.getCars(userId, false, true).then((response: any) => {
      //console.log('getCars response:', response);
      setCars(response.cars);
    });
  };

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    handleReload(user.id);
  }, [user?.id]);

  return (
    <>
      <div className="text-center" style={{justifyContent: 'center', marginBottom: '30px'}}>
        <Box
          sx={{
            height: 'auto',
            '@media print': {
              height: 'auto !important',
            },
            width: '100%',
          }}
        >
          <Typography variant="h3" gutterBottom className="text-center">Favorite Cars</Typography>
          <Grid container columnSpacing={2} spacing={2} justifyContent="center">
            {cars?.map((car: CarLike) => (
              <Grid item key={car.carId} lg={4} md={6} xs={12}>
                <CarGarageModel
                  car={car.car}
                  editMode={false}
                  onReload={handleReload}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};