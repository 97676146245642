import http from '../modules/http-common';
import { RaceTrack } from '../types';

const getRaceTracks = async () => {
  const response = await http
    .get('tracks');
  return response.data;
};

const getRaceTrack = async (trackId: number) => {
  const response = await http
    .get(`tracks/${trackId}`);
  return response.data;
};

const createRaceTrack = async (track: RaceTrack) => {
  const response = await http
    .post('tracks/create', { track });
  return response.data;
};

const deleteRaceTrack = async (trackId: number) => {
  const response = await http
    .delete(`tracks/${trackId}/delete`);
  return response.data;
};

export const RaceTrackService = {
  getRaceTracks,
  getRaceTrack,
  createRaceTrack,
  deleteRaceTrack,
};