import env from 'react-dotenv';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Paper,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  FavoriteOutlined as FavoriteOutlinedIcon,
  Share as ShareIcon,
} from '@mui/icons-material';

import { Routes } from '../../consts';
import { CardWithIcon, CarSpecsTabControl, ExpandMore } from '..';
import { ShareDialog } from '../Dialogs';
import {
  buildCarName,
  createNotification,
  getCarImageUrl,
  pluralize,
} from '../../modules';
import { CarService } from '../../services';
import { getUserToken } from '../../stores';

export const CarGridModel = (props: any) => {
  //console.log('CarGridModel props:', props);

  const { car, onReload } = props;
  const currentUser = getUserToken();
  const navigate = useNavigate();
  /*
  const [state, setState] = useState({
    expanded: false,
    openShareDialog: false,
    openCopyLink: false,
  });
  */
  const [expanded, setExpanded] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openCopyLink, setOpenCopyLink] = useState(false);
  const title = buildCarName(car);
  const isLiked = car.likes?.find((like: any) => like.userId === currentUser?.id);

  const onClick = () => navigate(Routes.car + car.id);
  const onMouseHover = (e: any) => e.target.style.cursor = 'pointer';
  const handleExpandClick = () => setExpanded(!expanded);
  const handleLike = () => {
    if (!currentUser?.id) {
      console.debug('current user not set');
      return;
    }
    CarService.likeCar(car.id, currentUser?.id).then((response: any) => {
      //console.log('likeCar response:', response);
      onReload && onReload();
      createNotification(currentUser?.id, car.userId, `${currentUser?.username} liked your car ${car.name ?? title}`);
    });
  };

  const handleOpenShare = () => setOpenShareDialog(true);
  const handleCloseShare = () => setOpenShareDialog(false);
  const handleCopyLink = () => {
    const url = `${env.SITE_BASE_URL}/cars/${car.id}`;
    window.navigator.clipboard.writeText(url);
    setOpenCopyLink(true);
  };

  return (
    <Paper
      className="text-nowrap"
      style={{
        backgroundColor: '#181818',
        borderRadius: 20,
        border: '1px solid black',
        minWidth: '350px',
      }}
    >
      <Card style={{width: '100%', color: 'grey', borderRadius: 20}}>
        <CardHeader
          onClick={onClick}
          onMouseEnter={onMouseHover}
          title={
            <CardWithIcon
              icon={
                <Avatar
                  alt={car?.name ?? title}
                  sx={{ bgcolor: car.color, border: "1px solid #000", color: 'white' }}
                  aria-label={car?.name ?? title}
                >
                  {car.model[0]}
                </Avatar>
              }
              title={title}
              subtitle={car.name}
              to={Routes.car + car.id}
            />
          }
          style={{padding: 0, margin: 0}}
          subheader={
            <div className="row small">
              <div className="col col-md-6 col-sm-12">
                <div className="float-start text-light">
                  <span style={{color: 'silver'}}>@{car.user?.username}</span>
                </div>
              </div>
              <div className="col col-md-6 col-sm-12">
                <div className="float-md-end float-sm-start text-light">
                  <small>{car.user?.firstName} {car.user?.lastName}</small>
                </div>
              </div>
            </div>
          }
          subheaderTypographyProps={{
            border: 'none',
          }}
        />
        <CardMedia
          component="img"
          height="256"
          onClick={onClick}
          onMouseEnter={onMouseHover}
          src={getCarImageUrl(car.id)}
          alt="Default image"
        />
        <CardActions disableSpacing>
          <IconButton
            aria-label="Add to favorites"
            onClick={handleLike}
            title="Like car">
            {car.likes?.length > 0 && isLiked
              ? (<FavoriteOutlinedIcon style={{ color: 'red' }} />)
              : (<FavoriteBorderOutlinedIcon />)
            }
          </IconButton>
          <small style={{fontSize: 14}}>{car.likes?.length ?? 0} Like{pluralize(car.likes?.length)}</small>
          <IconButton
            title="Share car"
            aria-label="Share car"
            onClick={handleOpenShare}
          >
            <ShareIcon />
          </IconButton>

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show more"
            title="Show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <CarSpecsTabControl {...car!} />
          </CardContent>
        </Collapse>
      </Card>

      <ShareDialog
        onClose={handleCloseShare}
        shareOpen={openShareDialog}
        onCopyLink={handleCopyLink}
        openCopyLink={openCopyLink}
        link={`${env.SITE_BASE_URL}/cars/${car.id}`}
        text={'This is a test'}
        // TODO: Share text
        //tags={[]}
      />
    </Paper>
  );
};

/*
  <div class="tab-pane fade" id="mods-tab-pane" role="tabpanel" aria-labelledby="mods-tab" tabindex="0">
    <table class="table table-dark text-light mb-1 text-start">
      <tbody>
        <tr>
          <th scope="row">Performance</th>
          <td>
            <div class="list-group">
              <div class="list-group-item list-group-item-dark">AEM cold air intake</div>
              <div class="list-group-item list-group-item-dark">Intercooler hot pipe resonator delete</div>
              <div class="list-group-item list-group-item-dark">ADD W1 Stage 1+2 oil catch can</div>
              <div class="list-group-item list-group-item-dark">Mac blow off value solenoid replacement</div>
              <div class="list-group-item list-group-item-dark">Flow master muffler delete</div>
              <div class="list-group-item list-group-item-dark">GoFastBits DVX blow off valve (70v/30r)</div>
              <div class="list-group-item list-group-item-dark">Carbon fiber functional hood vents</div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">Stability & Handling</th>
          <td>
            <div class="list-group">
              <div class="list-group-item list-group-item-dark">ARK Performance Strut Bar</div>
              <div class="list-group-item list-group-item-dark">Salvani Paddle shifter extensions</div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">Cosmetic & Useless</th>
          <td>
            <div class="list-group">
              <div class="list-group-item list-group-item-dark">Vertical/butterfly/lambo doors</div>
              <div class="list-group-item list-group-item-dark">Quick release bumper buttons</div>
              <div class="list-group-item list-group-item-dark">Carbon fiber shark fins/vortex</div>
              <div class="list-group-item list-group-item-dark">Carbon fiber front and rear OEM emblems</div>
              <div class="list-group-item list-group-item-dark">Devils claws</div>
              <div class="list-group-item list-group-item-dark">Plasti dipped front lip, side skirts, and rear diffuser accent red.</div>
              <div class="list-group-item list-group-item-dark">Plasti dipped stock wheels black</div>
              <div class="list-group-item list-group-item-dark">Blue interior lights</div>
              <div class="list-group-item list-group-item-dark">ODBII Boost Gauge</div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">Planned</th>
          <td>
            <div class="list-group">
              <div class="list-group-item list-group-item-dark">Front bumper cover mesh grill from @12gaugegarage</div>
              <div class="list-group-item list-group-item-dark">Rear bumper cover reflector delete with mesh vents from @12gaugegarage</div>
              <div class="list-group-item list-group-item-dark">Front bumper cover fog lights cover mesh grill from @12gaugegarage</div>
              <div class="list-group-item list-group-item-dark">Rear bumper cover mesh grill from @12gaugegarage</div>
              <div class="list-group-item list-group-item-dark">Tork motorsports front mount intercooler kit</div>
              <div class="list-group-item list-group-item-dark">ECU Tune</div>
              <div class="list-group-item list-group-item-dark">Wheels</div>
              <div class="list-group-item list-group-item-dark">exLED Demon Eyes Headlights</div>
              <div class="list-group-item list-group-item-dark">Borla cat back exhaust system</div>
              <div class="list-group-item list-group-item-dark">Coilovers or air suspension</div>
              <div class="list-group-item list-group-item-dark">Rear sway bar</div>
              <div class="list-group-item list-group-item-dark">Rear torsion bar</div>
              <div class="list-group-item list-group-item-dark">Stuffed turbo</div>
              <div class="list-group-item list-group-item-dark">2.0 throttle body upgrade</div>
              <div class="list-group-item list-group-item-dark">HKS SSQV4 blow off valve</div>
              <div class="list-group-item list-group-item-dark">Vented carbon fiber hood</div>
              <div class="list-group-item list-group-item-dark">Replace devils claws with mounted canards from eBay</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
*/