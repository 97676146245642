import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment';

import { ChallengeResultsButton } from '.';
import { Routes } from '../../consts';
import { buildCarName, getCarImageUrl } from '../../modules';
import { ChallengeService } from '../../services';

const useStyles: any = (theme: Theme) => ({
  root: {
    borderRadius: 5,
    border: '1px solid black',
    //cursor: 'pointer',
  },
  attendButton: {
    marginTop: 15,
    marginLeft: 15,
    float: 'left',
  },
  acceptedIcon: {
    color: 'lime',
    marginTop: 15,
    marginRight: 15,
    float: 'right',
    verticalAlign: 'middle',
  },
  notAcceptedIcon: {
    color: 'red',
    marginTop: 15,
    marginRight: 15,
    float: 'right',
    verticalAlign: 'middle',
  },
  acceptButton: {
    marginTop: 15,
    marginRight: 15,
    float: 'right',
  },
  submitButton: {
    marginTop: 15,
    marginRight: 15,
    float: 'right',
  },
  winnerText: {
    color: 'lime',
    marginTop: 15,
    marginLeft: 15,
    float: 'left',
  },
  carImage: {
    width: '100%',
    height: '200px',
    borderRadius: 8,
    objectFit: 'cover',
  },
});

export const ChallengeListItem = (props: any) => {
  //console.log('ChallengeListItem props:', props);
  const { challenge, currentUser, onOpen } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const hasCompleted = !!challenge?.resultsId && challenge?.resultsId > 0;
  const dateTime = moment(challenge.date).format('dddd MMMM Do, YYYY');
  const isAttending = !!(challenge?.attendees?.find((attendee: any) => attendee.userId === currentUser.id) ?? false);
  
  const handleViewDetails = (event: any) => window.location.href = Routes.challenge + challenge.id;

  const handleAttendee = () => {
    if (isAttending) {
      ChallengeService.deleteChallengeAttendee(challenge?.id, currentUser?.id);
    } else {
      ChallengeService.addChallengeAttendee(challenge?.id, currentUser?.id);
    }
  };

  return (  
    <Paper sx={classes.root}>
      {!challenge?.resultsId && (
        <Button
          variant="contained"
          size="small"
          style={classes.attendButton}
          onClick={handleAttendee}
        >
          {isAttending ? 'Back Out' : 'Attend'}
        </Button>
      )}
      <ChallengeResultsButton
        challenge={challenge}
        currentUser={currentUser}
        classes={classes}
      />
      <br />
      <Card
        sx={{borderRadius: 5, marginTop: hasCompleted ? 0 : 3}}
      >
        <CardHeader
          title={
            <Typography variant="h5" className="text-center">
              <a href={`/${challenge?.challenger?.username}`} className="instagram-tag">
                {challenge?.challenger?.username}
              </a>
              &nbsp;vs&nbsp;
              <a href={`/${challenge?.challengee?.username}`} className="instagram-tag">
                {challenge?.challengee?.username}
              </a>
            </Typography>
          }
          subheader={
            <Typography className="text-center">
              <strong>{dateTime}</strong>
            </Typography>
          }
        />
        <CardContent>
          <Grid
            container
            spacing={2}
            title="View challenge details"
            style={{
              cursor: 'pointer',
            }}
            onClick={handleViewDetails}
          >
            <Grid item sm={6} xs={12}>
              <Typography variant="h5" gutterBottom className="text-center">{challenge.challengerCar.name}</Typography>
              <CardActionArea href={Routes.car + challenge.challengerCarId}>
                <CardMedia
                  component="img"
                  image={getCarImageUrl(challenge.challengerCarId)}
                  alt=""
                  style={classes.carImage}
                />
              </CardActionArea>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h5" gutterBottom className="text-center">{challenge.challengeeCar.name}</Typography>
              <CardActionArea href={Routes.car + challenge.challengeeCarId}>
                <CardMedia
                  component="img"
                  image={getCarImageUrl(challenge.challengeeCarId)}
                  alt=""
                  style={classes.carImage}
                />
              </CardActionArea>
            </Grid>
          </Grid>

          <br />

          <Grid container spacing={2}>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2" color="text.secondary" className="mb-3">
                {challenge.challenger?.username} and {challenge.challengee?.username} go head to head at {challenge?.raceTrack?.name} with their {buildCarName(challenge?.challengerCar!)} vs {buildCarName(challenge?.challengeeCar!)}.
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Button
                variant="contained"
                onClick={onOpen}
                className="float-end"
                size="small"
              >
                Track Details
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        {challenge?.resultsId && (
          <CardActions disableSpacing>
            <Button
              className="w-100 text-center"
              style={{
                borderRadius: 5,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
              }}
              onClick={handleViewDetails}
            >
              View Winning Results
            </Button>
          </CardActions>
        )}
      </Card>
    </Paper>
  );
};