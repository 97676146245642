import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Fab,
  Grid,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Add as AddIcon } from '@mui/icons-material';

import { PhotoAlbumDialog } from '../Dialogs';
import { DefaultPhotoAlbumCoverUrl } from '../../consts';
import { ImageService } from '../../services';

const useStyles = (theme: Theme) => ({
  root: {
    height: 'auto',
    '@media print': {
      height: 'auto !important',
    },
    width: '100%',
  },
  title: {
    textDecoration: 'none',
    color: 'inherit',
    fontSize: 20,
  },
  header: {
    cursor: 'pointer',
  },
  container: {
    borderRadius: 20,
    border: '1px solid black',
  },
  cover: {
    height: 140,
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '10px',
    borderRadius: 3,
    border: '1px solid black',
    cursor: 'pointer',
  },
});

export const PhotoAlbumsGallery = (props: any) => {
  //console.log('PhotoAlbumsGallery props:', props);
  const { userId, editMode = false } = props;
  const [state, setState] = useState({
    albums: [],
    open: false,
    selectedAlbum: null,
  });
  const theme = useTheme();
  const classes = useStyles(theme);

  const createAlbum = () => {
    if (!editMode) {
      return;
    }
    const result = window.prompt(
      'Enter a name for the new photo album...',
      'New Album'
    );
    if (!result) {
      return;
    }
    ImageService.createAlbum(result, userId).then((response: any) => {
      //console.log('createAlbum response:', response);
      handleReload(!state.open);
    });
  };

  const handleReload = (close: boolean = false) => {
    ImageService.getAlbums(userId).then((response: any) => {
      //console.log('getAlbums response:', response);
      setState({...state, open: !close, albums: response.albums})
    });
  };

  const handleOpen = (album: any) => setState({...state, open: true, selectedAlbum: album});
  const handleClose = () => handleReload(true);

  // eslint-disable-next-line
  useEffect(() => handleReload(!state.open), [state.open, userId]);

  return (
    <Box sx={classes.root}>
      {editMode && (
        <div className="float-end">
          <Fab
            color="primary"
            size="small"
            aria-label="Add"
            onClick={createAlbum}
          >
            <AddIcon />
          </Fab>
        </div>
      )}
      <Grid container spacing={editMode ? 2 : 1}>
        {state.albums && state.albums?.map((album: any) => (
          <Grid key={album.name} item md={editMode ? 4 : 6} sm={6} xs={12}>
            <Card style={classes.container}>
              <CardHeader
                title={
                  <div className="text-center">
                    <a
                      href="#"
                      onClick={() => handleOpen(album)}
                      style={classes.title}
                    >
                      {album.name}
                    </a>
                  </div>
                }
                /*
                subheader={
                  <div className="text-center">
                    <small>September 14, 2016</small>
                  </div>
                }
                */
                style={classes.header}
              />
              <CardMedia
                image={album.images?.length > 0
                  ? album.images[0].url
                  : DefaultPhotoAlbumCoverUrl
                }
                title={album.name}
                onClick={() => handleOpen(album)}
                style={classes.cover}
              />
            </Card>
          </Grid>
        ))}
      </Grid>

      <PhotoAlbumDialog
        open={state.open}
        editMode={editMode}
        onClose={handleClose}
        onReload={handleReload}
        album={state.selectedAlbum}
        userId={userId}
      />
    </Box>
  );
};