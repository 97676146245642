import * as React from 'react';
import { Slide, useScrollTrigger } from '@mui/material';

export const HideOnScroll = (props: IHideOnScrollProps) => {
  const { children, className } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      className={className}
    >
      {children}
    </Slide>
  );
};

export interface IHideOnScrollProps {
  children: React.ReactElement;
  className?: string;
};