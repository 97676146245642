import { useState } from 'react';
import {
  AppBar,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Toolbar,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  Close as CloseIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { LikeComponent, UserStory } from '..';
import { useContacts, useSockets } from '../../hooks';
import { createNotification, inflateFlatArray } from '../../modules';
import { StoryService } from '../../services';
import { Story } from '../../types';

const useStyles: any = (theme: Theme) => ({
  senderIcon: {
    transform: 'rotate(-27deg) translateY(-4px)',
    //marginLeft: '20px',
  },
  senderInput: {
    margin: 1,
    width: '100%',
  },
  senderInputContainer: {
    marginTop: 5,
    width: '100%',
    display: 'inline-flex',
  },
  content: {
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },
  title: {
    flex: 1,
    marginLeft: 2,
  },
});

interface IViewStoryDialogProps {
  stories: Story[];
  currentUser: any;
  open: boolean;
  onReload: () => void;
  onClose: () => void;
};

interface IViewStoryDialogState {
  likes: number[],
  currentStoryId: number;
  text: string;
};

export const ViewStoryDialog = (props: IViewStoryDialogProps) => {
  //console.log('ViewStoryDialog props:', props);
  const { stories, currentUser, open, onReload, onClose } = props;
  const [state, setState] = useState<IViewStoryDialogState>({
    likes: [],
    currentStoryId: 0,
    text: '',
  });
  const { socket, sendMessage } = useSockets();
  const { startChat } = useContacts();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();

  const getCurrentStory = () => {
    const storyId = state.currentStoryId;
    const story = stories.find((x: Story) => x.id == storyId);
    return story;
  };

  const handleChange = (event: any) => {
    const { target: { name, value } } = event;
    setState({...state, [name]: value});
  };

  const handleSendMessage = () => {
    //console.log('handleSendMessage:', state.text);
    if (!socket.connected) {
      socket.connect();
    }
    const story = getCurrentStory();
    if (!story) {
      console.error('failed to lookup story with id:', state.currentStoryId, stories);
      return;
    }
    startChat(socket, 0, [story.userId], false);
    sendMessage(currentUser?.id, 0, state.text);
  };

  const handleLikeStory = (storyId: number) => {
    if (storyId === 0) {
      return;
    }
    StoryService.likeStory(storyId, currentUser?.id).then((response: any) => {
      //console.log('likeStory response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to like story ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      const likes = response.likes;
      setState({...state, likes});
      onReload && onReload();

      const story = getCurrentStory();
      if (!story) {
        console.error('failed to lookup story with id:', storyId, stories);
        return;
      }
      createNotification(currentUser?.id, story.userId, `${currentUser?.username} liked your story ${storyId}`);
    });
  };

  const handleStoryStart = (storyId: number) => {
    //console.log('handleStoryStart:', storyId, stories);
    // eslint-disable-next-line
    const story = stories.find((x: any) => x.id == storyId);
    if (story) {
      const likes = inflateFlatArray(story?.likes ?? '[]');
      setState({...state, currentStoryId: storyId, likes});
    }
  };

  const handleClose = () => {
    setState({likes: [], currentStoryId: 0, text: ''});
    onClose && onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography
            variant="h6"
            component="h3"
            className="text-center"
            style={classes.title}
          >
            {/* eslint-disable-next-line */}
            {stories?.find((story: any) => story.id == state.currentStoryId)?.user?.username}
          </Typography>
          <IconButton
            color="inherit"
            title="Close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent style={classes.content}>
        <UserStory
          stories={stories}
          onStoryStart={handleStoryStart}
          onClose={handleClose}
        />
        <div style={classes.senderInputContainer}>
          <div className="me-3">
            <LikeComponent
              currentUser={currentUser}
              likes={state.likes}
              tooltip="Like story"
              onClick={() => handleLikeStory(state.currentStoryId)}
            />
          </div>
          <FormControl variant="outlined" style={classes.senderInput}>
            <InputLabel htmlFor="text">
              Send a message...
            </InputLabel>
            <OutlinedInput
              fullWidth
              id="text"
              name="text"
              type="text"
              onChange={handleChange}
              onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  handleSendMessage();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSendMessage}
                    edge="end"
                  >
                    <SendIcon style={classes.senderIcon} />
                  </IconButton>
                </InputAdornment>
              }
              label="Send a message..."
            />
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  );
};