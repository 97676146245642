import {
  Button,
  ButtonGroup,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  //AddAPhoto as AddImageIcon,
  ClearAll as ClearAllIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  HighlightAlt as HighlightAltIcon,
} from '@mui/icons-material';
import * as uuid from 'uuid';

import { Img } from '.';
import { getExtension } from '../../modules';

const useStyles: any = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  buttonsContainer: {
    display: 'flex',
    marginBottom: 25,
    paddingBottom: 3,
    borderBottom: '1px solid grey',
    justifyContent: 'end',
  },
  gridList: {
    width: 500,
    height: 450,
    overflowY: 'auto',
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    //top: 0,
    //bottom: 0,
    //right: 0,
    //left: 0,
    width: '100%',
    opacity: 0,
    border: '1px solid pink',
  },
  deleteImage: {
    marginLeft: '5px',
    cursor: 'pointer',
    color: 'white',
  },
  addImage: {
    marginRight: '5px',
    cursor: 'pointer',
    color: 'white',
  },
  previewImage: {
    width: '100%',
    height: 'auto',
  },
  previewImageContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  previewImageList: {
    whiteSpace: 'nowrap',
    padding: '0 6px',
    verticalAlign: 'bottom',
    flexShrink: 0,
    listStyleType: 'none',
  },
  previewImageListItem: {
    width: '100%',
    verticalAlign: 'bottom',
    position: 'static',
    display: 'inline-block',
  },
});

interface IUploadImageGalleryProps {
  onPreviewImages?: (images: any[]) => void;
  onUploadImages?: (images: any[]) => void;
  onDeleteImage: (path: string) => void;
  onClearImages?: () => void;
  //images: StoryImage[];
  images: any[];
};

export const UploadImageGallery = (props: IUploadImageGalleryProps) => {
  const { images, onPreviewImages, onUploadImages, onDeleteImage, onClearImages } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleDeleteImage = (image: any) => {
    //console.log('handleDeleteImage:', image);
    //if (!image.name) {
    if (!image.url) {
      console.debug('image name is null, skipping delete');
      return;
    }

    const result = window.confirm(`Are you sure you want to remove this image?`);
    if (!result) {
      return;
    }
    //onDeleteImage(image.name);
    onDeleteImage(image.url);
  };

  const handleFileChange = (event: any) => {
    event.preventDefault();

    const files = event.target.files;
    if (files?.length === 0) {
      return false;
    }

    const images = [];
    for (const file of files) {
      const ext = getExtension(file.name);
      const fileName = `${uuid.v4()}.${ext}`;
      images.push({
        id: fileName,
        file: file,
      });
    }
    //onUploadImages(images);
    onPreviewImages && onPreviewImages(images);
  };

  const PreviewImagesList = () => {
    return (
      <ImageList
        cols={2}
        rowHeight={180}
        style={classes.gridList}
      >
        {images?.length > 0 && images?.map((image: any) => (
          <ImageListItem key={image.id ?? uuid.v4()}>
            <div style={classes.previewImageContainer}>
              <ul style={classes.previewImageList}>
                <li style={classes.previewImageListItem}>
                  <Img fileName={image.url} style={classes.previewImage} />
                </li>
              </ul>
            </div>
            <ImageListItemBar
              style={{height: '45px'}}
              title={
                <div className="text-center">
                  {/*
                  <IconButton onClick={() => handleSetImage(image)} title={image.name}>
                    <AddImageIcon style={styles.addImage} />
                  </IconButton>
                  */}
                  <IconButton
                    title={`Delete photo ${image.id ?? image.url}`}
                    size="small"
                    onClick={() => handleDeleteImage(image)}
                  >
                    <DeleteIcon style={classes.deleteImage} />
                  </IconButton>
                </div>
              }
              position='bottom'
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  return (
    <Paper>
      <div style={classes.buttonsContainer}>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <label htmlFor="upload-files-button">
          <Button
            variant="contained"
            component="span"
            size="small"
          >
            <HighlightAltIcon />&nbsp;Choose...
            <input
              id="upload-files-button"
              type="file"
              accept="image/*"
              title="Select files..."
              style={classes.uploadInput}
              onChange={handleFileChange}
              multiple
            />
          </Button>
        </label>
        {images?.length > 0 && (
          <>
            {onUploadImages && (
              <>
                &nbsp;
                <Button
                  variant="contained"
                  component="span"
                  title="Upload images"
                  size="small"
                  onClick={() => onUploadImages(images)}
                >
                  <CloudUploadIcon />&nbsp;Upload
                </Button>
              </>
            )}
            &nbsp;
            <Button
              variant="contained"
              component="span"
              title="Clear all images"
              size="small"
              color="error"
              onClick={onClearImages}
            >
              <ClearAllIcon />&nbsp;Clear All
            </Button>
          </>
        )}
      </ButtonGroup>
      </div>
      <div style={classes.root}>
        {PreviewImagesList()}
      </div>
    </Paper>
  );
};