import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
  AccessTime as AccessTimeIcon,
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  Subject as SubjectIcon,
} from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Theme, useTheme } from '@mui/material/styles';
import { format } from 'date-fns';

import { CalendarContext, Datepicker, TimeSelect } from '.';
import { CalendarService } from '../../services';
import { getUserToken } from '../../stores';
import { CalendarEvent } from '../../types';

const Transition: any = forwardRef<unknown, TransitionProps>((props: any, ref: any) => {
  return <Slide direction='down' itemRef={ref} {...props} />;
});

const useStyles: any = ((theme: Theme) => ({
  divTitleButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  fullScreenButton: {
    color: theme.palette.grey[900],
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    // marginTop: -30,
    // width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    // minWidth: 120,
  },
  formControlFlex: {
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  title: {
    marginTop: 0,
  },
  descriptionIcon: {
    // marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  datepicker: {
    width: 130,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
  dayOfWeek: {
    marginLeft: theme.spacing(1),
    color: grey[500],
  },
}));

//const DefaultTime = '01:00';
const TimeInterval = 30;

const timeOptions = Array.from(Array(24).keys()).reduce((time: any[], hour: number) => {
  Array.from(Array(60 / TimeInterval).keys()).map(i => {
    const timeItem = (+(hour + '.' + i * TimeInterval)).toFixed(2).replace('.', ':');
    time.push({ value: timeItem, label: timeItem });
    return null;
  });
  return time;
}, []);

export const CalendarEventDialog = (props: any) => {
  //console.log('CalendarEventDialog props:', props);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  let {
    modal = false,
    eventDialogMaxWidth = 'md',
    fullscreen = false,
    allowFullScreen = false,
    withCloseIcon = true,
    eventID = 0,
    title,
    description,
    openDialog,
    eventBeginDate,
    eventBeginTime,
    eventEndDate,
    eventEndTime,
    // minutes,
    locale,
  } = stateCalendar;
  const currentUser = getUserToken();

  const handleCloseDialog = () => {
    setStateCalendar({ ...stateCalendar, openDialog: false, openViewDialog: false });
  };

  const [fullScreen, setFullScreen] = useState(false);
  const textFieldTitle: React.RefObject<HTMLInputElement> = useRef(null);
  const [titleTF, setTitleTF] = useState(title);
  const [descriptionTF, setDescriptionTF] = useState(description);

  useEffect(() => setTitleTF(title), [title]);
  useEffect(() => setDescriptionTF(description), [description]);

  const handleCreateEvent = (event: CalendarEvent) => {
    CalendarService.createEvent(event).then((response: any) => {
      console.log('createEvent response:', response);
      if (response.status !== 200) {
        // TODO: Show error notification
        return;
      }
      handleCloseDialog();
    });
  };

  const handleUpdateEvent = (event: CalendarEvent) => {
    CalendarService.updateEvent(event).then((response: any) => {
      console.log('updateEvent response:', response);
      if (response.status !== 200) {
        // TODO: Show error notification
        return;
      }
      handleCloseDialog();
    });
  };

  return useMemo(() => {
    const handleOnClose = () => !modal && handleClose();
    const handleClose = () => handleCloseDialog();
    const handleFullScreen = () => setFullScreen(!fullScreen);

    const handleOk = () => {
      //const markers = get('markers') || [];
      const marker: CalendarEvent = {
        id: eventID > 0 ? eventID : 0,
        title: titleTF,
        description: descriptionTF,
        challengeId: null,
        begin: new Date(format(formatDateTime(eventBeginDate, eventBeginTime.value), 'yyyy/MM/dd HH:mm:ss')),
        end: new Date(format(formatDateTime(eventEndDate, eventEndTime.value), 'yyyy/MM/dd HH:mm:ss')),
        createdByUserId: currentUser?.id,
      };

      if (eventID > 0) {
        handleUpdateEvent(marker);
      } else {
        handleCreateEvent(marker);
      }

      //set('markers', [...markers.filter((markEvent: any) => markEvent.id !== eventID), marker]);
      setTitleTF('');
      setDescriptionTF('');

      handleClose();
    };

    const formatDateTime = (newDate: Date, newTime: string) => {
      const dateTxt = format(newDate, 'yyyy/MM/dd');
      return new Date(dateTxt + ' ' + newTime);
    };

    const onChangeBeginDate = (newDate: Date) => {
      //console.log('onChangeBeginDate:', newDate);
      setStateCalendar({
        ...stateCalendar,
        eventBeginDate: newDate,
        eventEndDate: new Date(format(newDate, 'yyyy/MM/dd ') + format(eventEndDate, 'HH:mm')),
      });
    };

    const onChangeEndDate = (newDate: Date) => {
      //console.log('onChangeEndDate:', newDate);
      setStateCalendar({ ...stateCalendar, eventEndDate: newDate });
    };

    const onChangeBeginTime = (newValue: any) => {
      //console.log('onChangeBeginTime:', newValue);
      setStateCalendar({ ...stateCalendar, eventBeginTime: newValue });
    };

    const onChangeEndTime = (newValue: any) => {
      //console.log('onChangeBeginTime:', newValue);
      setStateCalendar({ ...stateCalendar, eventEndTime: newValue });
    };

    const dateFormat = 'dd/MM/yyyy';
    const buttonDisabled =
      eventBeginTime && eventEndTime
        ? formatDateTime(eventBeginDate, eventBeginTime?.value) >
          formatDateTime(eventEndDate, eventEndTime?.value)
        : false;

    return (
      <Dialog
        fullScreen={fullscreen || fullScreen}
        fullWidth={true}
        maxWidth={eventDialogMaxWidth}
        open={openDialog}
        onClose={handleOnClose}
        aria-labelledby='max-width-dialog-title'
        TransitionComponent={Transition}
        keepMounted={false}
      >
        <DialogTitle>
          {title}
          <div style={classes.divTitleButton}>
            {allowFullScreen ? (
              <IconButton
                aria-label='Close'
                style={classes.fullScreenButton}
                onClick={handleFullScreen}
              >
                {!fullScreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
              </IconButton>
            ) : null}
            {withCloseIcon ? (
              <IconButton
                aria-label='Close'
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        </DialogTitle>
        <DialogContent>
          <form style={classes.form} noValidate>
            <FormControl style={classes.formControl}>
              <TextField
                inputRef={textFieldTitle}
                style={classes.title}
                fullWidth={true}
                placeholder='Title'
                name='title'
                value={titleTF}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTitleTF(event.target.value)
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter' && !buttonDisabled) {
                    handleOk()
                    handleClose()
                  }
                }}
                margin='normal'
                required={true}
              />
            </FormControl>
            <FormControl style={{...classes.formControl, ...classes.formControlFlex}}>
              <AccessTimeIcon />
              <Datepicker
                styleCls={classes.datepicker}
                dateFormat={dateFormat}
                originalValue={new Date(eventBeginDate)}
                onChange={onChangeBeginDate}
              />
              <TimeSelect
                placeholder=''
                options={timeOptions}
                originalValue={{
                  value: eventBeginTime.value,
                  label: eventBeginTime.label,
                }}
                onChange={onChangeBeginTime}
              />
              <Typography style={classes.dayOfWeek}>
                {format(eventBeginDate ?? new Date(), 'eeee', { locale })}
              </Typography>
            </FormControl>
            <FormControl style={{...classes.formControl, ...classes.formControlFlex}}>
              <AccessTimeIcon />
              <Datepicker
                styleCls={classes.datepicker}
                dateFormat={dateFormat}
                originalValue={eventEndDate}
                onChange={onChangeEndDate}
              />
              <TimeSelect
                placeholder=''
                options={timeOptions}
                originalValue={{
                  value: eventEndTime.value,
                  label: eventEndTime.label,
                }}
                onChange={onChangeEndTime}
              />
              <Typography style={classes.dayOfWeek}>
                {format(eventEndDate, 'eeee', { locale })}
              </Typography>
            </FormControl>
            <FormControl style={{...classes.formControl, ...classes.formControlFlex}}>
              <SubjectIcon style={classes.descriptionIcon} />
              <TextField
                fullWidth={true}
                placeholder='Description'
                multiline
                style={classes.textField}
                onChange={(event: any) => {
                  setDescriptionTF(event.target.value)
                }}
                value={descriptionTF}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOk}
            color='primary'
            disabled={buttonDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [
    openDialog,
    titleTF,
    descriptionTF,
    eventBeginDate,
    eventBeginTime,
    eventEndDate,
    eventEndTime,
    allowFullScreen,
    classes,
    eventDialogMaxWidth,
    eventID,
    fullScreen,
    fullscreen,
    handleCloseDialog,
    locale,
    modal,
    title,
    description,
    withCloseIcon,
    handleCreateEvent,
    handleUpdateEvent,
    stateCalendar,
    setStateCalendar,
  ]);
};