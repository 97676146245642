import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { UserAvatar } from '..';
import { getAvatarUrl } from '../../modules';
import { RelationshipService } from '../../services';

export const FollowersDialog = (props: any) => {
  //console.log('FollowersDialog props:', props);
  const { open, title, profile, currentUser, onClose } = props;

  const goToProfile = (username: string) => window.location.href = `/${username}`;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
      <List sx={{ pt: 0 }}>
        {profile?.followers?.length > 0 ? profile?.followers?.map((follower: any) => {
          const isFollowingUser = RelationshipService.isFollowingUser(follower.followerUserId, currentUser);
           return (
          <ListItem key={follower?.followerUserId} disableGutters>
            <ListItemButton onClick={() => {} /*</ListItem>handleListItemClick(follower?.username)*/}>
              <ListItemAvatar>
                <UserAvatar
                  fileName={getAvatarUrl(follower?.followerUserId)}
                  fullName={follower?.following?.username}
                  onClick={() => goToProfile(follower?.following?.username)}
                />
              </ListItemAvatar>
              <ListItemText>
                <div className="row text-nowrap">
                  <div className="col col-xs-12">
                    <div className="float-start">
                      {follower?.following?.username}
                    </div>
                  </div>
                  <div className="col col-xs-12 float-end">
                    <div className="float-end">
                      {follower?.followerUserId !== currentUser?.id ? (
                        <Button
                          variant="contained"
                          size="small"
                        >
                          {isFollowingUser ? "Follow Back" : "Unfollow"}
                        </Button>
                      ) : ('')}
                    </div>
                  </div>
                </div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          );
        }) : ('No Followers')}
        </List>
      </DialogContent>
    </Dialog>
  );
};