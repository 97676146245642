import React, { useState } from 'react';
import classnames from 'classnames';
import { Grid, IconButton } from '@mui/material';
import {
  //Crop as CropIcon,
  Menu as MenuIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
  PhotoCamera as ScreenshotIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from '@mui/icons-material';
import { v4 } from 'uuid';

import { Dropdown, VideoRecorder, VideoSrc } from '.';
import { useMediaDevices, useVideoCall } from '../../hooks';
import { createDownloadLink } from '../../modules';
import { getUserToken } from '../../stores';
import { User, UserVideoStream, VideoDimensions } from '../../types';

import '../../css/chat/index.css';

export interface VideoProps {
  stream: UserVideoStream;
  user: User;
  forceContain?: boolean;
  style?: React.CSSProperties;
};

export const Video = (props: VideoProps) => {
  //console.log('Video props:', props);
  //const { forceContain, stream, style, user } = props;
  const { stream, style, user } = props;
  //const [objectFit, setObjectFit] = useState(forceContain ? 'contain' : '');
  const [selectedVideoRef, setSelectedVideoRef] = useState<HTMLVideoElement>();

  const currentUser = getUserToken();
  const {
    onMinimizeStream,
    onMaximizeStream,
  } = useVideoCall();
  const {
    localSettings,
    onToggleMic,
    onToggleRemoteAudio,
  } = useMediaDevices();

  const minimized = stream?.windowState === 'minimized';
  const className = classnames('video-container', {
    minimized,
    mirrored: stream?.isMirrored ?? false,
  });

  const handleMinimize = () => onMinimizeStream(stream);
  const handleMaximize = () => onMaximizeStream(stream);
  const handleToggle = () => minimized ? handleMaximize() : handleMinimize();
  //const handleToggleCover = () => setObjectFit(forceContain ? 'contain' : (objectFit !== '' ? '' : 'contain'));

  const handleScreenshot = (fileName: string = 'screenshot.png', mimeType: string = 'image/png') => {
    if (!selectedVideoRef) {
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = selectedVideoRef.videoWidth;
    canvas.height = selectedVideoRef.videoHeight;

    context?.drawImage(selectedVideoRef, 0, 0, canvas.width, canvas.height);

    // Convert canvas image to base64 data URL
    const url = canvas.toDataURL(mimeType);
    createDownloadLink(url, fileName);
  };

  const handleResize = (dimensions: VideoDimensions) => {
    //console.log('handleResize:', dimensions, stream);
    stream.dimensions = dimensions
  };

  const handleVideoRefCallback = (videoRef: HTMLVideoElement | null, video: UserVideoStream) => {
    if (videoRef && 'srcObject' in videoRef) {
      (videoRef as unknown as { srcObject: MediaStream | null }).srcObject = video?.stream;
    }

    if (videoRef) {
      setSelectedVideoRef(videoRef);
      /*
      const remoteStream: any = callState!.remoteStreams.find((stream: any) => stream.id === video?.userId);
      if (remoteStream?.stream) {
        //videoRef.srcObject = remoteStream.stream;
        if (fullscreen) {
          if (videoRef.requestFullscreen) {
            videoRef.requestFullscreen();
          }// else if (ref.mozRequestFullScreen) { // Firefox
          //  ref.mozRequestFullScreen();
          //} else if (ref.webkitRequestFullscreen) { // Chrome, Safari and Opera
          //  ref.webkitRequestFullscreen();
          //} else if (ref.msRequestFullscreen) { // IE/Edge
          //  ref.msRequestFullscreen();
          //}
          //videoRef.onfullscreenchange = (event: Event) => setFullscreen(!!document.fullscreenElement);
        }
      }
      */
      if (!videoRef.autoplay) {
        videoRef.autoplay = true;
      }
    }
  };

  const handleToggleAudio = () => {
    if (stream.userId === currentUser?.id) {
      const audioTracks = stream.stream.getAudioTracks();
      audioTracks.forEach((track) => track.enabled = !localSettings.isAudioInEnabled);
      onToggleMic();      
    } else {
      onToggleRemoteAudio(stream.userId);
    }
  };

  return (
    <div className={className} style={{...style, borderRadius: '5px'}}>
      <VideoSrc
        id={stream?.stream?.id}
        autoPlay={localSettings.isVideoEnabled!}
        src={stream}
        //videoRef={selectedVideoRef!}
        muted={!localSettings.isAudioInEnabled}
        mirrored={stream?.isMirrored ?? false}
        //objectFit={objectFit}
        onResize={handleResize}
        onVideoRefCallback={handleVideoRefCallback}
        onToggle={handleToggle}
      />
      <div className='video-footer'>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="float-start">
              {currentUser?.id !== stream.userId && (
                <IconButton
                  title={localSettings.remoteAudio[stream.userId] ? 'Mute microphone' : 'Unmute microphone'}
                  size="small"
                  onClick={handleToggleAudio}
                >
                  {(localSettings.remoteAudio[stream.userId] ?? false) ? <MicIcon /> : <MicOffIcon color="error" />}
                </IconButton>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="text-center">
              <span
                className="nickname"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  border: '1px solid rgba(30, 30, 30, 0.7)',
                  borderRadius: 25,
                  paddingTop: '3px',
                  paddingBottom: '3px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
              >
                {user?.username ?? stream?.userId} ({stream?.type})
              </span>
            </div>
          </Grid>
          <Grid item xs={4} style={{whiteSpace: 'nowrap'}}>
            <div className="float-end" style={{whiteSpace: 'nowrap'}}>
              <IconButton
                title="Take a screenshot"
                size="small"
                onClick={() => handleScreenshot(`screenshot_${stream?.userId}_${v4()}.png`)}
              >
                <ScreenshotIcon />
              </IconButton>
              <VideoRecorder stream={stream?.stream} />

              <Dropdown fixed label={<MenuIcon color='action' />}>
                <li className='action-maximize' onClick={handleMaximize}>
                  <ZoomInIcon />
                  &nbsp;Maximize
                </li>
                <li className='action-minimize' onClick={handleMinimize}>
                  {minimized ? <ZoomInIcon /> : <ZoomOutIcon />}
                  &nbsp;Minimize
                </li>
                {/*!forceContain && (
                  <li className='action-toggle-fit' onClick={handleToggleCover}>
                    <CropIcon />
                    &nbsp;Toggle Fit
                  </li>
                )*/}
              </Dropdown>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};