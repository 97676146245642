import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import {
  LatestChallengesList,
  NewestMembersList,
  //RunTimesList,
  //RunTimeType,
} from '..';
import { StatisticService } from '../../services';

export const HomePageSidebar = (props: any) => {
  const [stats, setStats] = useState<any>();

  useEffect(() => {
    StatisticService.getStats().then((response: any) => {
      //console.log('getStats response:', response);
      setStats(response.stats);
    });
  }, [ setStats]);

  return (
    <>
      <div className="mb-3">
        <Typography variant="h5" gutterBottom className="text-center">
          Latest Challenges
        </Typography>
        <LatestChallengesList />
      </div>
      <div className="mb-3">
        <Typography variant="h5" gutterBottom className="text-center">
          Newest Members
        </Typography>
        <NewestMembersList />
      </div>
      {/*
      <div className="mb-3">
        <Typography variant="h5" gutterBottom className="text-center">
          Latest Run Times
        </Typography>
        <RunTimesList timeType={RunTimeType.latest} />
      </div>
      */}
      <div className="mb-3">
        <Typography variant="h5" gutterBottom className="text-center">
          Platform Statistics
        </Typography>
        <Paper style={{borderRadius: 20, border: '1px solid black', padding: 15}}>
          <Grid container spacing={2}>
            <Grid item xs={6}>Total Members</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.users.toLocaleString()}</div></Grid>
            <Grid item xs={6}>Total Posts</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.posts.toLocaleString()}</div></Grid>
            <Grid item xs={6}>Total Images</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.images.toLocaleString()}</div></Grid>
            <Grid item xs={6}>Total Videos</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.videos.toLocaleString()}</div></Grid>
            <Grid item xs={6}>Total Cars</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.cars.toLocaleString()}</div></Grid>
            <Grid item xs={6}>Total Track Runs</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.times.toLocaleString()}</div></Grid>
            <Grid item xs={6}>Total Challenges</Grid>
            <Grid item xs={6}><div className="float-end">{stats?.challenges.toLocaleString()}</div></Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};