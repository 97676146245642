 import { useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material';

import { Time } from '../../types';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => onPageChange(event, 0);
  const handleBackButtonClick = (event: any) => onPageChange(event, page - 1);

  const handleNextButtonClick = (event: any) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, right: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export const CarRaceTrackTimes = (props: any) => {
  //console.log('CarRaceTrackTimes props:', props);
  const { car } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rows = car?.times?.length ?? 0;

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        //border: '1px solid black',
        backgroundColor: 'background.paper',
        borderRadius: 8,
      }}
    >
      <Table sx={{ minWidth: 650, backgroundColor: 'background.paper' }} size="small" aria-label="a dense table">
        <TableHead style={{ backgroundColor: '#212121' }}>
          <TableRow>
            <TableCell
              align="left"
              component="th"
              scope="row"
              style={{color: 'darkgrey'}}
            >
              Track
            </TableCell>
            <TableCell align="right" style={{color: 'darkgrey'}}>Length&nbsp;(mi)</TableCell>
            <TableCell
              align="right"
              sortDirection="asc"
              style={{color: 'darkgrey'}}
            >
              Time&nbsp;(sec)
            </TableCell>
            <TableCell align="right" style={{color: 'darkgrey'}}>Speed&nbsp;(MPH)</TableCell>
            <TableCell align="right" style={{color: 'darkgrey'}}>Reaction Time&nbsp;(sec)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {car?.times?.map((time: Time) => (
            <TableRow
              key={time.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {time.raceTrack?.name}
              </TableCell>
              <TableCell align="right">{time.raceTrack?.length}</TableCell>
              <TableCell align="right">{time.time}</TableCell>
              <TableCell align="right">{time.speed}</TableCell>
              <TableCell align="right">{time.reaction}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, { value: -1, label: 'All' }]}
              colSpan={5}
              count={rows}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};