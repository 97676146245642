import { Avatar, Grid } from '@mui/material';
import moment from 'moment';

import {
  ChatMessageEmojiFooter,
  ChatMessageFileFooter,
  IChatMessageProps,
} from '..';
import { getAvatarUrl } from '../../../modules';
import './ChatMessageContainer.css';

// Credits: https://codesandbox.io/s/material-ui-chat-drh4l?file=/src/Message.js
export const ChatMessageRight = (props: IChatMessageProps) => {
  const {
    message,
    editMode = false,
    showUsername = true,
    showAvatar = true,
    isSelected = false,
    isUnread = false,
    onContextMenu,
    onClick,
    onUserClick,
    currentUserId,
  } = props;

  return (
    <>
      {showUsername && (
        <div className="displayNameRight">
          <a
            href={`/${message.username}`}
            title={`Visit ${message.username}'s profile`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {message.username}
          </a>
        </div>
      )}
      <div
        title={moment(message.date).format('LLLL')}
        className="messageRowRight"
        onClick={onClick}
        onContextMenuCapture={() => onContextMenu(message)}
      >
        <div
          className="messageRight"
          style={{
            backgroundColor: `rgba(0, 197, 255, ${isSelected ? 0.8 : 1})`,
            boxShadow: isSelected
              ? 'inset 0 0 5rem rgba(0, 0, 0, 0.5)'
              : '0 1px 4px 0 rgb(0, 0, 0, 0.5)',
            color: isUnread ? 'red' : 'inherit',
          }}
        >
          <p className="messageContent">{message.text}</p>
          <div className="messageTimestamp">
            {moment(message.date).calendar()}
          </div>
          <Grid container spacing={2}>
            {!editMode && (
              <Grid item xs={12}>
                <ChatMessageEmojiFooter
                  message={message}
                  userId={currentUserId}
                />
              </Grid>
            )}
            {message.files?.length > 0 && (
              <Grid item xs={12}>
                <ChatMessageFileFooter message={message} />
              </Grid>
            )}
          </Grid>
        </div>
        {showAvatar && (
          <Avatar
            src={getAvatarUrl(message.userId)}
            alt={message.username}
            title={`View ${message.username}'s profile details`}
            onClick={() => onUserClick && onUserClick({id: message.userId, username: message.username})}
            style={{cursor: 'pointer'}}
          />
        )}
      </div>
    </>
  );
};