import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  EmojiEmotions as EmojiEmotionsIcon,
  PermMedia as MediaIcon,
  RemoveCircle as RemoveIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { EmojiClickData } from 'emoji-picker-react';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  ChatContextMenu,
  ChatMessageDateHeader,
  ChatMessageHeader,
  ChatMessageLeft,
  ChatMessageRight,
  EmojiPickerContainer,
  IChatMessageContainerProps,
  IChatMessageContainerState,
  Img,
} from '../../../components';
import { Events, MaxFileUploadLimit, MaxFileUploadSize } from '../../../consts';
import { EditChatMessageDetailsDialog } from '../../Dialogs';
import { useSockets } from '../../../hooks';
import { fileToDataUrl } from '../../../modules';
import { Message, PostImage } from '../../../types';

import './ChatMessageContainer.css';

const useStyles: any = (theme: Theme) => ({
  uploadImagesButton: {
    position: 'absolute',
    margin: 0,
    //width: '100%',
    width: 0,
    opacity: 0,
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  imgListItemGroup: {
    display: 'flex',
    position: 'relative',
  },
  imgListItem: {
    width: '100%',
    margin: 0,
    verticalAlign: 'bottom',
    position: 'static',
  },
  imgListItemContainer: {
    display: 'inline-block',
    width: '100%',
    marginRight: '8px',
    transition: 'transform .25s',
  },
  imgRemove: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  imgRemoveContainer: {
    position: 'absolute',
    width: '28px',
    backgroundColor: 'rgba(255, 255, 255, 0.22)',
    height: '28px',
    right: 12,
    top: 4,
    cursor: 'pointer',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
});

export const ChatMessageContainer = (props: IChatMessageContainerProps) => {
  //console.log('ChatMessageContainer props:', props);
  const { senderUser, onUserClick } = props;
  const [state, setState] = useState<IChatMessageContainerState>({
    text: '',
    openEmojiPicker: false,
    anchorEl: null,
    images: [],
    selectedMessage: null,
    typingStatus: '',
    openEditDialog: false,
  });
  const btnEmojiRef = useRef(null);
  const messagesEndRef: any = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { socket, activeRoomId, rooms, messages, sendMessage, deleteMessage } = useSockets();
  const theme = useTheme();
  const classes = useStyles(theme);
  let lastDate: Date;
  
  const handleCloseEditMessage = () => setState({...state, openEditDialog: false});
  const handleUpdateChatMessage = (message: Message) => {
    //console.log('handleUpdateChatMessage:', message);
    setState({...state, openEditDialog: true, selectedMessage: message});
    //updateMessage(message);
  };
  const handleDeleteChatMessage = (id: number) => deleteMessage(id);
  const handleCloseContextMenu = () => setState({...state, selectedMessage: null});

  const sendData = () => {
    sendMessage(senderUser?.id, activeRoomId, state.text, state.images);
    setState({...state, text: '', typingStatus: '', images: []});
  };

  const handleEmojiClick = (emoji: EmojiClickData, event: MouseEvent) => {
    setState({...state, text: `${state.text}${emoji.emoji}`});
  };
  const handleEmojiClose = () => setState({...state, openEmojiPicker: false});

  const toggleEmojis = (event: any) => {
    setState({
      ...state,
      openEmojiPicker: !state.openEmojiPicker,
      anchorEl: btnEmojiRef?.current
    });
  };

  const handleRemoveImage = (image: PostImage) => {
    const newImages = state.images.filter((img: PostImage) => img.id !== image.id);
    setState({...state, images: newImages});
  };

  const handleFileChange = async (event: any) => {
    event.preventDefault();

    const files = event.target.files;
    if (files?.length <= 0) {
      return;
    }

    let uploadFiles: File[] = [];
    for (const file of files) {
      // Ignore files exceeding size limit
      if (file.size > MaxFileUploadSize) {
        enqueueSnackbar(`File size ${(file.size / 1024 / 1024).toFixed(2)}MB for '${file.name}' exceeds maximum size allowed of ${MaxFileUploadSize / 1024 / 1024}MB, this file has been ignored.`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        continue;
      }
      uploadFiles.push(file);
    }

    if (uploadFiles.length > MaxFileUploadLimit) {
      uploadFiles = uploadFiles.slice(0, MaxFileUploadLimit - 1);
      enqueueSnackbar(`Maximum of ${MaxFileUploadLimit} files can be uploaded per message, the first 5 have been included and the remaining ignored.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }

    const readers: PostImage[] = [];
    for (const file of uploadFiles) {
      const dataUrl = await fileToDataUrl(file);
      readers.push(dataUrl);
    }
    Promise.all(readers).then((values: any[]) => setState({...state, images: values}));
  };

  const handleTyping = () => {
    socket.emit('typing', `${senderUser?.username} is typing...`);
  };

  const isDiffDate = (date: Date, setLastDate: boolean = true) => {
    if (lastDate === null || lastDate === undefined) {
      if (setLastDate) {
        lastDate = date;
      }
      return true;
    }
    const result = moment(date).isAfter(lastDate, 'day');
    if (result && setLastDate) {
      lastDate = date;
    }
    return result;
  };

  useEffect(() => {
    socket.on(Events.Server.TypingResponse, (status: any) => setState({...state, typingStatus: status}));
  }, [socket, state]);

  const getLastUnreadMessageId = (roomId: number, userId: number) => {
    const room = rooms.find((r: any) => r.id === roomId);
    const user = room?.users?.find((u: any) => u.userId === userId);
    const messageId = user?.lastReadMessageId;
    return messageId;
  };

  const scrollToBottom = () => messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  useEffect(scrollToBottom, [scrollToBottom]);

  return (
    <>
      {activeRoomId > 0 && (
        <Paper className="paper" elevation={2}>
          <ChatMessageHeader
            user={senderUser}
            activeRoomId={activeRoomId}
          />
          <Paper className="messagesBody">
            {messages && messages?.map((message: any) => (
              <div key={message.id}>
                {isDiffDate(new Date(message.date)) && (
                  <ChatMessageDateHeader
                    key={message.date}
                    date={message.date}
                  />
                )}
                <ChatContextMenu
                  key={message.id}
                  message={message}
                  onUpdate={handleUpdateChatMessage}
                  onDelete={handleDeleteChatMessage}
                  onClose={handleCloseContextMenu}
                >
                  {message.userId === senderUser?.id ? (
                    <ChatMessageRight
                      key={message.id}
                      message={message}
                      editMode={false}
                      showUsername={false}
                      showAvatar={false}
                      isSelected={message.id === state.selectedMessage?.id}
                      //isUnread={message.id > getLastUnreadMessageId(message.roomId, senderUser?.id)}
                      onContextMenu={(msg: any) => setState({...state, selectedMessage: msg })}
                      onClick={() => setState({...state, selectedMessage: message})}
                      onUserClick={onUserClick}
                      currentUserId={senderUser?.id}
                    />
                  ) : (
                    <ChatMessageLeft
                      key={message.id}
                      message={message}
                      editMode={false}
                      showUsername={true}
                      showAvatar={true}
                      isSelected={message.id === state.selectedMessage?.id}
                      isUnread={message.id > getLastUnreadMessageId(message.roomId, senderUser?.id)}
                      onContextMenu={(msg: any) => setState({...state, selectedMessage: msg })}
                      onClick={() => setState({...state, selectedMessage: state.selectedMessage !== null ? null : message})}
                      onUserClick={onUserClick}
                      currentUserId={senderUser?.id}
                    />
                  )}
                </ChatContextMenu>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </Paper>
    
          <div className="ms-3 mt-0 w-100">
            <p className="text-muted mb-0" style={{textAlign: 'start'}}>{state.typingStatus}</p>
          </div>
      
          <div className="message-editor">
            <div className="msg-editor" title="Write your message here">
              <TextField
                type="text"
                autoFocus
                fullWidth
                value={state.text}
                placeholder="Send a message..."
                onChange={(event: any) => setState({...state, text: event.target.value})}
                onKeyUp={(event: any) => {
                  if (event.key === 'Enter') {
                    sendData();
                  }
                  handleTyping();
                }}
              />
            </div>
            <div style={classes.shareOption}>
              <IconButton
                size="small"
                className="file-icon"
                title="Add a file such as an image or video"
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <label htmlFor="fileInput">
                  <MediaIcon style={{...classes.shareIcon, color: 'grey'}} />
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*" // TODO: support any file type
                  style={classes.uploadImagesButton}
                  onChange={handleFileChange}
                  multiple
                />
              </IconButton>
            </div>
      
            <IconButton
              ref={btnEmojiRef}
              aria-describedby={(Boolean(state.anchorEl)) ? 'spring-popper' : undefined}
              size="small"
              className="emoji-icon"
              title="Add an emoji expression"
              onClick={toggleEmojis}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <EmojiEmotionsIcon style={{color: 'grey'}} />
            </IconButton>
      
            <IconButton
              className="sender-icon"
              title="Send message"
              onClick={sendData}
            >
              <SendIcon style={{color: 'grey'}} />
            </IconButton>
          </div>
    
          {state.images?.length > 0 && (
            <Card elevation={0}>
              <CardContent>
                <Grid container spacing={2}>
                  {state.images.map((image: PostImage) => (
                    <Grid item key={image.id} md={4} sm={6} xs={12}>
                    <div style={classes.imgListItemGroup}>
                      <IconButton
                        onClick={() => handleRemoveImage(image)}
                        style={classes.imgRemoveContainer}
                      >
                        <RemoveIcon style={classes.imgRemove} />
                      </IconButton>
                      <div style={classes.imgListItemContainer}>
                        <div style={classes.imgListItem}>
                          <Img fileName={image.url} style={classes.img} />
                        </div>
                      </div>
                    </div>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          )}
    
          <EmojiPickerContainer
            onEmojiClick={handleEmojiClick}
            onClose={handleEmojiClose}
            anchorEl={state.anchorEl}
            openEmojiPicker={state.openEmojiPicker}
          />

          <EditChatMessageDetailsDialog
            message={state.selectedMessage}
            currentUser={senderUser}
            open={state.openEditDialog}
            onClose={handleCloseEditMessage}
            fullScreen={true}
          />
        </Paper>
      )}
    </>
  );
};