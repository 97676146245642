import { Theme } from '@mui/material/styles';

import theme from '../../themes/theme';

const useStyles: any = ((theme: Theme) => ({
  lineDivisorContainer: {
    borderTop: '1px solid #dadce0',
    // minWidth: '100%',
  },
  lineDivisor: {
    height: 60,
    '&:after': {
      content: "''",
      borderBottom: '1px solid #dadce0',
      position: 'absolute',
      width: '100%',
      marginTop: -1,
      zIndex: 3,
      pointerEvents: 'none',
    },
  },
  columnDivisor: {
    height: '100%',
    paddingLeft: 8,
    borderRight: '1px solid #dadce0',
  },
}));

export const LineDivisor = (props: any) => {
  const classes = useStyles(theme);
  return (
    <div style={classes.lineDivisorContainer}>
      {Array.from(Array(24).keys()).map((_: any, ix: number) => (
        <div
          key={`time-line-divisor-${ix}`}
          style={classes.lineDivisor}
          data-group='time-line'
        />
      ))}
    </div>
  );
};