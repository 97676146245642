import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { buildCarName } from '../../modules';
import { Car } from '../../types';

export interface IChallengeResultProps {
  car: Car;
  bestTime: number;
  bestReaction: number;
  topSpeed: number;
  totalRuns: number;
};

const useStyles: any = (theme: Theme) => ({
  root: {
    borderRadius: 12,
    border: '1px solid black',
  },
  content: {
    backgroundColor: '#121212',
  },
  listItem: {
    borderRadius: 12,
  },
  listItemValue: {
    textAlign: 'end',
  },
  title: {
    align: 'center',
  },
  subheader: {
    textAlign: 'center',
  },
});

export const ChallengeListItemResult = (props: IChallengeResultProps) => {
  //console.log('ChallengeListItemResult props:', props);
  const { car, bestTime, bestReaction, topSpeed, totalRuns } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Card style={classes.root}>
      <CardHeader
        title={buildCarName(car)}
        titleTypographyProps={classes.title}
        subheader={car?.name}
        subheaderTypographyProps={classes.subheader}
      />
      <CardContent style={classes.content}>
        <List dense disablePadding>
          <ListItem disablePadding>
            <ListItemButton style={classes.listItem}>
              <ListItemText primary="Best Time" />
              <ListItemText primary={`${bestTime} sec`} style={classes.listItemValue} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton style={classes.listItem}>
              <ListItemText primary="Best Reaction" />
              <ListItemText primary={`${bestReaction} sec`} style={classes.listItemValue} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton style={classes.listItem}>
              <ListItemText primary="Top Speed" />
              <ListItemText primary={`${topSpeed} MPH`} style={classes.listItemValue} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton style={classes.listItem}>
              <ListItemText primary="Total Runs" />
              <ListItemText primary={totalRuns} style={classes.listItemValue} />
            </ListItemButton>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};