import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { EditChatMessageDetails } from '..';

export const EditChatMessageDetailsDialog = (props: any) => {
  //console.log('EditChatMessageDetailsDialog props:', props);
  const { open, currentUser, message, onClose } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {message?.user?.username}&nbsp; | &nbsp;
          </Typography>
          <IconButton color="inherit" onClick={onClose} title="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <EditChatMessageDetails
        message={message}
        currentUser={currentUser}
      />
    </Dialog>
  );
};