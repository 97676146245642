import http from '../modules/http-common';
import { Post } from '../types';

const getPostDetails = async (postId: number) => {
  const response = await http
    .get(`posts/${postId}/details`);
  return response.data;
};

const getPosts = async (userId: number, offset: number = 0, limit: number = 10, feed: boolean = false, includeDeleted: boolean = false) => {
  if (!userId) {
    return;
  }
  const response = await http
    .get(`posts/${userId}?offset=${offset}&limit=${limit}&includeDeleted=${includeDeleted}&feed=${feed}`);
  return response.data;
};

const createPost = async (post: Post) => {
  const response = await http
    .post('posts/create', { post });
  return response.data;
};

const deletePost = async (postId: number) => {
  const response = await http
    .delete(`posts/${postId}/delete`);
  return response.data;
};

const likePost = async (postId: number, userId: number) => {
  const response = await http
    .post(`posts/${postId}/like`, { userId });
  return response.data;
};

export const PostService = {
  getPostDetails,
  getPosts,
  createPost,
  deletePost,
  likePost,
};