import React, { useState } from 'react';
import {
  Fab,
  Paper,
  Zoom,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

import { SingleImageUploadDialog } from '../Dialogs';
import { ImageService } from '../../services';

const UserBannersFolder = '/images/banners';
const UserBannerFileExt = 'jpg';

export const UserBannerChanger = (props: any) => {
  //console.log('UserBannerChanger props:', props);
  const { userId = 0, path } = props;

  const {
    photoUrl: currentPhotoUrl = `${UserBannersFolder}/${userId}.${UserBannerFileExt}`,
    photoFile: currentPhotoFile = null,
  } = {};
  const [state, setState] = useState<any>({
    photoUrl: currentPhotoUrl,
    photoFile: currentPhotoFile,
    hasChanges: false,
  });

  const handleImageChange = (file: File, image: any) => {
    const hasChanges = file !== state.photoFile || image !== state.photoUrl;
    setState({...state, photoUrl: image, photoFile: file, hasChanges});
  };

  const handleSaveBanner = (e: any) => {
    e.preventDefault();

    const hasChanges = currentPhotoUrl !== state.photoUrl || currentPhotoFile !== state.photoFile;
    if (!hasChanges) {
      return;
    }

    if (state.photoFile) {
      ImageService.uploadImage(state.photoFile, path).then((response: any) => {
        //console.log('UserBannerChanger uploadImage response:', response);
        setState({...state, photoFile: null, hasChanges: false});
      });
    }
  };

  const handleDeleteBanner = (e: any) => {
    e.preventDefault();

    const result = window.confirm(`Are you sure you want to delete your current profile banner?`);
    if (!result) {
      return;
    }

    ImageService.deleteImage(state.photoUrl).then((response: any) => {
      //console.log('UserBannerChanger deleteImage response:', response);
      setState({...state, photoUrl: '', photoFile: null, hasChanges: false});
    });
  };

  return (
    <Paper
      className="bg-dark text-light"
      elevation={6}
      style={{
        marginTop: '50px',
        position: 'relative',
        borderRadius: 20,
        border: '1px solid black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Fab
        size="small"
        style={{ position: 'absolute', top: -15, right: -15 }}
        onClick={handleDeleteBanner}
        color="error"
        aria-label="Delete"
      >
        <DeleteIcon />
      </Fab>

      {state.photoUrl && (
        <img
          style={{
            width: '100%',
            height: 'auto',
            marginTop: -40,
            border: '1px solid black',
            borderRadius: 20,
          }}
          alt="User Profile Banner"
          src={state.photoUrl}
        />
      )}
      {(<Zoom in={state.hasChanges}>
        <Fab
          onClick={handleSaveBanner}
          style={{ marginBottom: -20 }}
          color="primary"
          aria-label="Save"
        >
          <SaveIcon />
        </Fab>
      </Zoom>
      )}
      <SingleImageUploadDialog handleSelected={handleImageChange} />
    </Paper>
  );
};