import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface IViewImageDialogProps {
  open: boolean;
  title: string;
  image: any;
  onClose: () => void;
};

export const ViewImageDialog = (props: IViewImageDialogProps) => {
  const { open, onClose, title, image } = props;

  const onDialogClick = (event: any) => {
    const { target: { className } } = event;
    //console.log('dialog:', event);
    if (typeof className !== 'object') {
      if (className?.includes('MuiDialogContent-root') ||
          className?.includes('MuiDialogTitle-root')) {
        onClose && onClose();
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        //fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            //boxShadow: 'none',
          },
          onClick: onDialogClick,
        }}
      >
        <DialogContent style={{overflow: 'hidden'}}>
          <IconButton
            onClick={onClose}
            title="Close"
            className="float-end"
            style={{
              backgroundColor: 'rgba(128, 128, 128, 0.7)',
              position: 'absolute',
              //top: '69px',
              //right: '29px',
              top: '25px',
              right: '30px',
              zIndex: 100,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <img
              src={image}
              alt=""
              style={{
                //width: '100%',
                //height: 'auto',
                width: 'auto',
                height: 'calc(100vh - 8em)',
                objectFit: 'cover',
                overflow: 'hidden',
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};