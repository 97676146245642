import { useSnackbar } from 'notistack';
import { Socket } from 'socket.io-client';

import { Events } from '../consts';
import { ContactsContext } from '../hooks';
import { MessageService } from '../services';

export type ContactsContextType = {
  markContactRead: Function;
  startChat: Function;
};

export const ContactsProvider = (props: any) => {
  //console.log('ContactsProvider props:', props);
  const { user } = props;
  const { enqueueSnackbar } = useSnackbar();

  const markContactRead = (roomId: number) => {
    MessageService.markRead(roomId).then((response: any) => {
      //console.log('markRead response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to mark messages as read, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
    });
  };

  const startChat = (socket: Socket, roomId: number, selectedUserIds: number[], setActive: boolean = true) => {
    //console.log('startChat:', roomId, selectedUserIds, setActive);
    /*
    if (roomId === 0) {
      socket.emit(Events.Client.CreateRoom, {
        senderUserId: user?.id,
        selectedUserIds,
      });
    }
    */

    socket.emit(Events.Client.JoinRoom, {
      roomId,
      active: setActive,
      senderUserId: user?.id,
      senderUserName: user?.username,
      selectedUserIds,
    });
  };

  const context = {
    markContactRead,
    startChat,
  };

  return (
    <ContactsContext.Provider
      value={context}
      {...props}
    />
  );
};