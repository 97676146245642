import {
  Grid,
  Paper,
} from '@mui/material';

import { ImageGallerySwiper, Post2 } from '..';

export const ViewPostDetails = (props: any) => {
  //console.log('ViewPostDetails props:', props);
  const { currentUser, post } = props;
  const hasImages = (post?.images?.length ?? 0) > 0;

  return (
    <Grid container spacing={2} width="100%">
      {hasImages && (
      <Grid item md={7} xs={12} style={{
        whiteSpace: 'nowrap',
        margin: '1em 0',
      }}>
        <div style={{
          backgroundColor: 'black',
          height: '100%',
        }}>
          <ImageGallerySwiper images={post.images} />
        </div>
      </Grid>
      )}
      <Grid item md={hasImages ? 5 : 12} xs={12}>
        {post && (
          <Paper style={{borderRadius: 20}}>
            <Post2
              key={post?.id}
              post={post}
              currentUser={currentUser}
              editMode={false}
              openComments={true}
              showImages={false}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};