import { AvatarGroup } from '@mui/material';

import { UserAvatar } from '..';
import { getAvatarUrl } from '../../modules';

export const UserAvatarGroup = (props: any) => {
  //console.log('UserAvatarGroup props:', props);
  const { userIds, maxShown = 2, spacing = 20 } = props;

  return (
    <AvatarGroup
      spacing={spacing}
      //spacing="small"
      max={maxShown}
      total={userIds?.length ?? 0}
    >
      {userIds?.length > 0 && userIds?.map((userId: number) => (
        <UserAvatar
          key={userId}
          fileName={getAvatarUrl(userId)}
          //fullName={user?.username}
          showBadge={true}
          size={32}
        />
      ))}
    </AvatarGroup>
  );
};