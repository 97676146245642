import React, { Children, FC, memo } from 'react';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  HideOnScroll,
  SidebarToggleButton,
  TitlePortal,
} from '.';
import { NavbarProfile as DefaultUserMenu } from '..';
import { DefaultAppBarBackColor, DefaultAppBarForeColor } from '../../consts';
import { User } from '../../types';

/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {Object} props
 * @param {ReactNode} props.children React node/s to be rendered as children of the AppBar
 * @param {string} props.className CSS class applied to the MuiAppBar component
 * @param {string} props.color The color of the AppBar
 * @param {Element | boolean} props.rightMenu A custom user menu component for the AppBar. <UserMenu/> component by default. Pass false to disable.
 *
 * @example // add a custom button to the AppBar
 *
 * const MyAppBar = () => (
 *   <AppBar>
 *   <TitlePortal />
 *   <MyCustomButton />
 *   </AppBar>
 * );
 *
 * @example // without a user menu
 *
 * const MyAppBar = () => <AppBar rightMenu={false} />;
 */
export const AppBar: FC<IAppBarProps> = memo(props => {
  //console.log('AppBar props:', props);
  const {
    alwaysOn,
    user,
    children,
    className,
    color = 'primary',
    //backgroundColor = DefaultAppBarBackColor,
    rightMenu = DefaultUserMenu,
    container: Container = alwaysOn ? 'div' : HideOnScroll,
    ...rest
  } = props;
  //const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Container className={className}>
      <StyledAppBar
        className={AppBarClasses.appBar}
        color={color}
        {...rest}
      >
        <Toolbar
          disableGutters
          //variant={isXSmall ? 'regular' : 'dense'}
          variant="regular"
          className={AppBarClasses.toolbar}
        >
          <>
            <SidebarToggleButton className={AppBarClasses.menuButton} />
            {Children.count(children) === 0
              ? <TitlePortal className={AppBarClasses.title} />
              : children
            }
            {typeof rightMenu === 'boolean'
              ? rightMenu
                ? <DefaultUserMenu user={user} />
                : null
              : rightMenu
            }
          </>
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
});

export interface IAppBarProps extends Omit<MuiAppBarProps, 'title'> {
  alwaysOn?: boolean;
  user?: User;
  container?: React.ElementType<any>;

  //toolbar?: JSX.Element;
  rightMenu?: JSX.Element | boolean;
  backgroundColor?: string;
};

export const AppBarClasses = {
  appBar: `RaAppBar-appBar`,
  toolbar: `RaAppBar-toolbar`,
  menuButton: `RaAppBar-menuButton`,
  menuButtonIconClosed: `RaAppBar-menuButtonIconClosed`,
  menuButtonIconOpen: `RaAppBar-menuButtonIconOpen`,
  title: `RaAppBar-title`,
};

const StyledAppBar = styled(MuiAppBar, {
  name: 'RaAppBar',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${AppBarClasses.toolbar}`]: {
    padding: `0 ${theme.spacing(1)}`,
    color: DefaultAppBarForeColor,
    backgroundColor: DefaultAppBarBackColor,
    borderBottom: '1px solid black',
    [theme.breakpoints.down('md')]: {
      minHeight: theme.spacing(6),
    },
  },
  [`& .${AppBarClasses.menuButton}`]: {
    marginRight: '0.2em',
  },
  [`& .${AppBarClasses.title}`]: {},
}));