import React, { ReactNode, useState } from 'react';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { MessageService } from '../../../services';

interface IChatContextMenuProps {
  message: any;
  onUpdate: (message: any) => void;
  onDelete: (id: number) => void;
  onClose: () => void;
  children: ReactNode;
};

interface IChatContextMenuState {
  mouseX?: number;
  mouseY?: number;
};

export const ChatContextMenu = (props: IChatContextMenuProps) => {
  //console.log('ChatContextMenu props:', props);
  const { message, onUpdate, onDelete, onClose, children } = props;
  const [state, setState] = useState<IChatContextMenuState | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setState(state === null
      ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
      : null,
      // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
      // Other native context menus might behave different.
      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
    );
  };
  const handleClose = () => {
    setState(null);
    onClose && onClose();
  };
  const handleEdit = () => {
    /*
    MessageService.updateMessage(message).then((response: any) => {
      console.log('updateMessage response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to edit message, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      } else {
        onUpdate(message);
      }
      handleClose();
    });
    */
    onUpdate(message);
  };
  const handleDelete = () => {
    const result = window.confirm('Are you sure you want to delete this post?');
    if (!result) {
      return;
    }

    MessageService.deleteMessage(message.id).then((response: any) => {
      //console.log('deleteMessage response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to delete message, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      } else {
        onDelete(message.id);
      }
      handleClose();
    });
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
      {children}
      <Menu
        open={state !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={state !== null
          ? { top: state.mouseY ?? 0, left: state.mouseX ?? 0 }
          : undefined
        }
      >
        <MenuItem
          className="bg-dark text-light"
          title="Edit message"
          onClick={handleEdit}
        >
          <EditIcon />&nbsp;Edit
        </MenuItem>
        <MenuItem
          className="bg-dark text-light"
          title="Delete message"
          onClick={handleDelete}
        >
          <DeleteIcon />&nbsp;Delete
        </MenuItem>
      </Menu>
    </div>
  );
}