import React from 'react';
import classnames from 'classnames';
import { IconButton } from '@mui/material';

import '../../css/chat/index.css';

export interface ToolbarButtonProps {
  className?: string;
  badge?: string | number;
  blink?: boolean;
  icon: any;
  offIcon?: any;
  on?: boolean;
  title: string;
  size: any;
  onClick: (event: React.MouseEvent) => void;
};

export const ToolbarButton = (props: ToolbarButtonProps) => {
  //console.log('ToolbarButton props:', props);
  const {
    blink, on, title, badge, icon, offIcon, className, size = 'small',
    onClick,
  } = props;

  const Icon = !on && offIcon ? offIcon : icon;
  const classNames = classnames('button', className, { blink, on });

  return (
    <IconButton
      className={classNames}
      size={size}
      onClick={onClick}
    >
      <Icon className='icon' />
      {!!badge && <span className='badge'>{badge}</span>}
      <span className='tooltip'>{title}</span>
    </IconButton>
  );
};