import React, { useState } from 'react';
import { Fab } from '@mui/material';
import { Feedback as FeedbackIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { SendFeedbackDialog } from '../Dialogs';
import { UserService } from '../../services';
import { getUserToken } from '../../stores';
import { Feedback } from '../../types';

export const FloatingFeedbackButton = (props: any) => {
  //console.log('FloatingFeedbackButton props:', props);
  const { size = 'small' } = props;
  const currentUser = getUserToken();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSendFeedback = (feedback: Feedback) => {
    //console.log('handleSendFeedback:', feedback);
    if (feedback.text)

    UserService.sendFeedback(feedback).then((response: any) => {
      console.log('sendFeedback response:', response);
      setOpen(false);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to submit feedback, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      enqueueSnackbar(`Successfully sent, thank you for your feedback!`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    });
  };
  const handleCloseFeedback = () => setOpen(false);

  return currentUser && (
    <>
      <Fab
        color="primary"
        size={size}
        title="Send feedback"
        aria-label="send feedback"
        style={{
          margin: 0,
          position: 'fixed',
          top: 'auto',
          bottom: 7,
          left: 7,
          zIndex: 1200,
        }}
        onClick={(event: any) => {
          event.preventDefault();
          setOpen((prev: boolean) => !prev);
        }}
      >
        <FeedbackIcon />
      </Fab>

      {open && (
        <SendFeedbackDialog
          currentUser={currentUser}
          onSend={handleSendFeedback}
          onClose={handleCloseFeedback}
        />
      )}
    </>
  );
};