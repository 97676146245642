import React, { useState } from 'react'
import { Image as ImageIcon } from '@mui/icons-material';
import { DefaultProfileBannerUrl } from '../../consts';

const styles = {
  cover: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  loding: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    //position: 'relative',
    color: '#cacecd',
    fontWeight: 400,
  },
  loadingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingImage: {
    fill: 'aliceblue',
    width: '50px',
    height: '50px',
  },
};

export const ImageCover = (props: any) => {
  //console.log('ImageCover props:', props);
  const { fileName, style } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleLoadImage = () => setIsImageLoaded(true);

  const imgStyle = Object.assign({},
    { backgroundColor: 'blue' },
    isImageLoaded ? { display: 'none' } : styles.loding,
  );
  const bgStyle = Object.assign({}, styles.cover,
    {
      backgroundImage: `url(${fileName || DefaultProfileBannerUrl})`,
      width: props.width,
      height: props.height,
      borderRadius: props.borderRadius
    },
    style,
  );

  return (
    <>
      <div style={bgStyle}>{props.children}</div>
      <div style={imgStyle}>
        <div style={styles.loadingContent as any}>
          <ImageIcon style={styles.loadingImage} />
          <div>Image not loaded</div>
        </div>
      </div>
      <img
        onLoad={handleLoadImage}
        src={fileName || ''}
        style={{
          display: 'none',
          objectFit: 'cover',
        }}
        alt=""
      />
    </>
  );
};