import { CircularProgress } from '@mui/material';

export const LoadMoreProgress = (props: any) => {
  const { size = 30, thickness = 5 } = props;
  return (
    <div key='load-more-progress' style={{
      textAlign: 'center',
      paddingTop: '4px',
      backgroundColor: 'transparent',
      paddingBottom: '4px',
      width: '76px',
      margin: '3px auto 15px',
      borderRadius: '9px',
    }}>
      <CircularProgress
        size={size}
        thickness={thickness}
        style={{color: 'dodgerblue' }}
      />
    </div>
  );
};