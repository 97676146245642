import { PatternFormat } from 'react-number-format';

export const FormattedPhoneNumber = (props: any) => {
  const { value, defaultValue = '--' } = props;

  return value ? (
    <PatternFormat
      type="tel"
      format="# (###) ###-####"
      mask="_"
      value={value}
      allowEmptyFormatting={false}
      displayType="text"
    />
  ) : (
    defaultValue
  );
};