import Stories from 'react-insta-stories';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment';

import { StoriesFolder } from '../../consts';
import { getAvatarUrl, getDirName } from '../../modules';
import { StoryService } from '../../services';
import { getUserToken } from '../../stores';
import { Story } from '../../types';

const useStyles: any = (theme: Theme) => ({
  root: {
    //justifyContent: 'center',
    //width: '100%',
    //height: '100%',
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
  content: {
    justifyContent: 'center',
    width: '100%',
  },
});

const prepareMedia = (stories: Story[]) => {
  const images: any[] = [];
  if ((stories?.length ?? 0) === 0) {
    return images;
  }

  for (const story of stories) {
    images.push({
      url: `${StoriesFolder}${story.id}/${story.uri}`,
      header: {
        heading: story.user?.username,
        subheading: moment(story.createdAt).fromNow(),
        profileImage: getAvatarUrl(story.userId),
      },
      //styles: {
      //  objectFit: 'cover',
      //},
    });
  }
  return images;
};

export const UserStory = (props: any) => {
  //console.log('UserStory props:', props);
  const { stories, onStoryStart, onClose } = props;
  const currentUser = getUserToken();
  const theme = useTheme();
  const classes = useStyles(theme);
  const media = prepareMedia(stories);

  const handleStoryStart = async (currentIndex: number, data: any) => {
    //console.log('handleStoryStart:', currentIndex, data);
    const storyId = getDirName(data.url);
    if (!storyId) {
      console.error('failed to parse story id from data:', data);
      return;
    }
    onStoryStart(storyId);
  };

  const handleStoryEnd = async (currentIndex: number, data: any) => {
    //console.log('handleStoryEnd:', currentIndex, data);
    const storyId = getDirName(data.url);
    if (!storyId) {
      console.error('failed to parse story id from data:', data);
      return;
    }
    await StoryService.markRead(parseInt(storyId), currentUser?.id);
  };

  return (
    <Stories
      storyStyles={{
        storyContent: classes.root,
      }}
      storyContainerStyles={{width: '100%'}}
      storyInnerContainerStyles={{width: '100%'}}
      stories={media}
      defaultInterval={2000}
      width='100%'
      height='100%'
      onAllStoriesEnd={onClose}
      onStoryStart={handleStoryStart}
      onStoryEnd={handleStoryEnd}
    />
  );
};