import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';

import { UserAvatar } from '..';
import { ViewStoryDialog } from '../Dialogs';
import { getAvatarUrl } from '../../modules';
import { StoryService } from '../../services';
import { Story, StoryStatus } from '../../types';
import './UserStoryCircle.css';

const useStyles: any = (theme: Theme) => ({
  avatar: {
    borderRadius: 50,
    padding: 3,
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    boxShadow: '0px 5px 17px -7px rgba(0, 0, 0, 0.50)',
  },
  avatarBorder: {
    '--color-1': '#2378b3', //deepskyblue
    '--color-2': '#00ff00', //navy
    background: `
      linear-gradient(
        170deg,
        var(--color-1) 50%,
        var(--color-2)
      )
    `,
    boxShadow: '0px 5px 17px -7px rgba(255, 255, 255, 0.50)',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    //maxWidth: theme.breakpoints.down('xs') ? 125 : theme.breakpoints.down('sm') ? 150 : 350,
    maxWidth: 110,
    fontSize: 14,
    textAlign: 'center',
  },
  addIcon: {
    //border: '0.5px solid silver',
    borderRadius: 50,
    position: 'relative',
    color: '#1e90ff', //theme.palette.primary.main, //'#1e90ff', //#00cdef
    backgroundColor: '#1f1f1f',
    bottom: 40,
    left: 40,
    //margin: -25,
    padding: 0,
    overflow: 'hidden',
  },
});

interface IUserStoryCircleProps {
  currentUser: any;
  follower: any;
  showUsername?: boolean;
  showAddButton?: boolean;
  tooltip?: string;
  onAdd?: (event: any) => void;
};

interface IUserStoryCircleState {
  open: boolean;
  stories: Story[];
  showNewBorder: boolean;
};

export const UserStoryCircle = (props: IUserStoryCircleProps) => {
  //console.log('UserStoryCircle props:', props);
  const {
    currentUser, follower,
    showUsername = true,
    showAddButton = false,
    tooltip = 'Add to your story',
    onAdd,
  } = props;

  const [state, setState] = useState<IUserStoryCircleState>({
    open: false,
    stories: [],
    showNewBorder: false,
  });
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleGetStory = () => {
    StoryService.getStory(follower?.userId).then(async (response: any) => {
      //console.log('getStory response:', response);
      if ((response?.status ?? 500) !== 200) {
        return;
      }
      const stories = response.story;
      let showNewBorder = false;
      for (const story of stories) {
        //const result = await StoryService.hasSeenStory(story.id, currentUser?.id)
        const result = story.status.find((x: StoryStatus) => x.userId === currentUser?.id && !x.unread);
        if (!result) {
          showNewBorder = true;
        }
      }
      setState({...state, showNewBorder, stories}); //, open: false});
    });
  };
  
  const handleOpen = () => setState({...state, open: true});
  const handleClose = () => setState({...state, open: false});

  // eslint-disable-next-line
  useEffect(() => handleGetStory(), []);

  return (
    <div className="text-center">
      <div style={classes.ellipsis}>
        <div
          className="story"
          style={{
            ...classes.avatar,
            ...(state.showNewBorder ? classes.avatarBorder : {})
          }}
        >
          <UserAvatar
            fileName={getAvatarUrl(follower?.userId)}
            fullName={follower?.username}
            showBadge={true}
            size={84}
            onClick={handleOpen}
          />
        </div>
        {showAddButton && onAdd && (
          <IconButton
            title={tooltip}
            onClick={onAdd}
            style={classes.addIcon}
          >
            <AddCircleIcon />
          </IconButton>
        )}
        {showUsername && (<small>{follower?.username}</small>)}
      </div>

      <ViewStoryDialog
        open={state.open && state.stories.length > 0}
        onReload={() => handleGetStory()}
        onClose={handleClose}
        stories={state.stories}
        currentUser={currentUser}
      />
    </div>
  );
};