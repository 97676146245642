import React, { useEffect, useRef, useState } from 'react';
import { EmojiClickData } from 'emoji-picker-react';
import {
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import {
  EmojiEmotions as EmojiEmotionsIcon,
} from '@mui/icons-material';

import { EmojiPickerContainer, PostComment, UserAvatar } from '..';
import { createNotification, getAvatarUrl } from '../../modules';
import { CommentService } from '../../services';
import { Comment } from '../../types';
import './Comments.css';

export const Comments = (props: any) => {
  //console.log('comments props:', props);
  const { post, user } = props;
  const [state, setState] = useState({
    text: '',
    comments: [],
    error: null,
    isLoading: false,
    openEmojiPicker: false,
    anchorEl: null,
  });
  const btnEmojiRef = useRef(null);

  const handleReload = () => {
    CommentService.getComments(post.id).then((response: any) => {
      //console.log('getComments response:', response);
      setState({...state, comments: response.comments});
    });
  };

  const handleSendComment = async (event: any) => {
    event.preventDefault();
    const comment: Comment = {
      userId: user.id,
      postId: post.id,
      text: state.text,
      score: 0,
      likes: [],
      deleted: false,
    };
    CommentService.createComment(post.id, comment).then((response: any) => {
      //console.log('createComment response:', response);
      if (!response.success) {
        setState({...state, error: response.message});
        return;
      }
      const comments: any = [...state.comments, comment];
      setState({...state, text: '', comments});
      createNotification(user.id, post.userId, `${user?.username} commented on your post ${post.id}`);
    });
  };

  const handleEmojiClick = (emoji: EmojiClickData, event: MouseEvent) => setState({...state, text: `${state.text}${emoji.emoji}`});
  const handleEmojiClose = () => setState({...state, openEmojiPicker: false});

  const toggleEmojis = (event: any) => {
    setState({...state, openEmojiPicker: !state.openEmojiPicker, anchorEl: btnEmojiRef?.current});
  };

  useEffect(() => handleReload(), []);

  return (
    <div className="comments">
      {!!user && (
        <div className="write">
          <UserAvatar
            fullName={`${user?.username}`}
            fileName={getAvatarUrl(user?.id)}
            size={42}
            style={{border: '1px solid black'}}
          />
          <TextField
            //variant="contained"
            fullWidth
            multiline
            size="small"
            placeholder="Add a comment..."
            value={state.text}
            onChange={(event: any) => setState({...state, text: event.target.value})}
            InputProps={{endAdornment: (
              <IconButton
                ref={btnEmojiRef}
                size="small"
                onClick={toggleEmojis}
                title="Add an emoji expression"
              >
                <EmojiEmotionsIcon htmlColor="goldenrod" />
              </IconButton>
            )}}
          />
  
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendComment}
          >
            Send
          </Button>
        </div>
      )}
      <EmojiPickerContainer
        onClose={handleEmojiClose}
        onEmojiClick={handleEmojiClick}
        anchorEl={state.anchorEl}
        openEmojiPicker={state.openEmojiPicker}
      />
      {state.error ? 'Something went wrong' : state.isLoading
        ? 'Loading'
        : state.comments.map((comment: any) => (
          <PostComment
            key={comment.id}
            comment={comment}
            user={user}
            onReload={handleReload}
          />
      ))}
    </div>
  );
};