import * as uuid from 'uuid';
import moment from 'moment';

import {
  AvatarsFolder,
  BannersFolder,
  CarsFolder,
  SoundsFolder,
} from '../consts';
import { NotificationService } from '../services';
import {
  Base64File,
  Car,
  PostImage,
  VideoCallMessage,
} from '../types';

export const buildCarName = (car: Car) => {
  if (!car) {
    return '';
  }
  let name = `${car.year} ${car.manufacturer} ${car.model}`;
  if (car.trim) {
    name += ` ${car.trim}`;
  }
  return name;
};

export const createImage = async (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export const getCroppedImg = async (imageSrc: any, pixelCrop: any, rotation: number = 0) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  // As a blob
  /*
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
  */
};

export const sortByKey = (a: any, b: any, key: any) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const sortBy = <T>(a: T, b: T) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const getExtension = (fileName: string) => {
  let re: RegExp = /(?:\.([^.]+))?$/;
  return re.exec(fileName)![1];
};

export const getFileName = (path: string) => {
  return path.split('/').pop();
};

export const getDirName = (path: string) => {
  const parts = path.split('/');
  if (parts.length > 2) {
    return parts[parts.length - 2];
  }
  return parts.length === 0 ? path : parts.pop();
};

export const fileToDataUrl = (file: File): Promise<PostImage> => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const ext = getExtension(file.name);
        const fileName = `${uuid.v4()}.${ext}`;
        const image: PostImage = {
          id: fileName,
          url: reader.result?.toString() ?? '',
          file,
        };
        resolve(image);
      };
      reader.onerror = () => reject(reader);
      reader.readAsDataURL(file);
  });
};

export const getAvatarUrl = (userId: number) => {
  const url = `${AvatarsFolder}${userId}.jpg`;
  return url;
};

export const getBannerUrl = (userId: number) => {
  const url = `${BannersFolder}${userId}.jpg`;
  return url;
};

export const inflateFlatArray = (obj: any) => {
  if (!obj || typeof obj !== 'string') {
    return obj;
  }
  const array = JSON.parse(obj ?? '[]') ?? [];
  return array;
};

export const isActive = (path: string) => {
  const pathname = window.location.pathname;
  const result = path === pathname;
  return result;
};

export const isAdmin = (roles: string[]) => roles?.includes('ROLE_ADMIN');

export const pluralize = (length: number) => length === 1 ? '' : 's';

export const getCarImageUrl = (carId: number) => {
  const url = `${CarsFolder}${carId}/default.jpg`;
  return url;
};

export const manufacturers = [
  'Abarth', 'AC', 'Acura', 'Alfa-Romeo', 'Alpina', 'Aston Martin', 'Audi',
  'BMW', 'Bentley', 'Buick',
  'Cadilac', 'Chevrolet', 'Chrysler', 'Custom',
  'Daewoo', 'Daihatsu', 'Dodge',
  'Eagle',
  'Ferrari', 'Fiat', 'Fisker', 'Ford', 'Freighliner',
  'GMC', 'Genesis', 'Geo',
  'Honda', 'Hummer', 'Hyundai',
  'Infinity', 'Isuzu',
  'Jaguar', 'Jeep',
  'Kla',
  'Lamborghini', 'Land Rover', 'Lexus', 'Lincoln', 'Lotus',
  'Mazda', 'Maserati', 'Maybach', 'McLaren', 'Mercedez-Benz', 'Mercury', 'Mini', 'Mitsubishi',
  'Nissan',
  'Oldsmobile',
  'Panoz', 'Plymouth', 'Polestar', 'Pontiac', 'Porsche',
  'Ram', 'Rivian', 'Rolls Royce',
  'Saab', 'Saturn', 'Smart', 'Subaru', 'Susuki',
  'Tesla', 'Toyota',
  'Volkswagen', 'Volvo',
];

export const years = () => {
  const years = [];
  for (let i = 2025; i >= 1920; --i) {
    years.push(i);
  }
  return years;
};

export const getUserTags = (text: string) => {
  const result = text?.match(/(@[^\s]+)/g); //^.(.*)$
  return result;
};

const FacebookRegex = /(?:https?:\/\/)?(?:www.)?(?:facebook|fb)(?:.(com|me)\/)?([@a-zA-Z0-9-_]+.*)?(.+ )/g;
//const TwitterRegex = /(?:https?:\/\/)?(?:www.)?(?:twitter|medium|facebook|fb|vimeo|instagram)(?:.(com|me)\/)?([@a-zA-Z0-9-_]+.*)?(.+ )/g;
const TwitterRegex = /(?:https?:\/\/)?(?:www.)?(?:twitter)(?:.(com|me)\/)?([@a-zA-Z0-9-_]+.*)?(.+ )/g;
const InstagramRegex = /(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_]+.*)?(.+ )/g;
const TikTokRegex = /(?:https?:\/\/)?(?:www.)?(?:tiktok)(?:.com\/)?([@a-zA-Z0-9-_]+.*)?(.+ )/g;

export const hasFacebookUrl = (text: string) => {
  const result = (text?.match(FacebookRegex)?.length ?? 0) > 0;
  return result;
};

export const getFacebookUrls = (text: string) => {
  const result = text.match(FacebookRegex) ?? [];
  return result.map((url: string) => url.trim());
};

export const hasTwitterUrl = (text: string) => {
  const result = (text?.match(TwitterRegex)?.length ?? 0) > 0;
  return result;
};

export const getTwitterUrls = (text: string) => {
  const result = text.match(TwitterRegex) ?? [];
  return result.map((url: string) => url.trim());
};

export const hasInstagramUrl = (text: string) => {
  const result = (text?.match(InstagramRegex)?.length ?? 0) > 0;
  return result;
};

export const getInstagramUrls = (text: string) => {
  const result = text.match(InstagramRegex) ?? [];
  return result.map((url: string) => url.trim());
};

export const hasTikTokUrl = (text: string) => {
  const result = (text?.match(TikTokRegex)?.length ?? 0) > 0;
  return result;
};

export const getTikTokUrls = (text: string) => {
  const result = text.match(TikTokRegex) ?? [];
  return result.map((url: string) => url.trim());
};

export const substr = (text: string, maxChars: number = 15, addEllipsis: boolean = true) => {
  if (text.length <= maxChars) {
    return text;
  }
  const result = text.substring(0, Math.min(text.length, maxChars));
  return addEllipsis ? result + '...' : result;
};

export const createNotification = (senderUserId: number, receiverUserId: number, message: string) => {
  NotificationService.createNotification({
    senderUserId,
    receiverUserId,
    text: message,
    unread: true,
    deleted: false,
  }).then((response: any) => {
    console.log('createNotification response:', response);
  }).catch((err: any) => {
    console.error('createNotification error:', err);
  });
};

export const playNotificationSound = (fileName: string = 'notification.mp3', duration: number = 0) => {
  const path = SoundsFolder + fileName;
  const audio = new Audio(path);
  audio.play();

  if (duration > 0) {
    setTimeout(() => audio.pause(), duration);
  }
};

export const isOnline = (lastOnlineDate: Date, thresholdM: number = 15) => {
  const now = moment().unix();
  const last = moment(lastOnlineDate).unix();
  const diff = Math.round(now - last);
  const isOnline = diff <= thresholdM * 60;
  return isOnline;
};

export const rndNum = (value: any, precision: number = 4) => {
  return parseFloat(value).toFixed(precision);
};

export const isTouchEnabled = () =>
  ('ontouchstart' in window ) ||
  (navigator.maxTouchPoints > 0);// ||
  //(navigator.msMaxTouchPoints > 0);

export const downloadData = (data: any, fileName: string, mimeType: 'video/webm') => {
  const blob = new Blob(data, { type: mimeType });
  const url = URL.createObjectURL(blob);
  createDownloadLink(url, fileName);
};

export const createDownloadLink = (url: string, fileName: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const generateId = () => Math.floor(Math.random() * 2 ** 18).toString(36).padStart(4);

export const formatFileSize = (bytes: number, si: boolean = false, dp: number = 1) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const r = 10**dp;
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const elipses = (text: string, maxLength: number = 25, includeElipses: boolean = true) => {
  if (text.length <= maxLength) {
    return text;
  }
  let newText = text.substring(0, Math.min(maxLength, text.length));
  if (includeElipses) {
    newText += '...';
  }
  return newText;
};

export const packageFileMessage = async (message: VideoCallMessage) => {
  const files: Base64File[] = [];
  for (const file of message.files!) {
    const { name, size, type } = file;
    const reader = new window.FileReader();
    const base64File = await new Promise<Base64File>(resolve => {
      reader.addEventListener('load', () => {
        resolve({
          name,
          size,
          type,
          data: reader.result as string,
        });
      });
      reader.readAsDataURL(file);
    });
    files.push(base64File);
  }
  return files;
};