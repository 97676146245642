import React, { useEffect, useState } from 'react';

export const useAudio = (url: string, waitTimeout: number = 15) => {
  const [audio] = useState(new Audio(url));
  // TODO: audio.volume
  const [playing, setPlaying] = useState(false);

  const start = () => {
    setPlaying(true);
    audio.play();
  };
  const stop = () => {
    //setPlaying(false);
    audio.pause();
  };
  const toggle = () => setPlaying(prev => !prev);

  useEffect(() => {
    //playing ? audio.play() : audio.pause();
    if (playing && waitTimeout > 0) {
      setTimeout(() => {
        audio.pause();
        setPlaying(false);
      }, waitTimeout * 1000);
    }
  }, [playing, audio, waitTimeout]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return { playing, start, stop, toggle };
};