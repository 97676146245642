import React, { MouseEvent, useState } from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreVertIcon,
  Visibility as ViewIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { Routes } from '../../consts';
import { CardWithIcon, CarSpecificationsList, ExpandMore } from '..';
import { EditCarDetailsDialog, ViewCarDetailsDialog } from '../Dialogs';
import { buildCarName, getCarImageUrl } from '../../modules';
import { CarService } from '../../services';

export const CarGarageModel = (props: any) => {
  //console.log('CarGarageModel props:', props);
  const { car, editMode = true, onReload } = props;
  const [state, setState] = useState<any>({
    expanded: false,
    openViewDialog: false,
    openEditDialog: false,
    anchorEl: null,
    title: buildCarName(car),
  });
  const open = Boolean(state.anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const onMouseHover = (e: any) => e.target.style.cursor = 'pointer';
  const handleExpandClick = () => setState({...state, expanded: !state.expanded})

  const handleOpenEditDialog = () => setState({...state, openEditDialog: true, anchorEl: null})
  const handleCloseEditDialog = () => setState({...state, openEditDialog: false, anchorEl: null})

  const handleOpenViewDialog = () => setState({...state, openViewDialog: true, anchorEl: null})
  const handleCloseViewDialog = () => setState({...state, openViewDialog: false, anchorEl: null})

  const handleDelete = () => {
    const result = window.confirm(`You are about to delete your car '${state.title}' aka '${car.name}', this will remove it from your garage. Are you sure that you want to do this?`);
    if (!result) {
      return;
    }
    CarService.deleteCar(car.id).then((response: any) => {
      console.log('deleteCar response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to delete car ${car.name ?? state.title}, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }

      enqueueSnackbar(`Successfully deleted car ${car.name ?? state.title}`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      onReload && onReload();
    });
  }

  const handleOpenMoreMenu = (event: MouseEvent<HTMLButtonElement>) =>
    setState({...state, anchorEl: event.currentTarget});
  const handleCloseMoreMenu = () => setState({...state, anchorEl: null});

  return (
    <Paper
      style={{
        backgroundColor: '#181818',
        borderRadius: 20,
        border: '1px solid black',
        minWidth: '350px',
      }}
    >
      <Card
        style={{width: '100%', color: 'grey', borderRadius: 20}}
      >
        <CardHeader
          /*avatar={
            <Avatar sx={{ bgcolor: car.color, border: "1px solid #000", color: 'white' }} aria-label="Car">
              {car.model[0]}
            </Avatar>
          }*/
          action={editMode ?
            <>
              <IconButton onClick={handleOpenMoreMenu}>
                <MoreVertIcon />
              </IconButton>
              <CarMenu
                open={open}
                anchorEl={state.anchorEl}
                onClose={handleCloseMoreMenu}
                onView={handleOpenViewDialog}
                onEdit={handleOpenEditDialog}
                onDelete={handleDelete}
              />
            </> : <></>
          }
          onMouseEnter={onMouseHover}
          title={
            <CardWithIcon
              icon={
                <Avatar
                  alt={car?.name ?? state.title}
                  sx={{ bgcolor: car.color, border: "1px solid #000", color: 'white' }}
                  aria-label={car?.name ?? state.title}
                >
                  {car.model[0]}
                </Avatar>
              }
              title={state.title}
              subtitle={car.name}
              //to={Routes + car.id}
              //onClick={handleOpenEditDialog}
            />
          }
          style={{padding: 0, margin: 0}}
          subheader={
            <div className="row small">
              <div className="col col-md-6 col-sm-12">
                <div className="float-start text-light">
                  <span style={{color: 'silver'}}>@{car.user?.username}</span>
                </div>
              </div>
              <div className="col col-md-6 col-sm-12">
                <div className="float-md-end float-sm-start text-light">
                  <small>{car.user?.firstName} {car.user?.lastName}</small>
                </div>
              </div>
            </div>
          }
          subheaderTypographyProps={{
            border: 'none',
          }}
        />
        <CardMedia
          component="img"
          height="256"
          onMouseEnter={onMouseHover}
          src={getCarImageUrl(car.id)}
          alt="Default image"
          style={{
            objectFit: 'cover',
          }}
        />
        <CardActions disableSpacing>
          <ExpandMore
            expand={state.expanded}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
            aria-label="Show more"
            title="Show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={state.expanded} timeout="auto" unmountOnExit>
          <CardContent className="text-center mt-0 pt-0">
            {car?.specs && (
              <>
                <strong>Specifications</strong>
                <CarSpecificationsList
                  specs={car?.specs}
                  carId={car?.id}
                  showImage={false}
                />
              </>
            )}
            {car?.mods && ( // TODO: Car modifications list
              <>
                <strong>Modifications</strong>
              </>
            )}
          </CardContent>
        </Collapse>
      </Card>

      <ViewCarDetailsDialog
        car={car}
        open={state.openViewDialog}
        onClose={handleCloseViewDialog}
      />

      <EditCarDetailsDialog
        car={car}
        open={state.openEditDialog}
        onClose={handleCloseEditDialog}
      />
    </Paper>
  );
};

const CarMenu = (props: any) => {
  const { open, anchorEl, onClose, onView, onEdit, onDelete } = props;
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem onClick={onView} className="bg-dark text-light">
        <ListItemIcon>
          <ViewIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>View</ListItemText>
      </MenuItem>
      <MenuItem onClick={onEdit} className="bg-dark text-light">
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      <MenuItem onClick={onDelete} className="bg-dark text-light">
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
};