import http from '../modules/http-common';
import { CalendarEvent } from '../types';

const getEvent = async (eventId: number) => {
  const response = await http
    .get('calendar/events/' + eventId);
  return response.data;
};

const getEvents = async () => {
  const response = await http
    .get('calendar/events');
  return response.data;
};

const createEvent = async (event: CalendarEvent) => {
  const response = await http
    .post('calendar/events/create', { event });
  return response.data;
};

const updateEvent = async (event: CalendarEvent) => {
  const response = await http
     .put(`calendar/events/${event.id}/update`, { event });
  return response.data;
};

const deleteEvent = async (eventId: number) => {
  const response = await http
    .delete(`calendar/events/${eventId}/delete`);
  return response.data;
};

export const CalendarService = {
  getEvent,
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
};