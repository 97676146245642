import React, { ReactElement, ReactNode } from 'react';
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useSidebarState } from '.';

export interface ISubMenuProps {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
};

export const SubMenu = (props: ISubMenuProps) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props;
  const [sidebarIsOpen] = useSidebarState();

  const style = {
    backgroundColor: '#414141',
    //color: 'dodgerblue',
  };

  const header = (
    <MenuItem dense={dense} onClick={handleToggle} sx={style}>
      <ListItemIcon sx={{ minWidth: 5, marginLeft: -0.3, marginRight: 0.50 }}>
        {isOpen ? <ExpandMoreIcon /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <div>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          sx={{
            '& a': {
              transition:
                'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              paddingLeft: sidebarIsOpen ? 4 : 2,
              ...style,
            },
          }}
        >
          {children}
        </List>
      </Collapse>
    </div>
  );
};