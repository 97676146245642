import React, { useRef, useState } from 'react';
import env from 'react-dotenv';
import { Link } from 'react-router-dom';
import {
  FacebookEmbed,
  InstagramEmbed,
  TikTokEmbed,
  TwitterEmbed,
} from 'react-social-media-embed';
import { Grid } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  ContentCopy as RepostIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  ShareOutlined as ShareOutlinedIcon,
  Textsms as TextsmsIcon,
  TextsmsOutlined as TextsmsOutlinedIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import moment from 'moment';

import {
  Comments,
  CreateUserTagComponent,
  IconButtonWithTooltip,
  ImageGallerySwiper,
  LikeComponent,
  NewPost,
  ReportUserButton,
  UserAvatar,
} from '..';
import { EditPostDetailsDialog, ShareDialog, ViewPostDetailsDialog } from '../Dialogs';
import { useIsMobile, useResize } from '../../hooks';
import {
  createNotification,
  getAvatarUrl,
  getFacebookUrls, getInstagramUrls, getTwitterUrls, getTikTokUrls,
  hasFacebookUrl, hasInstagramUrl, hasTwitterUrl, hasTikTokUrl,
  getUserTags,
  inflateFlatArray,
  pluralize,
} from '../../modules';
import { PostService } from '../../services';
import { Post, User } from '../../types';

interface IPostProps {
  post: any; //Post;
  currentUser: User;
  editMode: boolean;
  openComments: boolean;
  showImages?: boolean;
  onReload?: () => void;
};

const useStyles: any = (theme: Theme) => ({
  root: {
    //-webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    //-moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    /*box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);*/
    boxShadow: '0px 0px 25px -10px rgba(0, 0, 0, 0.38)',
    borderRadius: '20px',
    border: '1px solid black',
  },
  container: {
    padding: '20px',
    width: '100%',
    cursor: 'pointer',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'cover',
    margin: 'auto 10px auto 10px',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  userInfo: {
    display: 'flex',
    width: '100%',
  },
  userInfoDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    marginTop: '8px',
  },
  fullName: {
    fontWeight: 800,
    fontSize: '16px',
    color: 'white',
  },
  username: {
    fontWeight: 500,
    fontSize: '14px',
    color: 'silver',
  },
  date: {
    flex: 1,
    alignSelf: 'center',
    color: 'darkgray',
    fontSize: '10px',
  },
  content: {
    margin: '10px',
    wordWrap: 'break-word',
  },
  contentImage: {
    width: '100%',
    maxHeight: '500px',
    objectFit: 'cover',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '14px',
  },
  clampWidth: {
    maxWidth: 'calc(100vw - 10em)',
  },
});

export const Post2 = (props: IPostProps) => {
  //console.log('Post2 props:', props);
  const { post, currentUser, editMode = false, openComments = false, showImages = true, onReload } = props;
  const [state, setState] = useState({
    commentOpen: openComments,
    openMenu: false,
    isLoading: false,
    openShareDialog: false,
    openCopyLink: false,
    openViewPostDialog: false,
    openEditPostDialog: false,
  });

  const postRef: any = useRef();
  const { width } = useResize(postRef);
  const isMobile = useIsMobile();
  const isCurrentUser = post?.user?.id === currentUser?.id;
  const likes = inflateFlatArray(post.likes ?? '[]');
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleLikePost = () => {
    PostService.likePost(post.id, currentUser?.id).then((response: any) => {
      //console.log('likePost response:', response);
      onReload && onReload();
      createNotification(currentUser?.id, post.userId, `${currentUser?.username} liked your post ${post.id}`);
    });
  };

  const handleDeletePost = () => {
    const result = window.confirm('Are you sure you want to delete your post?');
    if (!result) {
      return;
    }
    PostService.deletePost(post.id).then((response: any) => {
      //console.log('deletePost response:', response);
      onReload && onReload();
    });
  };

  const handleOpenViewPost = () => {
    //navigate(`/posts/${post.id}`);
    const isOpen = 
      state.openViewPostDialog ||
      state.openEditPostDialog ||
      (state.openViewPostDialog && editMode) ||
      (state.openEditPostDialog && editMode);
    // TODO: console.log('isOpen:', isOpen);
    if (isOpen) {
      return;
    }

    setState({...state, openViewPostDialog: true});
  };
  const handleCloseViewPost = () => setState({...state, openViewPostDialog: false});

  const handleOpenEditPost = () => setState({...state, openEditPostDialog: true});
  const handleCloseEditPost = () => setState({...state, openEditPostDialog: false});

  const handleOpenShare = () => setState({ ...state, openShareDialog: true });
  const handleCloseShare = () => setState({ ...state, openShareDialog: false, openCopyLink: false });
  const handleCopyLink = () => {
    const url = `${env.SITE_BASE_URL}/posts/${post.id}`;
    window.navigator.clipboard.writeText(url);
    setState({ ...state, openCopyLink: true });
  };
  const handleOpenComments = () => !openComments && setState({ ...state, commentOpen: !state.commentOpen });

  const handleRepost = () => {
  };

  return (
    <div style={classes.root} ref={postRef}>
      <div style={classes.container}>
        <div style={classes.user}>
          <div
            title="Click or tap to view post"
            style={classes.userInfo}
            onClick={handleOpenViewPost}
          >
            <UserAvatar
              fullName={post.user?.username}
              fileName={getAvatarUrl(post.userId)}
              size={36}
              style={classes.avatar}
            />
            <div style={classes.userInfoDetails}>
              <Link
                to={`/${post.user?.username}`}
                className="text-nowrap instagram-tag"
              >
                {post.user?.firstName && (<><span style={classes.fullName} color="primary">{post.user?.firstName} {post.user?.lastName}</span><br /></>)}
                <span style={classes.username} color="secondary">@{post.user?.username}</span><br />
                <span style={classes.date} title={moment(post.createdAt).toString()}>
                  {moment(post.createdAt).fromNow()}
                </span>
              </Link>
            </div>
          </div>
          {editMode ? (
            <IconButtonWithTooltip
              title="Delete post"
              onClick={handleDeletePost}
              icon={<DeleteIcon style={{justifyContent: 'end'}} />}
            />
          ) : (
            <MoreHorizIcon
              onClick={() => setState({ ...state, openMenu: !state.openMenu })}
              //style={{visibility: post.userId === userId ? 'visible' : 'hidden'}}
              style={{justifyContent: 'end'}}
            />
          )}
          {state.openMenu && (
            <Grid container spacing={0} style={{justifyContent: 'end'}}>
              {(!openComments && (
                <Grid item>
                  <IconButtonWithTooltip
                    title="View post"
                    onClick={handleOpenViewPost}
                    icon={<VisibilityIcon />}
                  />
                </Grid>
              ))}
              {post.userId === currentUser?.id && !editMode && (
                <>
                  <Grid item>
                    <IconButtonWithTooltip
                      title="Edit post"
                      onClick={handleOpenEditPost}
                      icon={<EditIcon />}
                    />
                  </Grid>
                  <Grid item>
                    <IconButtonWithTooltip
                      title="Delete post"
                      onClick={handleDeletePost}
                      icon={<DeleteIcon />}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </div>

        <div
          title="Click or tap to view post"
          style={classes.content}
          onClick={handleOpenViewPost}
        >
          {buildPostBody(post, showImages, width, classes)}
          {editMode && (
            <NewPost
              user={currentUser}
              onReload={onReload}
              editModePost={post}
            />
          )}
        </div>

        {!editMode && (
          <div style={classes.info}>
            <div style={classes.item}>
              <LikeComponent
                currentUser={currentUser}
                likes={likes}
                tooltip={likes.includes(currentUser?.id) ? 'Unlike post' : 'Like post'}
                onClick={handleLikePost}
              />
            </div>  
            {!post.disableComments && (
              <div style={classes.item}>
                <IconButtonWithTooltip
                  title={state.commentOpen ? 'Hide comments' : 'View comments'}
                  onClick={handleOpenComments}
                  icon={state.isLoading
                    ? 'Loading'
                    : state.commentOpen
                      ? <TextsmsIcon />
                      : <TextsmsOutlinedIcon />
                  }
                />
                {post.comments?.length > 0 ? (
                  `${post.comments?.length ?? 0} ${isMobile ? '' : `Comment${pluralize(post.comments?.length)}`}`
                ) : ''}
              </div>
            )}
            {!post.disableSharing && (
              <div style={classes.item}>
                <IconButtonWithTooltip
                  title={'Share post'}
                  onClick={handleOpenShare}
                  icon={<ShareOutlinedIcon />}
                  disabled={!currentUser}
                />
              </div>
            )}
            <div style={classes.item}>
              <IconButtonWithTooltip
                title={'Repost post'}
                onClick={handleRepost}
                icon={<RepostIcon style={{color: 'grey'}} />}
                disabled={true} // TODO: Add repost post support
              />
            </div>
            <div style={classes.item}>
              <ReportUserButton
                isCurrentUser={isCurrentUser}
                isDisabled={!currentUser}
                currentUser={currentUser}
                profile={post.user}
                onReload={onReload}
              />
            </div>
          </div>
        )}
        {!post.disableComments && state.commentOpen && !editMode && (
          <Comments
            post={post}
            user={currentUser}
          />
        )}
      </div>
      {!post.disableSharing && (
        <ShareDialog
          onClose={handleCloseShare}
          shareOpen={state.openShareDialog}
          onCopyLink={handleCopyLink}
          openCopyLink={state.openCopyLink}
          link={`${env.SITE_BASE_URL}/posts/${post.id}`}
          text={post.body}
          //tags={[]}
        />
      )}
      <ViewPostDetailsDialog
        post={post}
        currentUser={currentUser}
        open={state.openViewPostDialog}
        onClose={handleCloseViewPost}
        fullScreen={true}
      />

      <EditPostDetailsDialog
        post={post}
        currentUser={currentUser}
        open={state.openEditPostDialog}
        onClose={handleCloseEditPost}
        fullScreen={true}
      />
    </div>
  );
};

const buildPostBody = (post: Post, showImages: boolean = true, width: number, classes: any) => {
  return (
    <>
      {hasFacebookUrl(post.body) && createFacebookEmbeds({ urls: getFacebookUrls(post.body), width: width > 550 ? 350 : 250 })}
      {hasTwitterUrl(post.body) && createTwitterEmbeds({ urls: getTwitterUrls(post.body), width: width > 325 ? 325 : 250 })}
      {hasInstagramUrl(post.body) && createInstagramEmbeds({ urls: getInstagramUrls(post.body), width: width > 328 ? 328 : 250 })}
      {hasTikTokUrl(post.body) && createTikTokEmbeds({ urls: getTikTokUrls(post.body), width: width > 325 ? 325 : 250 })}
      {parseUserTags(post.body)}
      {showImages && (post.images?.length ?? 0) > 0 && (
        <div style={classes.clampWidth}>
          <ImageGallerySwiper images={post.images} />
        </div>
      )}
    </>
  );
};

const parseUserTags = (text: string) => {
  const userTags = getUserTags(text) ?? [];
  if (userTags?.length === 0) {
    return text;
  }
  return (<CreateUserTagComponent body={text} userTags={userTags} />);
};

const createTwitterEmbeds = (props: any) => {
  const { urls, width = 325 } = props;
  return (
    <>
      {urls && urls.map((url: string) => (
        <div key={url} style={{ display: 'flex' }}>
          <TwitterEmbed url={url} width={width} />
        </div>
      ))}
    </>
  );
};

const createFacebookEmbeds = (props: any) => {
  const { urls, width = 550 } = props;
  return (
    <>
      {urls && urls.map((url: string) => (
        <div key={url} style={{ display: 'flex' }}>
          <FacebookEmbed url={url} width={width} />
        </div>
      ))}
    </>
  );
};

const createInstagramEmbeds = (props: any) => {
  const { urls, width = 328 } = props;
  return (
    <>
      {urls && urls.map((url: string) => (
        <div key={url} style={{ display: 'flex' }}>
          <InstagramEmbed url={url} width={width} />
        </div>
      ))}
    </>
  );
};

const createTikTokEmbeds = (props: any) => {
  const { urls, width = 325 } = props;
  return (
    <>
      {urls && urls.map((url: string) => (
        <div key={url} style={{ display: 'flex' }}>
          <TikTokEmbed url={url} width={width} />
        </div>
      ))}
    </>
  );
};