import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Theme, useTheme } from '@mui/material/styles';
import { format, differenceInMinutes } from 'date-fns';

import { CalendarContext } from '.';

const useStyles: any = ((theme: Theme, isSmall: boolean = false) => ({
  headerContainer: {
    height: 100,
    '&:after': {
      content: "''",
      position: 'absolute',
      top: 165,
      left: 300,
      height: 1,
      width: 'calc(100% + 145px)',
      borderTop: '1px solid grey',
    },
  },
  headerFirstColumn: {
    height: 15,
    marginTop: 85,
    paddingLeft: 8,
    borderRight: '1px solid grey',
  },
  headerColumn: {
    borderRight: '1px solid grey',
    position: 'relative',
    paddingRight: 12,
    flex: '1 1 auto',
    height: 15,
    marginTop: 85,
  },
  headerLabelsFirst: {
    position: 'absolute',
    top: -75,
    left: -1,
    height: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: isSmall ? 9 : 11,
    color: '#70757a',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  headerLabelsSecond: {
    position: 'absolute',
    top: -55,
    left: -1,
    height: 45,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    color: '#70757a',
  },
  headerLabelsThird: {
    position: 'absolute',
    top: -7,
    left: -1,
    height: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
  },
  headerLabelToday: {
    width: isSmall ? 32 : 45,
    height: isSmall ? 32 : 45,
    lineHeight: isSmall ? '32px' : '45px',
    fontSize: isSmall ? 14 : 24,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: '1px solid grey',
    borderRadius: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      //borderColor: theme.palette.primary[800],
      //backgroundColor: theme.palette.primary[800],
    },
  },
  headerLabelNotToday: {
    width: isSmall ? 32 : 45,
    height: isSmall ? 32 : 45,
    lineHeight: isSmall ? '32px' : '45px',
    fontSize: isSmall ? 14 : 24,
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    border: '1px solid',
    borderRadius: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  timeColumnContainer: {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
    height: 'auto',
    overflowY: 'hidden',
    flex: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    minWidth: 45,
    maxWidth: 45,
    marginTop: -8,
  },
  timeColumn: {
    position: 'relative',
    WebkitBoxSizing: 'border-box',
    marginLeft: 'auto',
  },
  timeColumnElement: {
    position: 'relative',
    height: 60,
    paddingRight: 8,
  },
  board: {
    minWidth: '100%',
    height: '100%',
    flex: 'none',
    verticalAlign: 'top',
    overflow: 'hidden',
    position: 'relative',
    borderBottom: '1px solid grey',
  },
}));

export const CalendarHeader = (props: any) => {
  //console.log('CalendarHeader props:', props);
  const { selectedWeekIndex, selectedWeek } = props;

  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale, layout, defaultEventDuration } = stateCalendar;
  const [currentTimePosition, setCurrentTimePosition] = useState();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme, isSmall);

  useEffect(() => {
    setInterval(() => {
      const now = new Date();
      const initTime = new Date(format(now, 'yyyy/MM/dd 0:0:0'));
      const position: any = differenceInMinutes(now, initTime);
      setCurrentTimePosition(position);
    }, 1000);
  }, []);

  return useMemo(() => {
    const viewLayout = Array.from(Array(layout === 'week' ? 7 : layout === 'day' ? 1 : 0).keys());

    const handleDayClick = (event: any) => {
      const gridParent = event.target.parentElement.parentElement;
      setStateCalendar({
        ...stateCalendar,
        layout: 'day',
        selectedDate: new Date(gridParent.dataset.day),
      });
      // handleOpenCloseDialog();
    };

    return (
      <Grid
        container
        spacing={0}
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        style={classes.headerContainer}
      >
        <Grid item xs={1} style={{...classes.timeColumnContainer, ...classes.timeColumn}}>
          <div style={classes.timeColumnElement} />
        </Grid>

        <Grid item xs>
          <Grid
            container
            spacing={0}
            direction='row'
            justifyContent='center'
            alignItems='flex-start'
            style={classes.board}
          >
            <div style={classes.headerFirstColumn} />
            {viewLayout.map(index => {
              const day = layout === 'week' ? selectedWeek[index] : selectedDate;
              const isToday = format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy');

              return (
                <Grid
                  item
                  xs
                  id={`header-day-${index}`}
                  data-day={day}
                  style={classes.headerColumn}
                  key={`header-label-${layout}-${selectedWeekIndex}-${day}`}
                >
                  <div style={classes.headerLabelsFirst}>
                    <span>{format(day, 'eee', { locale })}</span>
                  </div>
                  <div style={classes.headerLabelsSecond}>
                    <span
                      onClick={handleDayClick}
                      style={(isToday ? classes.headerLabelToday : classes.headerLabelNotToday)}
                    >
                      {format(day, 'd', { locale })}
                    </span>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    classes,
    currentTimePosition,
    selectedDate,
    locale,
    layout,
    defaultEventDuration,
    selectedWeek,
    selectedWeekIndex,
    stateCalendar,
    setStateCalendar,
  ]);
};