import {
  Box,
  Drawer,
  IconButton,
  styled,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }: any) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

export const ChatProfileDetails = (props: any) => {
  //console.log('ChatProfileDetails props:', props);
  const { open, profile, profileDetails, onClose, children } = props;
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  
  return (
    <Box sx={{ display: 'flex' }}>
      <Main open={!(!open || isLarge)}>
        {children}
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div style={{marginTop: '60px'}}>
          <IconButton
            title={`Close ${profile?.username}'s profile details panel`}
            onClick={onClose}
            style={{marginRight: 'auto'}}>
            <ChevronRightIcon />
          </IconButton>
          <Box sx={{ display: 'flex' }}>
            {profileDetails}
          </Box>
        </div>
      </Drawer>
    </Box>
  );
};