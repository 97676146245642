import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import format from 'date-fns/format';

import { CalendarContext, EventMark } from '.';
import { CalendarService } from '../../services';
import { CalendarEvent } from '../../types';

const useStyles: any = ((theme: Theme, isSmall: boolean = false) => ({
  title: {
    textTransform: 'capitalize',
    fontSize: isSmall ? 12 : 16,
  },
  calendarDayHeader: {
    borderRight: '1px solid grey',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
    minWidth: isSmall ? 48 : 64,
  },
  calendarDay: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    borderRadius: 0,
    minWidth: isSmall ? 48 : 64,
    minHeight: isSmall ? 72 : 108,
    maxHeight: isSmall ? 72 : 108,
    height: '100%',
  },
  calendarDayWeekend: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
    minWidth: isSmall ? 48 : 64,
    minHeight: isSmall ? 72 : 108,
    maxHeight: isSmall ? 72 : 108,
    height: '100%',
  },
  today: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.background.paper,
    borderStyle: 'solid',
    borderRadius: '50%',
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main[800],
    },
  },
  calendarDayFirst: {
    borderTop: '1px solid grey', // #dadce0
    borderRight: '1px solid grey',
  },
  calendarDayLast: {
    borderTop: '1px solid grey',
  },
  calendarDayOther: {
    borderTop: '1px solid grey',
    borderRight: '1px solid grey',
  },
}));

export const CalendarLayoutMonth = (props: any) => {
  //console.log('CalendarLayoutMonth props:', props);
  const { weeks } = props;

  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme, isSmall);
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const { locale } = stateCalendar; // defaultEventDuration

  const maxHeight = (weeks: any[]) => {
    const size = weeks.length;
    if (size === 5) {
      return { height: 'calc((100% / 5) - 21.2px)' };
    }
    return { height: 'calc((100% / 6) - 17.5px)' };
  };

  useEffect(() => getEvents(), []);

  const getEvents = () => {
    CalendarService.getEvents().then((response: any) => {
      //console.log('getEvents response:', response);
      if (response.status !== 200) {
        // TODO: Show error notification
        return;
      }
      setEvents(response.events);
    });
  };

  const getEventData = (day: Date, skipMarkers: boolean = false) => {
    const monthEvents = (events &&
      events.sort((a: CalendarEvent, b: CalendarEvent) => {
        return new Date(a.begin).getTime() - new Date(b.begin).getTime();
      })) || [];

    const dayEvents = monthEvents.filter((event: CalendarEvent) =>
      format(new Date(event.begin), 'yyyyMMdd') === format(day, 'yyyyMMdd'));

    const dayHoursEvents = dayEvents
      .map((event: CalendarEvent) => new Date(event.begin).getHours())
      .sort((numberA: number, numberB: number) => numberA - numberB);

    const eventsByHour = dayHoursEvents.reduce((acc: any[], hour: number) => {
      const len = dayHoursEvents.filter((eventHour: number) => eventHour === hour).length;
      !acc.some((accItem: any) => accItem.hour === hour) && acc.push({ hour, len });
      return acc;
    }, []);

    if (skipMarkers) {
      const events = eventsByHour.map((evHour: any) => {
        return dayEvents
          .filter((event: CalendarEvent) => new Date(event.begin).getHours() === evHour.hour);
        });
      return events;
    }

    const markers = eventsByHour.map((evHour: any) => {
      return dayEvents
        .filter((event: CalendarEvent) => new Date(event.begin).getHours() === evHour.hour)
        .map((event: CalendarEvent, index: number) => (
          <EventMark
            key={`event-${event.id}`}
            calendarEvent={event}
            seq={index}
            len={evHour.len}
            layout={'month'}
          />
        ));
    });
    return markers;
  };

  const openEventsDialog = (day: string) => {
    const selectedDay = new Date(day);
    const calendarEvents = getEventData(selectedDay, true);
    setStateCalendar({
      ...stateCalendar,
      openViewAllDialog: true,
      selectedDay,
      calendarEvents,
    });
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction='row'
        justifyContent='center'
        alignItems='center'
        wrap='nowrap'
      >
        {weeks[0].map((weekDay: Date, index: number) => (
          <Grid item xs key={`calendar-column-header-label-${index}`}>
            <div style={{...classes.calendarDayHeader, borderRight: index === 6 ? '' : classes.calendarDayHeader.borderRight}}>
              <Typography style={classes.title}>
                {format(weekDay, 'eee', { locale })}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>

      {weeks.map((week: any, weekIndex: number) => (
        <Grid
          key={`calendar-week-${weekIndex}`}
          container
          spacing={0}
          direction='row'
          justifyContent='space-evenly'
          alignItems='stretch'
          wrap='nowrap'
          style={maxHeight(weeks)}
        >
          {week.map((day: any, dayIndex: number) => {
            const isToday = format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy');
            const eventsOfDay = getEventData(day);

            return (
              <CalendarMonthDate
                key={`calendar-day-${day}`}
                weekIndex={weekIndex}
                dayIndex={dayIndex}
                classes={classes}
                day={day}
                isToday={isToday}
                events={eventsOfDay}
                locale={locale}
                onClick={openEventsDialog}
              />
            );
          })}
        </Grid>
      ))}
    </>
  );
};

const CalendarMonthDate = (props: any) => {
  //console.log('CalendarMonthDate props:', props);
  const { dayIndex, classes, day, isToday, events, locale, onClick } = props;

  return (
    <Grid
      item
      xs
      style={{
        overflow: 'hidden',
        ...(dayIndex === 0 || dayIndex === 6 ? classes.calendarDayWeekend : classes.calendarDay),
        ...(dayIndex === 0 ? classes.calendarDayFirst : dayIndex === 6 ? classes.calendarDayLast : classes.calendarDayOther),
      }}
      onClick={() => onClick(day)}
    >
      <Typography style={classes.title}>
        <span style={isToday ? classes.today : {}}>
          {day.getDate()}
        </span>
        {day.getDate() === 1
          ? format(new Date(day), ' MMM', { locale })
          : null}
      </Typography>
      <Grid
        container
        spacing={0}
        style={{overflow: 'hidden', marginTop: isToday ? 6 : 0}}
      >
        {events.length > 0 && events.map((eventItem: any, index: number) => (
          <Grid key={`event-${day}-${index}`} item xs={12}>
            {eventItem}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};