export enum FeedbackType {
  Awesome = 'Awesome',
  Happy = 'Happy',
  Acceptable = 'Acceptable',
  Sad = 'Sad',
  Bug = 'Bug',
};

export class Feedback {
  /**
   * Constructor
   */
  constructor (
    /**
     * Feed text
     */
    public text: string,
  
    /**
     * Feed type
     */
    public feedType: FeedbackType,
  
    /**
     * The user who send the feedback
     */
    public userId: number,
    ) {
  }
};