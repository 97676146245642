import { IconButton } from '@mui/material';
import { Mail as MessageIcon } from '@mui/icons-material';

export const MessageUserButton = (props: any) => {
  //console.log('MessageUserButton props:', props);
  const { isCurrentUser, isBlocked = false, isDisabled = false, profile, onReload } = props;

  const onSendMessageClick = () => {
    console.log('send message clicked');
    onReload && onReload();
  };

  return (
    <IconButton
      title={`Send message to ${profile?.username}`}
      onClick={onSendMessageClick}
      //className="me-3"
      disabled={isCurrentUser || isBlocked || isDisabled}
    >
      <MessageIcon style={{color: isCurrentUser || isBlocked || isDisabled ? 'grey' : 'white'}} />
    </IconButton>
  );
};