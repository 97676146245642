import React, { useCallback, useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';

import { NewPost, Stream } from '..';
import { DefaultStreamPostsLimit } from '../../consts';
import { withRouter } from '../../hooks';
import { PostService, UserService } from '../../services';
import { Post } from '../../types';

const useStyles: any = (theme: Theme) => ({
  root: {
    maxWidth: theme.breakpoints.only('xs')
      ? 'calc(100vw - 1em)'
      : 'calc(100vw - 7em)',
  },
});

const NewsFeed = (props: any) => {
  //console.log('NewsFeed props:', props);
  const { isUserFeed, showNewPost, currentUser, params } = props;
  const [posts, setPosts] = useState<Post[]>([]);
  const [profileUser, setProfileUser] = useState<any>(null);
  const [isEOF, setIsEOF] = useState(false);
  const offset = posts?.length;
  const theme = useTheme();
  const classes = useStyles(theme);

  const handlePosts = (user: any = null, concatPosts: boolean = false) => {
    if (profileUser === null) {
      setProfileUser(user);
    }

    PostService.getPosts(user?.id, offset, DefaultStreamPostsLimit, isUserFeed).then((response: any) => {
      //console.log('getPosts response:', response);
      if (!response?.posts) {
        return;
      }
      if (response.eof) {
        setIsEOF(true);
      }
      if (concatPosts) {
        setPosts(posts.concat(response.posts));
      } else {
        setPosts(response.posts);
      }
    });
  };

  const handleReload = useCallback(() => {
    if (params?.username) {
      UserService.getUser(params.username).then((response: any) => {
        //console.log('getUser response:', response);
        handlePosts(response.user);
      });
    } else {
      handlePosts(currentUser);
    }
  }, [currentUser]);

  useEffect(() => handleReload(), []);

  // TODO: Add new post to posts list instead of reload
  return (
    <div style={classes.root}>
      {showNewPost && (
        <NewPost
          user={currentUser}
          onReload={handleReload}
        />
      )}
       <Stream
        posts={posts}
        loadStream={() => handlePosts(profileUser, true)}
        limit={DefaultStreamPostsLimit}
        eof={isEOF}
        onReload={handleReload}
        currentUser={currentUser}
      />
    </div>
  );
};

export default withRouter(NewsFeed);