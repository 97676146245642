import http from '../modules/http-common';

const getStats = async () => {
  const response = await http
    .get(`stats`);
  return response.data;
};

export const StatisticService = {
  getStats,
};