import { createContext, useContext } from 'react';

import { MediaDeviceContextType } from '../contexts';

export const MediaDeviceContext = createContext<MediaDeviceContextType>({
  localSettings: {
    isAudioInEnabled: false,
    isVideoEnabled: true,
    devices: {
      audioInputs: [],
      audioOutputs: [],
      videoInputs: [],
    },
    selectedAudioOutDevice: '',
    selectedAudioInDevice: '',
    selectedVideoDevice: '',

    remoteAudio: {},
  },

  onSetLocalSettings: () => false,
  onSetMediaDevice: () => false,
  onStartStream: () => false,
  onToggleMic: () => false,
  onToggleVideo: () => false,
  onToggleRemoteAudio: () => false,
});

export const useMediaDevices = () => useContext(MediaDeviceContext);