import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import {
  Admin,
  Datagrid,
  EditGuesser,
  EmailField,
  List,
  Resource,
  Show,
  ShowGuesser,
  SimpleList,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import env from 'react-dotenv';
import ReactGA from 'react-ga4';
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { ErrorBoundaryWrapper, TopAppBar, Layout, SidebarMenu } from './components';
import { darkTheme } from './components/Sidebar/themes';
import { ContactsProvider, SocketsProvider, VideoCallContextProvider } from './contexts';
import { useIsMobile } from './hooks';
import { isTouchEnabled } from './modules';
import {
  AboutPage,
  CarDetailsPage,
  ChallengeDetailsPage,
  ChallengesPage,
  EventsPage,
  FavoriteCarsPage,
  FleetPage,
  GaragePage,
  HomePage,
  LoginPage,
  MessagesPage,
  NotificationsPage,
  PageNotFound,
  PeoplePage,
  PhotoAlbumsPage,
  PrivacyPage,
  ProfilePage,
  RegisterPage,
  RepairsGalleryPage,
  SettingsPage,
  TermsOfServicePage,
  TestPage,
  VerifyEmailPage,
} from './pages';
import { getUserToken } from './stores';

import ApiDataProvider from './ApiDataProvider';
import { authProvider } from './AuthProvider';

const App = () => {
  const isMobile = useIsMobile();
  const dataProvider = ApiDataProvider(env.BASE_API_URL);
  const currentUser = getUserToken();

  if (env.GOOGLE_ANALYTICS) {
    ReactGA.initialize(env.GOOGLE_ANALYTICS);
  }

  const dndOptions = isTouchEnabled() ? {
    enableTouchEvents: true,
    enableMouseEvents: false,
  } : {
    captureDraggingState: false,
  };

  const adminUsers = {
    list: UsersList,
    show: UserShow,
    edit: EditGuesser,
    recordRepresentation: 'id',
  };
  const adminCars = {
    list: CarsList,
    show: CarShow,
    recordRepresentation: 'id',
  };

  const routes = (
    <div className="text-light bg-dark" style={{padding: isMobile ? '3px' : '15px'}}>
      <Switch>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/cars" element={<FleetPage />} />
        <Route path="/cars/:carId" element={<CarDetailsPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/challenges" element={<ChallengesPage />} />
        <Route path="/challenges/:challengeId" element={<ChallengeDetailsPage />} />
        <Route path="/members" element={<PeoplePage />} />
        <Route path="/repairs" element={<RepairsGalleryPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/verifyemail/:code?" element={<VerifyEmailPage />} />
        <Route path="/account/garage" element={<GaragePage />} />
        <Route path="/account/messages" element={<MessagesPage />} />
        <Route path="/account/notifications" element={<NotificationsPage />} />
        <Route path="/account/favorites" element={<FavoriteCarsPage />} />
        <Route path="/account/gallery" element={<PhotoAlbumsPage />} />
        <Route path="/account/settings" element={<SettingsPage />} />
        <Route path="/tests" element={<TestPage />} />
        <Route
          path="/admin/*"
          element={
            <Admin
              authProvider={authProvider}
              /*basename="admin"*/
              dataProvider={dataProvider}
            >
              <Resource name="users" {...adminUsers}>
                <Route path="" element={<UsersList />} />
                <Route path=":id" element={<ShowGuesser />} />
              </Resource>
              <Resource name="cars" {...adminCars}>
                <Route path="" element={<CarsList />} />
              </Resource>
            </Admin>
          }
        />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="/:username" element={<ProfilePage />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Switch>
    </div>
  );

  // https://mui.com/material-ui/customization/dark-mode/
  const theme = createTheme(darkTheme);

  return (
    <ErrorBoundaryWrapper>
      <ThemeProvider theme={theme}>
        <DndProvider
          backend={isTouchEnabled() ? TouchBackend : HTML5Backend}
          options={dndOptions}
        >
        <CssBaseline />
        <BrowserRouter>
          <SocketsProvider userId={currentUser?.id}>
            <VideoCallContextProvider>
              <ContactsProvider user={currentUser}>
                <Layout
                  appbar={TopAppBar}
                  sidebar={SidebarMenu}
                  children={routes}
                />
              </ContactsProvider>
            </VideoCallContextProvider>
          </SocketsProvider>
        </BrowserRouter>
        </DndProvider>
      </ThemeProvider>
    </ErrorBoundaryWrapper>
  );
};

export const UsersList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  return (
    <List resource="users">
      {isSmall ? (
        <SimpleList
          primaryText={(record: any) => record.firstName}
          secondaryText={(record: any) => record.username}
          tertiaryText={(record: any) => record.email}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="username" />
          <EmailField source="email" />
          <TextField source="age" />
          <TextField source="country" />
          <TextField source="dob" />
          <TextField source="gender" />
          <TextField source="emailVerified" />
          <TextField source="enabled" />
          <TextField source="lastLoginAt" />
          <TextField source="createdAt" />
          <TextField source="updatedAt" />
        </Datagrid>
      )}
    </List>
  );
};

export const UserShow = () => {
  //const { id } = useParams();
  // TODO: const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  return (
    <Show resource="users" title="User">
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="username" />
      </SimpleShowLayout>
    </Show>
  );
};

export const CarsList = () => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  return (
    <List resource="cars">
      {isSmall ? (
        <SimpleList
          primaryText={(record: any) => record.name}
          secondaryText={(record: any) => `${record.year} ${record.manufacturer} ${record.model}`}
          tertiaryText={(record: any) => record.color}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="year" />
          <TextField source="manufacturer" />
          <TextField source="model" />
          <TextField source="trim" />
          <TextField source="color" />
          <TextField source="user.username" />
          <TextField source="createdAt" />
          <TextField source="updatedAt" />
        </Datagrid>
      )}
    </List>
  );
};

export const CarShow = () => {
  // TODO: const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  return (
    <Show resource="cars" title="Car">
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="year" />
        <TextField source="manufacturer" />
        <TextField source="model" />
        <TextField source="trim" />
        <TextField source="color" />
      </SimpleShowLayout>
    </Show>
  );
};

export default App;
/** 
 * #121212 - Sidebar
 * #181818 - Cards
*/