import React, { useContext, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Theme, useTheme } from '@mui/material/styles';
import { differenceInMinutes, format } from 'date-fns';

import { CalendarContext } from '.';

const useStyles: any = ((theme: Theme, layout: string, length: number) => ({
  marker: {
    overflow: 'hidden',
    position: 'absolute',
    //border: '1px solid rgba(66, 165, 245, 0.8)',
    //backgroundColor: 'rgba(66, 165, 245, 0.8)',
    border: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    padding: '3px 5px 5px',
    borderRadius: 3,
    cursor: 'pointer',
    zIndex: 50,
    '&:hover': {
      zIndex: 53,
      backgroundColor: 'rgba(66, 165, 245, 1)',
    },
    minHeight: 23,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    maxWidth: layout === 'month' ? '100%' : `calc(${(100 / length)}% - 12px)`,
    marginLeft: 7,
    marginRight: 7,
    marginBottom: 1,
  },
  markerMonth: {
    margin: 3,
    overflow: 'hidden',
    position: 'relative',
    //border: '1px solid rgba(66, 165, 245, 0.8)',
    //backgroundColor: 'rgba(66, 165, 245, 0.8)',
    border: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    marginBottom: 1,
    borderRadius: 3,
    cursor: 'pointer',
    zIndex: 50,
    '&:hover': {
      zIndex: 53,
      backgroundColor: 'rgba(66, 165, 245, 1)',
    },
    maxHeight: 23,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  markerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: layout === 'day' ? 14 : 12,
    textAlign: 'center',
    marginTop: 3,
  },
  markerBeginEnd: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    fontSize: layout === 'day' ? 12 : 10,
  },
  markerText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    fontSize: layout === 'day' ? 12 : 7,
  },
}));

const getStyles = (
  left: number,
  top: number,
  isDragging: boolean,
  partOfStyle: React.CSSProperties,
): React.CSSProperties => {
  const transform = `translate3d(${left}px, ${top}px, 0)`;

  return {
    position: 'absolute',
    transform: isDragging ? transform : 'initial',
    WebkitTransform: isDragging ? transform : 'initial',
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom 'empty image' drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    ...partOfStyle,
  };
};

const DateFormat = 'yyyy/MM/dd 0:0:0';
const TimeFormat = 'hh:mm a';

export const EventMark = (props: any) => {
  //console.log('EventMark props:', props);
  const { calendarEvent, len, seq, layout } = props;

  const theme = useTheme();
  const classes = useStyles(theme, layout, len);
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { defaultEventDuration } = stateCalendar;

  const beginDate = new Date(calendarEvent.begin);
  const endDate = new Date(calendarEvent.end);
  const beginDateFormatted = format(beginDate, TimeFormat);
  const endDateFormatted = format(endDate, TimeFormat);

  const currentDay = beginDate;
  const initTime = new Date(format(currentDay, DateFormat));
  const position = differenceInMinutes(currentDay, initTime) + 2;
  const duration = differenceInMinutes(endDate, beginDate) - 3;
  const left = (100 / len) * seq + 1;
  const tooltip = `${calendarEvent.title}\nStarts: ${beginDateFormatted}\nEnds: ${endDateFormatted}`;

  const viewEvent = (props: any) => {
    const { calendarEvent } = props;
    setStateCalendar({
      ...stateCalendar,
      openViewDialog: true,
      calendarEvent,
    });
  };

  const [{ isDragging }, dragRef, dragPreview] = useDrag({
    type: 'box',
    item: { type: 'box' },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    const options: any = { captureDraggingState: false };
    dragPreview(getEmptyImage(), options);
  }, [dragPreview]);

  const partOfStyle: React.CSSProperties = {
    marginTop: layout === 'month' ? position : 3,
    height: duration,
  };

  const onDragStart = (eventEl: any, calendarEvent: any) => {
    const width = eventEl.currentTarget.parentElement.parentElement.offsetWidth;
    const height = eventEl.currentTarget.clientHeight + 5;

    setStateCalendar({
      ...stateCalendar,
      startDragging: true,
      draggingEventId: calendarEvent.id,
      calendarEvent,
      ghostProperties: { width, height },
    });
  };

  return (
    <div
      id={calendarEvent.id}
      key={calendarEvent.id}
      title={tooltip}
      ref={layout !== 'month' ? dragRef : null}
      onDragStart={(eventEl: any) => onDragStart(eventEl, calendarEvent)}
      style={{
        ...getStyles(left, position / 57 - 2, isDragging, partOfStyle),
        ...(layout === 'month' ? classes.markerMonth : classes.marker),
        flex: 1,
      }}
      onClick={(eventEl: any) =>
        viewEvent({
          eventEl,
          calendarEvent,
          defaultEventDuration,
          stateCalendar,
          setStateCalendar,
        })
      }
    >
      <div style={classes.markerTitle}>{calendarEvent.title}</div>
      <hr style={{margin: 3}} />
      <div style={classes.markerBeginEnd}>
        <span>{beginDateFormatted}</span> - <span>{endDateFormatted}</span>
      </div>
      {calendarEvent.description && (
        <div style={{...classes.markerText}}>
          {calendarEvent.description}
        </div>
      )}
    </div>
  );
};