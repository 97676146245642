import http from '../modules/http-common';
import { Car } from '../types';

const getCar = async (carId: number) => {
  const response = await http
    .get('cars/' + carId);
  return response.data;
};

const getCars = async (inFleet: boolean = false) => {
  const response = await http
    .get(`cars?inFleet=${inFleet}`);
  return response.data;
};

const createCar = async (car: Car) => {
  const response = await http
    .post('cars/create', { car });
  return response.data;
};

const updateCar = async (car: Car) => {
  const response = await http
     .put(`cars/${car.id}/update`, { car });
  return response.data;
};

const deleteCar = async (carId: number) => {
  const response = await http
    .delete(`cars/${carId}/delete`);
  return response.data;
};

const likeCar = async (carId: number, userId: number) => {
  const response = await http
    .get(`cars/${carId}/like?userId=${userId}`);
  return response.data;
};

export const CarService = {
  getCar,
  getCars,
  createCar,
  updateCar,
  deleteCar,
  likeCar,
};