import {
  DEVICE_DEFAULT_ID,
  DEVICE_DISABLED_ID,
  MediaKind,
} from '..';
import { createBlackVideoTrack, MediaStream } from '../window';
import { VideoStreamTypeCamera } from '../../../types';

const getUserMediaFail = (resolve: () => void, reject: (err: Error) => void) => {
  reject(new Error(
    'No API to retrieve media stream. This can happen if you ' +
    'are using an old browser, or the application is not using HTTPS'));
};

export interface EnumrateDevicesParams {
  // getUserMedia controls whether a call to enumerateDevices needs to call
  // getUserMedia. This is needed so that all device names are populated
  // properly. But if we successfully called getUserMedia before, we don't need
  // to call it again, since the stream and its tracks will be discarded, so
  // it's a waste of resources.
  getUserMedia: boolean;
};

export const enumerateDevices = async (params: EnumrateDevicesParams) => {
  let stream: MediaStream | undefined;

  if (params.getUserMedia) {
    try {
      stream = await getUserMedia({ audio: true, video: true });
      console.log('enumerateDevices:', stream);
    } catch (err) {
      // We can continue if this fails, the devices won't have real names.
    }
  }

  let devices: MediaDeviceInfo[];
  try {
    devices = await navigator.mediaDevices.enumerateDevices();
  } finally {
    if (stream) {
      console.log('stream:', stream);
      //stream.getTracks().forEach((track: any) => track.stop());
      stream.getTracks().forEach((track: MediaStreamTrack) => track.enabled = true);
    }
  }

  return devices;
};

declare global {
  interface Navigator {
    webkitGetUserMedia?: typeof navigator.mediaDevices.getUserMedia;
    mozGetUserMedia?: typeof navigator.mediaDevices.getUserMedia;
  }
};

export const getUserMedia = async (constraints: MediaStreamConstraints): Promise<MediaStream> => {
  //console.log('getUserMedia:', constraints);
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    //console.log('getUserMedia stream:', stream);
    return stream;
  }

  const _getUserMedia: typeof navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    getUserMediaFail;

  return new Promise<MediaStream>(async (resolve, reject) => {
    const media = await _getUserMedia.call(navigator, constraints);
    resolve(media);
  });
};

export interface DisplayMediaConstraints {
  audio: boolean;
  video: any;
};

const defaultDisplayMediaConstraints: DisplayMediaConstraints = {
  //audio: true,
  //video: false,
  video: true,
  audio: false,
};

export interface SizeConstraint {
  width: number;
  height: number;
};

export type GetMediaTrackParams = {
  kind: MediaKind;
  constraint: MediaTrackConstraints | false;
};

export const getMediaTrack = async (params: GetMediaTrackParams) => {
  console.log('getMediaTrack:', params);
  const payload: any = {
    kind: params.kind,
    track: undefined,
    type: VideoStreamTypeCamera,
  };
  if (!params.constraint) {
    return payload;
  }
  if (params.kind === 'audio') {
    const mediaStream = await getUserMedia({
      audio: params.constraint,
      video: false,
    });
    payload.track = mediaStream.getAudioTracks()[0];
  } else {
    const mediaStream = await getUserMedia({
      audio: false,
      video: params.constraint,
    });
    payload.track = mediaStream.getVideoTracks()[0];
  }
  return payload;
};

// getBlankVideoTrack is a workaround that allows us to use replaceTrack with a
// dummy track before we can call getUserMedia on some phones which don't
// support simultaneous streams from multiple cameras.
export const getBlankVideoTrack = (params: GetMediaTrackParams) => {
  let width = 640;
  let height = 480;

  if (params.kind !== 'video') {
    throw new Error('kind must be video');
  }

  if (typeof params.constraint !== 'boolean') {
    if (params.constraint.width && params.constraint.height) {
      width = params.constraint.width as number;
      height = params.constraint.height as number;
    }
  }

  const track = createBlackVideoTrack(width, height);
  return {
    kind: params.kind,
    track: track,
    type: VideoStreamTypeCamera,
  };
};

export const getMediaStream = async (peerId: number, constraints: MediaStreamConstraints) => {
  console.debug('getMediaStream', constraints);
  // Need to init audioProcessor on user action (e.g. click).
  //await audioProcessor.init();

  if (!constraints.audio && !constraints.video) {
    const payload = {
      id: peerId,
      stream: new MediaStream(),
      type: VideoStreamTypeCamera,
    };
    return payload;
  }
  
  const payload = {
    id: peerId,
    type: VideoStreamTypeCamera,
    stream: await getUserMedia(constraints),
  };
  
  return payload;
};

export const getDesktopStream = async (constraints: DisplayMediaConstraints = defaultDisplayMediaConstraints): Promise<MediaStream> => {
  //console.log('getDisplayMedia:', constraints);
  const desktopConstraints: any = {
    video: {
      advanced: [
        { displaySurface: 'window' }, // default, browser, monitor, window
      ],
    },
    audio: false,
  };
  //const stream = await navigator.mediaDevices.getDisplayMedia(constraints ?? defaultDisplayMediaConstraints);
  //const stream = await navigator.mediaDevices.getDisplayMedia(desktopConstraints);
  const stream = await navigator.mediaDevices?.getDisplayMedia();
  return stream;
};

// getDeviceId is a helper for figuring out the correct device ID.
export const getDeviceId = (enabled: boolean, constraint: MediaTrackConstraints): string => {
  if (!enabled) {
    return DEVICE_DISABLED_ID;
  }

  if (typeof constraint?.deviceId !== 'string') {
    return DEVICE_DEFAULT_ID;
  }

  return constraint?.deviceId;
};

export const getTracksByKind = (stream: MediaStream, kind: MediaKind) => {
  return kind === 'video' ? stream.getVideoTracks() : stream.getAudioTracks();
};

export const getDimensionsFromStream = (stream: MediaStream) => {
  const videos = stream.getVideoTracks();
  if (videos.length === 0) {
    return undefined;
  }
  return getDimensionsFromTrack(videos[0]);
};

export const getDimensionsFromTrack = (track: MediaStreamTrack) => {
  const settings = track.getSettings();
  const { width, height } = settings;

  if (!width || !height) {
    return undefined;
  }

  return {
    x: width,
    y: height,
  };
};