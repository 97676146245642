import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { getCarImageUrl, rndNum } from '../../modules';

export const CarSpecificationsList = (props: any) => {
  //console.log('car specs list props:', props);
  const { specs, carId, showImage = true } = props;
  const image = getCarImageUrl(carId);

  return (
    <>
    {carId > 0 && showImage && (
      <img
        src={image}
        alt=""
        style={{
          width: '100%',
          height: 'auto',
          //maxWidth: '300px',
          maxHeight: '300px',
          objectFit: 'cover',
        }}
      />
    )}
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        color: 'white',
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
      }}
      component="nav"
      aria-labelledby="car-specs-subheader"
      //subheader={
      //  <ListSubheader component="div" id="car-specs-subheader">
      //    Specifications
      //  </ListSubheader>
      //}
    >
      {/*
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Year:</div>
            <div className="float-end">{car.year}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Manufacturer:</div>
            <div className="float-end">{car.manufacturer}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Model:</div>
            <div className="float-end">{car.model}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Trim:</div>
            <div className="float-end">{car.trim}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Color:</div>
            <div className="float-end">{car.color}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      */}
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Engine Size:</div>
            <div className="float-end">{specs?.engineSize ? specs?.engineSize : '--'}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Horsepower:</div>
            <div className="float-end">{specs?.horsepower ? specs?.horsepower : '--'}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Displacement:</div>
            <div className="float-end">{specs?.displacement ? specs?.displacement : '--'}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Weight:</div>
            <div className="float-end">{specs?.weight ? rndNum(specs?.weight, 0) : '--'} lbs</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">0-60 Time:</div>
            <div className="float-end">{specs?.timeTo60 ? rndNum(specs?.timeTo60, 2) : '--'} sec</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Top Speed:</div>
            <div className="float-end">{specs?.topSpeed ? rndNum(specs?.topSpeed, 2) : '--'} MPH</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
            <div className="float-start">Status:</div>
            <div className="float-end">{specs?.status ? specs?.status : '--'}</div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
    </>
  );
};