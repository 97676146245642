import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';

import { DialState } from '..';
import { useAudio, useVideoCall } from '../../hooks';

const DefaultAnswerTimeout = 10; // seconds
let answerTimer: NodeJS.Timer | null | undefined = undefined;

interface AnswerVideoCallDialogProps {
  open: boolean;
  playRinger: boolean;
  onAccept: () => void;
  onDecline: () => void;
  onClose: (force: boolean, dialState: DialState) => void;
};

export const AnswerVideoCallDialog = (props: AnswerVideoCallDialogProps) => {
  //console.log('AnswerVideoCallDialog props:', props);
  const { open, playRinger, onAccept, onDecline, onClose } = props;
  let [answerTimeout, setAnswerTimeout] = useState(DefaultAnswerTimeout);
  const { playing, start, stop } = useAudio('/sounds/phone-ringing.mp3');
  const { callState, usersOnline } = useVideoCall();
  const userId = callState?.incomingCall?.peer
    ? parseInt(callState?.incomingCall?.peer)
    : callState?.creatorUserId; // TODO: 
  const user = usersOnline[userId!];

  const stopRinging = useCallback(() => {
    if (playRinger && playing) {
      stop();
    }
  }, [playRinger, playing, stop]);

  const reset = useCallback(() => {
    stopRinging();

    clearInterval(answerTimer!);
    answerTimer = undefined;
  }, [stopRinging]);

  const handleAccept = () => {
    reset();
    onAccept();
    setAnswerTimeout(DefaultAnswerTimeout);
  };

  const handleDecline = () => {
    reset();
    onDecline();
    setAnswerTimeout(DefaultAnswerTimeout);
  };

  const handleClose = useCallback((dialState: DialState) => {
    reset();
    onClose(true, dialState);
    setAnswerTimeout(DefaultAnswerTimeout);
  }, [onClose, reset]);

  useEffect(() => {
    if (!open && playing) {
      stopRinging();
    }
    if (open && !answerTimer) {
      answerTimer = setInterval(() => {
        if (!open) {
          return;
        }
  
        if (answerTimeout <= 0) {
          handleClose(callState?.dialState ?? 'hung-up');
          return;
        }
  
        answerTimeout--;
        setAnswerTimeout(answerTimeout);
      }, 1 * 1000);
    }

    if (open && playRinger && !playing) {
      start();
    }
  }, [
    open, playing, playRinger, answerTimeout, callState?.dialState,
    handleClose, stopRinging, start,
  ]);

  return (
    <Dialog
      open={open}
      onClick={() => handleClose(callState?.dialState ?? 'hung-up')}
      style={{
        //width: '500px',
      }}
    >
      <DialogTitle style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <PhoneIcon color="primary" fontSize="large" />&nbsp;Incoming Call&nbsp;
        <IconButton
          title="Close"
          color="inherit"
          size="small"
          className="float-end"
          onClick={() => handleClose(callState?.dialState ?? 'hung-up')}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress
          color="primary"
          size={60}
          sx={{marginRight: '1rem'}}
        />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} className="text-center">
            &nbsp;<b>{user?.username ?? user?.id}</b> is calling...
          </Grid>
        </Grid>
        <br />
        <div className="row">
          <div className="col col-xs-6">
            <Button
              color="primary"
              title="Answer Call"
              variant="contained"
              size="small"
              onClick={handleAccept}
            >
              Answer
            </Button>
          </div>
          <div className="col col-xs-6">
            <Button
              color="error"
              title="Decline Call"
              variant="contained"
              size="small"
              className="text-nowrap"
              onClick={handleDecline}
            >
              Decline {answerTimeout < 1 ? '' : `(${answerTimeout})`}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};