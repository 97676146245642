import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { ViewCarDetails } from '..';
import { buildCarName } from '../../modules';

export const ViewCarDetailsDialog = (props: any) => {
  //console.log('ViewCarDetailsDialog props:', props);
  const { car, open, onClose } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {car?.name}&nbsp;|&nbsp;{buildCarName(car)}
          </Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
            title="Close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ViewCarDetails
        car={car}
        onReload={() => console.log('handleReload')}
      />
    </Dialog>
  );
};