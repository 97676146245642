import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { CarGridModel } from '../components';
import { SearchBar } from '../components/Sidebar';
import { CarService, SearchService } from '../services';
import { Car } from '../types';

const useStyles: any = (theme: Theme) => ({
  root: {
    maxWidth: 'calc(100vw - 0.6em)',
  },
});

const FleetPage = (props: any) => {
  const [cars, setCars] = useState([]);
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleReload = () => {
    CarService.getCars(true).then((response: any) => {
      //console.log('getCars response:', response);
      setCars(response.cars);
    })
    .catch((err: any) => {
      console.error('error:', err);
    });
  };

  const handleSearch = (text: string) => {
    //console.log('handleSearch:', text);
    SearchService.search(text).then((response: any) => {
      //console.log('search response:', response);
      const { cars } = response.results;
      setCars(cars);
    });
  };

  useEffect(() => handleReload(), []);

  return (
    <div style={classes.root}>
      <Typography variant="h3" gutterBottom className="text-center">The Fleet</Typography>
      <Grid container /*columnSpacing={0}*/ spacing={2} className="mb-3" justifyContent="center">
        <Grid key='search' item xs={12}>
          <div className="float-end">
            <SearchBar
              onSearch={handleSearch}
              showResults={false}
            />
          </div>
        </Grid>
        {cars.map((car: Car) => (
          <Grid key={car.id} item md={6} xs={12}>
            <CarGridModel car={car} onReload={handleReload} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FleetPage;