import React, { useState } from 'react';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { DriveEta as CarIcon } from '@mui/icons-material';
import { ViewImageDialog } from '../components/Dialogs';

interface CarRepair {
  id: number;
  brand: string;
  model: string;
  trim: string;
  year: number;
  image: string;
  repairs: {
    id: number;
    description: string;
    images: string[];
  }[];
};

const carRepairs: CarRepair[] = [
  {
    id: 1,
    brand: 'Dodge',
    model: 'Charger',
    trim: 'SXT',
    year: 2016,
    image: 'https://cdn.discordapp.com/attachments/614217994465312778/1145071311773761566/IMG_20230819_162754.jpg',
    repairs: [
      {
        id: 1,
        description: 'Front Passenger Window',
        images: [
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071177740587068/IMG_20230819_133141.jpg',
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071309408186438/IMG_20230819_145615.jpg',
        ],
      },
      {
        id: 2,
        description: 'Front Bumper Cover',
        images: [
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071178516529203/IMG_20230819_133151.jpg',
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071310075080826/IMG_20230819_152546.jpg',
        ],
      },
      {
        id: 3,
        description: 'Rear Bumper Cover',
        images: [
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071178977906708/IMG_20230819_133221.jpg',
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071309735329882/IMG_20230819_150826.jpg',
        ],
      },
      {
        id: 4,
        description: 'Complementary Car Wash',
        images: [
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071310880391198/IMG_20230819_154504.jpg',
          'https://cdn.discordapp.com/attachments/614217994465312778/1145071311773761566/IMG_20230819_162754.jpg',
        ],
      },
    ],
  },
];

const useStyles: any = (theme: Theme, isSmall: boolean) => ({
  root: {
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  container: {
    padding: 16,
    borderRight: isSmall ? '' : '1px solid grey',
    borderBottom: isSmall ? '1px solid #ccc' : '',
    width: isSmall ? '100%' : 300,
    whiteSpace: 'nowrap',
  },
  profileImage: {
    borderRadius: '50%',
    border: '1px solid grey',
    width: 256,
    height: 256,
    maxWidth: 512,
    maxHeight: 512,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  listItem: {
    borderRadius: 20,
  },
  divider: {
    margin: '8px 0',
    backgroundColor: '#ccc',
  },
  detailsContainer: {
    padding: 8,
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  repairItem: {
    marginBottom: 8,
  },
  repairImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  repairImage: {
    maxWidth: '100%',
    width: 'auto',
    marginBottom: 8,
    maxHeight: 400,
    borderRadius: 3,
    marginLeft: 3,
    marginRight: 3,
    cursor: 'pointer',
  },
});

const RepairsGalleryPage = (props: any) => {
  //console.log('GalleryPage props:', props);
  const [selectedCar, setSelectedCar] = useState<CarRepair | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>();

  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme, isSmall);

  const handleCarSelection = (car: CarRepair) => setSelectedCar(car);
  const handleViewImage = (image: string) => {
    setSelectedImage(image);
    setOpen(true);
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
    setOpen(false);
  };

  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">
        Tio's Repair Gallery
      </Typography>
      <div style={classes.root}>
        <div style={classes.container}>
          <Typography variant="h6" className="text-center" style={{borderBottom: '1px solid grey'}}>
            Car Repairs
          </Typography>
          <List>
            {carRepairs.map((car) => (
              <ListItem
                button
                key={car.id}
                onClick={() => handleCarSelection(car)}
                style={classes.listItem}
              >
                <ListItemIcon>
                  <CarIcon />
                </ListItemIcon>
                <ListItemText primary={`${car.year} ${car.brand} ${car.model}`} />
              </ListItem>
            ))}
          </List>
        </div>
  
        <div style={classes.detailsContainer}>
          {selectedCar ? (
            <div>
              <div className="text-center mb-3">
                <Typography variant="h5" className="mb-1">
                  {`${selectedCar.year} ${selectedCar.brand} ${selectedCar.model} (${selectedCar.trim})`}
                </Typography>
                <img
                  src={selectedCar.image}
                  alt={`${selectedCar.year} ${selectedCar.brand} ${selectedCar.model}`}
                  style={classes.profileImage}
                  onClick={() => handleViewImage(selectedCar.image)}
                />
              </div>
              <Divider style={classes.divider} />
  
              <Grid container spacing={2}>
                {selectedCar.repairs.map((repair) => (
                  <Grid item key={repair.id} xs={12} sm={6} style={classes.repairItem}>
                    <Typography variant="subtitle1" className="text-center">
                      {repair.description}
                    </Typography>
                    <div style={classes.repairImageContainer}>
                      {repair.images.map((image) => (
                        <img
                          key={image}
                          src={image}
                          alt={repair.description}
                          style={classes.repairImage}
                          onClick={() => handleViewImage(image)}
                        />
                      ))}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <Typography>Select a car to view repair details.</Typography>
          )}
        </div>
      </div>
      <ViewImageDialog
        open={open}
        title={`${selectedCar?.year} ${selectedCar?.brand} ${selectedCar?.model}`}
        image={selectedImage}
        onClose={handleCloseImage}
      />
    </>
  );
};

export default RepairsGalleryPage;