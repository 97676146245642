import React from 'react';
import { Box } from '@mui/material';

export interface ITabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
};

export const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="bg-dark text-light"
      {...rest}
    >
      {value === index && (
        <Box sx={{ p: 2 /*3*/ }}>
          {children}
        </Box>
      )}
    </div>
  );
};