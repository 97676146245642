import React, { useEffect, useState } from 'react';
import {
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { Notify, NotifyButtonsPosition } from '../../components';
import { NotificationService } from '../../services';
import { getUserToken } from '../../stores';
import { Notification } from '../../types';

export const NotificationsPage = (props: any) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const currentUser = getUserToken();
  const { enqueueSnackbar } = useSnackbar();

  const handleReloadNotifications = () => {
    NotificationService.getNotifications(currentUser?.id).then((response: any) => {
      //console.log('getNotifications response:', response);
      const notifications = response.notifications;
      setNotifications(notifications);
    });
  };

  const handleSeenNotify = (event: any, notification: any) => {
    //console.log('handleSeenNotify:', event, notification);
    event.preventDefault();

    if (!notification?.id || !notification?.unread) {
      return;
    }

    NotificationService.markRead(notification.id).then((response: any) => {
      //console.log('markRead response:', response);
      let remanining = notifications;
      for (let notify of remanining) {
        if (notify.id === notification.id) {
          notify.unread = false;
          break;
        }
      }
      setNotifications(remanining);
    });
    //navigate(notification.url);
  };

  const handleDeleteNotify = (event: any, id: number) => {
    //console.log('handleDeleteNotify:', event, id);
    event.preventDefault();

    NotificationService.deleteNotification(id).then((response: any) => {
      //console.log('deleteNotification response:', response);
      const filtered = notifications.filter((x: any) => x.id !== id);
      setNotifications(filtered);
    });
  };

  const handleClearNotify = () => {
    //console.log('handleClearNotify');
    NotificationService.clearNotifications(currentUser.id).then((response: any) => {
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to clear all of your notifications, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      setNotifications([]);
    });
  };

  useEffect(() => handleReloadNotifications(), []);

  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">
        Notifications
      </Typography>
      <Notify
        open={true}
        notifications={notifications}
        usePopover={false}
        buttonsPosition={NotifyButtonsPosition.Top}
        showViewAllButton={false}
        onSeen={handleSeenNotify}
        onDelete={handleDeleteNotify}
        onClear={handleClearNotify}
      />
    </>
  );
};