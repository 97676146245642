import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, Paper } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { LoadMoreProgress, Post2 } from '..';
import { withRouter } from '../../hooks';
import { Post } from '../../types';

const useStyles: any = (theme: Theme) => ({
  paper: {
    borderRadius: 20,
    maxWidth: theme.breakpoints.only('xs')
      ? '100vw'
      : 'calc(100vw - 7em)',
  },
});

/**
 * Credits: https://github.com/red-gold/react-social-network/blob/next/src/containers/stream/StreamComponent.tsx
 * @param props 
 * @returns 
 */
const Stream = (props: any) => {
  //console.log('Stream props:', props);
  const { posts, eof, currentUser, loadStream, onReload } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  /**
   * Scroll loader
   */
  const scrollLoad = () => loadStream && loadStream!();

  return (
    <InfiniteScroll
      // This is important field to render the next data
      dataLength={posts?.length ?? 0}
      next={scrollLoad}
      hasMore={!eof}
      loader={<LoadMoreProgress key='load-more-progress' />}
      endMessage={
        <p style={{ textAlign: 'center', marginTop: '25px' }}>
          <b>Yay! You are all caught up.</b>
        </p>
      }
      // Below props only if you need pull down functionality
      refreshFunction={scrollLoad}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={
        <h3 style={{ textAlign: 'center' }}>
          &#8595; Pull down to refresh
        </h3>
      }
      releaseToRefreshContent={
        <h3 style={{ textAlign: 'center' }}>
          &#8593; Release to refresh
        </h3>
      }
    >
      <Grid container spacing={2}>
        {posts && posts?.map((post: Post) => (
          <Grid item key={post.id} xs={12}>
            <Paper style={classes.paper}>
              <Post2
                key={post.id}
                post={post}
                currentUser={currentUser}
                editMode={false}
                openComments={false}
                onReload={onReload}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

export default withRouter(Stream);