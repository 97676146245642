import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { UserAvatar } from '..';
import { getAvatarUrl } from '../../modules';
import { RelationshipService } from '../../services';

export const FollowingDialog = (props: any) => {
  //console.log('FollowingDialog props:', props);
  const { open, title, profile, currentUser, onClose } = props;
  const [userProfile, setUserProfile] = useState<any>(profile);

  const goToProfile = (username: string) => window.location.href = `/${username}`;

  const handleFollowClick = (userId: number) => {
    const isFollowing = RelationshipService.isFollowingUser(userId, currentUser);
    //console.log('isFollowing:', isFollowing, currentUser.id, userId);
    if (isFollowing) {
      RelationshipService.deleteRelationship(userId, currentUser?.id).then((response: any) => {
        //console.log('delete follow response:', response);
        setUserProfile(response.user);
      });
    } else {
      RelationshipService.createRelationship(userId, currentUser?.id).then((response: any) => {
        //console.log('create follow response:', response);
        setUserProfile(response.user);
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List sx={{ pt: 0 }}>
          {userProfile?.following?.length > 0 ? userProfile?.following?.map((following: any) => {
            const isFollowingUser = RelationshipService.isFollowingUser(following.followedUserId, currentUser);
            return (
            <ListItem key={following?.followedUserId} disableGutters>
              <ListItemButton>
                <ListItemAvatar>
                  <UserAvatar
                    fileName={getAvatarUrl(following?.followedUserId)}
                    fullName={following?.followers?.username}
                    onClick={() => goToProfile(following?.followers?.username)}
                    tooltip={`Visit ${following?.followers?.username}'s profile`}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <div className="row text-nowrap">
                    <div className="col col-8">
                      <div className="float-start">
                        {following?.followers?.username}
                      </div>
                    </div>
                    <div className="col col-4 float-end">
                      <div className="float-end">
                        {following?.followedUserId !== currentUser?.id && (
                          <Button
                            variant="contained"
                            size="small"
                            title={isFollowingUser ? "Unfollow user" : "Start following user"}
                            onClick={() => handleFollowClick(following?.followedUserId)}
                          >
                            {isFollowingUser ? "Unfollow" : "Follow"}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            );
          }) : ('Not Following Anyone')}
        </List>
      </DialogContent>
    </Dialog>
  );
};