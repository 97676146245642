import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { RaceTrackDetails } from '..';

export const ViewRaceTrackDetailsDialog = (props: any) => {
  //console.log('ViewRaceTrackDetailsDialog props:', props);
  const { open, onClose, track } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="view-race-track-dialog"
      aria-describedby="view-race-track-details"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {track?.name} Details
          </Typography>
          <IconButton
            title="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <RaceTrackDetails
          key={track?.name}
          track={track}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};