/** References:
 * - https://github.com/bezkoder/react-typescript-login-example
 * - https://github.com/bezkoder/react-typescript-authentication-example
 * - https://github.com/bezkoder/react-hooks-jwt-auth
 * - https://github.com/Akshay80/React-TS-login-using-jwt
 * - https://www.bezkoder.com/integrate-react-express-same-server-port/
 * - https://www.bezkoder.com/react-custom-hook-typescript/
 */

import React, { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { TabPanel } from '../components';
import {
  MyCarsPage,
  MyChallengesPage,
  MyTimesPage,
} from './Account';
import { getUserToken } from '../stores';

const a11yProps = (index: number, selected: number) => {
  return {
    id: `horizontal-tab-${index}`,
    color: index === selected ? 'white' : 'inherit',
    'aria-controls': `horizontal-tabpanel-${index}`,
    style: {
      fontSize: 14,
    },
  };
};

const useStyles: any = (theme: Theme) => ({
  root: {
    justifyContent: 'center',
    maxWidth: 'calc(100vw - 0.5em)',
  },
});

const GaragePage = (props: any) => {
  const currentUser = getUserToken();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = (event: any, newValue: number) => setValue(newValue);
  const handleTabColor = (index: number, selected: number) =>
    index === selected
      ? ''
      : 'text-secondary'; //: 'text-light'

  return (
    <div style={classes.root}>
      <Typography variant="h3" gutterBottom className="text-center">My Garage</Typography>
      <Box
        sx={{
          bgcolor: 'background.paper',
          color: 'white',
          width: '100%',
          //maxWidth: 'calc(100vw - 5em)',
        }}
      >
        <Tabs
          orientation="horizontal"
          value={value}
          onChange={handleChange}
          aria-label="Dragster profile options"
          sx={{ borderRight: 1, borderColor: 'divider' }}
          className="bg-dark text-light"
          variant="fullWidth"
          centered
        >
          <Tab label="My Cars" className={handleTabColor(0, value)} {...a11yProps(0, value)} />
          <Tab label="My Track Times" className={handleTabColor(1, value)} {...a11yProps(1, value)} />
          <Tab label="My Challenges" className={handleTabColor(2, value)} {...a11yProps(2, value)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MyCarsPage user={currentUser} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyTimesPage user={currentUser} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MyChallengesPage user={currentUser} />
      </TabPanel>
    </div>
  );
};

export default GaragePage;