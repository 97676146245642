import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { AddReaction as AddReactionIcon } from '@mui/icons-material';
import { EmojiClickData } from 'emoji-picker-react';
import { useSnackbar } from 'notistack';

import { EmojiPickerContainer } from '../..';
import { MessageService } from '../../../services';
import '../ChatMessageContainer/ChatMessageContainer.css';

export const ChatMessageEmojiFooter = (props: any) => {
  //console.log('ChatMessageEmojiFooter props:', props);
  const { message, userId } = props;
  const [state, setState] = useState({
    open: false,
    anchor: null,
  });
  const { enqueueSnackbar } = useSnackbar();

  const addRemoveEmoji = (emoji: string) => {
    MessageService.addEmoji(message.id, userId, emoji).then((response: any) => {
      //console.log('addEmoji response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to add or remove emoji from message, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      if (response.added) {
        message.emojis.push({
          messageId: message.id,
          userId,
          emoji,
        });
      } else {
        message.emojis = message.emojis.filter((e: any) => e.userId !== userId && e.emoji !== emoji);
      }
    });
  };

  const onClick = (emoji: EmojiClickData, event: MouseEvent) => addRemoveEmoji(emoji.emoji);
  const onClose = () => setState({...state, open: false, anchor: null});

  return (
    <div className="messageEmojiFooter">
      <div style={{display: 'inline-flex', flexDirection: 'row'}}>
        <div className="messageEmojiFooterContainer">
          {message.emojis && message.emojis?.map((emoji: any, index: number) => (
            <IconButton
              key={index}
              //title=""
              size="small"
              onClick={() => addRemoveEmoji(emoji.emoji)}
            >
              {emoji.emoji}
            </IconButton>
          ))}
        </div>
        <IconButton
          title="Add emoji to message"
          className="messageAddEmoji"
          size="small"
          onClick={(event: any) => setState({...state, open: true, anchor: event.currentTarget})}
        >
          <AddReactionIcon />
        </IconButton>
      </div>
      <EmojiPickerContainer
        openEmojiPicker={state.open}
        anchorEl={state.anchor}
        onEmojiClick={onClick}
        onClose={onClose}
      />
    </div>
  );
};