import { Parallax, Background } from 'react-parallax';

import { ImageCover } from '..';
import { DefaultProfileBannerUrl } from '../../consts';

export const ProfileHeader = (props: any) => {
  //console.log('ProfileHeader props:', props);
  const { banner = DefaultProfileBannerUrl } = props;

  return (
    <Parallax
      strength={500}
      style={{
        height: '455px',
        borderRadius: '2px',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        position: 'relative',
      }}
      bgStyle={{ position: 'relative' }}
    >
      <Background>
        <ImageCover
          width="100%"
          height="510px"
          fileName={banner}
          borderRadius={12}
        />
      </Background>
    </Parallax>
  );
};