import env from 'react-dotenv';
import React, { useCallback, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useSnackbar } from 'notistack';

import { Events } from '../consts';
import { SocketContext } from '../hooks';
import { decrypt, encrypt, playNotificationSound } from '../modules';
import { MessageService } from '../services';
import { Message } from '../types';

export type SocketContextType = {
  socket: Socket | any;
  activeRoomId: number;
  rooms: any[];
  messages?: any[];

  sendMessage: Function;
  addMessage: Function;
  addMessages: Function;
  updateMessage: Function;
  deleteMessage: Function;
  clearMessages: Function;
  getChatHistory: Function;
  setActiveRoomId: Function;

  createVideoCallRoom: (roomId: number, userIds: number[]) => void;
  joinVideoCallRoom: (roomId: number, userId: number, userIds: number[]) => void;
  joinVideoCallRoomRelay: (roomId: number, userId: number, userIds: number[]) => void;
  leaveVideoCallRoom: (roomId: number, userId: number) => void;
};

const socket = io(env.WEB_SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 3 * 1000,
  autoConnect: true,
  extraHeaders: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
  },
  transports: ['websocket', 'polling'],// 'flashsocket'],
  withCredentials: true,
  forceNew: true,
});

interface SocketsProviderProps {
  userId: number;
  children: any;
};

export const SocketsProvider = (props: SocketsProviderProps) => {
  //console.log('SocketsProvider props:', props);
  const { userId } = props;
  socket.auth = { userId };

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<any>({
    activeRoomId: 0,
    rooms: [],
    messages: [],
  });

  socket.on(Events.Connect, () => {
    console.log('OnConnect:', socket.id);
  });

  socket.on(Events.Disconnect, () => {
    console.log('OnDisconnect:', userId, socket.id, state);
  });

  socket.on(Events.Server.Rooms, (rooms: any[]) => {
    //console.log('OnServerRooms:', rooms);
    setState({...state, rooms});
  });

  //socket.on(Events.Server.CreatedRoom, (room: any) => {
  //  console.log('OnCreatedRoom:', room);
  //});

  socket.on(Events.Server.JoinedRoom, (roomId: number, active: boolean) => {
    //console.log('OnJoinedRoom:', roomId, active);
    if (active) {
      setActiveRoomId(roomId);
    }
  });

  socket.on(Events.Server.LeftRoom, (userId: number, roomId: number) => {
    console.log('OnLeftRoom:', userId, roomId);
  });

  socket.on(Events.Server.ReceivedMessage, (message: any) => {
    //console.log('OnReceivedMessage:', message);
    if (!message.text) {
      return;
    }

    // Check if message received is for active chat to prevent
    // messages from other users/rooms being shown since we are
    // broadcasting to all rooms in order to intercept incoming
    // messages.
    if (message.roomId !== state.activeRoomId) {
      socket.emit(Events.Client.GetRooms, userId);
      return;
    }

    const decryptedMessage = decrypt(message.text);
    //dispatchProcess(false, decryptedMessage, message.text);
    const msg = {
      ...message,
      text: decryptedMessage,
    };
    addMessage(msg);

    // TODO: Make configurable
    if (message.userId !== userId) {
      playNotificationSound();
    }
  });

  const sendMessage = (senderUserId: number, roomId: number, text: string, images: any[] = []) => {
    //console.log('sendMessage:', senderUserId, roomId, text, images);
    if (senderUserId === 0 || roomId === 0 || !text || text === '') {
      return;
    }

    const encryptedMessage: any = {
      socketId: socket.id,
      senderUserId,
      roomId,
      text: encrypt(text),
    };
    socket.emit(Events.Client.SendMessage, encryptedMessage, (messageId: number) => {
      //console.log('sendMessage:', messageId);
      if (!(images?.length > 0) || messageId === 0) {
        return;
      }
  
      for (const file of images) {
        socket.emit(Events.Client.UploadFile, file.file, file.id, messageId, roomId, senderUserId, (response: any) => {
          //console.log('uploadFile response:', response);
          if (response.status !== 200) {
            enqueueSnackbar(`Failed to upload file, error: ${response.message}`, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          }
        });
      }
    });
    //socket.emit('privateMessage', encryptedMessage);
  };

  const setActiveRoomId = (roomId: number) => {
    setState({...state, activeRoomId: roomId});
  };

  const addMessage = (message: any) => {
    //console.log('addMessage:', message);
    if (message.roomId !== state.activeRoomId) {
      // Skip any incoming messages that are not for the active chat room
      return;
    }
    setState({...state, messages: [...state.messages, message]});
  };

  const addMessages = useCallback((messages: any[], clearExisting = true) => {
    //console.log('addMessages:', messages, clearExisting);
    const newMessages = clearExisting
      ? messages
      : [...state.messages, ...messages];
    setState({...state, messages: newMessages});
  }, [state]);

  const updateMessage = (message: any) => {
    console.log('updateMessage:', message);
    // TODO: Update message changes
  };

  const deleteMessage = (messageId: number) => {
    //console.log('deleteMessage:', messageId);
    const messages = state.messages.filter((message: any) => message.id !== messageId);
    setState({...state, messages});
  };

  const clearMessages = useCallback(() => {
    //console.log('clearMessages');
    setState({...state, messages: []});
  }, [state, setState]);

  const getChatHistory = useCallback((roomId: number) => {
    //console.log('getChatHistory:', roomId);
    if (roomId === 0) {
      return;
    }

    MessageService.getMessages(roomId).then((response: any) => {
      //console.log('getMessages response:', roomId, response);
      const pastMessages = response.messages.map((message: Message) => {
        return {
          id: message.id,
          socketId: socket?.id ?? '',
          userId: message.senderUserId,
          username: message.sender?.username,
          roomId: message.roomId,
          text: message.body ? decrypt(message.body) : '',
          date: message.createdAt,
          emojis: message.emojis,
          files: message.files,
        };
      });

      if (pastMessages?.length > 0) {
        addMessages([...pastMessages], true);
      } else {
        clearMessages();
      }
    });
  }, [addMessages, clearMessages]);

  const createVideoCallRoom = (creatorUserId: number, userIds: number[]) => {
    socket?.emit('create-room', { creatorUserId, userIds });
  };

  const joinVideoCallRoomRelay = (roomId: number, userId: number, userIds: number[]) => {
    socket?.emit('join-room-relay', { roomId, userId, userIds });
  };

  const joinVideoCallRoom = (roomId: number, userId: number) => {
    socket?.emit('join-room', { roomId, userId });
  };

  const leaveVideoCallRoom = (roomId: number, userId: number) => {
    socket?.emit('leave-room', { roomId, userId });
  };

  useEffect(() => {
    if (userId > 0) {
      socket.emit(Events.Client.GetRooms, userId);
    }
    if (state.activeRoomId > 0) {
      getChatHistory(state.activeRoomId);
    }
    // eslint-disable-next-line
  }, [userId, state.activeRoomId]);

  const context = {
    socket,
    ...state,

    sendMessage,
    addMessage,
    addMessages,
    updateMessage,
    deleteMessage,
    clearMessages,
    getChatHistory,
    setActiveRoomId,

    createVideoCallRoom,
    joinVideoCallRoom,
    joinVideoCallRoomRelay,
    leaveVideoCallRoom,
  };

  return (
    <SocketContext.Provider
      value={context}
      {...props}
    />
  );
};