import React, { Fragment, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { UploadImages } from '..';
import { buildCarName, getCarImageUrl } from '../../modules';
import { TimeService, UserService } from '../../services';
import { Car, RaceTrack } from '../../types';
 
const steps = ['Car', 'Details', 'Pictures'];

export const NewTimeDialog = (props: any) => {
  //console.log('NewMyTimeModal props:', props);
  const { user, raceTracks, onClose } = props;

  const [state, setState] = useState({
    open: false,
    id: 0,
    time: 0.00,
    speed: 0,
    reaction: 0.00,
    trackId: 0,
    carId: 0,
    userId: user.id,
    borderColor: '1px solid gray',
  });

  const handleClick = async () => {
    const model = await TimeService.createTime(state);
    //console.log('model:', model);
    handleClose();

    onClose && onClose(model.time);
  };
  const handleOpen = () => setState({...state, open: true});
  const handleClose = () => setState({...state, open: false});
 
  return (
    <>
      <Fab
        color="primary"
        size="small"
        aria-label="add"
        onClick={handleOpen}
      >
        <AddIcon />
      </Fab>
      <Dialog
        fullScreen
        open={state.open}
        onClose={handleClose}
      >
        <AppBar position="relative">
          <Toolbar>
            <Typography variant="h6" component="h3" className="text-center" sx={{ ml: 2, flex: 1 }}>
              New Track Run Time
            </Typography>
            <IconButton color="inherit" onClick={handleClose} title="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/*<DialogTitle className="bg-dark text-light">New Track Run Time</DialogTitle>*/}
        <DialogContent className="bg-dark text-light">
          <div className="container text-center" style={{maxWidth: '75%'}}>
            <p>
              Create a new track run time for your car.
            </p>
          </div>
          <CheckoutPage
            raceTracks={raceTracks}
            state={state}
            setState={setState}
            user={user}
          />
        </DialogContent>
        <DialogActions className="bg-dark text-light">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleClick}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getStepContent = (step: number, props: any) => {
  switch (step) {
    case 0:
      return <CarSelectionForm {...props} />;
    case 1:
      return <RunTimeDetailsForm {...props} />;
    case 2:
      return <CarPicturesForm {...props} />;
    default:
      throw new Error('Unknown step');
  }
};

const CheckoutPage = (props: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <Container component="main" maxWidth="md" sx={{ mb: 2 }}>
      <Paper variant="outlined" sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }} className="bg-dark text-light">
        <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fragment>
          {getStepContent(activeStep, props)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={props.state.carId === 0}
              hidden={activeStep === steps.length - 1}
              sx={{ mt: 3, ml: 1 }}
            >
              Next
            </Button>
          </Box>
        </Fragment>
      </Paper>
    </Container>
  );
};

const CarSelectionForm = (props: any) => {
  const { user } = props;
  const [cars, setCars] = useState([]);

  useEffect(() => {
    if (!user) {
      return;
    }
    UserService.getCars(user.id).then((response: any) => {
      //console.log('get cars response:', response);
      setCars(response.cars);
    });
  }, [user]);

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom className="text-center mb-3">
        Select a Car
      </Typography>
      <CarSelector cars={cars} {...props} />
    </Fragment>
  );
};

const RunTimeDetailsForm = (props: any) => {
  const { raceTracks, state, setState } = props;
  const raceTrack = raceTracks?.find((track: any) => track.id === state.trackId);

  const handleChange = (event: any) => {
    const { target: { name, type, value, checked } } = event;
    const val = type === 'checkbox'
      ? checked
      : type === 'number'
        ? value //parseFloat(value).toFixed(4)
        : value;
    setState({ ...state, [name]: val });
  };

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom className="text-center mb-3">
        Track Run Details
      </Typography>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <FormControl fullWidth className="mb-3">
            <InputLabel id="track-label">Race Track</InputLabel>
            <Select
              labelId="track-label"
              name="trackId"
              value={state.trackId}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
            >
              <MenuItem key='empty' value={0} selected={state.trackId === 0} disabled={state.trackId === 0}>
                Select a track
              </MenuItem>
              {(raceTracks?.length ?? 0) > 0 && raceTracks?.map((track: RaceTrack) => {
                return (
                  <MenuItem key={track.name} value={track.id}>
                    {track.name}
                  </MenuItem>
                );
              })}
            </Select>
            {state.trackId > 0 && (
              <>
                Address: {raceTrack.address}<br />
                Phone: {raceTrack.phone}<br />
                Website: {raceTrack.website}<br />
              </>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="track-label">Track</InputLabel>
            <Select
              autoFocus
              labelId="track-label"
              id="track"
              name="track"
              label="Race Track Length"
              fullWidth
              value={state.track}
              maxRows={10}
              onChange={handleChange}
            >
              <MenuItem value="1/8">1/8</MenuItem>
              <MenuItem value="1/4">1/4</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            margin="dense"
            id="time"
            name="time"
            label="Run Time (sec)"
            type="number"
            fullWidth
            value={state.time}
            variant="standard"
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            margin="dense"
            id="speed"
            name="speed"
            label="Top Speed (MPH)"
            type="number"
            fullWidth
            value={state.speed}
            variant="standard"
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            margin="dense"
            id="reaction"
            name="reaction"
            label="Reaction Time (sec)"
            type="number"
            fullWidth
            value={state.reaction}
            variant="standard"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const CarPicturesForm = (props: any) => {
  return (
    <Fragment>
      <div className="text-center">
        <Typography variant="h6" gutterBottom className="mb-3">
          Pictures & Videos
        </Typography>
        <UploadImages key="upload-images" {...props} />
      </div>
    </Fragment>
  );
};

const CarSelector = (props: any) => {
  const { cars } = props;

  return (
    <Box
      style={{
        overflow: 'auto',
        maxHeight: '60vh',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Grid
        container
        spacing={4}
        style={{
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {cars.map((car: Car) =>
          <Grid key={car.id} item md={4} sm={6} xs={6}>
            <CarSelectionModel car={car} {...props} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const CarSelectionModel = (props: any) => {
  const { car, state, setState } = props;
  const title = buildCarName(car);

  const onMouseHover = (e: any) => e.target.style.cursor = 'pointer';
  const onClick = () => {
    const borderColor = state.borderColor === '1px solid gray'
      ? '2px solid dodgerblue'
      : '1px solid gray';
    setState({ ...state, carId: car.id, borderColor });
  };

  const selectedBorderColor = state.carId === car.id
    ? '2px solid dodgerblue'
    : '1px solid gray';

  return (
    <Card
      sx={{ color: "white", backgroundColor: "#414549", border: selectedBorderColor }}
      onClick={onClick}
    >
      <CardHeader
        onMouseEnter={onMouseHover}
        title={title}
        titleTypographyProps={{color: "white", fontSize: '14px'}}
      />
      <CardMedia
        component="img"
        //height="128"
        width="128"
        onMouseEnter={onMouseHover}
        src={getCarImageUrl(car.id)}
        alt="Default image"
      />
    </Card>
  );
};