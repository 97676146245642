import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { PeopleOutline as ChallengeIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { ChallengeDialog } from '../Dialogs';
import { RaceTrackService, UserService } from '../../services';
import { Challenge } from '../../types';

export const ChallengeUserButton = (props: any) => {
  //console.log('ChallengeUserButton props:', props);
  const { isCurrentUser, isDisabled = false, currentUser, profile, onReload } = props;
  const [open, setOpen] = useState(false);
  const [raceTracks, setRaceTracks] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const isBlocked = UserService.isUserBlocked(profile?.id, currentUser!);
  const isChallenged = currentUser?.challenges?.findIndex((challenge: Challenge) => challenge.challengerUserId === profile?.id || challenge.challengeeUserId === profile?.id);
  const hasCars = (currentUser?.cars?.length ?? 0) > 0;
  const hasCarsProfile = (profile?.cars?.length ?? 0) > 0;
  const canChallengeUser = !isCurrentUser && !isBlocked && !isDisabled && (hasCars && hasCarsProfile);
  const challengeTooltip = canChallengeUser
    ? (isChallenged ? "Backout" : "Challenge")
    : isBlocked
      ? 'Unable to challenge, member is blocked.'
      : isDisabled
        ? 'Unable to challenge, member is disabled.'
        : !hasCars
          ? 'Unable to challenge, you have no cars.'
          : !hasCarsProfile
            ? 'Unable to challenge, member has no cars.'
            : 'Unable to challenge member at this time.';

  const handleOpen = () => {
    if (!canChallengeUser) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    RaceTrackService.getRaceTracks().then((response: any) => {
      //console.log('getRaceTracks response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to fetch race tracks ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      setRaceTracks(response.tracks);
    });
  }, [setRaceTracks, enqueueSnackbar]);

  return (
    <>
      <IconButton
        title={challengeTooltip}
        onClick={handleOpen}
        //disabled={!canChallengeUser}
      >
        <ChallengeIcon style={{color: !canChallengeUser ? 'grey' : 'white'}} />
      </IconButton>
      <ChallengeDialog
        open={open}
        title="Challenge"
        raceTracks={raceTracks}
        profile={profile}
        currentUser={currentUser}
        onClose={handleClose}
      />
    </>
  );
};