import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment';

import { sortByKey } from '../../modules';
import { ChallengeService } from '../../services';
import { Challenge } from '../../types';

const useStyles: any = (theme: Theme) => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    //maxWidth: theme.breakpoints.down('xs') ? 125 : theme.breakpoints.down('sm') ? 150 : 350,
    maxWidth: 250,
  },
});

export const LatestChallengesList = (props: any) => {
  //console.log('LatestChallengesList props:', props);
  const { showUpcoming = false, maxUpcomingChallenges = 5 } = props;
  const [challenges, setChallenges] = useState<Challenge[]>();
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    ChallengeService.getChallenges().then((response: any) => {
      //console.log('getChallenges response:', response);
      const data = response.challenges
        .filter((a: any) => showUpcoming ? new Date(a.date) >= new Date() : new Date(a.date) < new Date())
        .sort((a: any, b: any) => showUpcoming ? sortByKey(a, b, 'date') : sortByKey(b, a, 'date'))
        .slice(0, maxUpcomingChallenges);
      //console.log('challenges:', data);
      setChallenges(data);
    });
  }, [setChallenges, showUpcoming, maxUpcomingChallenges]);

  return (
    <Paper style={{borderRadius: 20, border: '1px solid black'}}>
      <List>
        {challenges && challenges?.map((challenge: Challenge) => (
          <ListItem key={challenge.id} className="text-center">
            <ListItemText className="text-nowrap">
              <div className="float-start" style={classes.ellipsis}>
                <a
                  href={`/challenges/${challenge.id}`}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <strong>
                    {challenge.challengerCar?.name} <small>({challenge.challenger?.username})</small> vs {challenge.challengeeCar?.name} <small>({challenge.challengee?.username})</small>
                  </strong>
                </a>
              </div>
              <div className="float-end">
                <small title={moment(challenge.date).toString()}>
                  {moment(challenge.date).fromNow()}
                </small>
              </div>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};