import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  TodayOutlined as TodayOutlinedIcon,
} from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { addMonths, format, getMonth, subMonths } from 'date-fns';

import { CalendarContext, getWeekDays } from '.';

const useStyles: any = ((theme: Theme) => ({
  root: {
    minHeight: 264,
    minWidth: 240,
    background: theme.palette.background.paper,
  },
  title: {
    marginLeft: theme.spacing(1),
    textTransform: 'capitalize',
  },
  dayHeader: {
    textAlign: 'center',
    fontSize: 12,
    color: theme.palette.primary.main,
    lineHeight: '26px',
    padding: theme.spacing(0.2),
    borderColor: theme.palette.background.paper,
    borderStyle: 'solid',
    textTransform: 'capitalize',
    background: theme.palette.background.paper,
    height: 34.3,
    width: 34.3,
  },
  day: {
    textAlign: 'center',
    fontSize: 12,
    cursor: 'pointer',
    borderRadius: '50%',
    borderWidth: theme.spacing(0.4),
    lineHeight: '26px',
    padding: theme.spacing(0.2),
    //background: theme.palette.background.paper,
    height: 34.3,
    width: 34.3,
  },
  today: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.background.paper,
    borderStyle: 'solid',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      //backgroundColor: theme.palette.primary[800],
    },
  },
  notToday: {
    borderColor: theme.palette.background.paper,
    borderStyle: 'solid',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  selected: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.background.paper,
    borderStyle: 'solid',
    backgroundColor: '#0055ff', //theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.text.primary,
      //backgroundColor: theme.palette.primary[600],
    },
  },
  notCurrentMonth: {
    color: theme.palette.grey[500],
    //background: theme.palette.background.paper,
  },
  navigation: {
    marginRight: theme.spacing(0.5),
  },
  tooltip: {
    marginTop: 2,
  },
  todayButton: {
    marginRight: 2,
  },
  todayIcon: {
    fontSize: '1.5rem',
    padding: 2,
  },
}));

const DaysPerWeek = 7;

export const CalendarSmall = (props: any) => {
  const { isDatepicker = false, onChangeDatepicker = () => {}, datepickerValue } = props;
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, locale } = stateCalendar;
  const theme = useTheme();
  const classes = useStyles(theme);

  //const [internalDate, setInternalDate] = useState(isDatepicker ? datepickerValue : selectedDate);
  const [internalDate, setInternalDate] = useState(isDatepicker ? datepickerValue : selectedDate);
  const [selectedInternalDate, setSelectedInternalDate] = useState(isDatepicker ? datepickerValue : null);

  useEffect(() => {
    setInternalDate(isDatepicker ? datepickerValue : selectedDate);
    !isDatepicker && selectedDate !== selectedInternalDate && setSelectedInternalDate('');
  }, [selectedDate, selectedInternalDate, isDatepicker, datepickerValue]);

  return useMemo(() => {
    const weeks = getWeekDays(internalDate, DaysPerWeek);
    const findNewDate = (props: any) => {
      const { direction } = props;
      setInternalDate(direction === '<' ? subMonths(internalDate, 1) : addMonths(internalDate, 1));
    };

    const selectDate = (props: any) => {
      const { newDate } = props;
      if (!isDatepicker) {
        setStateCalendar({ ...stateCalendar, selectedDate: newDate });
        setSelectedInternalDate(newDate);
      } else {
        onChangeDatepicker(newDate);
      }
    };

    return (
      <section style={classes.root}>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={0} wrap='nowrap'>
          <Grid item xs={8}>
            <Typography style={classes.title}>
              {format(internalDate, 'MMMM yyyy', { locale })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={0}
            wrap='nowrap'
            style={classes.navigation}
          >
            {isDatepicker && (
              <Tooltip
                title={`${format(new Date(), 'eeee, d MMMM', { locale })}`}
                style={classes.tooltip}
              >
                <IconButton
                  size='small'
                  aria-label='Today'
                  onClick={() => {
                    setInternalDate(new Date());
                  }}
                  style={classes.todayButton}
                >
                  <TodayOutlinedIcon style={classes.todayIcon} />
                </IconButton>
              </Tooltip>
            )}
            {' '}
            <Tooltip
              title='Month'
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                size='small'
                onClick={event => findNewDate({ event, direction: '<' })}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
            {' '}
            <Tooltip
              title='Month'
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                size='small'
                onClick={event => findNewDate({ event, direction: '>' })}
              >
                <ChevronRightIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={0} direction='row' justifyContent='center' alignItems='center' wrap='nowrap'>
          {weeks[0].map((weekDay: Date, index: number) => {
            return (
              <Grid item xs key={`small-calendar-column-header-${index}`}>
                <Typography style={classes.dayHeader}>
                  {format(weekDay, 'eeeee', { locale })}
                </Typography>
              </Grid>
            )
          })}
        </Grid>

        {weeks.map((week: any, weekIndex: number) => (
          <Grid
            container
            spacing={0}
            direction='row'
            justifyContent='center'
            alignItems='center'
            wrap='nowrap'
            key={`small-calendar-${weekIndex}`}
          >
            {week.map((day: any, dayIndex: number) => {
              const isToday = format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy');
              const isSelected =
                !!selectedInternalDate &&
                !isToday &&
                format(day, 'ddMMyyyy') === format(selectedInternalDate, 'ddMMyyyy');

              const isCurrentMonth = getMonth(internalDate) === getMonth(day);

              return (
                <Grid item xs key={`small-calendar-${weekIndex}-column-${dayIndex}`}>
                  <Typography
                    style={{
                      ...classes.day,
                      ...(isToday ? classes.today : classes.notToday),
                      ...(!isToday && isSelected ? classes.selected : {}),
                      ...(!isCurrentMonth ? classes.notCurrentMonth : {}),
                    }}
                    onClick={() => selectDate({ newDate: day })}
                  >
                    {day.getDate()}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </section>
    );
  }, [classes, internalDate, locale, selectedInternalDate]);
};