import http from '../modules/http-common';
import { Challenge, ChallengeResults } from '../types';

const getChallenges = async () => {
  const response = await http
    .get('challenges');
  return response.data;
};

const getChallenge = async (challengeId: number, includeImages: boolean = false) => {
  const response = await http
    .get(`challenges/${challengeId}?includeImages=${includeImages}`);
  return response.data;
};

const createChallenge = async (challenge: Challenge) => {
  const response = await http
    .post('challenges/create', { challenge });
  return response.data;
};

const deleteChallenge = async (challengeId: number) => {
  const response = await http
    .delete(`challenges/${challengeId}/delete`);
  return response.data;
};

const acceptChallenge = async (challengeId: number) => {
  const response = await http
    .get(`challenges/${challengeId}/accept`);
  return response.data;
};

const submitChallengeResults = async (challengeId: number, results: ChallengeResults) => {
  const response = await http
    .post(`challenges/${challengeId}/results`, { results });
  return response.data;
};

const addChallengeAttendee = async (challengeId: number, userId: number, voteWinningCarId: number | null = null) => {
  const response = await http
    .post(`challenges/${challengeId}/attendee`, { userId, voteWinningCarId });
  return response.data;
};

const deleteChallengeAttendee = async (challengeId: number, userId: number) => {
  const response = await http
    .delete(`challenges/${challengeId}/attendee?userId=${userId}`);
  return response.data;
};

export const ChallengeService = {
  getChallenge,
  getChallenges,
  createChallenge,
  deleteChallenge,
  acceptChallenge,
  submitChallengeResults,
  addChallengeAttendee,
  deleteChallengeAttendee,
};