import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { RaceTrackService } from '../../services';
import { getUserToken } from '../../stores';
import { RaceTrack, RaceTrackLength } from '../../types';

export const CreateRaceTrackDialog = (props: any) => {
  //console.log('CreateRaceTrackDialog props:', props);
  const { open, raceTracks, onClose } = props;
  const currentUser = getUserToken();
  const [state, setState] = useState<RaceTrack>({
    name: '',
    address: '',
    phone: '',
    website: '',
    length: RaceTrackLength.EighthMile,
    description: '',
    raceEntranceFee: 0,
    specEntranceFee: 0,
    createdByUserId: currentUser?.id,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    // Check that race track doesn't already exist with name
    const exists = !!raceTracks?.find((track: RaceTrack) => track.name === state.name) ?? false;
    if (exists) {
      enqueueSnackbar(`Unable to create race track, already exists with name ${state.name}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }

    RaceTrackService.createRaceTrack(state).then((response: any) => {
      console.log('createRaceTrack response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to create race track ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      onClose && onClose();
    });
  };

  const handleChange = (event: any) => {
    const { target: { name, value }} = event;
    setState({...state, [name]: value});
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
            {currentUser?.username}&nbsp; | &nbsp;Create New Race Track
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSubmit}
          >
            Create
          </Button>
          &nbsp;
          <IconButton
            color="inherit"
            title="Close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container component="main" sx={{ mb: 2 }}>
          <Paper
            variant="outlined"
            sx={{
              my: { xs: 1, md: 2 },
              p: { xs: 1, md: 2 },
            }}
            className="bg-dark text-light"
          >
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoFocus
                  //margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  value={state.name}
                  variant="standard"
                  onChange={handleChange}
                  className="mb-3"
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="length-label">Length</InputLabel>
                  <Select
                    autoFocus
                    labelId="length-label"
                    id="length"
                    name="length"
                    label="Race Track Length"
                    fullWidth
                    value={state.length}
                    maxRows={10}
                    onChange={handleChange}
                  >
                    <MenuItem value="1/8">1/8</MenuItem>
                    <MenuItem value="1/4">1/4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  //margin="dense"
                  id="address"
                  name="address"
                  label="Address"
                  type="text"
                  fullWidth
                  value={state.address}
                  variant="standard"
                  onChange={handleChange}
                  className="mb-3"
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                   //margin="dense"
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  type="text"
                  fullWidth
                  value={state.phone}
                  variant="standard"
                  onChange={handleChange}
                  className="mb-3"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  //margin="dense"
                  id="website"
                  name="website"
                  label="Website"
                  type="text"
                  fullWidth
                  value={state.website}
                  variant="standard"
                  onChange={handleChange}
                  className="mb-3"
                />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="Description"
                  id="description"
                  name="description"
                  className="bg-dark text-light"
                  placeholder="Write a description about this race track such as hours open..."
                  minRows={3}
                  maxRows={10}
                  value={state.description}
                  onChange={handleChange}
                  style={{
                    width: '100%',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-center">
                  Pricing
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="raceEntranceFee">
                    Racer Entrance Fee
                  </InputLabel>
                  <Input
                    id="raceEntranceFee"
                    name="raceEntranceFee"
                    type="double"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    value={state.raceEntranceFee}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="specEntranceFee">
                    Spectator Entrance Fee
                  </InputLabel>
                  <Input
                    id="specEntranceFee"
                    name="specEntranceFee"
                    type="number"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    value={state.specEntranceFee}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </DialogContent>
    </Dialog>
  );
};