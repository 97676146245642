import React from 'react'
import { Popover } from '@mui/material';
import EmojiPicker, { EmojiStyle, Theme } from 'emoji-picker-react';

export const EmojiPickerContainer = (props: any) => {
  //console.log('EmojiPickerContainer props:', props);
  const { onEmojiClick, onClose, anchorEl, openEmojiPicker } = props;

  return (
    <Popover
      open={openEmojiPicker}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <EmojiPicker
        onEmojiClick={onEmojiClick}
        theme={Theme.DARK}
        emojiStyle={EmojiStyle.GOOGLE}
      />
    </Popover>
  );
};