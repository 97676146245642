import { Time, User } from '.';

export type Car = {
  id: number;
  name: string;
  year: number;
  manufacturer: string;
  model: string;
  trim: string;
  color: string;
  description: string;
  userId: number;
  showInFleet: boolean;
  showInLeaderboard: boolean;
  specs?: CarSpecifications | null;
  mods?: CarModification[] | null;
  user?: User | null;
  images?: CarImage[] | null;
  times?: Time[];
  blockChallenges: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type CarImage = {
  id: string;
  name: string;
  url: string;
  file?: File;
};

export type CarModification = {
  carId: number;
  type: string;
  value: string;
};

export type CarSpecifications = {
  carId: number;
  engineSize: string;
  horsepower: number;
  displacement: string;
  weight: number;
  timeTo60: number;
  topSpeed: number;
  status: CarStatus;
};
  
export enum CarStatus {
  Stock = 'Stock',
  Modified = 'Modified',
  NonOp = 'Non-Operational',
};

export type CarLike = {
  carId: number;
  car: Car;
  userId: number;
  user: User;
};