import { AuthProvider } from 'react-admin';

import { set, get, clear } from './modules';
import { AuthService } from './services';
import { getUserToken, setUserToken } from './stores';

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await AuthService.login(username, password);
    if (response?.status !== 200) {
      const message = response?.message ?? 'Unknown error occurred';
      console.error('login error:', message);
      return Promise.reject(message);
    }
    set('username', username);
    setUserToken(response);
    return Promise.resolve(getUserToken());
  },
  logout: () => {
    clear('username');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () =>
    get('username') ? Promise.resolve() : Promise.reject(),
  getPermissions: () => Promise.resolve([]),
  getIdentity: () =>
    Promise.resolve(getUserToken()),
};