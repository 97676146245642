import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import moment from 'moment';

import { sortByKey } from '../../modules';
import { UserService } from '../../services';
import { User } from '../../types';

const MaxMembers = 10;

export const NewestMembersList = (props: any) => {
  const [members, setMembers] = useState<User[]>();

  useEffect(() => {
    UserService.getUsers().then((response: any) => {
      //console.log('get members response:', response);
      const users = response.users?.splice(0, MaxMembers);
      users?.sort((a: any, b: any) => sortByKey(b, a, 'createdAt'));
      setMembers(users);
    });
  }, []);

  return (
    <>
      <Paper style={{borderRadius: 20, border: '1px solid black'}}>
        <List>
          {members?.map((member: User) => {
            const joined = moment(member?.createdAt).fromNow();
            return (
              <ListItem key={member?.id}>
                <ListItemText>
                  <div className="float-start">
                    <a
                      href={`/${member?.username}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <strong>{member?.username}</strong>
                    </a>
                  </div>
                  <div className="float-end">
                    <small>
                      Joined {joined}
                    </small>
                  </div>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </>
  );
};