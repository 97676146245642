import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';

import { UserService } from '../../services';
import { enqueueSnackbar } from 'notistack';

export const UpdateAccountDetailsPage = (props: any) => {
  //console.log('update account details props:', props);

  const { user } = props;
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dob: '12/31/1969',
    company: '',
    website: '',
    country: '',
    description: '',
  });

  useEffect(() => {
    UserService.getUser(user.username).then((response: any) => {
      //console.log('get user response:', response);
      setState({
        firstName: response.user?.firstName ?? '',
        lastName: response.user?.lastName ?? '',
        gender: response.user?.gender ?? '',
        dob: response.user?.dob ?? '12/31/1969',
        company: response.user?.company ?? '',
        website: response.user?.website ?? '',
        country: response.user?.country ?? '',
        description: response.user?.description ?? '',
      });
    })
  }, [user.username]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    UserService.changeUserDetails(user.id, state).then((response: any) => {
      //console.log('change user details response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`An error occurred while updating your account details: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }

      enqueueSnackbar('User account details successfully updated.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    });
  };

  return (
    <Paper
      className="bg-dark text-light"
      elevation={6}
      style={{
        //marginTop: '16px',
        position: 'relative',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '15px',
      }}
    >
        <Typography component="h1" variant="h5">
          Update Account Details
        </Typography>
        <form
          //style={{ margin: theme.spacing(2) }}
          onSubmit={handleSubmit}
          noValidate
        >
          <Grid container spacing={2} className="my-2">
            <Grid item md={6} xs={12}>
              <TextField
                value={state.firstName}
                onInput={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                //margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={state.lastName}
                onInput={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                //margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  value={state.gender}
                  label="Gender"
                  onChange={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                >
                  <MenuItem value={0}>Male</MenuItem>
                  <MenuItem value={1}>Female</MenuItem>
                  <MenuItem value={2}>Non-Binary</MenuItem>
                  <MenuItem value={3}>Other</MenuItem>
                  <MenuItem value={4}>Prefer not to answer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} className="mb-0">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date of Birth"
                  value={moment(state.dob)}
                  onChange={(newValue: any) => setState({ ...state, dob: newValue })}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={state.company}
                onInput={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                //margin="normal"
                fullWidth
                id="company"
                label="Company Name"
                name="company"
                autoComplete="company"
                //className="mt-2"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={state.website}
                onInput={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                //margin="normal"
                fullWidth
                id="website"
                label="Website"
                name="website"
                autoComplete="website"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={state.country}
                onInput={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                //margin="normal"
                fullWidth
                id="country"
                label="Location"
                name="country"
                autoComplete="country"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={state.description}
                onInput={(e: any) => setState({ ...state, [e.target.name]: e.target.value })}
                fullWidth
                rows={3}
                id="description"
                label="Description"
                name="description"
                multiline
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            //style={{ margin: theme.spacing(3, 0, 2) }}
          >
            Update Details
          </Button>
        </form>
    </Paper>
  );
};