import { AttachFile as FileIcon } from '@mui/icons-material';

import { MessageFile } from '../../../types';
import '../ChatMessageContainer/ChatMessageContainer.css';

export const ChatMessageFileFooter = (props: any) => {
  //console.log('ChatMessageFileFooter props:', props);
  const { message } = props;

  return (
    <div className="messageFileFooter">
      {message.files && message.files?.map((file: MessageFile, index: number) => (
        <a
          key={index}
          href={`/images/messages/${file.roomId}/${file.fileName}`}
          title={file.fileName}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            flex: 1,
          }}
        >
          {file.type === 0 ? (
            <img
              src={`/images/messages/${file.roomId}/${file.fileName}`}
              alt={file.fileName}
              width={64}
              height="auto"
              style={{
                flex: 1,
              }}
            />
          ) : (
            <>
              <FileIcon />
              {file.fileName}
            </>
          )}
        </a>
      ))}
    </div>
  );
};