import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';

import { DialState } from '..';
import { useAudio, useVideoCall } from '../../hooks';

const DefaultHangupTimeout = 10; // seconds
let outgoingTimer: NodeJS.Timer | null | undefined = undefined;

interface OutgoingVideoCallDialogProps {
  open: boolean;
  playRinger: boolean;
  onCancel: (force: boolean, dialState: DialState) => void;
};

export const OutgoingVideoCallDialog = (props: OutgoingVideoCallDialogProps) => {
  //console.log('OutgoingVideoCallDialog props:', props);
  const { open, playRinger, onCancel } = props;
  let [hangupTimeout, setHangupTimeout] = useState(DefaultHangupTimeout);
  const { playing, start, stop } = useAudio('/sounds/phone-ringing.mp3');
  const { callState, peerIds, usersOnline } = useVideoCall();
  const users = peerIds.map((p) => usersOnline[p]?.username ?? p).join(', ');

  const stopRinging = useCallback(() => {
    if (playRinger && playing) {
      stop();
    }
  }, [playRinger, playing, stop]);

  const handleClose = useCallback((force: boolean, dial: DialState) => {
    stopRinging();

    clearInterval(outgoingTimer!);
    outgoingTimer = undefined;

    onCancel(force, dial);
    setHangupTimeout(DefaultHangupTimeout);
  }, [onCancel, stopRinging]);

  useEffect(() => {
    if (!open && playing) {
      stopRinging();
    }
    if (open && !outgoingTimer) {
      outgoingTimer = setInterval(() => {
        if (!open) {
          return;
        }

        if (hangupTimeout <= 0 || callState?.dialState === 'in-call') {
          handleClose(false, callState?.dialState!);
          return;
        }

        hangupTimeout--;
        setHangupTimeout(hangupTimeout);
      }, 1 * 1000);
    }

    if (open && playRinger && !playing) {
      start();
    }
  }, [
    open, playing, playRinger, hangupTimeout, callState,
    handleClose, stopRinging, start,
  ]);

  return (
    <Dialog
      open={open}
      onClick={() => handleClose(false, callState?.dialState!)}
      style={{
        //width: '500px',
      }}
    >
      <DialogTitle style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <PhoneIcon color="primary" fontSize="large" />&nbsp;Calling&nbsp;
        <IconButton
          title="Close"
          color="inherit"
          size="small"
          className="float-end"
          onClick={() => handleClose(false, callState?.dialState!)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress
          color="primary"
          size={60}
          sx={{marginRight: '1rem'}}
        />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} className="text-center">
            {/*<PhoneIcon color="primary" fontSize="large" />*/}
            &nbsp;Calling&nbsp;<b>{users}</b>...
          </Grid>
        </Grid>
        <br />
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={() => handleClose(true, callState?.dialState!)}
        >
          Hang Up {hangupTimeout < 1 ? '' : `(${hangupTimeout})`}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
//&nbsp;Calling&nbsp;<b>{user?.username ?? user?.id}</b>...