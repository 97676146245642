import { createContext } from 'react';
import { Breakpoint } from '@mui/material/styles';
import { enUS } from 'date-fns/locale';

type TypeCalendarContext = {
  stateCalendar: CalendarState;
  setStateCalendar: Function;
};

export interface CalendarState {
  selectedDate: Date;
  selectedDay: Date;
  layout: string;
  locale: Locale;
  openDialog: boolean;
  openViewDialog: boolean;
  openViewAllDialog: boolean;
  eventBeginDate: Date;
  eventBeginTime: { value: string, label: string };
  eventEndDate: Date;
  eventEndTime: { value: string, label: string };
  defaultEventDuration: number;
  modal: boolean;
  eventDialogMaxWidth: false | Breakpoint | undefined;
  eventID: number;
  fullscreen: boolean;
  allowFullScreen: boolean;
  withCloseIcon: boolean;
  title: string;
  description: string;
  content: string;
  actions: string;
  calendarEvent: any;
  calendarEvents: any[];
  draggingEventId: number;
  startDragging: boolean;
  ghostProperties: { width: number, height: number, date: Date };
};

export const CalendarContext = createContext<TypeCalendarContext>({
  stateCalendar: {
    selectedDate: new Date(),
    selectedDay: new Date(),
    layout: 'month',
    locale: enUS,
    openDialog: false,
    openViewDialog: false,
    openViewAllDialog: false,
    eventBeginDate: new Date(),
    eventBeginTime: { value: '', label: '' },
    eventEndDate: new Date(),
    eventEndTime: { value: '', label: '' },
    defaultEventDuration: 0,
    modal: false,
    eventDialogMaxWidth: 'md',
    eventID: 0,
    fullscreen: false,
    allowFullScreen: false,
    withCloseIcon: true,
    title: '',
    description: '',
    content: '',
    actions: '',
    calendarEvent: {},
    calendarEvents: [],
    draggingEventId: -1,
    startDragging: false,
    ghostProperties: { width: 0, height: 0, date: new Date() },
  },
  setStateCalendar: () => {},
});