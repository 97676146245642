import React, { useCallback, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import {
  Download as DownloadIcon,
  RadioButtonChecked as StartIcon,
  StopCircle as StopIcon,
} from '@mui/icons-material';

import { downloadData } from '../../modules';

// Reference: https://codepen.io/mozmorris/pen/yLYKzyp?editors=0011
export const VideoRecorder = (props: any) => {
  //console.log('VideoRecorder props:', props);
  // mimeType: video/webm, video/mp4
  const { stream, mimeType = 'video/webm', fileName = 'stream-capture.webm' } = props;
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
    mediaRecorderRef.current?.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current?.start();
  }, [setCapturing, mediaRecorderRef, stream]);

  const handleDataAvailable = useCallback(({ data }: any) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, [setRecordedChunks]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current?.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length === 0) {
      return;
    }
    downloadData(recordedChunks, fileName, mimeType);
    setRecordedChunks([]);
  }, [recordedChunks]);

  return (
    <>
      {capturing ? (
        <IconButton
          title="Stop capturing stream"
          size="small"
          onClick={handleStopCaptureClick}
        >
          <StopIcon />
        </IconButton>
      ) : (
        <IconButton
          title="Start capturing stream"
          size="small"
          onClick={handleStartCaptureClick}
        >
          <StartIcon />
        </IconButton>
      )}

      {recordedChunks.length > 0 && (
        <IconButton
          title="Download recorded stream"
          size="small"
          onClick={handleDownload}
        >
          <DownloadIcon />
        </IconButton>
      )}
    </>
  );
};