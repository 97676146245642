import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { LoginRedirectDelay, Routes } from '../consts';
import { AuthService } from '../services';

const RegisterPage = (props: any) => {
  //console.log('RegisterPage props:', props);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const submitData = async (data: any) => {
    //console.log('submitData:', data);
    try {
      const response = await AuthService.register(data.username, data.email, data.password);
      //console.log('register response:', response);
      if (response.status !== 200) {
        const errorMessage = response.data.message;
        enqueueSnackbar(`An error occurred registering account '${data.username}': ${errorMessage}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      //console.log('register data:', data);
      enqueueSnackbar(`Account '${data.username}' was successfully registered, redirecting to login page.`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      reset();
      setTimeout(() => window.location.href = Routes.login, LoginRedirectDelay);
    } catch (err: any) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      console.error('Failed to register user account:', data.username, '\nError:', errorMessage);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  };
  return (
    <Paper elevation={6}>
      <div
        className="bg-dark text-light"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography variant="h3" gutterBottom className="text-center">Register</Typography>
        <div className="card card-container bg-dark text-light p-1" style={{ maxWidth: "500px" }}>
          <div className="card-body">
            <form
              autoComplete="off"
              onSubmit={handleSubmit(submitData)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label='Username'
                    type="text"
                    autoComplete="username"
                    autoFocus
                    {...register("username", {
                      required: "Username is required!",
                    })}
                  />
                  {errors.username && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.username.message?.toString()}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label='Email Address'
                    type="email"
                    autoComplete="email"
                    {...register("email", {
                      required: "Email is required!",
                    })}
                  />
                  {errors.email && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.email.message?.toString()}
                    </p>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label='Password'
                    type="password"
                    autoComplete="current-password"
                    {...register("password", {
                      required: "Password is required!",
                    })}
                  />
                  {errors.password && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.password.message?.toString()}
                    </p>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password_confirm"
                    label='Confirm Password'
                    type="password"
                    autoComplete="confirm-password"
                    {...register("password_confirm", {
                      required: "Confirm Password is required",
    
                      validate: (value) =>
                        value === watch("password") ||
                        "Passwords don't match.",
                    })}
                  />
                  {errors.password_confirm && (
                    <p className="text-danger" style={{ fontSize: 14 }}>
                      {errors.password_confirm.message?.toString()}
                    </p>
                  )}
                </Grid>
              </Grid>
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: theme.spacing(3, 0, 2) }}
              >
                Sign up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  Already have an account?&nbsp;
                  <Link style={{ textDecoration: "none" }} to="/login">
                    Log In
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default RegisterPage;