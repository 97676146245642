import React, { useEffect, useState } from 'react';
import env from 'react-dotenv';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  Build as RepairIcon,
  Dashboard as DashboardIcon,
  EmojiEvents as EmojiEventsIcon,
  Event as EventIcon,
  People as PeopleIcon,
  TimeToLeave as CarIcon,
} from '@mui/icons-material';

import {
  IMenuProps,
  MenuItemLink,
  NavbarBrandHeader,
  SubMenu,
  useSidebarState,
} from '..';
import { Routes } from '../../consts';
import { getAvatarUrl } from '../../modules';
import { getUserToken } from '../../stores';

const useStyles: any = (theme: Theme) => ({
  root: {
    backgroundColor: '#212121',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
    width: 'auto',
    flexShrink: 0,
  },
  logoDivider: {
    marginBottom: theme.spacing(2),
    backgroundColor: 'silver',
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    textAlign: 'center',
  },
  avatar: {
    width: 84,
    height: 84,
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: 'silver',
  },
  listItem: {
    cursor: 'pointer',
    backgroundColor: '#212121',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  activeListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    //color: 'white',
    //backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    backgroundColor: '#212121',
  },
});

interface ISidebarMenuState {
  menuState: any;
  activeRoute: string;
};

export const SidebarMenu = ({ dense = false }: IMenuProps) => {
  const [state, setState] = useState<ISidebarMenuState>({
    menuState: {},
    activeRoute: window.location.pathname,
  });
  const [open, setOpen] = useSidebarState();
  const currentUser = getUserToken();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme);

  const SidebarItems = [
    { key: 'dashboard', to: Routes.home, text: 'Dashboard', icon: <DashboardIcon style={{color: state.activeRoute === Routes.home ? '#556cd6' : 'inherit'}} />, tooltip: 'Dashboard' },
    { key: 'cars', to: Routes.cars, text: 'Cars', icon: <CarIcon style={{color: state.activeRoute === Routes.cars ? '#556cd6' : 'inherit'}} />, tooltip: 'The Fleet' },
    { key: 'members', to: Routes.members, text: 'Members', icon: <PeopleIcon style={{color: state.activeRoute === Routes.members ? '#556cd6' : 'inherit'}} />, tooltip: 'Members' },
    { key: 'challenges', to: Routes.challenges, text: 'Challenges', icon: <EmojiEventsIcon style={{color: state.activeRoute === Routes.challenges ? '#556cd6' : 'inherit'}} />, tooltip: 'Challenges' },
    { key: 'events', to: Routes.events, text: 'Events', icon: <EventIcon style={{color: state.activeRoute === Routes.events ? '#556cd6' : 'inherit'}} />, tooltip: 'Events' },
    { key: 'repairs', to: Routes.repairs, text: 'Repairs', icon: <RepairIcon style={{color: state.activeRoute === Routes.repairs ? '#556cd6' : 'inherit'}} />, tooltip: 'Repairs' },
  ];

  const setActiveLink = (to: any) => {
    if (isSmall) {
      // TODO: setOpen(false);
    }
    setState({...state, activeRoute: to});
  };

  const handleToggle = (menu: string) => {
    setState((state: any) => ({
      ...state,
      [menu]: !(state && state[menu]),
    }));
  };

  // eslint-disable-next-line
  useEffect(() => setActiveLink(window.location.pathname), []);

  return (
    <Box
      sx={{
        top: 0,
        width: open ? 140 : 0, // 200
        height: '100vh',
        backgroundColor: '#212121',
        ...classes.root,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {isSmall && (
        <>
          <div style={classes.logoWrapper} onClick={() => setOpen(false)}>
            <NavbarBrandHeader
              title={env.SITE_TITLE}
              titleFontSize={14}
              logoSize={42}
              display='flex'
              justifyContent='center'
            />
          </div>
          <Divider style={classes.logoDivider} />
          <div style={classes.profile}>
            <Link to="/account/settings" onClick={() => setOpen(false)}>
              <Avatar
                src={getAvatarUrl(currentUser?.id)}
                alt=""
                style={classes.avatar}
              />
            </Link>
            <Typography
              style={classes.nameText}
              variant="h6"
            >
              {currentUser?.firstName} {currentUser?.lastName}
            </Typography>
            <Typography variant="subtitle1">
              {currentUser?.username}
            </Typography>
          </div>
          <Divider style={classes.profileDivider} />
        </>
      )}
      {SidebarItems?.map((item: any) => {
        if (item.to) {
          return (
            <MenuItemLink
              key={item.key}
              to={item.to}
              state={{ _scrollToTop: true }}
              primaryText={item.text}
              leftIcon={item.icon}
              title={item.title}
              dense={dense}
              style={state.activeRoute === item.to ? classes.activeListItem : classes.listItem}
              onClick={setActiveLink}
            />
          );
        } else {
          const children = [];
          for (const child of item.children) {
            children.push(
              <MenuItemLink
                key={child.key}
                to={child.to}
                state={{ _scrollToTop: true }}
                primaryText={child.text}
                leftIcon={child.icon}
                title={child.title}
                dense={dense}
                style={state.activeRoute === item.to ? classes.activeListItem : classes.listItem}
                onClick={setActiveLink}
              />
            )
          }
          return (
            <SubMenu
              key={item.key}
              handleToggle={() => handleToggle(item.text)}
              isOpen={!state.menuState[item.text]}
              name={item.text}
              icon={item.icon}
              dense={dense}
            >
              {children}
            </SubMenu>
          );
        }
      })}

      {/*<Divider style={classes.profileDivider} />*/}
      
      <div
        style={{
          backgroundColor: '#212121',
          display: 'flex',
          flexGrow: 2,
          flex: 1,
          flexDirection: 'column',
          //marginTop: 'calc(100vh - 30em)',
          //marginTop: 'calc(100% - 100vh)',
          //marginTop: 'calc(100vh - 19.3vh)',
          //marginTop: 'calc(100vh - 80em)',
          height: isSmall ? 'auto' : '100%',
        }}
      >
        {/*
        <MenuItemLink
          key="feedback"
          to=""
          state={{ _scrollToTop: true }}
          primaryText="Feedback"
          leftIcon={<FeedbackIcon />}
          dense={dense}
          style={{
            ...style,
          }}
          onClick={(event: any) => {
            event.preventDefault();
            setState({...state, openFeedback: true});
          }}
        />
        */}
      </div>
    </Box>
  );
};

/*
import React, {  useEffect, useState } from 'react';
import {
  Box,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
  //Collections as CollectionsIcon,
  Dashboard as DashboardIcon,
  EmojiEvents as EmojiEventsIcon,
  Event as EventIcon,
  //Leaderboard as LeaderboardIcon,
  People as PeopleIcon,
  //Settings as SettingsIcon,
  TimeToLeave as CarIcon,
} from '@mui/icons-material';

import { Routes } from '../../consts';
import {
  IMenuProps,
  MenuItemLink,
  SubMenu,
  useSidebarState,
} from '.';
import { isActive } from '../../modules';

export const SidebarMenu = ({ dense = false }: IMenuProps) => {
  const SidebarItems = [
    { key: 'dashboard', to: Routes.home, text: 'Dashboard', icon: <DashboardIcon style={{color: isActive(Routes.home) ? '#556cd6' : 'inherit'}} />, tooltip: 'Dashboard' },
    { key: 'cars', to: Routes.cars, text: 'Cars', icon: <CarIcon style={{color: isActive(Routes.cars) ? '#556cd6' : 'inherit'}} />, tooltip: 'The Fleet' },
    { key: 'members', to: Routes.members, text: 'Members', icon: <PeopleIcon style={{color: isActive(Routes.members) ? '#556cd6' : 'inherit'}} />, tooltip: 'Members' },
    //{ key: 'events-menu', text: 'Events', icon: <EventIcon style={{color: isActive('/events') ? '#556cd6' : 'inherit'}} />, tooltip: 'Events', children: [
      { key: 'challenges', to: Routes.challenges, text: 'Challenges', icon: <EmojiEventsIcon style={{color: isActive(Routes.challenges) ? '#556cd6' : 'inherit'}} />, tooltip: 'Challenges' },
      { key: 'events', to: Routes.events, text: 'Events', icon: <EventIcon style={{color: isActive(Routes.events) ? '#556cd6' : 'inherit'}} />, tooltip: 'Events' },
      //{ key: 'leaderboard', to: '/leaderboard', text: 'Leaderboard', icon: <LeaderboardIcon style={{color: isActive('/leaderboard') ? '#556cd6' : 'inherit'}} />, tooltip: 'Leaderboard' },
    //] },  
    //{ key: 'showcase', to: '/showcase', text: 'Showcase', icon: <CollectionsIcon style={{color: isActive('/showcase') ? '#556cd6' : 'inherit'}} />, tooltip: 'Showcase' },
  ];

  const [menuState, setMenuState] = useState<any>({});
  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  const [open] = useSidebarState();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const setActiveLink = () => setActiveRoute(window.location.pathname);

  const handleToggle = (menu: string) => {
    setMenuState((state: any) => ({
      ...menuState,
      [menu]: !(state && state[menu]),
    }));
  };

  useEffect(() => setActiveLink(), []);

  const style: any = {
    backgroundColor: '#414141',
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 52,
        marginTop: 0,
        top: isSmall ? '10px' : '60px',
        //position: 'sticky',
        position: open ? 'relative' : 'absolute',
        height: '100%',
        ...style,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {SidebarItems?.map((item: any) => {
        if (item.to) {
          return (
            <MenuItemLink
              key={item.key}
              to={item.to}
              state={{ _scrollToTop: true }}
              primaryText={item.text}
              leftIcon={item.icon}
              title={item.title}
              dense={dense}
              style={style}
              onClick={setActiveLink}
            />
          );
        } else {
          const children = [];
          for (const child of item.children) {
            children.push(
              <MenuItemLink
                key={child.key}
                to={child.to}
                state={{ _scrollToTop: true }}
                primaryText={child.text}
                leftIcon={child.icon}
                title={child.title}
                dense={dense}
                style={style}
                onClick={setActiveLink}
              />
            )
          }
          return (
            <SubMenu
              key={item.key}
              handleToggle={() => handleToggle(item.text)}
              isOpen={!menuState[item.text]}
              name={item.text}
              icon={item.icon}
              dense={dense}
            >
              {children}
            </SubMenu>
          );
        }
      })}
      
      <div
        style={{
          ...style,
          display: 'flex',
          flexGrow: 2,
          flex: 1,
          flexDirection: 'column',
          //marginTop: 'calc(100vh - 30em)',
          marginTop: 'calc(100vh - 19.3vh)',
          //marginTop: '100vh',
          //marginTop: 'calc(100vh - 17.5em)',
          //marginTop: '100vh',
          height: '100%',
        }}
      >
        {/*
        <MenuItemLink
          key="feedback"
          to=""
          state={{ _scrollToTop: true }}
          primaryText="Feedback"
          leftIcon={<FeedbackIcon />}
          dense={dense}
          style={{
            ...style,
          }}
          onClick={(event: any) => {
            event.preventDefault();
            setState({...state, openFeedback: true});
          }}
        />
        //}
      </div>
    </Box>
  );
};
*/