import React, { useState } from 'react';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';

import {
  CarsDialog,
  FollowersDialog,
  FollowingDialog,
} from './Dialogs';
import { User } from '../types';

interface ILabelDialogProps {
  label: string;
  value: string;
  profile: User | null;
  currentUser: any | null;
  //dialog: ReactNode | null;
  //onClick: () => void;
};

export const CarsLabelDialog = (props: ILabelDialogProps) => {
  //console.log('CarsLabelDialog props:', props);
  const { currentUser, profile, label, value/*, onClick*/ } = props;
  const [open, setOpen] = useState(false);

  const handleOpenDialogClick = () => {
    setOpen(true);
    //onClick && onClick();
  };
  const handleCloseDialogClick = () => setOpen(false);

  const style = {
    statLabel: {
      fontSize: 12,
      color: grey[500],
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 4,
      letterSpacing: '1px',
    },
  };

  return (
    <>
      <Box p={1} flex={'auto'}>
        <p style={style.statLabel}>{label}</p>
        <p
          title={`View ${profile?.username}'s Garage`}
          style={{...style.statValue, cursor: 'pointer'}}
          onClick={handleOpenDialogClick}
        >
          {value}
        </p>
      </Box>
      <CarsDialog
        open={open}
        onClose={handleCloseDialogClick}
        title={label}
        profile={profile}
        currentUser={currentUser}
      />
    </>
  );
};

export const FollowersLabelDialog = (props: ILabelDialogProps) => {
  //console.log('FollowersLabelDialog props:', props);
  const { currentUser, profile, label, value/*, onClick*/ } = props;
  const [open, setOpen] = useState(false);

  const handleOpenDialogClick = () => {
    setOpen(true);
    //onClick && onClick();
  };
  const handleCloseDialogClick = () => setOpen(false);

  const style = {
    statLabel: {
      fontSize: 12,
      color: grey[500],
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 4,
      letterSpacing: '1px',
    },
  };

  return (
    <>
      <Box p={1} flex={'auto'}>
        <p style={style.statLabel}>{label}</p>
        <p
          title={`View ${profile?.username}'s followers`}
          style={{...style.statValue, cursor: 'pointer'}}
          onClick={handleOpenDialogClick}
        >
          {value}
        </p>
      </Box>
      <FollowersDialog
        open={open}
        onClose={handleCloseDialogClick}
        title={label}
        profile={profile}
        currentUser={currentUser}
      />
    </>
  );
};

export const FollowingLabelDialog = (props: ILabelDialogProps) => {
  //console.log('FollowingLabelDialog props:', props);
  const { currentUser, profile, label, value/*, onClick*/ } = props;
  const [open, setOpen] = useState(false);

  const handleOpenDialogClick = () => {
    setOpen(true);
    //onClick && onClick();
  };
  const handleCloseDialogClick = () => setOpen(false);

  const style = {
    statLabel: {
      fontSize: 12,
      color: grey[500],
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 4,
      letterSpacing: '1px',
    },
  };

  return (
    <>
      <Box p={1} flex={'auto'}>
        <p style={style.statLabel}>{label}</p>
        <p
          title={`View ${profile?.username} followings`}
          style={{...style.statValue, cursor: 'pointer'}}
          onClick={handleOpenDialogClick}
        >
          {value}
        </p>
      </Box>
      <FollowingDialog
        open={open}
        onClose={handleCloseDialogClick}
        title={label}
        profile={profile}
        currentUser={currentUser}
      />
    </>
  );
};