import { User } from '.';

export type RaceTrack = {
  id?: any | null;
  name: string;
  address: string;
  phone: string;
  website: string;
  length: RaceTrackLength;
  raceEntranceFee: number;
  specEntranceFee: number;
  createdByUserId: number;
  createdByUser?: User | null;
  description: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export enum RaceTrackLength {
  EighthMile = '1/8',
  QuarterMile = '1/4',
};