import React, { useState } from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

export interface IIconButtonWithTooltipProps extends IconButtonProps {
  title: string;
  icon: React.ReactNode;
};

/**
 * An IconButton with a tooltip which ensures the tooltip is closed
 * on click to avoid ghost tooltips when the button position changes.
 */
 export const IconButtonWithTooltip = (props: IIconButtonWithTooltipProps) => {
  //console.log('IconButtonWithTooltip props:', props);
  const { title, icon, onClick, ...rest } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = (event: any) => {
    handleClose();
    onClick && onClick(event);
  };

  return (
    <Tooltip
      title={title}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <IconButton
        aria-label={title}
        onClick={handleClick}
        {...rest}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};