import { VideoCallContainer } from '../components';
//import { VideoCallContextProvider } from '../contexts';

/*
const orders: Order[] = [{
  status: 'delivered',
  basket: [{
    product_id: 123,
    quantity: 3,
  }],
  date: new Date(),
  total: 50,
}];
*/

const TestPage = () => {
  return (
    <>
      <VideoCallContainer />
      {/*<OrderChart orders={orders} />*/}
    </>
  );
};

export default TestPage;