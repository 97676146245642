import React, { ReactEventHandler } from 'react';
import classnames from 'classnames';

import { UserVideoStream, VideoDimensions } from '../../types';

import '../../css/chat/index.css';

export interface VideoSrcProps {
  id: string;
  autoPlay: boolean;
  src?: UserVideoStream;
  srcObject?: MediaStream | null;
  muted?: boolean;
  objectFit?: string;
  mirrored?: boolean;
  //videoRef?: RefObject<HTMLVideoElement>;
  onVideoRefCallback: (videoRef: HTMLVideoElement | null, video: UserVideoStream) => void;
  //onClick?: ReactEventHandler<HTMLVideoElement>;
  onLoadedMetadata?: ReactEventHandler<HTMLVideoElement>;
  onResize?: (dimensions: VideoDimensions) => void;
  onToggle: () => void;
};

const videoStyle = {
  width: '100%',
  height: 'auto',
  borderRadius: 5,
};

export class VideoSrc extends React.PureComponent<VideoSrcProps> {
  handleLoadedMetadata = (e: React.SyntheticEvent<HTMLVideoElement>) => {
    const el = e.target as HTMLVideoElement;
    this.maybeTriggerResize(el);

    if (this.props.onLoadedMetadata) {
      this.props.onLoadedMetadata(e);
    }
  }

  maybeTriggerResize = (el: HTMLVideoElement) => {
    const { onResize } = this.props;

    if (onResize && el.videoWidth && el.videoHeight) {
      onResize({
        x: el.videoWidth,
        y: el.videoHeight,
      });
    }
  }

  render() {
    const {
      id, autoPlay, mirrored, muted, src,
      onVideoRefCallback, onToggle,
    } = this.props;
    const className = classnames({
      mirrored,
    });

    return (
      <video
        id={id}
        key={'video_' + id}
        muted={muted}
        autoPlay={autoPlay}
        //playsInline={autoPlay}
        className={className}
        ref={(videoRef: HTMLVideoElement | null) => onVideoRefCallback(videoRef, src!)}
        style={videoStyle}
        // TODO: onClick={onClick}
        onLoadedMetadata={this.handleLoadedMetadata}
        onResize={(el: any) => this.maybeTriggerResize(el)}
        onDoubleClick={onToggle}
      />
    );
  };
};