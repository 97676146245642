import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { UserAvatar } from '..';
import { getAvatarUrl } from '../../modules';
import { UserService } from '../../services';
import { User } from '../../types';

export const MemberSelector = (props: any) => {
  const { currentUser, onSelectChanged } = props;
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  
  useEffect(() => {
    UserService.getUsers().then((response: any) => {
      setUsers(response.users);
    });
  }, [setUsers]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    user: User,
  ) => {
    setSelectedUser(user);
    onSelectChanged && onSelectChanged(user);
  };

  return (
    <Box sx={{ width: '100%', mb: 3, bgcolor: 'background.paper', borderRadius: 3 }}>
      <Typography variant="h5" gutterBottom className="text-center">Members</Typography>
      <List component="nav" aria-label="User list">
        {users && users?.map((user: User) => user.id !== currentUser.id && (
          <ListItemButton
            key={user.id}
            selected={selectedUser?.id === user.id}
            onClick={(event) => handleListItemClick(event, user)}
            style={{borderRadius: 8}}
          >
            <ListItemIcon>
              <UserAvatar
                fullName={user.username}
                fileName={getAvatarUrl(user.id)}
              />
            </ListItemIcon>
            <ListItemText primary={user.username} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};