import { Routes } from '../consts';
import http from '../modules/http-common';
import { clearUserToken } from '../stores';

const register = (username: string, email: string, password: string) => {
  return http.post('auth/register', {
    username,
    email,
    password,
  });
};

const login = async (username: string, password: string) => {
  const response = await http
    .post('auth/login', {
      username,
      password,
    });
  //console.log('login response:', response);
  const data = response.data;
  //const user = data?.data?.user;
  //if (user) {
  //  setUserToken(user);
  //}
  return data;
};

const logout = () => {
  clearUserToken();
  window.location.href = Routes.login;
};

const verifyEmail = async (code: string) => {
  try {
    const response = await http
      .get(`auth/verifyemail/${code}`);
    return response.data;
  } catch (err: any) {
    console.error('error:', err);
    return {
      status: 500,
      success: false,
      message: 'Failed to verify email.',
    };
  }
};

export const AuthService = {
  register,
  login,
  logout,
  verifyEmail,
};