import React, { useContext, useMemo } from 'react';
import format from 'date-fns/format';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
  AccessTime as AccessTimeIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';

import { CalendarContext, CreateEditEvent } from '.';
import { CalendarService } from '../../services';
import { CalendarEvent } from '../../types';

const Transition: any = React.forwardRef<unknown, TransitionProps>((props: any, ref: any) => {
  return <Slide direction='down' ref={ref} {...props} />
});

const useStyles: any = ((theme: Theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  optionsBar: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },
}));

export const CalendarEventsViewDialog = (props: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { openViewAllDialog, selectedDay, locale, calendarEvents } = stateCalendar;

  return useMemo(() => {
    const handleCloseViewDialog = () => {
      setStateCalendar({ ...stateCalendar, openViewAllDialog: false });
    };

    const handleEdit = (event: any, calendarEvent: CalendarEvent) => {
      CreateEditEvent({
        event,
        stateCalendar,
        setStateCalendar,
        calendarEvent,
      });
    };

    const handleDelete = (eventId: number) => {
      const result = window.confirm(`Are you sure that you want to delete this event?`);
      if (!result) {
        return;
      }
  
      CalendarService.deleteEvent(eventId).then((response: any) => {
        console.log('deleteEvent response:', response);
        if (response.status !== 200) {
          // TODO: Show error notification
          return;
        }
        //const events = stateCalendar.calendarEvents.filter((ev: any) => ev.id !== calendarEvent.id);
        //setStateCalendar({...stateCalendar, calendarEvents: events});
        handleCloseViewDialog();
      });
    };

    return (
      <Dialog
        fullScreen={false}
        fullWidth
        maxWidth='sm'
        open={openViewAllDialog}
        onClose={handleCloseViewDialog}
        TransitionComponent={Transition}
        keepMounted={false}
      >
        <DialogTitle>
          <span className="float-start" style={{fontSize: 18}}>
            Calendar Events - {format(selectedDay, 'eeee MMMM do yyyy')}
          </span>
          <IconButton
            aria-label='Close'
            className="float-end"
            onClick={handleCloseViewDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={classes.dialogContent}>
          {calendarEvents.length > 0 ? calendarEvents.map((calendarEvent: any) => (
            <div key={calendarEvent[0].id}>
              <div className="float-start text-center">
                <Typography variant="subtitle1" gutterBottom>{calendarEvent[0].title}</Typography>
              </div>
              <div className="float-end" style={classes.optionsBar}>
                <Tooltip title='Edit'>
                  <IconButton
                    size='medium'
                    aria-label='Edit event'
                    onClick={(eventEl: any) =>handleEdit(eventEl, calendarEvent)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                  <IconButton
                    size='medium'
                    edge='end'
                    aria-label='Delete event'
                    onClick={() => handleDelete(calendarEvent.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <br />
              <div className="mt-3">
                <Typography paragraph>
                  <AccessTimeIcon style={classes.icon} />
                  {format(calendarEvent[0].begin ? new Date(calendarEvent[0].begin) : new Date(), 'h:mm a -', { locale })}
                  {' '}
                  {format(calendarEvent[0].end ? new Date(calendarEvent[0].end) : new Date(), 'h:mm a', { locale })}
                </Typography>
              </div>
              <Typography paragraph fontSize='small'>
                {calendarEvent[0].description}
              </Typography>
              <hr />
            </div>
          )) : <>No events scheduled!</>}
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  }, [calendarEvents, classes, locale, openViewAllDialog]);
};