import {
  Typography,
} from '@mui/material';

const TermsOfServicePage = (props: any) => {
  return (
    <>
      <Typography variant="h3" gutterBottom className="text-center">Terms of Service</Typography>
    </>
  );
};

export default TermsOfServicePage;