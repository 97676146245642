import { createPortal } from 'react-dom';
import {
  ClickAwayListener,
  Collapse,
  Paper,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { CalendarSmall } from '.';

const useStyles: any = ((theme: Theme) => ({
  collapseCalendar: {
    position: "absolute",
    zIndex: 1600,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    // marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    maxWidth: 272,
  },
}));

export const DatepickerCalendar = (props: any) => {
  //console.log('DatepickerCalendar props:', props);
  const {
    datepickerValue = new Date(),
    calendarPosition = { top: 0, left: 0 },
    openCalendar,
    onClickAway,
    onChangeDateCalendar,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const popupCalendar = (
    <ClickAwayListener onClickAway={onClickAway}>
      <Collapse
        in={openCalendar}
        style={{
          ...classes.collapseCalendar,
          top: calendarPosition.top,
          left: calendarPosition.left,
        }}
      >
        <Paper style={classes.paper}>
          <CalendarSmall
            isDatepicker={true}
            onChangeDatepicker={onChangeDateCalendar}
            datepickerValue={datepickerValue}
          />
        </Paper>
      </Collapse>
    </ClickAwayListener>
  );

  const appRoot = document.body;
  return createPortal(popupCalendar, appRoot);
};