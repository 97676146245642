import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { PersonAdd as PersonAddIcon } from '@mui/icons-material';

import { MessageContactsDialog, UserAvatarGroup } from '../..';
import { useContacts, useSockets } from '../../../hooks';
import { substr } from '../../../modules';
import { User } from '../../../types';

export interface ChatMessageHeaderProps {
  //selectedContacts: { id: number, username: string }[];
  activeRoomId: number;
  user: User | undefined;
};

export const ChatMessageHeader = (props: ChatMessageHeaderProps) => {
  //console.log('ChatMessageHeader props:', props);
  const { activeRoomId, user } = props;
  const [open, setOpen] = useState(false);
  const { startChat } = useContacts();
  const { rooms } = useSockets();

  const handleStartMessage = (socket: any, roomId: number, selectedRoom: any) => {
    //console.log('handleStartMessage:', socket, roomId, selectedRoom);
    if (selectedRoom?.users?.length > 0) {
      const selectedUserIds = selectedRoom?.users?.map((user: any) => user.user?.userId) ?? [...selectedRoom] ?? [];
      startChat(socket, roomId, selectedUserIds!);
    } else {
      startChat(socket, roomId, selectedRoom);
    }
    //handleCloseContactsDialog();
  };

  const buildTitle = () => {
    const contact = rooms.find((c: any) => c.id === activeRoomId);
    const usernames = contact?.users
      ?.map((c: any) => c.user?.username)
    ?? [];
    const title = substr(usernames.join(', '), 25, true);
    return title;
  };

  const getUserIds = () => {
    const contact = rooms.find((c: any) => c.id === activeRoomId);
    const userIds = contact?.users
      ?.filter((c: any) => c.user?.id !== user?.id)
      ?.map((c: any) => c.user?.id)
    ?? [];
    return userIds;
  };

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userIds = getUserIds();

  return (
    <div
      className="text-center"
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        width: 'calc( 100% - 20px )',
        justifyContent: 'space-between',
        alignItems: 'center',
        //display: 'flex',
      }}
    >
      <div className="float-start">
        <UserAvatarGroup userIds={userIds} />
      </div>

      {buildTitle()}
      <IconButton
        title="Add user to current conversation"
        onClick={handleClick}
        className="float-end"
      >
        <PersonAddIcon />
      </IconButton>

      {open && (
        <MessageContactsDialog
          userId={user?.id}
          roomId={activeRoomId}
          ignoreUserIds={userIds}
          onClick={handleStartMessage}
          onClose={handleClose}
        />
      )}
    </div>
  );
};