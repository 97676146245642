import React, { useState } from 'react';
import { Paper, useMediaQuery } from '@mui/material';
import moment from 'moment';

import {
  Contact,
  IMessageContactsState,
  MessageContactsDialog,
  MessageContactsHeader,
  MessageSettingsDialog,
} from '../..';
import { useContacts, useSockets } from '../../../hooks';
import { getUserToken } from '../../../stores';

import './Contacts.css';
import '../ChatMessageContainer/ChatMessageContainer.css';

export const MessageContacts = (props: any) => {
  //console.log('MessageContacts props:', props);
  const [state, setState] = useState<IMessageContactsState>({
    openContactsDialog: false,
    openSettingsDialog: false,
    miniContacts: false,
  });
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const { startChat } = useContacts();
  const currentUser = getUserToken();
  const { socket, rooms, activeRoomId, setActiveRoomId } = useSockets();

  const handleOpenContactsDialog = () => setState({...state, openContactsDialog: true});
  const handleCloseContactsDialog = () => setState({...state, openContactsDialog: false});
  const handleOpenSettingsDialog = () => setState({...state, openSettingsDialog: true});
  const handleCloseSettingsDialog = () => setState({...state, openSettingsDialog: false});

  const handleStartMessage = (socket: any, roomId: number, selectedRoom: any) => {
    //console.log('handleStartMessage:', socket, roomId, selectedRoom);
    if (roomId > 0) {
      setActiveRoomId(roomId);
    } else if (selectedRoom?.id > 0) {
      setActiveRoomId(selectedRoom.id);
    }
    
    if (selectedRoom?.users?.length > 0) {
      const selectedUserIds = selectedRoom?.users?.map((user: any) => user.user?.id) ?? [...selectedRoom] ?? [];
      startChat(socket, roomId, selectedUserIds!);
    } else {
      startChat(socket, 0, selectedRoom);
    }
  };

  return (
    <>
      <div className="users-contacts">
        <Paper className="paper" elevation={2}>
          <MessageContactsHeader
            miniContacts={state.miniContacts}
            isLarge={isLarge}
            onOpenContactsDialog={handleOpenContactsDialog}
            onOpenSettingsDialog={handleOpenSettingsDialog}
          />
          {rooms && rooms?.map((contact: any) => (
            <Contact
              key={contact.id}
              userId={currentUser?.id}
              contact={contact}
              activeRoomId={activeRoomId}
              showUsername={!state.miniContacts && isLarge}
              onClick={() => handleStartMessage(socket, contact.id, contact)}
              //isUnread={contact.unread}
              showMessagePreview={isLarge}
              showDate={isLarge}
              date={moment(contact.updatedAt).calendar()}
            />
          ))}
        </Paper>
      </div>
      {state.openContactsDialog && (
        <MessageContactsDialog
          userId={currentUser?.id}
          roomId={0}
          onClick={handleStartMessage}
          onClose={handleCloseContactsDialog}
        />
      )}
      {state.openSettingsDialog && (
        <MessageSettingsDialog
          onClose={handleCloseSettingsDialog}
        />
      )}
    </>
  );
};