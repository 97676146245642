import { createTheme } from '@mui/material';

const AccentColor = '#556cd6';
const BackColor = '#212121';
const FontColor = 'white';

export const MuiDataTableTheme = createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          color: FontColor,
          backgroundColor: BackColor,
        },
        paper: {
          //boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: FontColor,
          backgroundColor: BackColor, //AccentColor,
        },
      },
    },
    MuiTableRow: {},
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: FontColor,
        },
        body: {
          color: FontColor,
        },
        footer: {
          color: FontColor,
          backgroundColor: AccentColor,
          borderBottom: 'none',
        },
        paddingCheckbox: {
          color: FontColor,
        },
      },
    },
    MuiTableHead: {},
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: FontColor,
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          //backgroundColor: BackColor,
          backgroundColor: AccentColor,
        },
        checkboxRoot: {
          color: FontColor,
        },
      },
    },
    MUIDataTableHead: {},
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: FontColor,
          backgroundColor: AccentColor,
        },
        contentWrapper: {
          color: FontColor,
        },
        toolButton: {
          color: FontColor,
        },
        sortActive: {
          color: FontColor,
        },
        sortAction: {
          color: FontColor,
        },
        sortLabelRoot: {
          color: FontColor,
        },
        data: {
          color: FontColor,
        },
      },
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          color: FontColor,
          backgroundColor: BackColor,
        },
        iconButton: {
          color: FontColor,
          backgroundColor: 'red',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: FontColor,
        },
        icon: {
          color: FontColor,
        },
        iconDirectionAsc: {
          color: FontColor,
        },
        iconDirectionDesc: {
          color: FontColor,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            backgroundColor: AccentColor,
          },
        },
      },
    },
  },
});