import React, { ChangeEvent, useState } from 'react';

export const UploadImage = (props: any) => {
  //console.log('upload image props:', props);
  const [state, setState] = useState({
    file: props.state.profile ?? {},
    preview: props.state.profile ? URL.createObjectURL(props.state.profile) : '',
    message: '',
  });

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }

    const file: File = files[0];
    setState({
      file: file,
      preview: URL.createObjectURL(file),
      message: '',
    });
    props.setState({...props.state, profile: file});
  };

  return (
    <div>
      <label className="btn btn-default p-0 pb-3">
        <input
          id="file"
          name="file"
          type="file"
          accept="image/*"
          onChange={selectFile}
          style={{
            color: 'white',
            width: '100%',
          }}
        />
      </label>
      <br />

      <img
        src={state.preview}
        alt=""
        style={{
          objectFit: 'cover',
          width: '100%',
          height: 'auto',
          maxWidth: '200px',
          maxHeight: '200px',
        }}
      />
      {state.message && (
        <div className="alert alert-secondary mt-3" role="alert">
          {state.message}
        </div> 
      )}
    </div>
  );
};