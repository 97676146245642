import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { ImageGallerySwiper, PhotoAlbumUploader } from '..';
import { ImageService } from '../../services';

interface IPhotoAlbumDialogProps {
  album: any;
  userId: number;
  open: boolean;
  editMode: boolean;
  onClose: () => void;
  onReload: () => void;
};

export const PhotoAlbumDialog = (props: IPhotoAlbumDialogProps) => {
  //console.log('PhotoAlbumDialog props:', props);
  const { album, userId, open, onClose, onReload, editMode = true } = props;
  const [albumImages, setAlbumImages] = useState(album?.images);
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteAlbum = () => {
    const result = window.confirm(`You are about to delete photo album '${album?.name}' and all ${albumImages.length} photos in it.\n\nProceeding with this action is irreversible and all photos are permanently deleted from our systems, are you sure that you want to do this?`);
    if (!result) {
      return;
    }
    ImageService.deleteAlbum(album?.name, userId).then((response: any) => {
      //console.log('deleteAlbum response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to delete photo album ${album?.name}, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      enqueueSnackbar(`Successfully deleted photo album ${album?.name}`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      onClose();
    });
  };

  const handleReloadImages = (callOnReload: boolean = false) => {
    const albumPath = `albums/${userId}/${album?.name}`;
    ImageService.getImages(albumPath).then((response: any) => {
      //console.log('getImages response:', response);
      setAlbumImages(response.images);
      if (callOnReload) {
        onReload && onReload();
      }
    });
  };

  useEffect(() => handleReloadImages(), [userId, album]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography
            variant="h6"
            component="h3"
            className="text-center"
            sx={{ ml: 2, flex: 1 }}
          >
            {album?.name}
          </Typography>
          <IconButton
            color="inherit"
            title="Close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {editMode && (
          <PhotoAlbumUploader
            //albumName={album?.name}
            folderName={`albums/${userId}/${album?.name}`}
            //userId={userId}
            onReloadImages={handleReloadImages}
          />
        )}
        <ImageGallerySwiper
          images={albumImages}
          editMode={editMode}
          onReload={handleReloadImages}
        />
      </DialogContent>
      <DialogActions className="mb-1">
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
        {editMode && (
          <Button variant="contained" color="error" onClick={handleDeleteAlbum}>
            Delete Album
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};