import 'bootstrap/dist/css/bootstrap.css';
import '@fontsource/roboto/400.css';
import './css/index.css'

import ReactDOM from 'react-dom/client';
import { createStore } from 'redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import App from './App';
import { AuthProvider } from './contexts';
import rootReducers from './stores/reducer';
import theme from './themes/theme';

const store = createStore(rootReducers);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SnackbarProvider>
  </ThemeProvider>
);