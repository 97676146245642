import {
  InputAdornment,
  TextField,
} from '@mui/material';

export const TextFieldWithIcon = (props: any) => {
  const { id, name, text, value, icon, onChange } = props;
  return (
    <TextField
      id={id}
      name={name}
      label={text}
      value={value}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        ),
      }}
      variant="standard"
      onChange={onChange}
    />
  );
};