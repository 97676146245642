import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
} from '@mui/material';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { TextFieldWithIcon } from '../../components';
import { UserService } from '../../services';
import { UserSocial } from '../../types';

export const UpdateUserSocialProfiles = (props: any) => {
  //console.log('UpdateUserSocialProfiles props:', props);
  const { user } = props;
  const [state, setState] = useState<UserSocial>({
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    tiktok: '',
    linkedin: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    UserService.getUser(user.username).then((response: any) => {
      //console.log('get user settings response:', response);
      const social = response.user?.social;
      setState({
        ...state,
        facebook: social?.facebook,
        twitter: social?.twitter,
        instagram: social?.instagram,
        youtube: social?.youtube,
        tiktok: social?.tiktok,
        linkedin: social?.linkedin,
      });
    });
  }, [state, user.username]);

  const handleChange = (event: any) => {
    const { target: { name, value } } = event;
    setState({...state, [name]: value});
  };

  const handleSubmit = (event: any) => {
    UserService.changeSocialProfiles(user.id, state).then((response: any) => {
      //console.log('change social profiles response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(response.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      enqueueSnackbar(`Updated social profiles successfully.`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextFieldWithIcon
          id="facebook"
          name="facebook"
          text="Facebook"
          value={state.facebook}
          icon={<FacebookIcon />}
          onChange={handleChange}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFieldWithIcon
          id="twitter"
          name="twitter"
          text="Twitter"
          value={state.twitter}
          icon={<TwitterIcon />}
          onChange={handleChange}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFieldWithIcon
          id="instagram"
          name="instagram"
          text="Instagram"
          value={state.instagram}
          icon={<InstagramIcon />}
          onChange={handleChange}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFieldWithIcon
          id="youtube"
          name="youtube"
          text="YouTube"
          value={state.youtube}
          icon={<YouTubeIcon />}
          onChange={handleChange}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFieldWithIcon
          id="tiktok"
          name="tiktok"
          text="TikTok"
          value={state.tiktok}
          icon={<TwitterIcon />}
          onChange={handleChange}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextFieldWithIcon
          id="linkedin"
          name="linkedin"
          text="LinkedIn"
          value={state.linkedin}
          icon={<LinkedInIcon />}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
        >
          Update Profiles
        </Button>
      </Grid>
    </Grid>
  )
};