import { IconButton } from '@mui/material';
import {
  PersonAdd as PersonAddIcon,
  PersonRemove as PersonRemoveIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { createNotification } from '../../modules';
import { RelationshipService } from '../../services';

export const FollowUserButton = (props: any) => {
  //console.log('FollowUserButton props:', props);
  const { isCurrentUser, isDisabled = false, currentUser, profile, onReload } = props;
  const isFollowing = RelationshipService.isFollowingUser(profile?.id, currentUser);
  const { enqueueSnackbar } = useSnackbar();

  const onFollowClick = () => {
    if (isFollowing) {
      RelationshipService.deleteRelationship(profile?.id, currentUser?.id).then((response: any) => {
        //console.log('deleteRelationship response:', response);
        enqueueSnackbar(`You stopped following ${profile?.username}`, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        createNotification(currentUser?.id, profile?.id, `${currentUser?.username} stopped following ${profile?.username}`);
        onReload && onReload();
      });
    } else {
      RelationshipService.createRelationship(profile?.id, currentUser?.id).then((response: any) => {
        //console.log('createRelationship response:', response);
        enqueueSnackbar(`You started following ${profile?.username}`, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        createNotification(currentUser?.id, profile?.id, `${currentUser?.username} started following ${profile?.username}`);
        onReload && onReload();
      });
    }
  };

  return (
    <IconButton
      title={isFollowing ? `Unfollow user ${profile?.username}` : `Follow user ${profile?.username}`}
      onClick={onFollowClick}
      //className="mx-3"
      disabled={isCurrentUser || isDisabled}
    >
      {isFollowing
        ? <PersonRemoveIcon style={{color: isCurrentUser || isDisabled ? 'grey' : 'white'}} />
        : <PersonAddIcon style={{color: isCurrentUser || isDisabled ? 'grey' : 'white'}} />}
    </IconButton>
  );
};

