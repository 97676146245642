export const DefaultAspectRatio = 16 / 9;

export type GridKind = 'auto' | 'legacy' | 'aspect';
export const SettingsGridAuto: GridKind = 'auto';
export const SettingsGridLegacy: GridKind = 'legacy';
export const SettingsGridAspect: GridKind = 'aspect';

export type MediaKind = 'audio' | 'video';
export const MediaKindVideo: MediaKind = 'video';
export const MediaKindAudio: MediaKind = 'audio';

export type DialState = 'hung-up' | 'dialing' | 'in-call';
export const DialStateHungUp: DialState = 'hung-up';
export const DialStateDialing: DialState = 'dialing';
export const DialStateInCall: DialState = 'in-call';

export type Panel = 'chat' | 'users' | 'settings';
export const SidebarPanelChat: Panel = 'chat';
export const SidebarPanelSettings: Panel = 'settings';
export const SidebarPanelUsers: Panel = 'users';

export const DEVICE_DISABLED_ID = 'disabled';
export const DEVICE_DEFAULT_ID = '';