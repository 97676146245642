import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Instagram as InstagramIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import {
  AvatarChanger,
  CarDetailsForm,
  CarSpecificationsForm,
  ImageGallerySwiper,
  PhotoAlbumUploader,
} from '..';
import { buildCarName, getCarImageUrl } from '../../modules';
import { CarService } from '../../services';

export const EditCarDetails = (props: any) => {
  //console.log('EditCarDetails props:', props);
  const { car, onClose } = props;

  const [state, setState] = useState({
    id: car?.id,
    name: car?.name ?? '',
    year: car?.year ?? '1969',
    manufacturer: car?.manufacturer ?? '',
    model: car?.model ?? '',
    trim: car?.trim ?? '',
    color: car?.color ?? 'Black',
    description: car?.description ?? '',
    showInFleet: car?.showInFleet ?? false,
    showInLeaderboard: car?.showInLeaderboard ?? false,
    userId: car?.userId,
    profile: getCarImageUrl(car),
    images: [],
    videos: [],
    blockChallenges: car?.blockChallenges ?? false,
    specs: car?.specs,
    mods: car?.mods,
  });
  const [specs, setSpecs] = useState(car?.specs ?? {
    engineSize: '',
    horsepower: 0,
    displacement: '',
    weight: 0,
    timeTo60: 0,
    topSpeed: 0,
    status: 'Stock',
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    state.specs = specs;
    CarService.updateCar(state).then((response: any) => {
      //console.log('updateCar response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to update car details: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }

      enqueueSnackbar(`Successfully updated ${state.name}'s car details.`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      onClose && onClose();
    });
  };

  const handleChange = (event: any) => {
    const { target: { name, value, type, checked } } = event;
    const val = type === 'checkbox' 
      ? checked
      : value;
    setState({...state, [name]: val});
  };

  const handleSpecsChange = (event: any) => {
    const { target: { name, value, type, checked } } = event;
    const val = type === 'checkbox'
      ? checked
      : value;
    setSpecs({ ...specs, [name]: val });
    console.log('specs:', specs);
  };

  const handleReloadImages = () => {
    // TODO: Reload car images
    console.log('handleReloadImages');
  };

  return (
    <Container sx={{ pt: 5 }}>
      <Button
        variant="contained"
        size="small"
        className="float-end mx-3"
        onClick={handleSubmit}
      >
        Save
      </Button>

      <Typography variant="h4" gutterBottom className="text-center">
        Edit Car {buildCarName(car)}
      </Typography>
      <Typography variant="h6" gutterBottom className="text-center">
        aka "{car?.name}"
      </Typography>
      <Grid container spacing={2} className="mt-1 text-center">
        <Grid item sm={4} xs={12} className="mb-3">
          <Link to={`https://instagram.com/${car?.user?.username}`} className="instagram-tag mb-3" target="_blank">
            <Typography variant="h5" gutterBottom>
              <InstagramIcon />&nbsp;@{car?.user?.username}
            </Typography>
          </Link>
          <div className="mb-3" style={{display: 'flex', justifyContent: 'center'}}>
            <AvatarChanger
              path={getCarImageUrl(car?.id)}
              title={car.name}
            />
          </div>
          <Typography variant="h6" gutterBottom className="text-center mb-3">
            Details
          </Typography>
          <CarDetailsForm
            state={state}
            showDefaultUpload={false}
            onChange={handleChange}
          />
          <Grid item xs={12} className="mb-3">
            <Typography variant="h6" gutterBottom className="text-center">
              Specifications
            </Typography>
            <CarSpecificationsForm
              specs={specs}
              onChange={handleSpecsChange}
            />
          </Grid>
          <Grid item xs={12} className="mb-3">
            <Typography variant="h6" gutterBottom className="text-center mb-3">
              Modifications
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h5" gutterBottom className="text-center">Photos</Typography>
          <PhotoAlbumUploader
            folderName={`cars/${car?.id}`}
            onReloadImages={handleReloadImages}
          />
          <ImageGallerySwiper
            images={car?.images}
            editMode={true}
          />
        </Grid>
      </Grid>
    </Container>
  );
};