import React, { useEffect, useState } from 'react';
import {
  Box,
  Fab,
  Grid,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { CarGarageModel } from '../../components';
import { NewCarDialog, ViewCarDetailsDialog } from '../../components/Dialogs';
import { UserService } from '../../services';
import { Car } from '../../types';

interface IMyCarsPageState {
  cars: Car[];
  openViewDialog: boolean;
  openNewDialog: boolean;
  selectedCar: Car | null;
};

export const MyCarsPage = (props: any) => {
  //console.log('MyCarsPage props:', props);
  const { user } = props;
  const [state, setState] = useState<IMyCarsPageState>({
    cars: [],
    openViewDialog: false,
    openNewDialog: false,
    selectedCar: null,
  });

  const handleReload = (userId: number) => {
    UserService.getCars(userId).then((response: any) => {
      //console.log('getCars response:', response);
      setState(prevState => {
        return {...prevState, cars: response.cars}
      });
    });
  };
  const handleNewCar = () => setState({...state, openNewDialog: true });
  const handleClose = (car: Car) => {
    if (!car?.id) {
      setState({...state, openNewDialog: false});
      return;
    }
    setState({...state, openNewDialog: false, cars: [...state.cars, car]});
  };

  const handleCloseView = () => setState({...state, openViewDialog: false, selectedCar: null});

  useEffect(() => {
    if (!user) {
      return;
    }
    handleReload(user.id);
  }, [user]);

  return (
    <Box
      sx={{
        height: 'auto',
        '@media print': {
          height: 'auto !important',
        },
        width: '100%',
      }}
    >
      <div className="float-end">
        <Fab
          color="primary"
          size="small"
          aria-label="Add"
          onClick={handleNewCar}
        >
          <AddIcon />
        </Fab>
        <NewCarDialog
          user={user}
          open={state.openNewDialog}
          onClose={handleClose}
        />
      </div>
      <Grid container columnSpacing={2} spacing={2} justifyContent="center">
        {state.cars?.map((car: Car) => (
          <Grid item key={car.id} lg={4} md={6} xs={12}>
            <CarGarageModel
              car={car}
              onReload={handleReload}
            />
          </Grid>
        ))}
      </Grid>

      <ViewCarDetailsDialog
        car={state.selectedCar}
        open={state.openViewDialog}
        onClose={handleCloseView}
      />
    </Box>
  );
};