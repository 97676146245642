import React, { MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Badge,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import {
  AccountCircle as AccountIcon,
  //AdminPanelSettings as AdminPanelSettingsIcon,
  Collections as PhotoGalleryIcon,
  Garage as GarageIcon,
  Logout as LogoutIcon,
  Message as MessageIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Star as CarIcon,
} from '@mui/icons-material';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { useSnackbar } from 'notistack';

import { Notify, NotifyButtonsPosition } from '..';
import { DefaultUserAvatarBackColor, DefaultUserAvatarForeColor } from '../../consts';
import { SearchBar } from '../Sidebar';
import { ChatMessengerDialog } from '../Dialogs';
import { getAvatarUrl } from '../../modules';
import { MessageService, NotificationService, SearchService } from '../../services';
import { Notification } from '../../types';

interface INavbarProfileProps {
  user: any;
  activeRoute?: string;
  showAdminDashboard?: boolean;
  onActiveRoute?: (event: any) => void;
  onLogout?: () => void;
};

interface INavbarProfileState {
  openMessageMenu: boolean;
  messageAnchorEl: any;
  openNotifyMenu: boolean;
  notifyAnchorEl: any;
  notifications: Notification[];
  notificationsUnread: number;
  messagesUnread: number;
};

const useStyles: any = (theme: Theme, isSmall: boolean = false) => ({
  wrapper: {
    height: '100%',
    //padding: '0px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropdownContainer: {
    listStyle: 'none',
    marginLeft: 5,
  },
  dropdownToggle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatar: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    width: isSmall ? 24 : 32,
    height: isSmall ? 24 : 32,
    borderRadius: '50%',
    border: '1px solid black',
    cursor: 'pointer',
    objectFit: 'cover',
    backgroundColor: DefaultUserAvatarBackColor,
    color: DefaultUserAvatarForeColor,
  },
  button: {
    width: isSmall ? 24 : 28, //theme.breakpoints.down('sm') ? 24 : 28,
    height: isSmall ? 24 : 28, //theme.breakpoints.down('sm') ? 24 : 28,
  },
  divider: {
    backgroundColor: 'white',
  },
});

export const NavbarProfile = (props: INavbarProfileProps) => {
  //console.log('NavbarProfile props:', props);
  const { showAdminDashboard, activeRoute, user, onActiveRoute, onLogout } = props;
  const [state, setState] = useState<INavbarProfileState>({
    openMessageMenu: false,
    messageAnchorEl: null,
    openNotifyMenu: false,
    notifyAnchorEl: null,
    notifications: [],
    notificationsUnread: 0,
    messagesUnread: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const classes = useStyles(theme, isSmall);

  const [searchResults, setSearchResults] = useState({
    users: [],
    cars: [],
    posts: [],
    comments: [],
    challenges: [],
    events: [],
  });

  const handleSearch = (text: string) => {
    SearchService.search(text).then((response: any) => {
      //console.log('search response:', response);
      setSearchResults(response.results);
    });
  };

  const handleCloseMessage = () => {
    setState({...state, openMessageMenu: false, messageAnchorEl: null});
  };
  const handleOpenMessage = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const target: any = event.currentTarget;
    const value = !state.openMessageMenu;
    setState({
      ...state,
      openMessageMenu: value,
      messageAnchorEl: value ? target : null,
    });
  };

  const handleCloseNotify = () => setState({...state, openNotifyMenu: false});
  const handleOpenNotify = (event: any) => {
    // Prevents ghost clicks
    event.preventDefault();
    setState({...state, openNotifyMenu: true, notifyAnchorEl: event.currentTarget});
  };

  const handleGoToProfile = (username: string) => window.location.href = `/${username}`;

  const NavbarItems = [
    { key: 'profile', to: `/${user?.username}`, onClick: () => handleGoToProfile(user?.username), text: 'My Profile', icon: <AccountIcon />, tooltip: 'View your public profile' },
    { key: 'garage', to: '/account/garage', text: 'My Garage', icon: <GarageIcon />, tooltip: 'My Garage' },
    { key: 'messages', to: '/account/messages', text: 'Messages', icon: <MessageIcon />, tooltip: 'Messages' },
    { key: 'notifications', to: '/account/notifications', text: 'Notifications', icon: <NotificationsIcon />, tooltip: 'Notifications' },
    { key: 'favorite-cars', to: '/account/favorites', text: 'Favorite Cars', icon: <CarIcon />, tooltip: 'Favorite Cars' },
    { key: 'gallery', to: '/account/gallery', text: 'Gallery', icon: <PhotoGalleryIcon />, tooltip: 'Photo Album Gallery' },
    { key: 'settings', to: '/account/settings', text: 'Settings', icon: <SettingsIcon />, tooltip: 'Settings' },
    { key: 'divider', to: 'divider' },
    { key: 'logout', onClick: onLogout, text: 'Logout', icon: <LogoutIcon />, tooltip: 'Logout' },
  ];

  const isActive = (path: string) => path === activeRoute;
  const getColor = (path: string) => isActive(path) ? 'white' : 'darkgray';

  const getUnreadCount = (notifications: any[]) => {
    const unread = notifications?.filter((x: any) => x.unread && !x.deleted)?.length ?? 0;
    return unread;
  };

  const handleReloadUnreadCounts = () => {
    NotificationService.getNotifications(user?.id).then((response: any) => {
      //console.log('getNotifications response:', response);
      const notifications = response.notifications;
      const unreadNotifications = getUnreadCount(notifications);
      MessageService.getUnreadCount(0, user?.id).then((response: any) => {
        //console.log('getUnreadCount response:', response);
        setState({
          ...state,
          notifications,
          notificationsUnread: unreadNotifications,
          messagesUnread: response.unread,
        });
      });
    });
  };

  const handleSeenNotify = (event: any, notification: any) => {
    //console.log('handleSeenNotify:', event, notification);
    event.preventDefault();

    if (!notification?.id || !notification?.unread) {
      return;
    }

    NotificationService.markRead(notification.id).then((response: any) => {
      //console.log('markRead response:', response);
      const notifications = state.notifications;
      for (const notify of notifications) {
        if (notify.id === notification.id) {
          notify.unread = false;
          break;
        }
      }
      const unread = getUnreadCount(notifications);
      setState({...state, notifications, notificationsUnread: unread});
    });
    //navigate(notification.url);
  };

  const handleDeleteNotify = (event: any, id: number) => {
    //console.log('handleDeleteNotify:', event, id);
    event.preventDefault();

    NotificationService.deleteNotification(id).then((response: any) => {
      //console.log('deleteNotification response:', response);
      const notifications = state.notifications.filter((x: any) => x.id !== id);
      const unread = getUnreadCount(notifications);
      setState({...state, notifications: [...notifications], notificationsUnread: unread});
    });
  };

  const handleClearNotify = () => {
    //console.log('handleClearNotify');
    NotificationService.clearNotifications(user.id).then((response: any) => {
      if (response.status !== 200) {
        enqueueSnackbar(`Failed to clear all of your notifications, error: ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      setState({...state, notifications: [], notificationsUnread: 0});
    });
  };

  // eslint-disable-next-line
  useEffect(() => handleReloadUnreadCounts(), []);

  return (
    <div style={classes.wrapper}>
      <SearchBar
        onSearch={handleSearch}
        results={searchResults}
      />

      <AppBarIconButton
        tooltip="Messages"
        ButtonIcon={MessageIcon}
        unreadCount={state.messagesUnread}
        classes={classes}
        isSmall={isSmall}
        onClick={handleOpenMessage}
      />
      <ChatMessengerDialog
        open={state.openMessageMenu}
        onClose={handleCloseMessage}
        anchor={state.messageAnchorEl}
      />

      <AppBarIconButton
        tooltip="Notifications"
        ButtonIcon={NotificationsIcon}
        unreadCount={state.notificationsUnread}
        classes={classes}
        isSmall={isSmall}
        onClick={handleOpenNotify}
      />
      <Notify
        open={state.openNotifyMenu}
        anchorEl={state.notifyAnchorEl}
        notifications={state.notifications}
        usePopover={true}
        buttonsPosition={NotifyButtonsPosition.Bottom}
        showViewAllButton={true}
        onSeen={handleSeenNotify}
        onDelete={handleDeleteNotify}
        onClose={handleCloseNotify}
        onClear={handleClearNotify}
      />
      
      {showAdminDashboard && (
        <>
        {/*
        <NavLink tag={Link} to="/admin">
          <Tooltip title="Admin Dashboard" enterDelay={300}>
            <IconButton>
              <AdminPanelSettingsIcon style={{width: '28px', height: '28px'}} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;
        </NavLink>
        &nbsp;&nbsp;&nbsp;
        */}
        </>
      )}

      <UncontrolledDropdown nav /*inNavbar*/ style={classes.dropdownContainer}>
        <DropdownToggle nav caret className="text-nowrap" style={classes.dropdownToggle}>
          <Avatar
            src={getAvatarUrl(user?.id)}
            alt={user?.username}
            style={classes.avatar}
            
          >
            {user?.username ? user?.username[0].toUpperCase() : <PersonIcon />}
          </Avatar>
          &nbsp;<strong className="profile-username text-light" style={{flex: 1}}>
            &nbsp;{user?.username}
          </strong>
        </DropdownToggle>
        <DropdownMenu dark>
          {NavbarItems && NavbarItems?.map((item: any) => (
            item.to === 'divider' ? (
              <DropdownItem divider key={item.key} style={classes.divider} />
            ) : (
              <DropdownItem
                key={item.key}
                active={isActive(item.to)}
                onClick={onActiveRoute} title={item.tooltip}
              >
                <NavLink
                  tag={Link}
                  to={item.to}
                  onClick={item.onClick}
                  style={{color: getColor(item.to)}}
                >
                  {item.icon}
                  &nbsp;{item.text}
                </NavLink>
              </DropdownItem>
            )
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

const AppBarIconButton = (props: any) => {
  const { tooltip, ButtonIcon, unreadCount, isSmall = false, classes, onClick } = props;
  const color = unreadCount > 0 ? 'white' : 'gray';
  const XIcon = <ButtonIcon style={{...classes.button, color}} />;

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton
          size={isSmall ? 'small' : 'medium'}
          onClick={onClick}
        >
          {unreadCount > 0 ? (
            <Badge
              badgeContent={unreadCount}
              overlap="circular"
              color="error"
              variant={isSmall ? 'dot' : 'standard'}
              max={isSmall ? 9 : 99}
            >
              {XIcon}
            </Badge>
          ) : (
            <>{XIcon}</>
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};