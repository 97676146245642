import { Avatar, Badge } from '@mui/material';

import { isOnline } from '../../modules';

interface IUserAvatarProps {
  fileName: string;
  fullName?: string;
  style?: any;
  size?: number;
  backColor?: string;
  color?: string;
  onClick?: () => void;
  tooltip?: string;
  showBadge?: boolean;
  lastOnline?: Date;
};

export const UserAvatar = (props: IUserAvatarProps) => {
  //console.log('UserAvatar props:', props);
  const {
    fileName,
    fullName,
    style,
    size,
    backColor = 'rgb(85, 108, 214)',
    color = 'white',
    onClick,
    tooltip,
    showBadge = false,
    lastOnline,
  } = props;

  const hasImage = fileName && fileName !== '' && fileName !== 'noImage';
  const isUserOnline = showBadge && lastOnline && isOnline(lastOnline!);

  const avatar = (
    <div title={tooltip} style={{display: 'inherit'}}>
      {hasImage ? (
        <Avatar
          src={fileName ? fileName : ' '}
          alt={fullName ? fullName : 'x'}
          title={fullName}
          style={{
            backgroundColor: backColor,
            color,
            border: '1px solid black',
            width: size || 36,
            height: size || 36,
            ...style,
          }}
          onClick={onClick}
        />
      ) : (
        <Avatar
          alt={fullName ? fullName : 'x'}
          title={fullName}
          style={{
            backgroundColor: backColor,
            color,
            border: '1px solid black',
            width: size || 36,
            height: size || 36,
            fontSize: (size || 36) / 2,
            ...style,
          }}
          onClick={onClick}
        >
          {fullName ? fullName?.slice(0, 1) : ''}
        </Avatar>
      )}
    </div>
  );

  return showBadge ? (
    <Badge
      variant="dot"
      overlap="circular"
      badgeContent=""
      color={isUserOnline ? 'success' : 'error'}
    >
      {avatar}
    </Badge>
  ) : (
    avatar
  );
};