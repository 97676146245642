import {
  Grid,
} from '@mui/material';

import { ChatMessageLeft, ChatMessageRight } from '..';
import { ImageGallerySwiper } from '../..';

export const EditChatMessageDetails = (props: any) => {
  //console.log('EditChatMessageDetails props:', props);
  const { currentUser, message } = props;
  const hasFiles = (message?.files?.length ?? 0) > 0;

  return (
    <Grid container spacing={2} width="100%">
      <Grid item xs={12}>
        {message?.userId === currentUser?.id ? (
          <ChatMessageRight
            key={message.id}
            message={message}
            editMode={true}
            showUsername={false}
            showAvatar={false}
            isSelected={false}
            //isUnread={message.id > getLastUnreadMessageId(message.roomId, senderUser?.id)}
            currentUserId={currentUser?.id}
          />
        ) : (
          <ChatMessageLeft
            key={message.id}
            message={message}
            editMode={false}
            showUsername={true}
            showAvatar={true}
            isSelected={false}
            isUnread={false}
            currentUserId={currentUser?.id}
          />
        )}
      </Grid>
      {hasFiles && (
        <Grid item xs={12} style={{
          whiteSpace: 'nowrap',
          margin: '1em 0',
        }}>
          <div style={{
            backgroundColor: 'black',
            height: '100%',
          }}>
            <ImageGallerySwiper
              images={message.files}
              editMode={true}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};