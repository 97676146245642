import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  LockOpen as LockOpenIcon,
  People as PeopleIcon,
  Lock as LockIcon,
} from '@mui/icons-material';

export enum VisibilityOptions {
  PUBLIC = 'public',
  FRIENDS = 'friends',
  PRIVATE = 'private',
};

export const PrivacyControlIconButton: React.FC = () => {
  const [visibility, setVisibility] = useState(VisibilityOptions.PUBLIC);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleVisibilityChange = (option: VisibilityOptions) => {
    setVisibility(option);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        //color="primary"
        aria-label="privacy"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        {visibility === VisibilityOptions.PUBLIC && <LockOpenIcon />}
        {visibility === VisibilityOptions.FRIENDS && <PeopleIcon />}
        {visibility === VisibilityOptions.PRIVATE && <LockIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          title="Event can view"
          selected={visibility === VisibilityOptions.PUBLIC}
          onClick={() => handleVisibilityChange(VisibilityOptions.PUBLIC)}
        >
          <LockOpenIcon />
          &nbsp;Public
        </MenuItem>
        <MenuItem
          title="Only friends can view"
          selected={visibility === VisibilityOptions.FRIENDS}
          onClick={() => handleVisibilityChange(VisibilityOptions.FRIENDS)}
        >
          <PeopleIcon />
          &nbsp;Friends
        </MenuItem>
        <MenuItem
          title="Only you can view"
          selected={visibility === VisibilityOptions.PRIVATE}
          onClick={() => handleVisibilityChange(VisibilityOptions.PRIVATE)}
        >
          <LockIcon />
          &nbsp;Private
        </MenuItem>
      </Menu>
    </>
  );
};