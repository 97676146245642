import React from 'react';
import reactStringReplace from 'react-string-replace';

export class CreateUserTagComponent extends React.Component {
  props: any;

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  render() {
    let body = this.props.body;
    for (const userTag of this.props.userTags) {
      body = reactStringReplace(body, userTag, (match: any, i: number) => (
        <a
          key={userTag + i}
          href={`/${userTag.replace('@', '')}`}
          style={{
            textDecoration: 'none',
            color: 'dodgerblue',
          }}
        >
          {userTag}
        </a>
      ));
    }
    return body;
  }
};