import { IconButton } from '@mui/material';
import {
  Favorite as LikedIcon,
  FavoriteBorder as UnlikedIcon,
} from '@mui/icons-material';

import { pluralize } from '../modules';

export const LikeComponent = (props: any) => {
  const { currentUser, likes = [], tooltip = 'Like', onClick } = props;
  const isLiked = (
    likes?.find((like: any) => like.userId === currentUser?.id) ||
    likes?.includes(currentUser?.id)
  ) ?? false;

  return (
    <div className="text-nowrap">
      <IconButton
        title={tooltip}
        onClick={onClick}
      >
        {isLiked
          ? <LikedIcon style={{ color: 'red' }} />
          : <UnlikedIcon />
        }
      </IconButton>
      {likes?.length ?? 0}&nbsp;
      <span className="likes-title">
        Like{pluralize(likes?.length)}
      </span>
    </div>
  );
};