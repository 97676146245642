import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { CarSelector, FormattedPhoneNumber, MemberSelector } from '..';
import { createNotification } from '../../modules';
import { CalendarService, ChallengeService, UserService } from '../../services';
import { getUserToken } from '../../stores';
import { RaceTrack, User } from '../../types';

export const ChallengeDialog = (props: any) => {
  //console.log('ChallengeDialog props:', props);
  const { open, profile, currentUser, raceTracks, onClose } = props;
  const [state, setState] = useState({
    cars: currentUser?.cars || [],
    profileCars: profile?.cars || [],
    challenger: currentUser || getUserToken(),
    challengee: profile,
    selectedChallengerCarId: 0,
    selectedChallengeeCarId: 0,
    selectedTrackId: 0,
    date: moment(),
    createEvent: true,
  });
  const { enqueueSnackbar } = useSnackbar();

  const raceTrack = raceTracks.find((track: any) => track.id === state.selectedTrackId);

  const handleReload = (userProfile: User, currentUser: User) => {
    if (!userProfile?.id) {
      return;
    }
    UserService.getCars(userProfile?.id).then((response: any) => {
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to fetch profile user ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      const profileCars = response.cars;
      UserService.getCars(currentUser.id).then((response: any) => {
        if (response.status !== 200) {
          enqueueSnackbar(`Unable to fetch race tracks.`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          return;
        }
        setState({
          ...state,
          cars: response.cars,
          profileCars,
          challengee: userProfile,
          selectedChallengeeCarId: 0,
        });
      });
    });
  };

  const handleCreate = () => {
    if (state.selectedTrackId === 0) {
      enqueueSnackbar(`No race track selected!`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }

    ChallengeService.createChallenge({
      challengerUserId: state.challenger.id,
      challengeeUserId: state.challengee.id,
      challengerCarId: state.selectedChallengerCarId,
      challengeeCarId: state.selectedChallengeeCarId,
      trackId: state.selectedTrackId,
      resultsId: null,
      accepted: false,
      date: state.date.toDate(),
    }).then((response: any) => {
      console.log('createChallenge response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to create challenge: ${response?.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      if (state.createEvent) {
        const track = raceTracks[state.selectedTrackId];
        const challenge = response.challenge;
        const challengeEvent = {
          challengeId: challenge?.id,
          title: `Race Challenge - ${state.challenger?.username} vs ${state.challengee?.username}`,
          description: `Join us as ${state.challenger?.username} and ${state.challengee?.username} challenge eachother with their cars ${state.selectedChallengerCarId} and ${state.selectedChallengeeCarId} at ${track?.name}`,
          begin: state.date.toDate(),
          end: state.date.toDate(),
          createdByUserId: currentUser?.id,
        }
        CalendarService.createEvent(challengeEvent).then((response: any) => {
          console.log('createEvent response:', response);
          if (response.status !== 200) {
            enqueueSnackbar(`Unable to create challenge event: ${response?.message}`, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
            return;
          }
        });
      }
      createNotification(currentUser?.id, state.challengee.id, `${currentUser?.username} has challenged you to a race!`);
      onClose && onClose();
    });
  };

  const handleChange = (event: any) => {
    //console.log('handleChange:', event);
    const { target: { name, value, type, checked } } = event;
    const val = type === 'checkbox'
      ? checked
      : value;
    setState({...state, [name]: val});
  };

  const handleUserChanged = (member: User) => {
    //console.log('handleUserChanged:', member);
    handleReload(member ?? state.challengee, currentUser ?? state.challenger);
  };

  useEffect(() => {
    const userProfile = profile ?? state.challengee;
    const currentUserProfile = currentUser ?? state.challenger;
    handleReload(userProfile, currentUserProfile);
    // eslint-disable-next-line
  }, [profile, currentUser, setState, state.challengee, state.challenger]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography
            variant="h6"
            component="h3"
            className="text-center"
            sx={{ ml: 2, flex: 1 }}
          >
            New Race Challenge
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleCreate}
          >
            Create
          </Button>
          <IconButton
            title="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {!profile && (
          <MemberSelector
            key="member-selector"
            currentUser={currentUser ?? state.challenger}
            onSelectChanged={handleUserChanged}
          />
        )}

        <div className="row mb-3 float-end">
          <FormControlLabel
            control={
              <Switch
                id="createEvent"
                name="createEvent"
                checked={state.createEvent}
                color="primary"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Create Calendar Event"
            className="text-light"
          />
        </div>

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Typography variant="h5" gutterBottom className="text-center">
              Event Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment} >
              <DatePicker
                label="Challenge Date"
                value={state.date}
                onChange={(newValue: any) => setState({...state, date: newValue})}
                sx={{ width: '100%', marginBottom: 3 }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="h5" gutterBottom className="text-center">
              Race Track
            </Typography>
            <FormControl fullWidth className="mb-3">
              <InputLabel id="track-label">Race Track</InputLabel>
              <Select
                labelId="track-label"
                name="selectedTrackId"
                value={state.selectedTrackId}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
              >
                <MenuItem
                  key='empty'
                  value={0}
                  selected={state.selectedTrackId === 0}
                  disabled={state.selectedTrackId === 0}
                >
                  Select a track
                </MenuItem>
                {(raceTracks?.length ?? 0) > 0 && raceTracks?.map((track: RaceTrack) => (
                  <MenuItem key={track.name} value={track.id}>
                    {track.name}
                  </MenuItem>
                ))}
              </Select>
              {state.selectedTrackId > 0 && (
                <>
                  Address: {raceTrack.address}<br />
                  Phone Number: <FormattedPhoneNumber value={raceTrack.phone} /><br />
                  Website: {raceTrack.websiteUrl}<br />
                  Track Length: {raceTrack.length} mile<br />
                </>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom className="text-center">
          Cars
        </Typography>
        <Grid container spacing={2} className="mb-3">
          <Grid item sm={6} xs={12}>
            <Typography variant="h6" gutterBottom className="text-center">
              You
            </Typography>
            <CarSelector
              title="Challenger"
              name="selectedChallengerCarId"
              value={state.selectedChallengerCarId}
              onChange={handleChange}
              cars={state.cars}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="h6" gutterBottom className="text-center">
              {profile?.username ?? profile ?? state.challengee?.username ?? state.profileCars[0]?.user?.username ?? 'No Selection'}
            </Typography>
            <CarSelector
              title="Challengee"
              name="selectedChallengeeCarId"
              value={state.selectedChallengeeCarId}
              onChange={handleChange}
              cars={state.profileCars}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};