import React, { forwardRef, ReactElement, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { useSidebarState } from '.';
import { isActive } from '../../modules';

/**
 * Displays a menu item with a label and an icon - or only the icon with a tooltip when the sidebar is minimized.
 * It also handles the automatic closing of the menu on tap on mobile.
 *
 * @typedef {Object} Props the props you can use
 * @prop {string|Location} to The menu item's target. It is passed to a React Router NavLink component.
 * @prop {string|ReactNode} primaryText The menu content, displayed when the menu isn't minimized. |
 * @prop {ReactNode} leftIcon The menu icon
 *
 * Additional props are passed down to the underling MUI <MenuItem> component
 * @see https://material-ui.com/api/menu-item/#menuitem-api
 *
 * @example // You can create a custom menu component using the <DashboardMenuItem> and <MenuItemLink> components:
 *
 * // in src/Menu.js
 * import * as React from 'react';
 * import { DashboardMenuItem, MenuItemLink } from 'react-admin';
 * import BookIcon from '@mui/icons-material/Book';
 * import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
 * import PeopleIcon from '@mui/icons-material/People';
 * import LabelIcon from '@mui/icons-material/Label';
 *
 * export const Menu = () => (
 *   <div>
 *     <DashboardMenuItem />
 *     <MenuItemLink to="/posts" primaryText="Posts" leftIcon={<BookIcon />}/>
 *     <MenuItemLink to="/comments" primaryText="Comments" leftIcon={<ChatBubbleIcon />}/>
 *     <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />}/>
 *     <MenuItemLink to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />}/>
 *   </div>
 * );
 *
 * // to use this custom menu component, pass it to a custom Layout:
 * // in src/Layout.js
 * import { Layout } from 'react-admin';
 * import { Menu } from './Menu';
 *
 * export const Layout = (props) => <Layout {...props} menu={Menu} />;
 *
 * // then, use this layout in the <Admin layout> prop:
 * // in src/App.js
 * import { Layout }  from './Layout';
 *
 * const App = () => (
 *   <Admin layout={Layout} dataProvider={simpleRestProvider('http://path.to.my.api')}>
 *     // ...
 *   </Admin>
 * );
 */
export const MenuItemLink = forwardRef<any, IMenuItemLinkProps>((props, ref) => {
  const {
    className,
    primaryText,
    leftIcon,
    onClick,
    tooltipProps,
    ...rest
  } = props;

  const to =
    (typeof props.to === 'string' ? props.to : props.to.pathname) || '';
  const match = isActive(to);
  const [open] = useSidebarState();

  const renderMenuItem = () => {
    return (
      <StyledMenuItem
        className={clsx(className, {
          [MenuItemLinkClasses.active]: !!match,
          [MenuItemLinkClasses.hover]: !match,
        })}
        // @ts-ignore
        component={LinkRef}
        ref={ref}
        tabIndex={0}
        {...rest}
        onClick={() => onClick && onClick(to)}
      >
        {leftIcon && (
          <ListItemIcon
            className={MenuItemLinkClasses.icon}
            onClick={() => onClick && onClick(to)}
          >
            {leftIcon}
         </ListItemIcon>
        )}
        {primaryText}
      </StyledMenuItem>
    );
  };

  return open ? (
    renderMenuItem()
  ) : (
    <Tooltip
      title={primaryText}
      placement="right"
      {...tooltipProps}
    >
      {renderMenuItem()}
    </Tooltip>
  );
});

export type IMenuItemLinkProps = LinkProps &
  MenuItemProps<'li'> & {
    leftIcon?: ReactElement;
    primaryText?: ReactNode;
    tooltipProps?: TooltipProps;
    to: any;
  };

export const MenuItemLinkClasses = {
  active: `RaMenuItemLink-active`,
  hover: `RaMenuItemLink-hover`,
  icon: `RaMenuItemLink-icon`,
};

const StyledMenuItem = styled(MenuItem, {
  name: 'RaMenuItemLink',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  //backgroundColor: '#414141',
  color: 'silver', //theme.palette.secondary.main,
  textDecoration: 'none',

  [`&.${MenuItemLinkClasses.active}`]: {
    color: theme.palette.primary.main,
  },

  [`&.${MenuItemLinkClasses.hover}:hover`]: {
    color: theme.palette.secondary.main,
  },

  [`& .${MenuItemLinkClasses.icon}`]: {
    minWidth: theme.spacing(5),
    marginLeft: -2,
  },
}));

const LinkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link ref={ref} {...props} />
));