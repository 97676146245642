import React, { SyntheticEvent, useState } from 'react';
import {
  AppBar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
} from '@mui/material';

import { ITabPanelProps } from '..';
import { rndNum } from '../../modules';
import {
  Car,
  CarModification,
} from '../../types';

export const CarSpecsTabPanel = (props: ITabPanelProps) => {
  //console.log('CarSpecsTabPanel props:', props)
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const CarSpecsTabControl = (car: Car) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => setValue(newValue);

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%', /*marginTop: 3,*/ borderRadius: 2 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="Specifications" {...a11yProps(0)} />
          <Tab label="Modifications" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <CarSpecsTabPanel value={value} index={0}>
        <List dense={true} disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Year:</div>
                <div className="float-end">{car.year}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Manufacturer:</div>
                <div className="float-end">{car.manufacturer}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Model:</div>
                <div className="float-end">{car.model}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Trim:</div>
                <div className="float-end">{car.trim ?? '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Color:</div>
                <div className="float-end">{car.color ?? '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Engine Size:</div>
                <div className="float-end">{car.specs?.engineSize ? car.specs?.engineSize : '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Horsepower:</div>
                <div className="float-end">{car.specs?.horsepower ? car.specs?.horsepower : '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Displacement:</div>
                <div className="float-end">{car.specs?.displacement ? car.specs?.displacement : '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Weight:</div>
                <div className="float-end">{car.specs?.weight ? rndNum(car.specs?.weight, 0) + ' lbs' : '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">0-60 Time:</div>
                <div className="float-end">{car.specs?.timeTo60 ? rndNum(car.specs?.timeTo60, 2) + ' secs' : '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Top Speed:</div>
                <div className="float-end">{car.specs?.topSpeed ? rndNum(car.specs?.topSpeed, 2) + ' MPH' : '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <div className="float-start">Status:</div>
                <div className="float-end">{car.specs?.status ?? '--'}</div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </CarSpecsTabPanel>
      <CarSpecsTabPanel value={value} index={1}>
      <List dense={false} style={{color: "black"}}>
          {car.mods?.map((mod: CarModification) => {
            return (
              <ListItem key={mod.type}>
                <ListItemText primary={mod.type} />
                <ListItemText primary={mod.value} />
              </ListItem>
            );
          })}
        </List>
      </CarSpecsTabPanel>
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};