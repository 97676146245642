import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack';

import { LoginRedirectDelay, Routes } from '../consts';
import { useCurrentUser } from '../contexts';
import { get, set } from '../modules';

const LoginPage = (props: any) => {
  //console.log('LoginPage props:', props);
  const [state, setState] = useState({
    username: get('username') ?? '',
    password: get('password') ?? '',
    loading: false,
    message: '',
    remember: get('remember') ?? false,
  });
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useCurrentUser();
  //const { currentUser, login } = useCurrentUser();
  //console.log('currentUser:', currentUser);

  const handleChange = (event: any) => {
    const { target: { name, value, type, checked } } = event;
    const val = type === 'checkbox' 
      ? checked
      : value;
    setState({ ...state, [name]: val });
  };

  const handleLogin = async (event: any) => {
    //console.log('handleLogin:', event);
    event.preventDefault();

    setState({ ...state, loading: true, message: '' });

    try {
      const response = await login(state.username, state.password);
      //console.log('login response:', response, state);
      if (response?.status !== 200) {
        const resMessage = response?.message ?? 'Unknown error occurred';
        enqueueSnackbar(resMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        setState({ ...state, loading: false, message: resMessage });
        return false;
      }

      setState({ ...state, loading: false, message: '' });
      enqueueSnackbar(`User account '${state.username}' logged in successfully.`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      setTimeout(() => {
        if (state.remember && state.username && state.password) {
          set('username', state.username);
          //set('password', state.password);
          set('remember', true);
        }
        window.location.href = Routes.account.garage;
      }, LoginRedirectDelay);
    } catch (err: any) {
      console.log('err:', err);
      window.alert('err: ' + JSON.stringify(err));
      const errorMessage = err.response ? err.response.data.message : err.message;
      enqueueSnackbar(`Error occurred trying to login user account '${state.username}': ${errorMessage}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
    setState({ ...state, loading: false });
    return false;
  };

  return (
    <Paper elevation={6}>
      <div
        className="bg-dark text-light"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: `100%`,
        }}
      >
        <Typography variant="h3" gutterBottom className="text-center">Login</Typography>
        <div
          className="card card-container bg-dark text-light p-3"
          style={{ maxWidth: "500px" }}
        >
          <div className="card-body">
            <form
              className="bg-dark text-light"
              autoComplete="off"
              onSubmit={handleLogin}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <TextField
                    variant="outlined"
                    className="text-light"
                    id="username"
                    label="Username"
                    name="username"
                    type="text"
                    value={state.username}
                    autoComplete="username"
                    onChange={handleChange}
                    autoFocus
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    className="text-light"
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={state.password}
                    autoComplete="password"
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="remember"
                        name="remember"
                        checked={state.remember}
                        color="primary"
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />}
                    label="Remember me"
                    className="text-light"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: theme.spacing(3, 0, 2) }}
                disabled={state.loading}
                fullWidth
              >
                {state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </Button>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item sm={5} xs={12}>
                    <Link style={{ textDecoration: 'none' }} to="/resetpassword">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <p className="card-text text-nowrap">
                      Don't have an account? &nbsp;
                      <Link style={{ textDecoration: 'none' }} to={"/register"}>
                        Sign Up
                      </Link>
                    </p>
                  </Grid>
                </Grid>
              </div>

              {state.message && (
                <div className="form-group mt-2">
                  <div className="alert alert-danger" role="alert">
                    {state.message}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default LoginPage;