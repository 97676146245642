import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import {
  ChatMessageContainer,
  ChatProfileDetails,
  MessageContacts,
  ProfileCard,
} from '../..';
import { getUserToken } from '../../../stores';

export interface IChatMessengerState {
  openProfileDetails: boolean;
  selectedProfileDetails: any | null;
  miniContacts: boolean;
};

export const ChatMessenger = (props: any) => {
  //console.log('ChatMessenger props:', props);
  const [state, setState] = useState<IChatMessengerState>({
    openProfileDetails: false,
    selectedProfileDetails: null,
    miniContacts: false,
  });
  const currentUser = getUserToken();
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const handleOpenChatProfileDetails = (user: any) => {
    setState({
      ...state,
      miniContacts: !isLarge,
      openProfileDetails: true,
      selectedProfileDetails: {
        id: user?.userId,
        username: user?.username,
      },
    });
  };
  
  const handleCloseChatProfileDetails = () => {
    setState({
      ...state,
      miniContacts: false, //!isLarge,
      openProfileDetails: false,
      selectedProfileDetails: null,
    });
  };

  return (
    <ChatProfileDetails
      open={state.openProfileDetails}
      profile={state.selectedProfileDetails}
      profileDetails={(
        <ProfileCard
          user={currentUser}
          profile={state.selectedProfileDetails}
          //onReload={handleReload}
        />
      )}
      onClose={handleCloseChatProfileDetails}
    >
      <Grid
        container
        columnSpacing={0}
        spacing={0}
        className="text-nowrap"
        style={{
          height: '100%',
          border: '1px solid grey',
        }}
      >
        <Grid
          item
          sm={!state.miniContacts && isLarge ? 3 : 2}
          xs={2}
          className={!state.miniContacts && isLarge ? '' : 'text-center'}
          style={{
            display: 'inline-flex',
            width: '100%',
            flex: 1,
            minWidth: !state.miniContacts && isLarge ? 'auto' : '60px',
          }}
        >
          <MessageContacts />
        </Grid>
        <Grid
          item
          sm={!state.miniContacts && isLarge ? 9 : 10}
          xs={10}
          style={{
            //width: '90%',
            flex: 1,
            minWidth: '100px',
          }}
        >
          <ChatMessageContainer
            senderUser={currentUser}
            onUserClick={handleOpenChatProfileDetails}
          />
        </Grid>
      </Grid>
    </ChatProfileDetails>
  );
};