/** References:
 * - https://vpic.nhtsa.dot.gov/api/
 * - https://vpic.nhtsa.dot.gov/api/vehicles/getallmanufacturers?format=json&page=100
 * - https://www.carqueryapi.com/demo/make-model-trim-list-view/
 * - https://support.carqueryapi.com/cart.php?a=view
 * - https://www.back4app.com/database/back4app/car-make-model-dataset/get-started/node-js/rest-api/node-fetch?objectClassSlug=all-cars-by-model-and-by-make-and-by-year
 * - https://stackoverflow.com/questions/62458179/unable-to-fetch-an-api-from-carquery-api-using-fetch
 * - https://www.carqueryapi.com/api/0.3/?callback=?&cmd=getModels&make=ford&year=2005&sold_in_us=1&body=SUV
 */

import React, { Fragment, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { CarDetailsForm, CarSpecificationsForm, UploadImages } from '..';
import { getCarImageUrl } from '../../modules';
import { CarService, ImageService } from '../../services';

const steps = ['Details', 'Pictures', 'Specifications', 'Modifications'];

interface INewCarState {
  id: number;
  name: string;
  year: number;
  manufacturer: string;
  model: string;
  trim: string;
  color: string;
  description: string;
  showInFleet: boolean;
  showInLeaderboard: boolean;
  userId: number;
  profile: string;
  images: any[];
  videos: any[];
  blockChallenges: boolean;
  specs: any;
  mods: any;
};

export const NewCarDialog = (props: any) => {
  //console.log('NewCarDialog props:', props);
  const { user, open, onClose } = props;

  const [state, setState] = useState<INewCarState>({
    id: 0,
    name: '',
    year: 2020,
    manufacturer: '',
    model: '',
    trim: '',
    color: 'Black',
    description: '',
    showInFleet: false,
    showInLeaderboard: false,
    userId: user.id,
    profile: '',
    images: [],
    videos: [],
    blockChallenges: false,
    specs: null,
    mods: null,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    if (!state.name || !state.manufacturer || !state.model || !state.year || !state.color) {
      enqueueSnackbar(`Failed to create car, required fields not set.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      return;
    }
    CarService.createCar(state).then((response: any) => {
      //console.log('createCar response:', response.car);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to create car ${response?.message}.`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      const model = response.car;
      UploadImageFiles(state, setState, model.id);
      onClose && onClose(model);
    });
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" component="h3" className="text-center" sx={{ ml: 2, flex: 1 }}>
            New Garage Car
          </Typography>
          <IconButton color="inherit" onClick={onClose} title="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/*<DialogTitle className="bg-dark text-light text-center">New Car</DialogTitle>*/}
      <DialogContent className="bg-dark text-light">
        <div className="container text-center" style={{maxWidth: '75%'}}>
          <p>
            Create a new car to keep track of times, challenge other members
            to races, showcase your car in The Fleet page, and more.
          </p>
        </div>
        <CheckoutPage
          state={state}
          setState={setState}
          user={user}
        />
      </DialogContent>
      <DialogActions className="bg-dark text-light">
        <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleClick}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

const getStepContent = (step: number, props: any) => {
  switch (step) {
    case 0:
      return <CarDetailsForm {...props} showDefaultUpload={true} />;
    case 1:
      return <CarPicturesForm {...props} />;
    case 2:
      return <CarSpecificationsForm {...props} />;
    case 3:
      return <CarModificationsForm {...props} />;
    default:
      throw new Error('Unknown step');
  }
};

const CheckoutPage = (props: any) => {
  //console.log('checkout page props:', props);
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <Container component="main" /*maxWidth="xs"*/ sx={{ mb: 2 }}>
      <Paper variant="outlined" sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }} className="bg-dark text-light">
        <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Fragment>
            <Typography variant="h5" gutterBottom>
              Thank you for your order.
            </Typography>
            <Typography variant="subtitle1">
              Your order number is #2001539. We have emailed your order
              confirmation, and will send you an update when your order has
              shipped.
            </Typography>
          </Fragment>
        ) : (
          <Fragment>
            {getStepContent(activeStep, props)}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                hidden={activeStep === steps.length - 1}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === 0 ? 'Advanced' : activeStep === steps.length - 1 ? 'Create' : 'Next'}
              </Button>
            </Box>
          </Fragment>
        )}
      </Paper>
    </Container>
  );
};

const CarPicturesForm = (props: any) => {
  return (
    <Fragment>
      <div className="text-center" style={{
        justifyContent: 'center',
        justifyItems: 'center',
      }}>
        <Typography variant="h6" gutterBottom className="mb-3">
          Pictures & Videos
        </Typography>
        <UploadImages key="upload-images" {...props} />
      </div>
    </Fragment>
  );
};

const CarModificationsForm = (props: any) => {
  const { state: { specs } } = props;
  const [state, setState] = useState<any>({
    engineSize: specs?.engineSize ?? '',
    horsepower: specs?.horsepower ?? 0,
    displacement: specs?.displacement ?? '',
    weight: specs?.weight ?? 0,
    timeTo60: specs?.timeTo60 ?? 0,
    topSpeed: specs?.topSpeed ?? 0,
    status: specs?.status ?? 'Stock',
  });

  const handleChange = (event: any) => {
    const {
      target: { name, type, value, checked },
    } = event;
    const val = type === 'checkbox'
      ? checked
      : value;
    const newState = {...state, [name]: val};
    setState(newState);
    props.setState({...props.state, specs: newState});
  };

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom className="text-center mb-3">
        Specifications & Modifications
      </Typography>
      <List
        disablePadding
        dense
      >
        <ListItem disablePadding dense sx={{ py: 0, px: 0 }}>
          <ListItemText primary="Engine Size" />
          <TextField
            id="engineSize"
            name="engineSize"
            type="text"
            variant="outlined"
            size="small"
            value={state.engineSize}
            placeholder="4-Cylinder 1.6L"
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disablePadding sx={{ py: 0, px: 0 }}>
          <ListItemText primary="Horsepower" />
          <TextField
            id="horsepower"
            name="horsepower"
            type="text"
            variant="outlined"
            size="small"
            value={state.horsepower}
            placeholder="283"
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disablePadding sx={{ py: 0, px: 0 }}>
          <ListItemText primary="Displacement" />
          <TextField
            id="displacement"
            name="displacement"
            type="text"
            variant="outlined"
            size="small"
            value={state.displacement}
            placeholder="Supercharger"
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disablePadding sx={{ py: 0, px: 0 }}>
          <ListItemText primary="Weight (lbs)" />
          <TextField
            id="weight"
            name="weight"
            type="text"
            variant="outlined"
            size="small"
            value={state.weight}
            placeholder="2,600"
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disablePadding sx={{ py: 0, px: 0 }}>
          <ListItemText primary="0-60 Time (sec)" />
          <TextField
            id="timeTo60"
            name="timeTo60"
            type="text"
            variant="outlined"
            size="small"
            value={state.timeTo60}
            placeholder="4.73"
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disablePadding sx={{ py: 0, px: 0 }}>
          <ListItemText primary="Top Speed (MPH)" />
          <TextField
            id="topSpeed"
            name="topSpeed"
            type="number"
            variant="outlined"
            size="small"
            value={state.topSpeed}
            placeholder="150"
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disablePadding sx={{ py: 0, px: 0 }}>
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              name="status"
              size="small"
              value={state.status}
              placeholder="Stock"
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
            >
              <MenuItem key="Stock" value="Stock">
                Stock
              </MenuItem>
              <MenuItem key="Modified" value="Modified">
                Modified
              </MenuItem>
              <MenuItem key="NonOp" value="NonOp">
                Non-Operational
              </MenuItem>
            </Select>
          </FormControl>
        </ListItem>
      </List>
    </Fragment>
  );
};

const UploadImageFiles = (state: any, setState: any, carId: number) => {
  setState({...state, progress: 0});

  if (state.profile) {
    const imagePath = getCarImageUrl(carId);
    ImageService.uploadImage(state.profile, imagePath, (event: any) => {
      setState({
        ...state,
        progress: Math.round((100 * event.loaded) / event.total),
      });
    }).then(async (response: any) => {
      setState({
        ...state,
        message: response.data.message,
      });
    })
    .catch((err: any) => {
      console.error('error:', err);
      setState({
        ...state,
        progress: 0,
        message: 'Could not upload images ' + state.images,
      });
    });
  }

  if (state.images.length > 0) {
    //console.log('state.images:', state.images, folder);
    ImageService.uploadImages(state.images, `cars/${carId}/`, (event: any) => {
      setState({
        ...state,
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
    .then((response: any) => {
      console.log('uploadImages response:', response);
    })
    .catch((err: any) => {
      console.error('error:', err);
      setState({
        ...state,
        progress: 0,
        message: 'Could not upload images ' + state.images,
      });
    });
  }

  if (state.videos.length > 0) {
    // TODO: Add support for uploading videos
  }
};