import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
} from '@mui/material';
import { AddAPhoto as AddImageIcon } from '@mui/icons-material';

import { UploadImageGallery } from '.';
import { ImageService } from '../../services';
import { CarImage } from '../../types';

interface IPhotoAlbumUploaderState {
  open: boolean;
  images: CarImage[];
};

export const PhotoAlbumUploader = (props: any) => {
  //console.log('PhotoAlbumUploader props:', props);
  const { folderName, onReloadImages } = props;
  const [state, setState] = useState<IPhotoAlbumUploaderState>({
    open: false,
    images: [],
  });

  const handleOpenGallery = () => setState({ ...state, open: true });
  const handleCloseGallery = () => setState({ ...state, open: false });

  const handlePreviewImages = (images: any[]) => {
    const newImages = [...state.images];
    for (const file of images) {
      const objectUrl = URL.createObjectURL(file.file);
      newImages.push({...file, url: objectUrl});
    }
    setState({...state, images: newImages});
  };

  const handleUploadImages = (images: any[]) => {
    //const folder = `albums/${userId}/${albumName}`;
    //console.log('handleUploadImages:', images, folderName);
    ImageService.uploadImages(images, folderName).then((response: any) => {
      //console.log('uploadImages response:', response);
      onReloadImages && onReloadImages();
      setState({images: [], open: false});
    });
  };

  const handleDeleteImage = (fileName: string) => {
    //ImageService.deleteImage(`albums/${userId}/${albumName}/${fileName}`).then((response: any) => {
    ImageService.deleteImage(`${folderName}/${fileName}`).then((response: any) => {
      //console.log('deleteImage response:', response);
      onReloadImages && onReloadImages();
    });
  };

  const handleClearImages = () => {
    const result = window.confirm(`Are you sure you want to clear your selection of images to upload?`);
    if (!result) {
      return;
    }
    setState({...state, images: []});
  };

  /*
  const handleReloadImages = () => {
    ImageService.getImages(`albums/${userId}/${albumName}`).then((response: any) => {
      //console.log('getImages response:', response);
      setState({ ...state, images: response.images });
      onReloadImages && onReloadImages();
    });
  };
  */

  //useEffect(() => handleReloadImages(), [albumName, userId]);

  return (
    <>
      <div className="float-end mb-3 me-5">
        <Fab
          color="primary"
          size="small"
          aria-label="Add"
          onClick={handleOpenGallery}
        >
          <AddImageIcon />
        </Fab>
      </div>
      <Dialog
        open={state.open}
        onClose={handleCloseGallery}
      >
        <DialogContent>
          <UploadImageGallery
            images={state.images}
            onPreviewImages={handlePreviewImages}
            onUploadImages={handleUploadImages}
            onDeleteImage={handleDeleteImage}
            onClearImages={handleClearImages}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            disableFocusRipple={true}
            disableRipple={true}
            onClick={handleCloseGallery}
            style={{ color: 'grey' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};