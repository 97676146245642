import React, { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import {
  Box,
  Card,
  Divider,
  Typography,
} from '@mui/material';

const cartouche = 'https://raw.githubusercontent.com/marmelab/react-admin/master/examples/demo/src/dashboard/cartouche.png';
const cartoucheDark = 'https://raw.githubusercontent.com/marmelab/react-admin/master/examples/demo/src/dashboard/cartoucheDark.png';

export interface CarWithIconProps {
  icon: any;
  to?: To | null;
  onClick?: () => void | null;
  title?: string;
  subtitle?: string | number;
  minHeight?: number;
  children?: ReactNode;
  style?: any;
};

export const CardWithIcon = ({ icon, title, subtitle, to, minHeight = 52, onClick, children, style }: CarWithIconProps) => (
  <Card
    sx={{
      minHeight,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
      },
    }}
    style={style}
    onClick={onClick}
  >
    <LinkOrClick to={to} onClick={onClick}>
      <Box
        sx={{
          overflow: 'inherit',
          padding: '16px',
          background: theme =>
            `url(${
              theme.palette.mode === 'dark'
                ? cartoucheDark
                : cartouche
            }) no-repeat`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .icon': {
            color: theme =>
              theme.palette.mode === 'dark'
                ? 'inherit'
                : '#dc2440',
          },
        }}
      >
        <Box width="3em" className="icon">{icon}</Box>
        <Box textAlign="right">
          <Typography color="textSecondary">{title}</Typography>
          <Typography variant="h5" component="h2">
            {subtitle || ' '}
          </Typography>
        </Box>
      </Box>
    </LinkOrClick>
    {children && <Divider />}
    {children}
  </Card>
);

export const LinkOrClick = (props: any) => {
  const { to, onClick, children } = props;

  return (
    <>
      {to !== null ? (
        <Link to={to ?? '#'}>
        {children}
        </Link>
      ) : (
        <div onClick={onClick}>
          {children}
        </div>
      )}
    </>
  );
};