import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  NoSsr,
  TextField,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

import defaultTheme from '../../themes/theme';

const animatedComponents = makeAnimated();
const timeRefExp = new RegExp('^([01]?[0-9]|2[0-3]):[0-5][0-9]$');

const useStyles: any = ((theme: Theme) => ({
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    // alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  select: {
    maxWidth: 60,
    minWidth: 60,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
}));

const inputComponent = ({ inputRef, ...props }: any) => (
  <div ref={inputRef} {...props} /> //ref={inputRef}
);

const Control = (props: any) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },
    }}
    {...props.selectProps.TextFieldProps}
  />
);

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

const SingleValue = (props: any) => (
  <Typography style={{ lineHeight: 'initial' }} {...props.innerProps}>
    {props.children}
  </Typography>
);

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

const ValueContainer = (props: any) => (
  <div className={props?.selectProps?.classes?.valueContainer}>
    {props.children}
  </div>
);

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

const components: any = {
  Control,
  SingleValue,
  ValueContainer,
  animatedComponents,
};

export const TimeSelect = (props: any) => {
  const {
    isClearable = false,
    showDropDownIndicator = false,
    allowMulti = false,
    allowSearch = true,
    placeholder = 'Please select',
    required = true,
    options = null,
    originalValue = null,
    length = 5,
    matchFrom = 'start', // 'start' || 'any'
    onChange = () => {},
  } = props;

  const [timeValue, setTimeValue] = useState(originalValue || 0);
  const [findElement, setFindElement] = useState(false);
  const refMenu: any = useRef();
  const classes: any = useStyles(defaultTheme);
  const theme = useTheme();
  
  useEffect(() => {
    if (findElement) {
      const firstTimeElement: any = document.querySelector(
        '#' + refMenu.current?.select?.select.inputRef.id.replace('input', 'option-0'),
      );
      const selected =
        firstTimeElement &&
        [...firstTimeElement.parentElement.children].find(
          el => el.innerHTML === timeValue.value,
        );

      selected && selected.scrollIntoView();
      setFindElement(false);
    };
  }, [findElement]);

  useEffect(() => setTimeValue(originalValue), [originalValue]);

  return useMemo(() => {
    const handleChange = (newValue: any, actionMeta: any) => {
      setTimeValue(newValue);
      onChange(newValue);
    };
    const handleInputChange = (inputValue: any, actionMeta: any) => {
      if (inputValue.length > length) {
        return inputValue.substr(0, 5);
      }

      if (
        (isNaN(inputValue) &&
          Array.from(inputValue).find((char: any) => isNaN(char) && char !== ':')) ||
        Array.from(inputValue).filter(char => char === ':').length > 1
      ) {
        const newInputValue = Array.from(inputValue)
          .filter((value: any) => !isNaN(value))
          .join('');
        return newInputValue;
      }
    };
    const formatCreateLabel = (inputValue: any) => inputValue;

    const handleNewOptionValidate = (inputValue: any) => {
      const isNew = timeRefExp.test(inputValue) && !options.find((cTime: any) => cTime.value === inputValue);
      return isNew;
    };

    const selectStyles = {
      input: (base: any) => ({
        ...base,
        color: theme.palette.text.primary,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
        '& input': {
          font: 'inherit',
        },
      }),
      menuPortal: (base: any) => ({ ...base, zIndex: 9999, minWidth: 80 }),

      dropdownIndicator: (base: any) => ({
        ...base,
        display: showDropDownIndicator ? 'initial' : 'none',
      }),

      indicatorSeparator: (base: any) => ({
        ...base,
        display: showDropDownIndicator ? 'initial' : 'none',
      }),
    };

    return (
      <NoSsr>
        <CreatableSelect
          ref={refMenu}
          className={classes.select}
          styles={selectStyles}
          components={components}
          options={options}
          defaultValue={originalValue}
          value={timeValue}
          isClearable={isClearable}
          isMulti={allowMulti}
          isSearchable={allowSearch}
          menuPortalTarget={document.body}
          menuPlacement='auto'
          required={required}
          placeholder={placeholder}
          formatCreateLabel={formatCreateLabel}
          isValidNewOption={handleNewOptionValidate}
          filterOption={createFilter({ matchFrom: matchFrom })}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onMenuOpen={() => setFindElement(true)}
        />
      </NoSsr>
    );
  }, [
    originalValue,
    timeValue,
    allowMulti,
    allowSearch,
    classes,
    isClearable,
    length,
    matchFrom,
    onChange,
    options,
    placeholder,
    required,
    showDropDownIndicator,
    theme,
  ]);
};