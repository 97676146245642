import { IconButton } from '@mui/material';
import { NoAccounts as BlockIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { UserService } from '../../services';
import { User } from '../../types';

export const BlockUserButton = (props: any) => {
  //console.log('BlockUserButton props:', props);
  const { isCurrentUser, isDisabled = false, currentUser, profile, onReload } = props;
  const isBlocked = UserService.isUserBlocked(profile?.id, currentUser!);
  const { enqueueSnackbar } = useSnackbar();

  const onBlockUser = (profile: User) => {
    if (isBlocked) {
      const result = window.confirm(`You are about to unblock user ${profile.username}, this will allow them to view your public posts. Are you sure you want to do this?`);
      if (!result) {
        return;
      }
      UserService.unblockUser(currentUser?.id, profile.id).then((response: any) => {
        //console.log('unblockUser response:', response);
        enqueueSnackbar(`You unblocked user ${profile?.username}`, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        //createNotification(currentUser?.id, profile?.id, `${currentUser?.username} stopped following ${profile?.username}`);
        onReload && onReload();
      });
    } else {
      const result = window.confirm(`You are about to block user ${profile.username}, neither you or they will be able to view each other's public posts. Are you sure you want to do this?`);
      if (!result) {
        return;
      }
      UserService.blockUser(currentUser?.id, profile.id, 0).then((response: any) => {
        //console.log('blockUser response:', response);
        enqueueSnackbar(`You blocked user ${profile?.username}`, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        //createNotification(currentUser?.id, profile?.id, `${currentUser?.username} stopped following ${profile?.username}`);
        onReload && onReload();
      });
    }
  };

  return (
    <IconButton
      title={(isBlocked ? 'Unblock' : 'Block') + ` user ${profile?.username}`}
      onClick={() => onBlockUser(profile)}
      //className="me-3"
      disabled={isCurrentUser || isDisabled}
    >
      <BlockIcon style={{color: isBlocked ? 'rgba(255, 0, 0, 0.5)' : 'inherit'}} />
    </IconButton>
  );
};