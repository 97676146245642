import http from '../modules/http-common';
import { Feedback, User, UserSocial } from '../types';

const getUser = async (username: string, includeImages: boolean = false) => {
  const response = await http
    .get(`users/${username}?includeImages=${includeImages}`);
  return response.data;
};

const getCars = async (userId: number, inFleet: boolean = false, onlyFavorites: boolean = false) => {
  const response = await http
    .get(`users/${userId}/cars?inFleet=${inFleet}&onlyFavorites=${onlyFavorites}`);
  return response.data;
};

const getChallenges = async (userId: number, includeMyChallenges: boolean = false) => {
  const response = await http
    .get(`users/${userId}/challenges?includeMyChallenges=${includeMyChallenges}`);
  return response.data;
};

const getUserById = async (userId: number, includeImages: boolean = false) => {
  const response = await http
    .get(`users/${userId}?includeImages=${includeImages}`);
  return response.data;
};

const getUsers = async () => {
  const response = await http
    .get('users');
  return response.data;
};

const deleteAccount = async (userId: number) => {
  const response = await http
    .delete(`users/${userId}/delete`);
  return response.data;
};

const changeUsername = async (userId: number, newUsername: string) => {
  const response = await http
    .post(`users/${userId}/change/username`, { newUsername });
  console.log('change username response:', response);
  return response.data;
};

const changeUserDetails = async (userId: number, user: any) => {
  const response = await http
    .post(`users/${userId}/change/details`, { ...user });
  return response.data;
};

const changeSocialProfiles = async (userId: number, userSocial: UserSocial) => {
  const response = await http
    .post(`users/${userId}/change/socials`, { userSocial });
  return response.data;
};

const blockUser = async (blockerUserId: number, blockedUserId: number, interval: number = 0) => {
  const response = await http
    .post(`users/${blockerUserId}/block`, { blockerUserId, blockedUserId, interval });
  return response.data;
};

const unblockUser = async (blockerUserId: number, blockedUserId: number) => {
  const response = await http
    .post(`users/${blockerUserId}/unblock`, { blockerUserId, blockedUserId });
  return response.data;
};

const reportUser = async (reporterUserId: number, reportedUser: User, type: string, message: string) => {
  const response = await http
    .post(`users/${reportedUser.id}/report`, { reporterUserId, type, message });
  return response.data;
};

const sendFeedback = async (feedback: Feedback) => {
  const response = await http
    .post(`users/${feedback.userId}/feedback`, { feedback });
  return response.data;
};

const getUserTags = async (tags: string[]) => {
  const response = await http
    .post('users/tags', { tags });
  return response.data;
};

const isUserBlocked = (userId: number, currentUser: User) => {
  const index = currentUser?.blockedUsers?.findIndex(
    (value: any) => value.blockedUserId === userId);
  const result = (index ?? -1) > -1;
  return result;
};

export const UserService = {
  getUser,
  getUserById,
  getUsers,
  getCars,
  getChallenges,
  deleteAccount,
  changeUsername,
  changeUserDetails,
  changeSocialProfiles,
  blockUser,
  unblockUser,
  isUserBlocked,
  reportUser,
  getUserTags,
  sendFeedback,
};