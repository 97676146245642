import http from '../modules/http-common';
import { Comment } from '../types';

const getComments = async (postId: number, includeDeleted: boolean = false) => {
  const response = await http
    .get(`comments/${postId}?includeDeleted=${includeDeleted}`);
  return response.data;
};

const createComment = async (postId: number, comment: Comment) => {
  const response = await http
    .post(`comments/${postId}/create`, { comment });
  return response.data;
};

const deleteComment = async (commentId: number) => {
  const response = await http
    .delete(`comments/${commentId}/delete`);
  return response.data;
};

const likeComment = async (commentId: number, userId: number) => {
  const response = await http
    .post(`comments/${commentId}/like`, { userId });
  return response.data;
};

export const CommentService = {
  getComments,
  createComment,
  deleteComment,
  likeComment,
};