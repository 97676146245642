import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ThemeOptions,
  useTheme as useThemeMUI,
} from '@mui/material';
import { isEqual, set, unset, get } from 'lodash';

export type ThemeSetter = (theme: ThemeOptions) => void;

export const useTheme = (themeOverride?: ThemeOptions): [ThemeOptions, ThemeSetter] => {
  const themeMUI = useThemeMUI();
  const [theme, setter] = useStore('theme', themeOverride!);
  return [theme || themeMUI, setter];
};

// allow the hook to work in SSR
const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : useEffect;

/**
 * Alternative to useCallback that doesn't update the callback when dependencies change
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 * @see https://github.com/facebook/react/issues/14099#issuecomment-440013892
 */
export const useEvent = <Args extends unknown[], Return>(
  fn: (...args: Args) => Return
): ((...args: Args) => Return) => {
  const ref = useRef<(...args: Args) => Return>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: Args) => ref.current(...args), []);
};

/**
* Store using memory
*
* @example
*
* import { memoryStore } from 'react-admin';
*
* const App = () => (
*   <Admin store={memoryStore()}>
*     ...
*   </Admin>
* );
*/
export const memoryStore = (storage: any = {}): Store => {
  const subscriptions: { [key: string]: Subscription } = {};
  const publish = (key: string, value: any) => {
    Object.keys(subscriptions).forEach(id => {
      if (!subscriptions[id]) return; // may happen if a component unmounts after a first subscriber was notified
      if (subscriptions[id].key === key) {
        subscriptions[id].callback(value);
      }
    });
  };
  return {
    setup: () => {},
    teardown: () => {
      Object.keys(storage).forEach(key => delete storage[key]);
    },
    getItem<T = any>(key: string, defaultValue?: T): T {
      return get(storage, key, defaultValue);
    },
    setItem<T = any>(key: string, value: T): void {
      set(storage, key, value);
      publish(key, value);
    },
    removeItem(key: string): void {
      unset(storage, key);
      publish(key, undefined);
    },
    removeItems(keyPrefix: string): void {
      const flatStorage = flatten(storage);
      Object.keys(flatStorage).forEach(key => {
        if (!key.startsWith(keyPrefix)) {
          return;
        }
        unset(storage, key);
        publish(key, undefined);
      });
    },
    reset(): void {
      const flatStorage = flatten(storage);
      Object.keys(flatStorage).forEach(key => {
        unset(storage, key);
        publish(key, undefined);
      });
    },
    subscribe: (key: string, callback: (value: string) => void) => {
      const id = Math.random().toString();
      subscriptions[id] = {
        key,
        callback,
      };
      return () => delete subscriptions[id];
    },
  };
};

// taken from https://stackoverflow.com/a/19101235/1333479
const flatten = (data: any) => {
  let result: any = {};
  function doFlatten(current: any, prop: string) {
      if (Object(current) !== current) {
          // scalar value
          result[prop] = current;
      } else if (Array.isArray(current)) {
          // array
          result[prop] = current;
      } else {
          // object
          var isEmpty = true;
          for (var p in current) {
              isEmpty = false;
              doFlatten(current[p], prop ? prop + '.' + p : p);
          }
          if (isEmpty && prop) result[prop] = {};
      }
  }
  doFlatten(data, '');
  return result;
};

/**
 * Get the Store stored in the StoreContext
 */
export const useStoreContext = () => useContext(StoreContext);

const defaultStore = memoryStore();

export const StoreContext = createContext<Store>(defaultStore);

export interface Store {
  setup: () => void;
  teardown: () => void;
  getItem: <T = any>(key: string, defaultValue?: T) => T;
  setItem: <T = any>(key: string, value: T) => void;
  removeItem: (key: string) => void;
  removeItems: (keyPrefix: string) => void;
  reset: () => void;
  subscribe: (key: string, callback: (value: any) => void) => () => void;
};

 type Subscription = {
  key: string;
  callback: (value: any) => void;
};

/**
 * Read and write a value from the Store
 *
 * useState-like hook using the global Store for persistence.
 * Each time a store value is changed, all components using this value will be re-rendered.
 *
 * @param {string} key Name of the store key. Separate with dots to namespace, e.g. 'posts.list.columns'.
 * @param {any} defaultValue Default value
 *
 * @return {Object} A value and a setter for the value, in an array - just like for useState()
 *
 * @example
 * import { useStore } from 'react-admin';
 *
 * const PostList = () => {
 *     const [density] = useStore('posts.list.density', 'small');
 *
 *     return (
 *         <List>
 *             <Datagrid size={density}>
 *                 ...
 *             </Datagrid>
 *         </List>
 *     );
 * }
 *
 * // Clicking on this button will trigger a rerender of the PostList!
 * const ChangeDensity: FC<any> = () => {
 *     const [density, setDensity] = useStore('posts.list.density', 'small');
 *
 *     const changeDensity = (): void => {
 *         setDensity(density === 'small' ? 'medium' : 'small');
 *     };
 *
 *     return (
 *         <Button onClick={changeDensity}>
 *             {`Change density (current ${density})`}
 *         </Button>
 *     );
 * };
 */
export const useStore = <T = any>(
  key: string,
  defaultValue?: T
): useStoreResult<T> => {
  const { getItem, setItem, subscribe } = useStoreContext();
  const [value, setValue] = useState(() => getItem(key, defaultValue));

  // subscribe to changes on this key, and change the state when they happen
  useEffect(() => {
    const storedValue = getItem(key, defaultValue);
    if (!isEqual(value, storedValue)) {
      setValue(storedValue);
    }
    const unsubscribe = subscribe(key, (newValue: any) => {
      setValue(typeof newValue === 'undefined' ? defaultValue : newValue);
    });
    return () => unsubscribe();
  }, [key, subscribe, defaultValue, getItem, value]);

  const set: any = useEvent((valueParam: T, runtimeDefaultValue: T) => {
    const newValue =
      typeof valueParam === 'function' ? valueParam(value) : valueParam;
    // we only set the value in the Store;
    // the value in the local state will be updated
    // by the useEffect during the next render
    setItem(
      key,
      typeof newValue === 'undefined'
        ? typeof runtimeDefaultValue === 'undefined'
          ? defaultValue
          : runtimeDefaultValue
        : newValue
    );
  });
  return [value, set];
};

export type useStoreResult<T = any> = [
  T,
  (value: T | ((value: T) => void), defaultValue?: T) => void
];