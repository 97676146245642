export const defaultTheme = {
  palette: {
    getContrastText: (text: string) => {
      console.log('getContrastText:', text);
      return text;
    },
    mode: 'dark' as 'dark',
    background: {
      //default: '#fafafb',
      //main: '#90caf9',
      main: '#556CD6',
    },
    primary: {
      //main: '#90caf9',
      main: '#556CD6',
    },
    secondary: {
      light: '#6ec6ff',
      //main: '#2196f3',
      main: '#FBBA72',
      dark: '#0069c0',
      //contrastText: '#fff',
      contrastText: '#000',
    },
  },
  typography: {
    h6: {
      fontWeight: 400,
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 50,
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled' as const,
        margin: 'dense' as const,
        size: 'small' as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled' as const,
        margin: 'dense' as const,
        size: 'small' as const,
      },
    },
  },
};