import { Theme, useTheme } from '@mui/material/styles';

const useStyles: any = ((theme: Theme) => ({
  currentTimeDot: {
    background: 'rgb(226, 57, 43)',
    borderRadius: '50%',
    content: "''",
    position: 'absolute',
    height: 12,
    width: 12,
    zIndex: 52,
    marginTop: -1000,
    marginLeft: -6.5,
  },
  currentTimeLine: {
    position: 'absolute',
    zIndex: 51,
    borderColor: 'rgb(226, 57, 43)',
    borderTop: '2px solid',
    left: 0,
    right: -1,
  },
}));

export const CurrentTimeMark = (props: any) => {
  const { marginTop = -1000 } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  
  return (
    <div title="Current time marker">
      <div style={{...classes.currentTimeDot, marginTop: marginTop - 5}} />
      <div style={{...classes.currentTimeLine, marginTop: marginTop}} />
    </div>
  );
};