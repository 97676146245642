import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';

import { UserStoryCircle } from '.';
import { CreateStoryDialog } from '../Dialogs';
import { StoryService } from '../../services';

const useStyles: any = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    maxWidth: 'calc(100vw - 1em)',
  },
});

export const StoryBar = (props: any) => {
  //console.log('StoryBar props:', props);
  const { currentUser } = props;
  const [state, setState] = useState({
    open: false,
    stories: [],
  });
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleOpenNew = () => setState({...state, open: true});
  const handleCloseNew = () => setState({...state, open: false});

  const handleReloadFollowers = (userId: number) => {
    StoryService.getFollowerStories(userId).then((response: any) => {
      //console.log('getFollowerStories response:', response);
      setState({...state, stories: response.stories});
    });
  };

  // eslint-disable-next-line
  useEffect(() => handleReloadFollowers(currentUser?.id), [currentUser]);

  return (
    <div className="scrollbar" style={classes.root}>
      <UserStoryCircle
        key={currentUser.id}
        currentUser={{...currentUser, userId: currentUser.id}}
        follower={{...currentUser, userId: currentUser.id}}
        showAddButton={true}
        onAdd={handleOpenNew}
      />

      {state.stories.length > 0 && state.stories.map((follower: any) => follower.userId !== currentUser?.id && (
        <UserStoryCircle
          key={follower.id}
          currentUser={{...currentUser, userId: currentUser.id}}
          follower={{...follower, username: follower.user?.username}}
        />
      ))}

      <CreateStoryDialog
        user={currentUser}
        open={state.open}
        onClose={handleCloseNew}
      />
    </div>
  );
};