import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import {
  IconButton,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';

import { UserAvatar } from '..';
import { getAvatarUrl } from '../../modules';

const useStyles: any = (theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {color: 'black'},
  closeIcon: {width: 12, height: 12},
  listItem: {
    backgroundColor: '#414141',
    borderRadius: 20,
    marginBottom: '6px',
    width: '100%',
  },
  username: {
    color: 'white',
  },
  description: {
    color: 'silver',
  },
  date: {
    color: 'grey',
  },
});

export const NotifyItem = (props: any) => {
  //console.log('NotifyItem props:', props);
  const { notification, url, onSeen, onDelete } = props;
  const date = moment(notification.createdAt).format('LLLL');
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ListItemButton
      dense
      title="Mark notification as read"
      style={{
        ...classes.listItem,
        ...(!notification.unread ? { opacity: 0.6 } : {})
      }}
    >
      <NavLink
        to={`/${notification.sender?.username}`}
        style={{
          marginRight: 10,
          textDecoration: 'none',
        }}
      >
        <UserAvatar
          fullName={notification.sender?.username}
          fileName={getAvatarUrl(notification.senderUserId)}
        />
      </NavLink>
      <ListItemText
        primary={
          <NavLink
            to={url}
            onClick={(event: any) => onSeen(event, notification)}
            style={{
              textDecoration: 'none',
             color: 'inherit',
            }}
          >
            <div style={classes.username}>{notification.sender?.username}</div>
            <div style={classes.description}>{notification.text}</div>
            <div style={classes.date}>{date.toLocaleString()}</div>
          </NavLink>
        }
      />
      <ListItemSecondaryAction style={classes.closeButton}>
        <IconButton
          title="Dismiss notification"
          onClick={(event: any) => onDelete(event, notification.id)}
        >
          <CloseIcon style={classes.closeIcon} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};