import React, { useState } from 'react';

import {
  AnswerVideoCallDialog,
  DefaultAspectRatio,
  GridKind,
  OutgoingVideoCallDialog,
  Panel,
  SettingsGridAuto,
  VideoCallGrid,
  VideoCallSidebar,
  VideoCallToolbar,
} from '.';
import { MediaDeviceContextProvider } from '../../contexts';
import { useVideoCall } from '../../hooks';
import { getUserToken } from '../../stores';

import '../../css/chat/index.css';

export const VideoCallContainer = (props: any) => {
  const {
    callState,
    participants,
    usersOnline,
    answerCall, declineCall, cancelCall,
  } = useVideoCall();

  const [panel, setPanel] = useState<Panel>('chat');
  const [openSidebar, setOpenSidebar] = useState(false);
  const [gridKind, setGridKind] = useState<GridKind>(SettingsGridAuto);
  const [showMinimizedToolbar, setShowMinimizedToolbar] = useState(true);
  const [playIncomingCallRinger, setPlayIncomingCallRinger] = useState(true);
  const [playOutgoingCallRinger, setPlayOutgoingCallRinger] = useState(false);

  const currentUser = getUserToken();
  const peerId = currentUser?.id;

  const cameraStreams = Object.values(participants).map((stream) => stream.camera!);
  const desktopStreams = Object.values(participants).map((stream) => stream.desktop!);
  const streams = [...cameraStreams, ...desktopStreams];
  const minimized = streams.filter((stream) => stream?.windowState === 'minimized');
  const maximized = streams.filter((stream) => stream?.windowState === undefined);

  const handleOpenSidebar = (panel: Panel | undefined) => {
    setOpenSidebar(true);
    setPanel(panel!);
  };
  const handleCloseSidebar = () => setOpenSidebar(false);
  const handleToggleSidebar = () => setOpenSidebar(prev => !prev);

  return (
    <div className="app">
      <MediaDeviceContextProvider callState={callState!}>
        <VideoCallToolbar
          user={currentUser}
          sidebarPanel={panel}
          openSidebar={openSidebar}
          onToggleSidebar={handleToggleSidebar}
        />
    
        <VideoCallSidebar
          userId={peerId}
          panel={panel}
          visible={openSidebar}
          gridKind={gridKind}
          showMinimizedToolbar={showMinimizedToolbar}
          playIncomingCallRinger={playIncomingCallRinger}
          playOutgoingCallRinger={playOutgoingCallRinger}
          onToggleIncomingCallRinger={() => setPlayIncomingCallRinger(prev => !prev)}
          onToggleOutgoingCallRinger={() => setPlayOutgoingCallRinger(prev => !prev)}
          onGridKindChange={setGridKind}
          onShowMinimizedToolbarToggle={() => setShowMinimizedToolbar(prev => !prev)}
          onHide={handleCloseSidebar}
          onShow={handleOpenSidebar}
        />
    
        <VideoCallGrid
          showMinimizedToolbar={showMinimizedToolbar}
          gridKind={gridKind}
          defaultAspectRatio={DefaultAspectRatio}
          usersOnline={usersOnline ?? {}}
          maximized={maximized}
          minimized={minimized}
          videoStyle={{
            padding: '5px',
            margin: '3px',
          }}
        />
    
        <AnswerVideoCallDialog
          open={callState?.openAnswerCallDialog!}
          playRinger={playIncomingCallRinger}
          onAccept={answerCall}
          onDecline={declineCall}
          onClose={cancelCall}
        />
    
        <OutgoingVideoCallDialog
          open={callState?.openOutgoingCallDialog!}
          playRinger={playOutgoingCallRinger}
          onCancel={cancelCall}
        />
      </MediaDeviceContextProvider>
    </div>
  );
};