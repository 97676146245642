import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { Routes } from '../../consts';
import { UserAvatar } from '..';
import { buildCarName, getCarImageUrl } from '../../modules';
import { Car } from '../../types';

export const CarsDialog = (props: any) => {
  //console.log('CarsDialog props:', props);
  const { open, title, profile, onClose } = props;

  const goToProfile = (carId: number) => {
    window.location.href = Routes.car + carId;
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List sx={{ pt: 0 }} disablePadding>
          {profile?.cars && profile?.cars?.map((car: Car) => (
            <ListItem key={car.id} disableGutters disablePadding>
              <ListItemButton onClick={() => goToProfile(car.id)}>
                <ListItemAvatar>
                  <UserAvatar
                    fileName={getCarImageUrl(car.id)}
                    fullName={car.name}
                    onClick={() => goToProfile(car.id)}
                    size={64}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <div className="mx-3">
                    {car.name}<br />
                    <small>{buildCarName(car)}</small>
                  </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};