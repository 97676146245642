import React, { ComponentType, HtmlHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import { styled, SxProps } from '@mui/material';

import {
  AppBar as DefaultAppBar,
  FloatingFeedbackButton,
  //FloatingMessageButton,
  IAppBarProps,
  Menu as DefaultMenu,
  IMenuProps,
  Sidebar as DefaultSidebar,
  SidebarMenu,
  //SkipNavigationButton,
  StickyFooter,
} from '..';

export const Layout = (props: ILayoutProps) => {
  const {
    appbar: AppBar = DefaultAppBar,
    children,
    className,
    menu: Menu = DefaultMenu,
    sidebar: Sidebar = DefaultSidebar,
    ...rest
  } = props;

  return (
    <>
    <StyledLayout className={clsx('layout', className)} {...rest}>
      {/*<SkipNavigationButton />*/}
      <div className={LayoutClasses.appFrame}>
        <AppBar />
        <main className={LayoutClasses.contentWithSidebar}>
          <DefaultSidebar>
            <SidebarMenu />
          </DefaultSidebar>
          <div id="main-content" className={LayoutClasses.content}>
            {children}
            <FloatingFeedbackButton />
            {/*<FloatingMessageButton />*/}
          </div>
        </main>
      </div>
    </StyledLayout>
    <StickyFooter />
    </>
  );
};

export interface ILayoutProps
  extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  appbar?: ComponentType<IAppBarProps>;
  className?: string;
  menu?: ComponentType<IMenuProps>;
  sidebar?: ComponentType<{ children: ReactNode }>;
  sx?: SxProps;
};

export const LayoutClasses = {
  appFrame: `RaLayout-appFrame`,
  contentWithSidebar: `RaLayout-contentWithSidebar`,
  content: `RaLayout-content`,
};

const StyledLayout = styled('div', {
  name: 'RaLayout',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: 'fit-content',
  width: '100%',
  height: '100%',
  color: theme.palette.primary.dark,

  [`& .${LayoutClasses.appFrame}`]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
    },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: 'flex',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    [theme.breakpoints.up('xs')]: {
      //marginLeft: theme.spacing(3),
      //marginRight: theme.spacing(3),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
    },
  },
}));