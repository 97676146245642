import { createContext, useContext, useState } from 'react';
import { AuthService } from '../services';
import { getUserToken, setUserToken } from '../stores';

interface ICurrentUserContextProps {
  currentUser: any;
  setCurrentUser: any;
  login: (username: string, password: string) => Promise<any>;
  logout: () => void;
};

const CurrentUserContext = createContext<ICurrentUserContextProps>({
  currentUser: getUserToken(),
  setCurrentUser: () => false, //Promise.resolve(),
  login: () => Promise.resolve(null),
  logout: () => false,
});

export const AuthProvider = (props: any) => {
  const [currentUser, setCurrentUser] = useState(null);

  const login = async (username: string, password: string) => {
    try {
      const response = await AuthService.login(username, password);
      if (response.status !== 200) {
        return response;
      }
      const user = response?.data?.user;
      if (user) {
        setUserToken(user);
        setCurrentUser(user);
      }
      return response;
    } catch (err: any) {
      console.log('Failed to login user account:', username, '\nError:', err);
    }
    return null;
  };

  const logout = () => AuthService.logout();

  const context = {
    currentUser,
    setCurrentUser,
    login,
    logout,
  };

  return (
    <CurrentUserContext.Provider
      value={context}
      {...props}
    />
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);