import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import env from 'react-dotenv';
import {
  Box,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { AppBar } from '.';
//import { ToggleThemeButton } from '../ToggleThemeButton';
import { NavbarBrandHeader, NavbarProfile } from '..';
import { isActive, isAdmin } from '../../modules';
import { AuthService } from '../../services';
import { getUserToken } from '../../stores';

export const TopAppBar = () => {  
  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  const currentUser = getUserToken();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const setActiveLink = () => setActiveRoute(window.location.pathname);

  const logOut = () => AuthService.logout();

  const RightBar = (props: any) => {
    //console.log('RightBar props:', props);
    const { user } = props;

    return (
      <>
        {user?.accessToken ? (
          <NavbarProfile
            user={user}
            showAdminDashboard={isAdmin(user!.roles!)}
            activeRoute={activeRoute}
            onLogout={logOut}
            onActiveRoute={setActiveLink}
          />
        ) : (
        <>
          <NavLink tag={Link} to="/register" active={isActive('/register')} className="mx-2" onClick={setActiveLink}>
            Register
          </NavLink>
          <NavLink tag={Link} to="/login" active={isActive('/login')} className="mx-2" onClick={setActiveLink}>
            Login
          </NavLink>
        </>
        )}
      </>
    );
  };

  return (
    <AppBar
      alwaysOn={true}
      user={currentUser}
      color="primary"
      elevation={1}
      rightMenu={<RightBar user={currentUser} />}
    >
      <NavbarBrandHeader
        title={env.SITE_TITLE}
        showTitle={true}
        titleFontSize={isSmall ? 14 : 20}
        showLogo={true}
        logoSize={isSmall ? 32 : 48}
        logoPath={env.SITE_LOGO_URL}
        homeUrl={env.SITE_HOME_URL}
      />
      {!isSmall && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};