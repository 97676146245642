import React, { useState } from 'react';
import {
  Avatar,
  Fab,
  InputBase,
  Paper,
  Zoom,
} from '@mui/material';
import {
  Camera as CameraIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { ImageUploadDialog } from '../Dialogs';
import { ImageService, UserService } from '../../services';

interface IAvatarChangerProps {
  path: string;
  title?: string;
  userId?: number;
};

export const AvatarChanger = (props: IAvatarChangerProps) => {
  //console.log('AvatarChanger props:', props);
  const { path, title, userId } = props;

  const {
    photoUrl: currentPhotoUrl = path,
    photoFile: currentPhotoFile = null,
    displayName: currentDisplayName = title,
  } = {};
  const [state, setState] = useState<any>({
    photoUrl: currentPhotoUrl,
    photoFile: currentPhotoFile,
    displayName: currentDisplayName,
    hasChanges: false,
    isImageDialogOpen: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleImageChange = (file: File, image: any) => {
    const hasChanges =
      (file && file !== state.photoFile) ||
      (image && image !== state.photoUrl);

    if (state.displayName && state.displayName !== currentDisplayName) {
      setState({...state, /*displayName: displayName,*/ hasChanges});
    }
    if (file && file !== currentPhotoFile) {
      setState({...state, photoFile: file, hasChanges});
    }
    if (image && image !== currentPhotoUrl) {
      setState({...state, photoUrl: image, hasChanges});
    }
  };

  const handleSaveAvatar = (event: any) => {
    event.preventDefault();

    if (!state.hasChanges) {
      return;
    }

    if (state.photoFile) {
      ImageService.uploadImage(state.photoFile, path).then((response: any) => {
        //console.log('uploadImage response:', response);
        if (response.status !== 200) {
          enqueueSnackbar(`Failed to change avatar image, error: ${response.message}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          return;
        }
        enqueueSnackbar(`Successfully changed avatar image`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        setState({...state, photoFile: null, hasChanges: false});
      });
    }

    if (state.displayName) {
      UserService.changeUsername(userId!, state.displayName).then((response: any) => {
        //console.log('changeUsername response:', response);
        if (response.status !== 200) {
          enqueueSnackbar(`Failed to change username, error: ${response.message}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          return;
        }
        enqueueSnackbar(`Successfully changed username to ${state.displayName}`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      });
    }
  };

  const handleDeleteAvatar = () => {
    const result = window.confirm(`Are you sure you want to delete your current avatar image?`);
    if (!result) {
      return;
    }

    ImageService.deleteImage(path).then((response: any) => {
      //console.log('deleteImage response:', response);
      setState({...state, photoFile: null, photoUrl: '', hasChanges: false});
    });

    handleCloseImageDialog();
  };

  const handleOpenImageDialog = () => setState({...state, isImageDialogOpen: true});
  const handleCloseImageDialog = () => setState({...state, isImageDialogOpen: false});

  return (
    <Paper
      elevation={6}
      className="bg-dark"
      style={{
        //backgroundColor: 'background.paper',
        marginTop: '50px',
        position: 'relative',
        borderRadius: 20,
        border: '1px solid black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Fab
        size="small"
        style={{ position: 'absolute', top: -15, right: -15 }}
        onClick={handleDeleteAvatar}
        color="error"
        aria-label="Delete"
      >
        <DeleteIcon />
      </Fab>

      <Fab
        onClick={handleOpenImageDialog}
        style={{
          position: 'absolute',
          zIndex: 99,
          top: 50,
          marginRight: -60,
        }}
        color="primary"
        aria-label="Save"
        size="small"
      >
        <CameraIcon />
      </Fab>

      {state.photoUrl && (
        <Avatar
          alt={title}
          style={{ width: 128, height: 128, marginTop: -40, border: '1px solid black' }}
          src={state.photoUrl}
        />
      )}
      {!state.photoUrl && (
        <Avatar
          alt={title}
          style={{ width: 128, height: 128, marginTop: -40, border: '1px solid black' }}
        >
          {state.displayName ? state.displayName[0].toUpperCase() : <PersonIcon />}
        </Avatar>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <InputBase
          value={state.displayName}
          onChange={(e) => {
            setState({...state, displayName: e.target.value});
          }}
          inputProps={{
            'aria-label': 'naked',
            style: {
              fontSize: 26,
              fontWeight: 'bold',
              textAlign: 'center',
            },
          }}
        />
      </div>
      <Zoom in={state.hasChanges}>
        <Fab
          onClick={handleSaveAvatar}
          style={{ marginBottom: -20 }}
          color="primary"
          aria-label="Save"
        >
          <SaveIcon />
        </Fab>
      </Zoom>
      <ImageUploadDialog
        open={state.isImageDialogOpen}
        onClose={handleCloseImageDialog}
        onCropSubmit={handleImageChange}
      />
    </Paper>
  );
};