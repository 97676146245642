import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { ViewCarDetails } from '../components';
import { withRouter } from '../hooks';
import { buildCarName } from '../modules';
import { CarService } from '../services';
import { Car } from '../types';

const CarDetailsPage = (props: any) => {
  //console.log('CarDetailsPage props:', props);
  const { params: { carId } } = props;
  const [car, setCar] = useState<Car | undefined>();
  const title = buildCarName(car!);

  const handleReload = () => {
    CarService.getCar(carId).then((response: any) => {
      //console.log('getCar response:', response);
      setCar(response.car);
    });
  };

  // eslint-disable-next-line
  useEffect(() => handleReload(), [carId]);

  return (
    <>
      <Typography variant="h4" gutterBottom className="text-center">
        {title}
      </Typography>
      {car?.name && (
        <Typography variant="h5" gutterBottom className="text-center">
          aka&nbsp;{`" ${car?.name} "`}
        </Typography>
      )}
      <ViewCarDetails
        car={car}
        onReload={handleReload}
      />
    </>
  );
};

export default withRouter(CarDetailsPage);