import React, { useCallback, useEffect, useState } from 'react';
import Cropper, { Point } from 'react-easy-crop';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

import { getCroppedImg } from '../../modules';

const getFiles = (ev: any) => {
  const files = [];
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === 'file') {
        files.push(ev.dataTransfer.items[i].getAsFile());
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let y = 0; y < ev.dataTransfer.files.length; y++) {
      files.push(ev.dataTransfer.files[y]);
    }
  }
  //console.log('files:', files);
  return files;
};

export interface IImageUploadDialogProps {
  open: boolean;
  onClose: () => void;
  onCropSubmit?: (file: File, path: string) => void;
  cropperProps?: any | null;
};

export interface IImageUploadDialogState {
  isOver: boolean;
  file: File | undefined;
  fileUrl: ArrayBuffer | string | undefined;
  croppedImage: string;
  crop: any;
  zoom: number;
  croppedAreaPixels: any;
};

export const ImageUploadDialog = (props: IImageUploadDialogProps) => {
  //console.log('ImageUploadDialog props:', props);
  const { open, onClose, onCropSubmit, cropperProps } = props;
  const [state, setState] = useState<IImageUploadDialogState>({
    isOver: false,
    file: undefined,
    fileUrl: undefined,
    croppedImage: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
  });

  const handleCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    console.log('handleCropComplete:', croppedArea, croppedAreaPixels);
    setState({...state, croppedAreaPixels: croppedAreaPixels});
  }, [state]);

  const handleClear = () => {
    setState({
      ...state,
      croppedImage: '',
      file: undefined,
      fileUrl: undefined,
      croppedAreaPixels: null,
      isOver: false,
    });
  };

  useEffect(() => {
    handleClear();
    return handleClear;
  }, []);

  const handleShowCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(state.fileUrl, state.croppedAreaPixels, 0);
      setState({...state, croppedImage: croppedImage!});

      if (onCropSubmit) {
        //console.log('showCroppedImage handleCropSubmit file:', file, fileUrl, croppedImage);
        onClose();
        onCropSubmit(state.file!, croppedImage!);
      }
    } catch (err) {
      console.error(err);
    }
  }, [state, onClose, onCropSubmit]);

  const handleFileDrop = (e: any) => {
    console.log('handleFileDrop:', e);
    e.preventDefault();
    const files = getFiles(e);
    if (files.length) {
      const file = files[0];
      setState({...state, file})
      const reader = new FileReader();
      reader.onload = (e: any) => setState({...state, fileUrl: e.target.result});
      reader.readAsDataURL(file);
    }
  };

  const handleFileDragOver = (e: any) => {
    e.preventDefault();
    setState({...state, isOver: true});
  };

  const handleFileDragLeave = (e: any) => {
    e.preventDefault();
    setState({...state, isOver: false});
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div
          style={{
            width: '100%',
            height: '100%',
            minWidth: 350,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!state.file && !state.fileUrl && (
            <div
              onDrop={handleFileDrop}
              onDragOver={handleFileDragOver}
              onDragLeave={handleFileDragLeave}
              style={{
                width: '100%',
                minHeight: 350,
                borderStyle: 'dashed',
                borderColor: state.isOver ? 'red' : 'grey',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <CloudUpload color="disabled" style={{ height: 60, width: 60 }} />
              <Typography variant="h6" style={{ color: 'grey' }}>
                Drop image here or
              </Typography>
              <input
                style={{ display: 'none' }}
                accept="image/*"
                id="contained-button-file"
                //multiple
                type="file"
                onChange={(e: any) => {
                  if (e.target.files) {
                    const file = e.target.files[0];
                    setState({...state, file});
                    const reader = new FileReader();
                    reader.onload = (e: any) => setState({...state, fileUrl: e.target.result});
                    reader.readAsDataURL(file);
                  }
                }}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ margin: 8 }}
                >
                  Upload
                </Button>
              </label>
            </div>
          )}
          {state.file && state.fileUrl && !state.croppedImage && (
            <div
              style={{
                position: 'relative',
                height: 300,
                width: '100%',
                background: 'black',
              }}
            >
              <Cropper
                cropShape="round"
                showGrid={false}
                image={state.fileUrl}
                crop={state.crop}
                zoom={state.zoom}
                aspect={1}
                onCropChange={(location: Point) => setState({...state, crop: location})}
                onCropComplete={handleCropComplete}
                onZoomChange={(zoom: number) => setState({...state, zoom: zoom})}
                {...cropperProps}
              />
            </div>
          )}
          {state.croppedImage && (
            <Box
              position="relative"
              display="inline-flex"
              style={{ height: 280 }}
            >
              <CircularProgress size={280} variant="determinate" />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  style={{ height: 250, borderRadius: '50%' }}
                  src={state.croppedImage}
                  alt="img"
                />
              </Box>
            </Box>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!state.file && !state.fileUrl}
          onClick={handleShowCroppedImage}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};