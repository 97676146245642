import { IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import moment from 'moment';

import { CreateUserTagComponent, LikeComponent, UserAvatar } from '../../components';
import {
  createNotification,
  getAvatarUrl,
  getUserTags,
  inflateFlatArray,
} from '../../modules';
import { CommentService } from '../../services';
import './Comments.css';

export const PostComment = (props: any) => {
  //console.log('PostComment props:', props);
  const { comment, user, onReload } = props;
  const likes = inflateFlatArray(comment?.likes ?? '[]');
  const createdAt = moment(comment.createdAt);

  const handleLike = () => {
    CommentService.likeComment(comment.id, user?.id).then((response: any) => {
      //console.log('likeComment response:', response);
      onReload && onReload();
      createNotification(user.id, comment.userId, `${user?.username} liked your comment ${comment.id} on post ${comment.postId}`);
    });
  };

  const handleDelete = () => {
    const result = window.confirm('Are you sure you want to delete your comment?');
    if (!result) {
      return;
    }
    CommentService.deleteComment(comment.id).then((response: any) => {
      //console.log('deleteComment response:', response);
      onReload && onReload();
    });
  };

  return (
    <div className="comment">
      <UserAvatar
        fullName={comment.user?.username}
        fileName={getAvatarUrl(comment.userId)}
      />
      <div className="info">
        <span className="m-0 mb-2">
          <a
            href={`/${comment.user?.username}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              margin: '0px',
              padding: '0px',
            }}
          >
            {comment.user?.firstName && (<><span className="fullName m-0 p-0" color="primary">{comment.user?.firstName} {comment.user?.lastName}</span><br /></>)}
            <span className="username m-0 p-0" color="secondary">@{comment.user?.username}</span>
          </a>
          <br />
          <small className="date" title={createdAt.toString()}>
            {createdAt.fromNow()}
          </small>
        </span>
        <p style={{marginBottom: 0}}>
          {parseUserTags(comment.text)}
        </p>
        <div style={{
          marginTop: '10px',
          paddingTop: '0px',
          marginBottom: '10px',
          paddingBottom: '0px',
          borderBottom: '1px solid darkgrey',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
          <LikeComponent
            currentUser={user}
            likes={likes}
            tooltip={likes.includes(user?.id) ? 'Unlike comment' : 'Like comment'}
            onClick={handleLike}
          />
          {comment.userId === user?.id && (
            <div className="container">
              <div className="float-end">
                <IconButton
                  title="Delete comment"
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const parseUserTags = (text: string) => {
  const userTags = getUserTags(text) ?? [];
  if (userTags?.length === 0) {
    return text;
  }
  return (<CreateUserTagComponent body={text} userTags={userTags} />);
};