import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

import { CarSpecificationsList } from '.';
import { buildCarName } from '../../modules';
import { Car } from '../../types';

export const CarSelector = (props: any) => {
  const { title, name, value, cars, onChange } = props;
  const [selectedCar, setSelectedCar] = useState<Car>();

  const handleSelectCar = ( car: Car) => setSelectedCar(car);

  return (
    <FormControl sx={{minWidth: 250, width: '100%'}}>
      <InputLabel id="label">{title}</InputLabel>
      <Select
        labelId="label"
        name={name}
        value={value}
        onChange={onChange}
        input={<OutlinedInput label="Name" />}
      >
        <MenuItem key={0} value={0} selected disabled>Select a car</MenuItem>
        {cars && cars?.map((car: Car) => (
          <MenuItem
            key={car.id}
            value={car.id}
            onClick={() => handleSelectCar(car)}
            disabled={car.blockChallenges}
            title={car.blockChallenges ? 'Challenges are blocked for this car' : buildCarName(car)}
          >
            {car.name}
          </MenuItem>
        ))}
      </Select>
      {value > 0 && (
        <CarSpecificationsList
          specs={selectedCar?.specs}
          carId={value}
        />
      )}
    </FormControl>
  );
};