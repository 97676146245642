import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { CarSelector, UploadImageGallery } from '..';
import { createNotification } from '../../modules';
import { ChallengeService, ImageService } from '../../services';

interface IChallengeResultsDialogState {
  challengeId: number;
  winnerCarId: number;
  winnerTimeId: number;
  loserTimeId: number;
  time: number;
  speed: number;
  reaction: number;
  images: any[];
};

export const ChallengeResultsDialog = (props: any) => {
  //console.log('ChallengeResultsDialog props:', props);
  const { open, challenge, currentUser, onClose } = props;
  const [state, setState] = useState<IChallengeResultsDialogState>({
    challengeId: challenge?.id,
    winnerCarId: 0,
    winnerTimeId: 0,
    loserTimeId: 0,
    time: 0,
    speed: 0,
    reaction: 0,
    images: [],
  });
  const { enqueueSnackbar } = useSnackbar();
  const folderName = `challenges/${challenge?.id}`;

  const handleChange = (event: any) => {
    //console.log('handleChange:', event);
    const { target: { name, value, type, checked } } = event;
    const val = type === 'checkbox'
      ? checked
      : value;
    setState({...state, [name]: val});
  };

  const handleSubmitResults = () => {
    if (state.winnerCarId === 0) {
      enqueueSnackbar(`No winning car selected!`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      console.error('no winning car selected');
      return;
    }

    const result = window.confirm(`You are about to submit the winning results of the racing challenge against '${challenge?.challenger?.username}', are you sure you want to do this?`);
    if (!result) {
      return;
    }

    ChallengeService.submitChallengeResults(challenge?.id, state).then(async (response: any) => {
      console.log('submitChallengeResults response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to submit challenge results ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        return;
      }
      
      createNotification(currentUser.id, challenge?.challengerUserId, `${currentUser?.username} has submitted winning results for challenge ${challenge?.id}`);
      if (state.images.length > 0) {
        await ImageService.uploadImages(state.images, folderName);
      }
      onClose && onClose();
      //handleReload();
    });
  };

  const handlePreviewImages = (images: any[]) => {
    const newImages = [...state.images];
    for (const file of images) {
      const objectUrl = URL.createObjectURL(file.file);
      newImages.push({...file, url: objectUrl});
    }
    setState({...state, images: newImages});
  };

  const handleDeleteImage = (fileName: string) => {
    const images = state.images?.filter((img: any) => img.url !== fileName);
    setState({...state, images});
  };

  const handleClearImages = () => {
    const result = window.confirm(`Are you sure you want to clear your selection of images to upload?`);
    if (!result) {
      return;
    }
    setState({...state, images: []});
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography
            variant="h6"
            component="h3"
            className="text-center"
            sx={{ ml: 2, flex: 1 }}
          >
            Submit Race Challenge Results
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleSubmitResults}
            >
            Submit
          </Button>
          &nbsp;
          <IconButton color="inherit" onClick={onClose} title="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} className="mb-3">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom className="text-center">
              Winning Car
            </Typography>
            <CarSelector
              title="Winner"
              name="winnerCarId"
              value={state.winnerCarId}
              onChange={handleChange}
              cars={[challenge?.challengerCar, challenge?.challengeeCar]}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              margin="dense"
              id="time"
              name="time"
              label="Time (sec)"
              type="number"
              fullWidth
              value={state.time}
              variant="standard"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              margin="dense"
              id="speed"
              name="speed"
              label="Top Speed (MPH)"
              type="number"
              fullWidth
              value={state.speed}
              variant="standard"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              margin="dense"
              id="reaction"
              name="reaction"
              label="Reaction Time (sec)"
              type="number"
              fullWidth
              value={state.reaction}
              variant="standard"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom className="text-center">
              Images & Videos
            </Typography>
            <UploadImageGallery
              onPreviewImages={handlePreviewImages}
              //onUploadImages={handleUploadImages}
              onDeleteImage={handleDeleteImage}
              onClearImages={handleClearImages}
              images={state.images}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};