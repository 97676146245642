import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import {
  BlockUserButton,
  CarsLabelDialog,
  ChallengeUserButton,
  FollowUserButton,
  FollowersLabelDialog,
  FollowingLabelDialog,
  MessageUserButton,
  ReportUserButton,
  SocialFollow,
  UserStoryCircle,
} from '..';
import { CreateStoryDialog, ViewStoryDialog } from '../Dialogs';
import { withRouter } from '../../hooks';
import { StoryService } from '../../services';

const useStyles: any = (theme: Theme) => ({
  root: {
    borderRadius: 20,
    border: '1px solid black',
    //maxWidth: 512,
    //width: 256,
    textAlign: 'center',
  },
  //avatar: {
  //  width: 60,
  //  height: 60,
  //  margin: 'auto',
  //},
  avatar: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButtonsContainer: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: 'grey',
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: 'grey',
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
  anchorLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  socialFollow: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    backgroundColor: 'white',
  },
});

const ProfileCard = (props: any) => {
  //console.log('ProfileCard props:', props);
  const { user, profile, onReload } = props;
  const [state, setState] = useState({
    openView: false,
    openNew: false,
    selectedStory: null,
  });
  const theme = useTheme();
  const styles = useStyles(theme);
  const isCurrentUser = profile?.id === user?.id;
  const { enqueueSnackbar } = useSnackbar();

  const handleReload = () => handleOpenView(profile?.id, false);
  const handleOpenView = (userId: number, open: boolean = true) => {
    StoryService.getStory(userId).then((response: any) => {
      //console.log('getStory response:', response);
      if (response.status !== 200) {
        enqueueSnackbar(`Unable to open story ${response.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        console.error('getStory:', response);
        return;
      }
      if (!open) {
        setState({...state, selectedStory: response.story});
      } else {
        setState({...state, openView: true, selectedStory: response.story});
      }
    });
  };
  const handleCloseView = () => setState({...state, openView: false});

  const handleOpenNew = () => setState({...state, openNew: true});
  const handleCloseNew = () => setState({...state, openNew: false});

  return (
    <Card style={styles.root}>
      <CardContent>
        <div style={styles.avatar}>
          <UserStoryCircle
            key={profile?.id}
            currentUser={user}
            follower={{...profile, userId: profile?.id}}
            showUsername={false}
            showAddButton={isCurrentUser}
            onAdd={handleOpenNew}
          />
        </div>
        <h3 style={styles.heading}>{profile?.username}</h3>
        <h5 style={styles.subheader}>{profile?.firstName} {profile?.lastName}</h5>
        {profile?.company && (
          <>
            <span style={styles.subheader}>
              <a
                href={profile?.company}
                target="_blank"
                rel="noreferrer"
                style={styles.anchorLink}
              >
                {profile?.company}
              </a>
            </span>
            <br />
          </>
        )}
        {profile?.website && (
          <>
            <span style={styles.subheader}>
              <a
                href={profile?.website}
                target="_blank"
                rel="noreferrer"
                style={styles.anchorLink}
              >
                {profile?.website}
              </a>
            </span>
            <br />
          </>
        )}
        {profile?.country && (
          <span style={styles.subheader}>{profile?.country}</span>
        )}
      </CardContent>
      {!isCurrentUser && (
        <div style={styles.actionButtonsContainer}>
          <FollowUserButton
            isCurrentUser={isCurrentUser}
            isDisabled={!user}
            currentUser={user}
            profile={profile}
            onReload={onReload}
          />
          <BlockUserButton
            isCurrentUser={isCurrentUser}
            isDisabled={!user}
            currentUser={user}
            profile={profile}
            onReload={onReload}
          />
          <MessageUserButton
            isCurrentUser={isCurrentUser}
            isDisabled={!user}
            currentUser={user}
            profile={profile}
            onReload={onReload}
          />
          <ChallengeUserButton
            isCurrentUser={isCurrentUser}
            isDisabled={!user}
            currentUser={user}
            profile={profile}
            onReload={onReload}
          />
          <ReportUserButton
            isCurrentUser={isCurrentUser}
            isDisabled={!user}
            currentUser={user}
            profile={user}
            onReload={onReload}
          />
        </div>
      )}
      {profile?.social && (
        <div style={styles.socialFollow}>
          <SocialFollow social={profile?.social} />
        </div>
      )}
      <Divider style={styles.divider} />
      <Box display={'flex'}>
        <FollowersLabelDialog
          currentUser={user}
          profile={profile}
          label="Followers"
          value={profile?.relationships?.followers?.length ?? 0}
        />
        <FollowingLabelDialog
          currentUser={user}
          profile={profile}
          label="Following"
          value={profile?.relationships?.following?.length ?? 0}
        />
      </Box>
      <Divider style={styles.divider} />
      <Box display={'flex'}>
        <Box p={1} flex={'auto'}>
          <p style={styles.statLabel}>Views</p>
          <p style={styles.statValue}>1,337</p>
        </Box>
        <Box p={1} flex={'auto'}>
          <p style={styles.statLabel}>Posts</p>
          <p style={styles.statValue}>{(profile?.postCount ?? 0).toLocaleString()}</p>
        </Box>
        <Box p={1} flex={'auto'}>
          <p style={styles.statLabel}>Impressions</p>
          <p style={styles.statValue}>369</p>
        </Box>
      </Box>
      <Divider style={styles.divider} />
      <Box display={'flex'}>
        <CarsLabelDialog
          currentUser={user}
          profile={profile}
          label="Cars"
          value={profile?.cars?.length ?? 0}
        />
        <Box p={1} flex={'auto'}>
          <p style={styles.statLabel}>Challenges</p>
          <p style={styles.statValue}>3</p>
        </Box>
        <Box p={1} flex={'auto'}>
          <p style={styles.statLabel}>Races</p>
          <p style={styles.statValue}>2</p>
        </Box>
      </Box>
      {profile?.description && (
        <>
          <Divider style={styles.divider} />
          <Box display={'flex'}>
            <Box p={2} flex={'auto'}>
              <p style={styles.statLabel} className="mb-1">About</p>
              <p style={{...styles.statValue, fontSize: 12, fontWeight: 500, marginBottom: 4}}>{profile?.description}</p>
            </Box>
          </Box>
        </>
      )}

      <ViewStoryDialog
        open={state.openView}
        onReload={handleReload}
        onClose={handleCloseView}
        stories={state.selectedStory ?? []}
        currentUser={user}
      />

      <CreateStoryDialog
        user={user}
        open={state.openNew}
        onClose={handleCloseNew}
      />
    </Card>
  );
};

export default withRouter(ProfileCard);