import {
  Button,
  List,
  Paper,
  Popover,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { NotifyItem } from '.';
import { Routes } from '../../consts';
import { Notification } from '../../types';

const useStyles: any = (theme: Theme, isPopover: boolean = false) => ({
  root: {
    //maxWidth: 360,
    maxHeight: isPopover ? 380 : '75vh',
    display: isPopover ? 'block' : 'flex',
    marginTop: isPopover ? 0 : 20,
  },
  noNotify: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  list: {
    maxHeight: isPopover ? 380 : '75vh',    
    overflowY: 'auto',
    width: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  button: {
    margin: 5,
  },
});

interface INotifyProps {
  open: boolean;
  anchorEl?: any;
  notifications: Notification[];
  onClose?: any;
  onSeen?: any;
  onDelete?: any;
  onClear?: any;
  usePopover: boolean;
  buttonsPosition: NotifyButtonsPosition;
  showViewAllButton: boolean;
};

export enum NotifyButtonsPosition {
  Top = 0,
  Bottom = 1,
};

export const Notify = (props: INotifyProps) => {
  //console.log('Notify props:', props);
  const {
    open = false, anchorEl = null, notifications = [],
    onClose, onSeen, onDelete, onClear,
    usePopover = false, buttonsPosition = NotifyButtonsPosition.Top,
    showViewAllButton = false,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme, usePopover);
 
  const notifyItemList = () => {
    const parsedDOM: any[] = [];
    if (notifications) {
      notifications.forEach((notification: any) => {
        parsedDOM.push(
          <NotifyItem
            key={notification.id}
            notification={notification}
            url={notification.url}
            onSeen={onSeen}
            onDelete={onDelete}
            onClose={onClose}
          />
        );
      });
    }    
    return parsedDOM;
  };

  const NotifyPopover = (props: any) => (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ className: classes?.paper }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {props.children}
    </Popover>
  );

  const NotifyButtons = () => (
    <>
      {showViewAllButton && (
        <Button
          variant="contained"
          style={classes.button}
          className="float-end"
          size="small"
          onClick={(event: any) => window.location.href = Routes.account.notifications}
        >
          View All
        </Button>
      )}
      <Button
        variant="contained"
        style={classes.button}
        className="float-end"
        size="small"
        color="error"
        onClick={(event: any) => {
          const result = window.confirm(`Are you sure you want to clear all ${notifications.length} of your notifications?`);
          if (!result) {
            return;
          }
          onClear && onClear(event);
        }}
      >
        Clear All
      </Button>
    </>
  );

  const NotifyList = () => (
    <>
      {buttonsPosition === NotifyButtonsPosition.Top && <NotifyButtons />}
      {!usePopover && <br />}
      <Paper elevation={4} style={classes.root}>
        {items.length > 0 ? (
          <List style={classes.list} className="scrollbar">
            {items}
          </List>
        ) : (
          <div style={classes.noNotify}>
            All caught up!
          </div>
        )}
      </Paper>
      {buttonsPosition === NotifyButtonsPosition.Bottom && <NotifyButtons />}
    </>
  );

  const items = notifyItemList();

  return usePopover ? (
    <NotifyPopover>
      <NotifyList />
    </NotifyPopover>
  ) : (
    <NotifyList />
  );
};