import { useEffect, useState } from 'react';

const getIsMobile = (maxWidth: number = 768) => window.innerWidth <= maxWidth;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => setIsMobile(getIsMobile());
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  
  return isMobile;
};