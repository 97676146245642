import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Grid } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { addMinutes, differenceInMinutes, format } from 'date-fns';

import {
  CalendarContext,
  CalendarEventsContainer,
  CreateEditEvent,
  CurrentTimeMark,
  EventMark,
  getTimePosition,
  LineDivisor,
} from '.';
import { CalendarService } from '../../services';
import { CalendarEvent } from '../../types';

const useStyles: any = ((theme: Theme) => ({
  board: {
    minWidth: '100%',
    height: '100%',
    flex: 'none',
    verticalAlign: 'top',
    overflow: 'hidden',
    position: 'relative',
  },
  columnDivisor: {
    height: '100%',
    paddingLeft: 8,
    borderRight: '1px solid grey',
  },
  dayContainer: {
    borderRight: '1px solid grey',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 12,
    width: '100%',
  },
  timeHourLine: {
    position: 'absolute',
    borderTop: '1px solid grey',
    left: 0,
    right: 0,
  },
}));

export const CalendarBoard = (props: any) => {
  //console.log('CalendarBoard props:', props);
  const { selectedWeekIndex, selectedWeek } = props;

  const { stateCalendar, setStateCalendar } = useContext(CalendarContext);
  const { selectedDate, layout, defaultEventDuration, draggingEventId } = stateCalendar;
  const [currentTimePosition, setCurrentTimePosition] = useState();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const theme = useTheme();
  const classes = useStyles(theme);

  const getEvents = () => {
    CalendarService.getEvents().then((response: any) => {
      //console.log('getEvents response:', response);
      if (response.status !== 200) {
        // TODO: Show error notification
        return;
      }
      setEvents(response.events);
    });
  };

  const updateEvent = (event: CalendarEvent) => {
    CalendarService.updateEvent(event).then((response: any) => {
      //console.log('updateEvent response:', response);
      if (response.status !== 200) {
        // TODO: Show error notification
        return;
      }
      const result = response.event;
      const eventMarkers = [
        ...events.filter((markEvent: CalendarEvent) => markEvent.id !== result.id),
        result,
      ];
      setEvents(eventMarkers);
    });
  };

  useEffect(() => {
    setInterval(() => {
      const position = getTimePosition(new Date());
      setCurrentTimePosition(position);
    }, 1000);
  }, []);

  const viewLayout = Array.from(Array(layout === 'week' ? 7 : layout === 'day' ? 1 : 0).keys());

  const getEventData = (day: Date) => {
    const monthEvents = (events &&
      events.sort((a: CalendarEvent, b: CalendarEvent) => {
        return new Date(a.begin).getTime() - new Date(b.begin).getTime();
      })) || [];

    const dayEvents = monthEvents.filter((event: CalendarEvent) =>
      format(new Date(event.begin), 'yyyyMMdd') === format(day, 'yyyyMMdd'));

    const dayHoursEvents = dayEvents
      .map((event: CalendarEvent) => new Date(event.begin).getHours())
      .sort((numberA: number, numberB: number) => numberA - numberB);

    const eventsByHour = dayHoursEvents.reduce((acc: any[], hour: number) => {
      const len = dayHoursEvents.filter((eventHour: number) => eventHour === hour).length;
      !acc.some((accItem: any) => accItem.hour === hour) && acc.push({ hour, len });
      return acc;
    }, []);

    const markers = eventsByHour.map((evHour: any) => {
      return dayEvents
        .filter((event: CalendarEvent) => new Date(event.begin).getHours() === evHour.hour)
        .map((event: CalendarEvent, index: number) => (
          <EventMark
            key={`event-${event.id}`}
            calendarEvent={event}
            seq={index}
            //len={evHour.len}
            len={dayEvents.length}
            layout={layout}
          />
        ));
    });
    return markers;
  };

  const onDrop = (event: any) => {
    const eventID = draggingEventId;
    const eventMarkGhost: any = document.querySelector('[data-ghost]');
    if (!eventMarkGhost) {
      return false;
    }

    const eventBeginDate = new Date(eventMarkGhost.dataset.date);
    if (!eventBeginDate) {
      return;
    }

    const draggedEvent = events.find((markEvent: CalendarEvent) => markEvent.id === eventID);
    const duration = differenceInMinutes(new Date(draggedEvent!.end), new Date(draggedEvent!.begin));

    const marker: CalendarEvent = {
      ...draggedEvent!,
      begin: new Date(format(eventBeginDate, 'yyyy/MM/dd HH:mm')),
      end: new Date(format(addMinutes(eventBeginDate, duration), 'yyyy/MM/dd HH:mm')),
    };

    updateEvent(marker);
    setStateCalendar({ ...stateCalendar, draggingEventId: -1 });
  };

  const [, drop] = useDrop({
    accept: 'box',
    drop(item: any, monitor: any) {
      return undefined;
    },
  });

  const viewLayoutEl = useMemo(() => {
    return viewLayout.map(index => {
      const day = layout === 'week' ? selectedWeek[index] : selectedDate;
      const isToday = format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy');
      const eventsOfDay = getEventData(day);

      return (
        <Grid
          item
          xs
          key={`board-day-column-${layout}-${selectedWeekIndex}-${day}-${index}`}
          id={`day-${index + 1}`}
          data-group='day-column'
          data-date={day}
          style={classes.dayContainer}
          onClick={(eventEl: any) =>
            CreateEditEvent({
              eventEl,
              defaultEventDuration,
              stateCalendar,
              setStateCalendar,
            })
          }
        >
          {Array.from(Array(23).keys()).map(hour => (
            <hr
              key={hour}
              style={{
                ...classes.timeHourLine,
                left: index === 0 ? -15 : 0,
                marginTop: getTimePosition(new Date(`12/12/2012 ${hour + 1}:00`)),
              }}
            />
          ))}
          {isToday && <CurrentTimeMark marginTop={currentTimePosition} />}
          {eventsOfDay && eventsOfDay.length > 0 && (
            <CalendarEventsContainer
              eventsOfDay={eventsOfDay}
              data-date={day}
            />
          )}
        </Grid>
      );
    });
  }, [
    classes,
    defaultEventDuration,
    getEventData,
    layout,
    selectedDate,
    selectedWeek,
    selectedWeekIndex,
    viewLayout,
    events,
    currentTimePosition,
    stateCalendar,
    setStateCalendar,
  ]);

  // eslint-disable-next-line
  useEffect(() => getEvents(), []);

  return (
    <Grid
      ref={drop}
      onDrop={onDrop}
      container
      spacing={0}
      direction='row'
      justifyContent='center'
      style={classes.board}
    >
      <LineDivisor />
      <div style={classes.columnDivisor} />
      {viewLayoutEl}
    </Grid>
  );
};