import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

export const NavbarBrandHeader = (props: any) => {
  //console.log('NavbarBrandHeader props:', props);
  const {
    title,
    showTitle = true,
    titleFontSize = 20,
    showLogo = true,
    logoSize = 48,
    logoPath = '/logo512.png',
    homeUrl = '/',
    ...rest 
  } = props;

  return (
    <div
      style={{
        //display: 'flex',
        position: 'relative',
        flexGrow: 1,
        right: 0,
        //paddingLeft: 0,
        //paddingRight: 0,
        //marginLeft: 0,
        //marginRight: 0,
        ...rest,
      }}
    >
      <NavbarBrand
        to={homeUrl}
        tag={Link}
        className="text-nowrap"
        style={{
          alignContent: 'center',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        {showLogo && (
          <img
            alt="logo"
            src={logoPath ?? "/logo512.png"}
            style={{
              fontSize: titleFontSize ?? 20,
              height: logoSize ?? 48,
              width: 'auto',
              marginLeft: 'auto',
              textShadow: '0 .15rem .1rem rgba(0, 0, 0, .5)',
              //boxShadow: 'inset 0 0 5rem rgba(0, 0, 0, .5)',
            }}
          />
        )}
        {showTitle && (
          <span
            //className="brand-title"
            style={{
              fontSize: titleFontSize ?? 26,
              //height: 64,
              //paddingLeft: '15px',
              marginLeft: '5px',
              //marginTop: '55px',
              //marginBottom: 'auto',
              textShadow: '0 .15rem .1rem rgba(0, 0, 0, .5)',
            }}
          >
            &nbsp;{title}
          </span>
        )}
      </NavbarBrand>
    </div>
  );
};