import React, { useState } from 'react';
import env from 'react-dotenv';
import {
  Alert,
  AlertTitle,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Adsense } from '@ctrl/react-adsense';

import {
  HomePageSidebar,
  NavbarBrandHeader,
  NewsFeed,
  StoryBar,
} from '../components';
import { get, set } from '../modules';
import { LoginPage } from '../pages';
import { getUserToken } from '../stores';

const HomePage = (props: any) => {
  const currentUser = getUserToken();
  const showNewPost = !!currentUser;
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      {currentUser ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StoryBar currentUser={currentUser} />
          </Grid>
          <Grid item xs={12}>
            <NewsFeed
              isUserFeed={true}
              showNewPost={showNewPost}
              currentUser={currentUser}
            />
          </Grid>
        </Grid>
      ) : (
        <div style={{maxWidth: isSmall ? 'calc(100vw - 1em)' : '100vw'}}>
          <div className="text-center" style={{justifyContent: 'center', marginBottom: '25px'}}>
            <NavbarBrandHeader
              title={env.SITE_TITLE}
              titleFontSize={isSmall ? 24 : 42}
              logoSize={isSmall ? 32 : 84}
            />
            <hr />
          </div>
          <p className="text-center mb-3">
            Welcome to {env.SITE_TITLE}, the social media platform for car enthusiests!<br />
            Showcase your car builds, keep track of run times from the track,
            challenge other drivers, meet new people, reconnect with familiar faces and more!
          </p>

          <p className="text-center mb-3">
            New to the site? <a href="/register" rel="noreferer" className="instagram-tag"><u>Click here</u></a> to create an account!
            Otherwise login below if you already have one.
          </p>

          <UnderConstructionNotice />

          <img
            src='/images/cars/gas-station.jpg'
            alt=''
            style={{
              maxHeight: 500,
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
            }}
          />
          <hr />

          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <LoginPage />
            </Grid>
            <Grid item md={4} xs={12}>
              <HomePageSidebar />
            </Grid>
          </Grid>
        </div>
      )}
      {!!env.GOOGLE_ADSENSE && !!env.GOOGLE_ADSENSE_SLOT && (
        <Adsense
          client={env.GOOGLE_ADSENSE}
          slot={env.GOOGLE_ADSENSE_SLOT}
        />
      )}
    </>
  );
};

const UnderConstructionNotice = () => {
  const showNotice = get('showUnderConstructionNotice', true);
  const [show, setShow] = useState(showNotice);

  const handleClose = () => {
    set('showUnderConstructionNotice', false);
    setShow(false);
  };

  return show ? (
    <Alert severity="info" className="mb-3" onClose={handleClose}>
      <AlertTitle>Notice</AlertTitle>
      Please bear with us as the website is still being developed. Some features may
      either not work or be buggy until eveything is polished. Please use the feedback
      tool to your left in the sidebar to report bugs and errors.
    </Alert>
  ) : null;
};

export default HomePage;