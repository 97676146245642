import React, { useState } from 'react';
import {
  Button,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ChangeEmailPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const handleSubmit = (event: any) => {
    event.preventDefault();

    // TODO: Show toast about email sent
    setTimeout(() => navigate('/login', { replace: true }), 2000);
  };

  return (
    <Paper
      className="bg-dark text-light"
      elevation={6}
      style={{
        //marginTop: '16px',
        position: 'relative',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '15px',
      }}
    >
      <Typography component="h1" variant="h5">
        Change Email Address
      </Typography>
      <form
        //style={{ margin: theme.spacing(2) }}
        onSubmit={handleSubmit}
        noValidate
      >
        <TextField
          value={username}
          onInput={(e: any) => setUsername(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="New Email Address"
          name="email"
          autoComplete="email"
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          //style={{ margin: theme.spacing(3, 0, 2) }}
        >
          Update Email
        </Button>
      </form>
    </Paper>
  );
};